import { oc } from 'ts-optchain';

import { Product } from '../models/Product';
import { UserProfile } from '../models/User';
import { environment } from '../../environments/environment';

export const isProductVisibleForUser = (product: Product, user?: UserProfile) => {
    if (oc(user).roles.b2b() && !environment.config.showB2BAllCategories && product.type === 'CATEGORY') {
        return !!product.segments && oc(user).segments([]).some(s => oc(product).segments([]).includes(s));
    }

    return !product.segments || oc(user).segments([]).some(s => oc(product).segments([]).includes(s));
};
