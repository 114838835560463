import { Drawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import cn from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

interface Props extends DrawerProps {
    children?: ReactNode;
    type?: 'flex' | 'dropdownMirror' | 'default';
}

const EnhancedDrawer: FunctionComponent<Props> = ({ type = 'default', ...bag }) => (
    <Drawer
        className={cn({
            'ant-drawer-flex': type === 'flex',
            'ant-drawer-dropdown-mirror': type === 'dropdownMirror',
        })}
        {...bag}
    />
);

export default EnhancedDrawer;
