import { Breadcrumb } from 'antd';
import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

const separator = '>';

export interface Route {
    path: string;
    breadcrumbName: string;
    disabled?: boolean;
}

interface OwnProps {
    routes: Route[];
}

type Props = RouteComponentProps & OwnProps;

const BreadCrumb: FunctionComponent<Props> = ({ routes: items, match, ...bag }) => {
    function itemRender(route: Route, params: string[], routes: Route[], paths: string[]) {
        const lastSlash = match.path.lastIndexOf('/');
        const relativePath = match.path.substring(lastSlash + 1);
        return (
            <NavLink
                className={cn({ active: relativePath === route.path })}
                to={`${route.path}`}
                style={{
                    pointerEvents: route.disabled ? 'none' : 'auto',
                    cursor: route.disabled ? 'not-allowed' : 'pointer',
                    opacity: route.disabled ? 0.5 : 1,
                }}
            >
                {route.breadcrumbName}
            </NavLink>
        );
    }

    return <Breadcrumb itemRender={itemRender} separator={separator} {...bag} routes={items} />;
};

export default withRouter(BreadCrumb);
