import { message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { oc } from 'ts-optchain';

import { transactionsApi } from '../../../../common/api/transaction';
import { AppState } from '../../../../common/models/AppState';
import { ProductSelection } from '../../../../common/models/Cart';
import { i18n, LANGUAGES } from '../../../../common/services/i18n';
import {
    createActionCreator,
    createActionType,
    createApiActionCreators,
    createReducer,
    RequestActionTypes,
} from '../../../../common/utils/redux-helpers';
import { selectUserProfile, } from '../../../user/ducks';
import { getValidTransactionItems, selectEshopConfig, selectShopping, selectTransactionVouchers } from '../../ducks';
import { selectCartItems, validateTransactionActions } from '../../ducks/cart';
import { selectAsyncRegisteredId, selectRecipientEmail, setAsyncUserIdAction } from '../../ducks/order';
import { delay } from 'redux-saga';
import { Config } from '../../../../common/models/Config';
import { B2BConfirmationPollingActionTypes } from '../b2b-confirmation/ducks';
import { UserProfile } from '../../../../common/models/User';
import {
    B2BPaymentType,
    Transaction,
    TransactionDataRequest,
    TransactionStatus
} from '../../../../common/models/NewTransaction';
import { history } from '../../../../root/store/history';
import { get } from 'lodash';
import { ConfirmationPollingActionTypes } from '../confirmation/ducks';

// Action types
export enum CheckoutActionTypes {
    SubmitOrder = '@@Checkout/SUBMIT_ORDER',
    SetSubmittingPayment = '@@Checkout/SUBMIT_PAYMENT_PROCESSING',
}

// Actions
export const submitOrderActions = createApiActionCreators(CheckoutActionTypes.SubmitOrder);
export const setSubmittingPaymentAction = createActionCreator(
    CheckoutActionTypes.SetSubmittingPayment
);

// Reducers
export interface CheckoutState {
    transaction: Transaction | null;
    submitting: boolean;
}

const initialState: CheckoutState = {
    transaction: null,
    submitting: false,
};

export const checkout = createReducer(initialState, {
    [CheckoutActionTypes.SubmitOrder]: {
        [RequestActionTypes.SUCCESS]: (state: CheckoutState, payload: Transaction) => ({
            ...state,
            transaction: payload,
        }),
    },
    [CheckoutActionTypes.SetSubmittingPayment]: (state: CheckoutState, payload: boolean) => ({
        ...state,
        submitting: payload,
    }),
    [ConfirmationPollingActionTypes.Start]: (state: CheckoutState) => ({
        ...state,
        transaction: null,
        submitting: false,
    }),
    [B2BConfirmationPollingActionTypes.Start]: (state: CheckoutState) => ({
        ...state,
        transaction: null,
        submitting: false,
    }),
});

// Selectors
export function selectCheckout(state: AppState) {
    return selectShopping(state).checkout;
}

export function selectCheckoutSubmitting(state: AppState) {
    return selectCheckout(state).submitting;
}

function* submitOrder(b2bType?: B2BPaymentType) {
    const { cartItems, changed }: { cartItems: ProductSelection[], changed: boolean } = yield call(getValidTransactionItems);

    if (changed) {
        return undefined;
    }

    const user: UserProfile | null = yield select(selectUserProfile);
    const asyncUserId = yield select(selectAsyncRegisteredId);
    const recipientEmail = yield select(selectRecipientEmail);
    const config: Config | null = yield select(selectEshopConfig);
    const vouchers: string[] = yield select(selectTransactionVouchers);

    const additionalData: TransactionDataRequest = {
        owner: oc(user).id() || asyncUserId || undefined,
        salesPoint: oc(user).roles.b2b() ? 'b2b' : 'eshop',
        paidWith: b2bType || 'card',
        meta: {
            email: recipientEmail,
            additionalVOP: oc(config).additionalVOP() || undefined,
            lang: i18n.language,
        }
    };

    const resp = yield call(
        transactionsApi.validate,
        cartItems,
        vouchers,
        additionalData,
    );

    if (resp.ok) {
        yield put(submitOrderActions.success(resp.data));
        yield put(setAsyncUserIdAction());

        return resp.data as Transaction & { skipPayment: boolean };
    }

    const errors = get(resp, 'response.data.errors', []);

    if (errors.includes('timeslotError')) {
        message.error(i18n.t('timeSlots.transaction.failed'), 10);
        yield put(validateTransactionActions.request());
    } else if (errors.includes('invalid-segment')) {
        message.error(i18n.t('transaction.error.invalidSegment'), 10);
        yield put(validateTransactionActions.request());
    } else if (errors.includes('missing-method')) {
        message.error(i18n.t('transaction.error.missingMethod'), 10);
        yield put(validateTransactionActions.request());
    } else if (errors.includes('unsupported-payment-type')) {
        message.error(i18n.t('transaction.error.unsupportedPaymentType'), 10);
        window.location.reload();
        yield put(validateTransactionActions.request());
    } else {
        message.error(i18n.t('unableToSubmit'), 5);
    }

    return undefined;
}

function* submitPayment(transactionId: string, successUrl: string, errorUrl: string) {
    const resp = yield call(
        transactionsApi.transactionPayment,
        transactionId,
        successUrl,
        errorUrl,
        LANGUAGES[i18n.language].momentLocale
    );

    if (resp.ok && resp.data.url) {
        message.info(i18n.t('payment.redirecting'));
        window.location.href = resp.data.url;
    } else {
        message.error(i18n.t('payment.error'));
    }

    yield delay(2000);
    yield put(setSubmittingPaymentAction(false));
}

// Sagas
function* watchSubmitOrder({ payload: invoicePayment }: any) {
    yield put(setSubmittingPaymentAction(true));

    const transaction: Transaction & { skipPayment: boolean } | undefined = yield call(submitOrder, invoicePayment);

    if (!transaction || !transaction.uuid) {
        yield put(setSubmittingPaymentAction(false));
        return;
    }

    if (transaction.status === TransactionStatus.unpaid) {
        message.info(i18n.t('payment.b2b.done'));
        history.replace(`/shopping/b2b-confirmation?transaction=${transaction.uuid}`);
        return;
    }

    if (transaction.skipPayment) {
        message.info(i18n.t('payment.notRequired'));

        if (transaction.salesPoint === 'b2b') {
            history.replace(`/shopping/b2b-confirmation?transaction=${transaction.uuid}`);
        } else {
            history.replace(`/shopping/confirmation?transaction=${transaction.uuid}`);
        }

        return;
    }

    yield call(
        submitPayment,
        transaction.uuid,
        transaction.salesPoint === 'b2b'
            ? `${window.location.origin}/shopping/b2b-confirmation`
            : `${window.location.origin}/shopping/confirmation`,
        `${window.location.origin}/shopping/checkout?error=true`
    );
}

export function* checkoutSaga() {
    yield takeLatest(
        createActionType(CheckoutActionTypes.SubmitOrder, RequestActionTypes.REQUEST),
        watchSubmitOrder
    );
}
