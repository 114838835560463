import { EntryMethod } from '../../../../models/NewTransaction';
import { ProductAssignerLabel } from '../index';
import { Icon } from 'antd';
import { i18n } from '../../../../services/i18n';
import React from 'react';
import { Token } from '../../../../models/Token';
import { environment } from '../../../../../environments/environment';
import { SelectOption } from '../../../Form/Select';

export const isCardSameType = (tags: string[], card: Token) => {
    if (environment.config.allowAdultExternal && card.meta.subType === 'external') {
        return tags.includes('adult');
    }

    return tags.includes(card.meta.subType);
};

export const METHOD_MAP = {
    [EntryMethod.Print]: 'for_print',
    [EntryMethod.Prepaid]: 'for_exchange',
    [EntryMethod.Exchange]: 'for_exchange_only',
    [EntryMethod.Card]: 'for_cards',
};

export const getExchangeOption = (): SelectOption => ({
    value: EntryMethod.Exchange,
    label: (
        <ProductAssignerLabel
            icon={<Icon type="sync" />}
            content={i18n.t('productAssigment.options.onlyExchange')}
        />
    ),
});

export const getOptions = (): SelectOption[] => [
    getExchangeOption(),
    {
        value: EntryMethod.Print,
        label: (
            <ProductAssignerLabel
                icon={<Icon type="mail" />}
                content={i18n.t('productAssigment.options.send')}
            />
        ),
    },
    {
        value: EntryMethod.Card,
        label: (
            <ProductAssignerLabel
                icon={<Icon type="plus" />}
                content={i18n.t('productAssigment.options.new')}
            />
        ),
    },
    {
        value: EntryMethod.Prepaid,
        label: (
            <ProductAssignerLabel
                icon={<Icon type="sync" />}
                content={i18n.t('productAssigment.options.dontHave')}
            />
        ),
    },
];
