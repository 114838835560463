import React from 'react';
import EnhancedModal from '../Modal';
import { ModalSizes } from '../../constants/modal';
import { i18n } from '../../services/i18n';
import { Button } from '../index';

interface Props {
    headerText: string;
    bodyText: string;
    buttonText: string;
    visible: boolean;
    onCancel: () => void;
}

export default function InfoModal(props: Props) {
    return (
        <React.Fragment>
            <EnhancedModal
                style={{
                    top: '25px',
                }}
                visible={props.visible}
                footer={null}
                width={ModalSizes.default}
                closable={false}
                onCancel={props.onCancel}
            >
                <div className="modal-container email-modal" id={'modal'}>
                    <h3> {props.headerText} </h3>
                    <p> {props.bodyText} </p>
                    <Button onClick={props.onCancel}>{props.buttonText}</Button>
                </div>
            </EnhancedModal>
        </React.Fragment>
    );
}
