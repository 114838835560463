import moment from 'moment-timezone';
import { oc } from 'ts-optchain';

import { DateRange, Product } from '../models/Product';
import { environment } from '../../environments/environment';

export const findNextEnabledDate = (enabledCheckFunction: (date) => boolean, maxDate) => {

    const d = moment();

    while (enabledCheckFunction(d) && d.isSameOrBefore(maxDate, 'day')) {
        d.add(1, 'day');
    }

    if (!d.isSameOrBefore(maxDate, 'day')) {
        return undefined;
    }

    return d;
};

export const isProductAndAnyVariantVisible = (p: Product): boolean => {

    return isProductVisible(p) && oc(p).variants([]).some(isProductVisible);
};

export const isProductVisible = (p: Product): boolean => {

    if (
        !p.visibility ||
        moment().isBefore(moment(p.visibility.from)) ||
        moment().isAfter(moment(p.visibility.to))
    ) {
        return false;
    }

    for (const e of oc(p).visibilityOptions.except([])) {

        if (moment().isBetween(moment(e.from), moment(e.to))) {
            return false;
        }
    }

    const days = oc(p).visibilityOptions.dayDOW([]);
    const weekday = moment().tz(environment.config.timeZone).isoWeekday() % 7;

    if (!days.includes(weekday)) {
        return false;
    }

    if (`${oc(p).visibilityOptions.dayTime.from('')}`.includes(':')) {
        const [fHour, fMinute] = oc(p).visibilityOptions.dayTime.from('').split(':');
        const [tHour, tMinute] = oc(p).visibilityOptions.dayTime.to('').split(':');
        const from = moment().tz(environment.config.timeZone).set('hour', Number(fHour)).set('minute', Number(fMinute));
        const to = moment().tz(environment.config.timeZone).set('hour', Number(tHour)).set('minute', Number(tMinute));

        return days.includes(weekday) &&
            moment().tz(environment.config.timeZone).isBetween(from, to, 'minute', '[]');
    }

    return true;
};

export const isProductAndSelectionValidAtDate = (p: Product | undefined, selection: string[], selectedDate) => {

    return isProductValidAtDate(p, selectedDate) &&
        selection.every(e => isProductValidAtDate(oc(p).variants([]).find(v => v._id === e), selectedDate));
};

export const isProductAndAnyVariantValidAtDate = (p: Product | undefined, selectedDate) => {

    return isProductValidAtDate(p, selectedDate) && oc(p).variants([]).some(v => isProductValidAtDate(v, selectedDate));
};

export const isProductValidAtDate = (p: Product | undefined, selectedDate): boolean => {

    const date = moment(selectedDate).startOf('day').tz('UTC', true).format();

    if (
        !p ||
        !p.validity ||
        moment(date).isBefore(moment(p.validity.from)) ||
        moment(date).isAfter(moment(p.validity.to))
    ) {
        return false;
    }

    if (
        p.dynamicValidity &&
        (
            moment(date).isBefore(moment().add(p.dynamicValidity.from, 'days').startOf('day').tz('UTC', true)) ||
            moment(date).isAfter(moment().add(p.dynamicValidity.to, 'days').endOf('day').tz('UTC', true))
        )
    ) {
        return false;
    }

    for (const e of oc(p).validityOptions.except([])) {

        if (moment(date).isBetween(moment(e.from), moment(e.to))) {
            return false;
        }
    }

    if (!oc(p).validityOptions.dayDOW([]).includes(moment(date).isoWeekday() % 7)) {
        return false;
    }

    const weekday = moment().tz(environment.config.timeZone).isoWeekday() % 7;

    if (
        oc(p).validityOptions.salesTime[weekday]() &&
        moment(date).tz('UTC').isSame(moment().tz('UTC'), 'day')
    ) {
        const { from, to } = oc(p).validityOptions.salesTime[weekday]() || {} as DateRange;

        if (!from || !to) {
            return false;
        }

        const [fHour, fMinute] = from.split(':');
        const [tHour, tMinute] = to.split(':');

        return moment().isBetween(
            moment()
                .tz(environment.config.timeZone)
                .set('hour', Number(fHour))
                .set('minute', Number(fMinute)),
            moment()
                .tz(environment.config.timeZone)
                .set('hour', Number(tHour))
                .set('minute', Number(tMinute)),
            'minute',
            '[]'
        );
    }

    return true;
};
