import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import { Footer, Header, Layout } from '../../common/components';
import * as queryString from 'querystring';
import { activateAccountActions } from '../user/ducks';

interface DispatchToProps {
    activateAccount?(token: string | string[]): void;
}

type Props = RouteComponentProps & DispatchToProps;

export const ActivateComponent: FunctionComponent<Props> = ({
    match,
    location,
    activateAccount,
}) => {
    const [initialized, setInitialized] = useState(false);

    useEffect(
        () => {
            const token = queryString.parse(location.search.slice(1)).token;
            if (!initialized && token) {
                activateAccount && activateAccount(token);
                setInitialized(true);
            }
        },
        [initialized]
    );

    return (
        <Layout>
            <Header />
            <Redirect path={`${match.path}/`} to={`/`} />
            <Footer />
        </Layout>
    );
};

const mapDispatchToProps: DispatchToProps = {
    activateAccount: activateAccountActions.request,
};

export const Activate = withRouter(
    connect(
        null,
        mapDispatchToProps
    )(ActivateComponent)
);
