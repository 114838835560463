import { useQuery } from 'react-query';
import { apiClient } from '../../../../../../../../common/services/api-client';
import { VariantsSelection } from '../VariantsDropdown';
import { oc } from 'ts-optchain';
import { useEffect, useState } from 'react';

export const useDatePrices = (dates: string[], selection: VariantsSelection[], enabled) => {
    const [prices, setPrices] = useState<Record<string, number>>({});

    const result = useQuery({
        queryKey: ['date-prices', dates, selection],
        queryFn: () => apiClient.post(
            `/v2/transaction/prices`,
            {
                origin: 'eshop',
                entries: selection.map((item) => ({
                    variantId: item.id,
                    count: item.count,
                })),
                dates,
            },
        ),
        enabled,
    });

    useEffect(() => {
        setPrices({
            ...prices,
            ...oc(result).data.data({}),
        });
    }, [result.data]);

    return {
        prices,
        isLoading: result.isLoading,
    };
};
