import React from 'react';
import { i18n } from '../../services/i18n';

interface Props {
    discountCount: number;
    handleClick: () => void;
}

export default function DiscountLink(props: Props) {
    return (
        <div className="discount-link" onClick={props.handleClick}>
            <h3 className="discount-link__title">
                {i18n.t('discountLink.title') + ` (${props.discountCount})`}
            </h3>
        </div>
    );
}
