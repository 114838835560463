import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useState } from 'react';

import { Button, CheckBox, Modal, Price } from '../../components';
import { ModalSizes } from '../../constants/modal';
import { i18n } from '../../services/i18n';

interface Props {
    visible?: boolean;
    total?: number;
    totalDiscounted?: number;
    onConfirm?(): void;
    onCancel?(): void;
}

const MarketingModal = ({ visible, total, totalDiscounted, onConfirm, onCancel }: Props) => {
    const [checked, setChecked] = useState(false);

    function handleCancelClick() {
        onCancel && onCancel();
    }

    function handleConfirmClick() {
        onConfirm && onConfirm();
    }

    function handleCheckBoxChange(e: CheckboxChangeEvent) {
        setChecked(e.target.checked);
    }

    return (
        <Modal visible={visible} footer={null} width={ModalSizes.default}>
            <div className="marketing-modal">
                <h1> {i18n.t('marketingModal.title')}</h1>
                <CheckBox
                    value={checked}
                    onChange={handleCheckBoxChange}
                    label={
                        <p>
                            <strong>
                                {i18n.t('marketingModal.contentTitle')}
                                <br />
                            </strong>
                            {i18n.t('marketingModal.content')}
                        </p>
                    }
                />
                <div className="marketing-modal-btns">
                    <Button onClick={handleConfirmClick} disabled={!checked}>
                        {i18n.t('marketingModal.confirmButton')} &nbsp;
                        <Price value={totalDiscounted} />
                    </Button>
                    <Button onClick={handleCancelClick} type="primary">
                        {i18n.t('marketingModal.cancelButton')} &nbsp;
                        <Price value={total} />
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default MarketingModal;
