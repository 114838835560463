import { Layout } from '../index';
import React from 'react';
import Banner from '../Banner';
import Row from 'antd/es/grid/row';
import Col from 'antd/es/grid/col';
import { BannerEntry } from '../../models/Banner';
import { i18n } from '../../services/i18n';

interface Props {
    banners: BannerEntry[];
}

export default function BannerView({ banners }: Props) {
    return banners.length > 0 ? (
        <Layout.Container className="banners-container">
            <h3>{i18n.t('bannerView.title')} </h3>
            <Row
                type="flex"
                gutter={4}
                className="banners-container__list"
                style={{ width: 'calc(100% + 4px)' }}
            >
                {banners.map((banner, index) => {
                    return (
                        <Col key={index} xs={24} md={12} className="banners-container__column">
                            <Banner banner={banner} />
                        </Col>
                    );
                })}
            </Row>
        </Layout.Container>
    ) : null;
}
