import { Icon, Spin } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';

import { AppState } from '../../../../common/models/AppState';
import { TicketItem, Transaction } from '../../../../common/models/NewTransaction';
import { i18n } from '../../../../common/services/i18n';
import {
    Button,
    FeedbackBox,
    Layout,
    PageDescription,
} from '../.././../../common/components';
import { Subheader } from '../../components';
import { resetCartAction } from '../../ducks/cart';

import {
    selectConfirmationError,
    selectConfirmationStatus, selectConfirmedTransaction,
    selectTicketEntries,
    startConfirmationPollingAction,
} from './ducks';
import { sendGtmEvent } from '../../../../common/utils/gtm-helpers';
import Item from './components/Item/Item';
import { oc } from 'ts-optchain';
import { environment } from '../../../../environments/environment';

interface DispatchToProps {
    loadTransaction: (id: string) => void;
    resetCart: () => void;
}

interface StateToProps {
    items: TicketItem[];
    error: boolean;
    confirmed: boolean;
    transaction: Transaction | null;
}

type Props = RouteComponentProps & DispatchToProps & StateToProps;

export function B2BConfirmationComponent(props: Props) {
    const [badUrlError, setBadUrlError] = useState(false);
    const [statisticsSent, setStatisticsSent] = useState(false);

    const { transaction: transactionId } = queryString.parse(props.history.location.search);

    useEffect(() => {
        if (!transactionId) {
            setBadUrlError(true);
        } else {
            props.loadTransaction(transactionId as string);
        }
    }, [transactionId]);

    useEffect(
        () => {
            if (!statisticsSent && props.items.length > 0 && oc(props.transaction).amount() !== undefined) {
                setStatisticsSent(true);

                sendGtmEvent({
                    event: 'page_load',
                    page: 'b2b-confirmation',
                    products: props.items.map(item => item.name),
                    price: oc(props.transaction).amount(NaN).toString(),
                });
            }
        },
        [props.items]
    );

    function handleBackHomeClick() {
        props.resetCart();
        props.history.push('/');
    }

    function renderSuccess() {
        return (
            <div className="confirmation">
                <FeedbackBox
                    type="info"
                    title={<Icon type="check-circle" />}
                    description={i18n.t('b2b.confirmation.banner.top')}
                />
                {props.items.map((item) => (
                    <Item key={item.uuid} item={item} />
                ))}
                <PageDescription
                    title={i18n.t('b2b.confirmation.banner.bottom.title')}
                    description={
                        <>
                            {i18n.t('b2b.confirmation.banner.bottom.description')}<br/><br/>
                            {i18n.t('b2b.confirmation.banner.bottom.transactionId')}: {transactionId}
                        </>
                    }
                />

                {oc(props.transaction).paidWith() === 'invoice' && oc(props.transaction).meta.invoice.depositPdfUrl() && (
                    <a href={oc(props.transaction).meta.invoice.depositPdfUrl()} target="_blank">
                        <Button block>{i18n.t('b2b.confirmation.downloadDepositInvoice')}</Button>
                    </a>
                )}

                {oc(props.transaction).paidWith() === 'card' && oc(props.transaction).meta.invoice.pdfURL() && (
                    <a href={oc(props.transaction).meta.invoice.pdfURL()} target="_blank">
                        <Button block>{i18n.t('b2b.confirmation.downloadInvoice')}</Button>
                    </a>
                )}

                {oc(props.transaction).paidWith() === 'order' && oc(props.transaction).meta.invoice.pdfURL() && (
                    <a href={oc(props.transaction).meta.invoice.pdfURL()} target="_blank">
                        <Button block>{i18n.t('b2b.confirmation.downloadOrder')}</Button>
                    </a>
                )}

                {props.items.some(i => !i.isGift) && oc(props.transaction).status() === 'finished' &&  (
                    <a
                        href={`${environment.protocol}://${environment.host}/transactions/${transactionId}/ticket?lang=${i18n.language}`}
                        target="_blank"
                    >
                        <Button block>{i18n.t('b2b.confirmation.downloadTickets')}</Button>
                    </a>
                )}

                {environment.config.downloadPrintTickets && oc(props.transaction).status() === 'finished' &&  (
                    <a
                        href={`${environment.protocol}://${environment.host}/transactions/${transactionId}/print-all-tickets?lang=${i18n.language}`}
                        target="_blank"
                    >
                        <Button block>{i18n.t('b2b.confirmation.downloadPrintTickets')}</Button>
                    </a>
                )}

                <Link to="/">
                    <Button block ghost onClick={handleBackHomeClick}>
                        {i18n.t('b2b.confirmation.backToHome')}
                    </Button>
                </Link>
            </div>
        );
    }

    return (
        <Layout.Content>
            <Subheader />
            <Layout.Container>
                <Spin spinning={!props.error && !props.confirmed && !badUrlError} delay={250}>
                    <div style={{ minHeight: '300px' }}>
                        {badUrlError && (
                            <FeedbackBox
                                type="negative"
                                title={i18n.t('b2b.confirmation.error.title')}
                                description={i18n.t('b2b.confirmation.error.description')}
                            />
                        )}
                        {props.error && (
                            <FeedbackBox
                                type="negative"
                                title={i18n.t('b2b.confirmation.transactionError.title')}
                                description={i18n.t('b2b.confirmation.transactionError.description')}
                            />
                        )}
                        {!props.error && !props.confirmed && (
                            <FeedbackBox
                                type="info"
                                title={i18n.t('b2b.confirmation.paymentLoading.title')}
                                description={i18n.t('b2b.confirmation.paymentLoading.description')}
                            />
                        )}
                        {!props.error && props.confirmed && renderSuccess()}
                    </div>
                </Spin>
            </Layout.Container>
        </Layout.Content>
    );
}

const mapDispatchToProps: DispatchToProps = {
    resetCart: resetCartAction.request,
    loadTransaction: startConfirmationPollingAction,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    items: selectTicketEntries(state),
    error: selectConfirmationError(state),
    confirmed: selectConfirmationStatus(state),
    transaction: selectConfirmedTransaction(state),
});

export const B2BConfirmation = connect(
    mapStateToProps,
    mapDispatchToProps
)(B2BConfirmationComponent);
