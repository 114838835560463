import { Icon, Tag } from 'antd';
import React from 'react';

import { environment } from '../../../environments/environment';
import { i18n } from '../../services/i18n';
import { TransactionDryRunBenefit } from '../../models/NewTransaction';
import LocalizedString from '../LocalizedString';

interface Props {
    benefit: TransactionDryRunBenefit;
}

export default function DiscountBadge({ benefit }: Props) {
    if (!benefit.badge) {
        return i18n.t(`${benefit.id}.badge`) && i18n.t(`${benefit.id}.badge`).length > 1 ? (
            <Tag className="discount-badge" color="green">
                <Icon type="check" />
                {i18n.t(`${benefit.id}.badge`)}
            </Tag>
        ) : null;
    }

    return (
        <Tag className="discount-badge" color="green">
            <Icon type="check" />
            <LocalizedString value={benefit.badge} />
        </Tag>
    );
}
