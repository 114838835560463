import Carousel from 'react-multi-carousel';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { breakpoints } from './breakpoints';
import CarouselArrow from './CarouselArrow';
import moment, { Moment } from 'moment';
import cn from 'classnames';
import { i18n } from '../../../../../../../../common/services/i18n';
import { oc } from 'ts-optchain';
import { isProductAndSelectionValidAtDate } from '../../../../../../../../common/utils/visibility-helpers';
import { Product } from '../../../../../../../../common/models/Product';
import { VariantsSelection } from '../VariantsDropdown';
import { useDatePrices } from './useDatePrices';
import { Price } from '../../../../../../../../common/components';
import { environment } from '../../../../../../../../environments/environment';

interface Props {
    small?: boolean;
    hidePrices?: boolean;
    validFrom: string;
    product?: Product;
    selectedVariants: VariantsSelection[];

    onDateChange: (date: Moment) => void;
}

const DateSelectCarousel = ({ small, hidePrices, validFrom, product, selectedVariants, onDateChange }: Props) => {
    const carouselRef = useRef<Carousel>(null);

    const [slide, setSlide] = useState(0);

    const dates = useMemo(
        () => {
            const res: string[] = [];
            const d = moment().subtract(1, 'days');

            while (d.add(1, 'days').isSameOrBefore(moment(oc(product).validity.to()))) {
                if (
                    !isProductAndSelectionValidAtDate(
                        product,
                        selectedVariants.filter(vs => vs.count > 0).map(vs => vs.id),
                        d
                    )
                ) {
                    continue;
                }

                res.push(moment(d).format('YYYY-MM-DD'));
            }

            return res;
        },
        [validFrom, product, selectedVariants],
    );

    const { prices } = useDatePrices(dates.slice(slide, slide + 8), selectedVariants, environment.config.showDatePrices && !hidePrices);

    const getCarouselOptions = useMemo(
        () => dates.map((d) => {
            const date = moment(d, 'YYYY-MM-DD');
            const withPrice = prices[d] !== undefined;

            return (
                <div
                    key={date.format()}
                    className={
                        cn(
                            'time-picker__date date-entry',
                            {'date-entry--active': date.isSame(validFrom, 'day')},
                        )
                    }
                    onClick={() => onDateChange(date)}
                >
                    {!withPrice && (
                        <>
                            <div className="date-entry__day">
                                {date.calendar(moment(), {
                                    sameDay: `[${i18n.t('timeSlots.today')}]`,
                                    nextDay: `[${i18n.t('timeSlots.tomorrow')}]`,
                                    nextWeek: 'dddd',
                                    lastDay: 'dddd',
                                    lastWeek: 'dddd',
                                    sameElse: 'dddd'
                                })}
                            </div>
                            <div className="date-entry__date">
                                {date.format('D.M')}
                            </div>
                        </>
                    )}
                    {withPrice && (
                        <>
                            <div className="date-entry__date date-entry__date--with-price">
                                {date.calendar(moment(), {
                                    sameDay: 'ddd D.M',
                                    nextDay: 'ddd D.M',
                                    nextWeek: 'ddd D.M',
                                    lastDay: 'ddd D.M',
                                    lastWeek: 'ddd D.M',
                                    sameElse: 'ddd D.M'
                                })}
                            </div>
                            <div className="date-entry__price">
                                <Price value={prices[d]} />
                            </div>
                        </>
                    )}
                </div>
            );
        }),
        [validFrom, dates, i18n.language, prices],
    );

    useEffect(() => {
        const index = dates.indexOf(moment(validFrom).format('YYYY-MM-DD'));

        if (carouselRef.current && index > -1) {
            const setting = Object.values(breakpoints(small)).find(v => v.breakpoint.min <= window.innerWidth && v.breakpoint.max > window.innerWidth);

            if (setting) {
                carouselRef.current.goToSlide(Math.max(index - Math.floor(setting.items / 2), 0));
            } else {
                carouselRef.current.goToSlide(index);
            }
        }
    }, []);

    const handleChangeSlides = (nextSlide: number) => {
        setSlide(nextSlide);
    };

    return (
        <div className="time-picker">
            <Carousel
                ref={carouselRef}
                responsive={breakpoints(small)}
                customRightArrow={<CarouselArrow right/>}
                customLeftArrow={<CarouselArrow/>}
                containerClass="time-picker__list"
                beforeChange={handleChangeSlides}
            >
                {getCarouselOptions}
            </Carousel>
        </div>
    );
};

export default DateSelectCarousel;
