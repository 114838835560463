import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { AppState } from '../../common/models/AppState';
import {
    createActionCreator,
    createActionType,
    createApiActionCreators,
    createReducer,
    RequestActionTypes,
} from '../../common/utils/redux-helpers';
import { selectUserProfile } from '../user/ducks';

import { api } from './api';

export interface CodeStartState {
    validated: boolean;
    benefit: string | null;
    error: string | null;
    applied: boolean;
    type: CodeVerifcationTypes | null;
}

export enum CodeVerifcationTypes {
    FromLink = 'FROM_LINK',
    FromLinkRegister = 'FROM_LINK_REGISTER',
    FromDiscountCenter = 'FROM_DISCOUNT_CENTER',
}

/**
 * ACTION TYPES
 */
enum CodeStartActionTypes {
    ValidateVoucher = '@@CodeStart/VOUCHER_VALIDATE',
    RunBenefitEvent = '@@CodeStart/RUN_BENEFIT_EVENT',
    RunRegisterBenefitEvent = '@@CodeStart/RUN_REGISTER_BENEFIT_EVENT',
    ResetCodeVerification = '@@CodeStart/RESET_CODE_VERIFICATION',
    SetVerificationType = '@@CodeStart/SET_VERIFICATION_TYPE',
}

/**
 * ACTIONS
 */
export const setVerificationTypeAction = createActionCreator(
    CodeStartActionTypes.SetVerificationType
);

export const registerBenefitEventAction = createActionCreator(
    CodeStartActionTypes.RunRegisterBenefitEvent
);

export const resetCodeVerificationAction = createActionCreator(
    CodeStartActionTypes.ResetCodeVerification
);

export const verifyTokenActions = createApiActionCreators(CodeStartActionTypes.ValidateVoucher);
export const runBenefitEventActions = createApiActionCreators(CodeStartActionTypes.RunBenefitEvent);

/**
 * REDUCERS
 */
const initialState: CodeStartState = {
    applied: false,
    validated: false,
    benefit: null,
    error: null,
    type: null,
};

const validated = createReducer(initialState.validated, {
    [CodeStartActionTypes.ValidateVoucher]: {
        [RequestActionTypes.FAILURE]: () => true,
        [RequestActionTypes.SUCCESS]: () => true,
    },
    [CodeStartActionTypes.ResetCodeVerification]: () => false,
});

const applied = createReducer(initialState.validated, {
    [CodeStartActionTypes.RunBenefitEvent]: {
        [RequestActionTypes.SUCCESS]: () => true,
    },
    [CodeStartActionTypes.RunRegisterBenefitEvent]: () => true,
    [CodeStartActionTypes.ResetCodeVerification]: () => false,
});

const benefit = createReducer(initialState.validated, {
    [CodeStartActionTypes.ValidateVoucher]: {
        [RequestActionTypes.SUCCESS]: (state: string, payload: string) => payload,
    },
    [CodeStartActionTypes.ResetCodeVerification]: () => null,
});

const type = createReducer(initialState.type, {
    [CodeStartActionTypes.SetVerificationType]: (state, payload) => payload,
    [CodeStartActionTypes.ResetCodeVerification]: () => null,
});

const error = createReducer(initialState.validated, {
    [CodeStartActionTypes.ValidateVoucher]: {
        [RequestActionTypes.FAILURE]: (state, error: string) => error,
        [RequestActionTypes.SUCCESS]: () => false,
    },
    [CodeStartActionTypes.ResetCodeVerification]: () => false,
});

export default combineReducers<CodeStartState>({
    validated,
    error,
    benefit,
    applied,
    type,
});

/**
 * SELECTORS
 */
export const selectCodeVerification = (state: AppState) => state.code;

export function selectCodeVerificationApplied(state: AppState) {
    return selectCodeVerification(state).applied;
}

export function selectCodeVerificationValidated(state: AppState) {
    return selectCodeVerification(state).validated;
}

export function selectCodeVerificationError(state: AppState) {
    return selectCodeVerification(state).error;
}

export function selectCodeVerificationBenefit(state: AppState) {
    return selectCodeVerification(state).benefit;
}

export function selectCodeVerificationType(state: AppState): CodeVerifcationTypes | null {
    return selectCodeVerification(state).type;
}

/**
 * SAGAS
 */
function* validateVoucher({ payload }: any) {
    const user = yield select(selectUserProfile);
    const resp = yield call(api.validateToken, payload, user ? user.id : undefined);
    if (resp.ok) {
        // TODO: Add journal interface & use oc
        const journal =
            resp.data && resp.data.journal && resp.data.journal[0] && resp.data.journal[0];
        if (journal) {
            yield put(verifyTokenActions.success(journal.benefit.id));
        } else {
            const error = resp.data && resp.data.benefitErrors && Object.values(resp.data.benefitErrors)[0];
            yield put(verifyTokenActions.failure(error || 'general'));
        }
    } else {
        yield put(verifyTokenActions.failure('general'));
    }
}

function* runBenefitEvent({ payload }: any) {
    const p = payload as { code: string; type: CodeVerifcationTypes };
    const user = yield select(selectUserProfile);

    if (!user || !user.id) {
        return;
    }

    const resp = yield call(api.runBenefitEvent, p.code, user.id);

    if (resp.ok) {
        // TODO: Add journal interface & use oc
        const journal =
            resp.data && resp.data.journal && resp.data.journal[0] && resp.data.journal[0];
        if (journal) {
            yield put(setVerificationTypeAction(p.type));
            yield put(runBenefitEventActions.success(journal.benefit.id));
        } else {
            const error = resp.data && resp.data.benefitErrors && Object.values(resp.data.benefitErrors)[0];
            yield put(verifyTokenActions.failure(error || 'general'));
        }
    } else {
        yield put(runBenefitEventActions.failure('general'));
    }
}

export function* codeSaga() {
    yield takeLatest(
        createActionType(CodeStartActionTypes.ValidateVoucher, RequestActionTypes.REQUEST),
        validateVoucher
    );

    yield takeLatest(
        createActionType(CodeStartActionTypes.RunBenefitEvent, RequestActionTypes.REQUEST),
        runBenefitEvent
    );
}
