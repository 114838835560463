import { Col, Row } from 'antd';
import React, { ReactNode } from 'react';
import { i18n } from '../../services/i18n';
import Price from '../Price';
import LocalizedString from '../LocalizedString';
import { Layout } from '../';
import { CmsContent } from '../../models/NewTransaction';
import { oc } from 'ts-optchain';

interface Props {
    type: string;
    basePrice?: string;
    discountPrice?: string;
    prefix?: ReactNode;
    className?: string;
    text?: {
        tl?: CmsContent;
        tr?: CmsContent;
        bl?: CmsContent;
        br?: CmsContent;
    };
}

export default function Savings(props: Props) {
    const cls = props.className ? 'saving-description' + props.className : 'saving-description';

    function renderNumeric() {
        const discountDiff = Number(props.basePrice) - Number(props.discountPrice);
        return (
            <React.Fragment>
                {!props.prefix && (
                    <div className="saving-section">
                        <Row className={cls}>
                            <Col span={8}>{i18n.t('aboutLipno.normalPrice')}</Col>

                            <Col span={8}>{i18n.t('aboutLipno.eshopPrice')}</Col>

                            <Col span={8}>{i18n.t('aboutLipno.savings')}</Col>
                        </Row>
                        <Row gutter={20} type="flex">
                            <Col span={8}>
                                <p className="saving-section-standard">
                                    <Price value={props.basePrice} />
                                </p>
                            </Col>
                            <Col span={8}>
                                <p className="saving-section-package">
                                    <Price value={props.discountPrice} />
                                </p>
                            </Col>
                            <Col span={8} style={{ paddingLeft: '20px' }}>
                                <p className="saving-section-discount">
                                    <Price value={discountDiff} />
                                </p>
                            </Col>
                        </Row>
                    </div>
                )}
                {props.prefix && (
                    <div className="saving-section">
                        <Row className={cls}>
                            <Col span={7} offset={3}>
                                {i18n.t('aboutLipno.normalPrice')}
                            </Col>

                            <Col span={7}>{i18n.t('aboutLipno.eshopPrice')}</Col>

                            <Col span={7}>{i18n.t('aboutLipno.savings')}</Col>
                        </Row>
                        <Row gutter={20} type="flex">
                            <Col span={3}>
                                <p className="saving-section-prefix">{props.prefix}</p>
                            </Col>
                            <Col span={7}>
                                <p className="saving-section-standard">
                                    <Price value={props.basePrice} />
                                </p>
                            </Col>
                            <Col span={7}>
                                <p className="saving-section-package">
                                    <Price value={props.discountPrice} />
                                </p>
                            </Col>
                            <Col span={7} style={{ paddingLeft: '20px' }}>
                                <p className="saving-section-discount">
                                    <Price value={discountDiff} />
                                </p>
                            </Col>
                        </Row>
                    </div>
                )}
            </React.Fragment>
        );
    }

    function renderWord() {
        return (
            <div className="banner-saving-section">
                <div className="saving-section">
                    <Layout.Container>
                        <Row className="saving-description-banner" type="flex" justify="center">
                            <Col span={12}>
                                <p style={{ float: 'right' }}>
                                    <LocalizedString value={oc(props.text).tl({})} />
                                </p>
                            </Col>

                            <Col span={12}>
                                <p style={{ float: 'left' }}>
                                    <LocalizedString value={oc(props.text).tr({})} />
                                </p>
                            </Col>
                        </Row>
                        <Row gutter={20} type="flex" justify="center">
                            <Col span={12}>
                                <p className="saving-section-package" style={{ float: 'right' }}>
                                    <LocalizedString value={oc(props.text).bl({})} />
                                </p>
                            </Col>
                            <Col span={12} style={{ paddingLeft: '20px' }}>
                                <p className="saving-section-discount" style={{ float: 'left' }}>
                                    <LocalizedString value={oc(props.text).br({})} />
                                </p>
                            </Col>
                        </Row>
                    </Layout.Container>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            {props.type === 'numeric' && renderNumeric()}
            {props.type === 'word' && renderWord()}
            {props.type === 'none' && null}
        </React.Fragment>
    );
}
