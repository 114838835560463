import { all, fork } from 'redux-saga/effects';

import { codeSaga } from '../../features/code/ducks';
import { personalSaga } from '../../features/personal/ducks';
import { eshopSaga } from '../../features/shopping/ducks';
import { userSaga } from '../../features/user/ducks';
import { dateChangeSaga } from '../../features/dateChange/ducks';

export default function* rootSaga() {
    yield all([
        fork(eshopSaga),
        fork(userSaga),
        fork(personalSaga),
        fork(codeSaga),
        fork(dateChangeSaga),
    ]);
}
