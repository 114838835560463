import { Fragment, ReactNode } from 'react';

const flags = [
    {
        key: 'aboutLipnoLink',
        visible: false,
    },
    {
        key: 'featuredProducts',
        visible: false,
    },
    {
        key: 'featuredAllDiscounts',
        visible: false,
    },
    {
        key: 'featuredAllDiscounts',
        visible: false,
    },
];

interface Props {
    flagKey: string;
    children: ReactNode;
}

export function FeatureFlag({ flagKey, children }: Props) {
    const flag = flags.find(flag => flag.key === flagKey);
    return flag && flag.visible ? <Fragment>{children}</Fragment> : null;
}
