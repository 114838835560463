import { Icon } from 'antd';
import cn from 'classnames';
import React from 'react';

import { cardAvatar } from '../../../resources/images';
import { LipnoCardTypes } from '../../models/Token';
import { i18n } from '../../services/i18n';
import DropdownMenu from '../DropdownMenu';
import moment from 'moment';
import { environment } from '../../../environments/environment';

interface Props {
    id: string;
    type: LipnoCardTypes;
    token: string;
    birthday?: Date;
    onRemove?: (id: string) => void;
    enableRemove?: boolean;
}

function LipnoCard({ id, type, token, birthday, onRemove, enableRemove }: Props) {
    function handleRemoveClick() {
        onRemove && onRemove(token);
    }

    return (
        <div className="card">
            <div className="card__avatar">
                <img src={cardAvatar} />
                <Identificator type={type} />
            </div>
            <div className="card__content">
                <h3>{tranformTitle(type)}</h3>
                <p>{i18n.t('cardName')} ID {id}</p>
                {environment.config.addCardType && (
                    <p>{i18n.t('birthday')} {moment(birthday).format('DD.MM.YYYY')}</p>
                )}
            </div>
            {enableRemove &&
                <div className="card__actions">
                    <DropdownMenu
                        menuItems={[{ title: i18n.t('card.remove'), onClick: handleRemoveClick }]}
                    >
                        <Icon type="ellipsis"/>
                    </DropdownMenu>
                </div>
            }
        </div>
    );
}

function Identificator({ type }: { type: string }) {
    return (
        <div
            className={cn('card-indentificator', {
                'card-indentificator--mini': type === 'mini',
                'card-indentificator--adult': type === 'adult',
                'card-indentificator--child': type === 'child',
            })}
        />
    );
}

function tranformTitle(type: LipnoCardTypes): string {
    switch (type) {
        case 'external': {
            return i18n.t('card.type.external');
        }
        case 'adult': {
            return i18n.t('card.type.adult');
        }
        case 'child': {
            return i18n.t('card.type.child');
        }
        case 'mini': {
            return i18n.t('card.type.mini');
        }
        default:
            return i18n.t('card.type.adult');
    }
}

LipnoCard.transformTitle = tranformTitle;
LipnoCard.Identificator = Identificator;

export default LipnoCard;
