import { BasicProps } from 'antd/lib/layout/layout';
import cn from 'classnames';
import React, { FunctionComponent } from 'react';

interface Props extends BasicProps {
    fullHeight?: boolean;
}

export const Container: FunctionComponent<Props> = ({ fullHeight = false, className, ...bag }) => (
    <div className={cn('container', className, { 'container--fullheight': fullHeight })} {...bag} />
);
