import { Form, Formik, FormikProps } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { oc } from 'ts-optchain';
import * as Yup from 'yup';

import { Button } from '../../components';
import { InputField } from '../../fields';
import { LoginFormValues } from '../../models/User';
import { i18n } from '../../services/i18n';
import PasswordInput from '../../fields/PasswordInput';
import { NewPasswordModal } from '../../modals';
import validator from 'validator';
import { api } from '../../../features/user/api';


interface Props {
    onSubmit(values: LoginFormValues);
    initialValues?: Partial<LoginFormValues>;
}

const LoginForm: FunctionComponent<Props> = ({ onSubmit, initialValues: values }) => {
    const schema = Yup.object<LoginFormValues>().shape<LoginFormValues>({
        email: Yup.string().required(i18n.t('form.required')),
        password: Yup.string().required(i18n.t('form.required')),
    });

    const [newPasswordModal, setNewPasswordModal] = useState(false);

    function renderNewPasswordModal(email) {
        function handleConfirmClick() {
            setNewPasswordModal(false);
        }

        return <NewPasswordModal visible={newPasswordModal} onConfirm={handleConfirmClick} email={email} />;
    }


    function handleOnSubmit(values: LoginFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm(formikBag: FormikProps<LoginFormValues>) {
        function checkForPassword() {
            if (validator.isEmail(formikBag.values.email)) {
                api.hasPassword(formikBag.values.email).then( res => {
                    if (res.hasOwnProperty('data') && res.data === false) {
                        setNewPasswordModal(true);
                    }
                }).catch(err => {
                    console.error(err.resp);
                });
            }
        }
        return (
            <div className="login-form">
                {renderNewPasswordModal(formikBag.values.email)}
                <Form>
                    <InputField
                        name="email"
                        label={i18n.t('login.email.label')}
                        placeholder={i18n.t('login.email.placeholder')}
                        onBlur={checkForPassword}
                    />
                    <PasswordInput
                        label={i18n.t('login.password.label')}
                        placeholder={i18n.t('login.password.placeholder')}
                    />
                    <Button
                        block
                        size="small"
                        onClick={formikBag.submitForm}
                        disabled={!formikBag.isValid}
                    >
                        {i18n.t('login.submit')}
                    </Button>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{ email: oc(values).email(''), password: '' }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
};

export default LoginForm;
