import { i18n } from '../../../../../common/services/i18n';
import { PaymentOption } from '../../../../../common/components';
import React from 'react';
import { B2BPaymentType } from '../../../../../common/models/NewTransaction';

interface Props {
    type: 'invoice' | 'order';
    paymentDisabled?: boolean;
    onSubmitPayment: (type: B2BPaymentType) => void;
}

const B2BPayment = ({ type, paymentDisabled, onSubmitPayment }: Props) => (
    <PaymentOption
        title={i18n.t(`checkout.payment.b2b.${type}.title`)}
        buttonTitle={i18n.t(`checkout.payment.b2b.${type}.button`)}
        submitting={paymentDisabled}
        onSelect={() => onSubmitPayment(type)}
    >
        <div dangerouslySetInnerHTML={{ __html: i18n.t(`checkout.payment.b2b.${type}.content`)}} />
    </PaymentOption>
);

export default B2BPayment;
