import { Card, Tooltip, Button } from 'antd';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import Truncate from 'react-truncate';
import { oc } from 'ts-optchain';
import { connect } from 'react-redux';

import { Price, TotalPrice } from '../../components';
import { Benefit, VariantType } from '../../models/Benefit';
import { i18n } from '../../services/i18n';
import { Product } from '../../models/Product';
import {
    VariantsDropdown,
    VariantsSelection,
} from '../../../features/shopping/features/eshop/components/ProductSelect/components/VariantsDropdown';
import {
    selectDiscountBadges,
    selectDiscountEntries, selectEshopSingleEntries, selectSeason, SingleVariant,
    validateSingleVariantActions
} from '../../../features/shopping/ducks';
import { AppState, Season } from '../../models/AppState';
import { environment } from '../../../environments/environment';
import { isProductVisible } from '../../utils/visibility-helpers';
import DynamicImg from '../DynamicImg';
import { EntryBase, TransactionDryRunBenefit } from '../../models/NewTransaction';
import BenefitValidity from '../BenefitValidity/BenefitValidity';
import LocalizedString from '../LocalizedString';

interface OwnProps {
    benefit: Benefit;
    product: Product;
    toCart?: boolean;
    onButtonClick?: (value: VariantsSelection[], product: Product) => void;
    onSelectUpdate?: (prod: Product, variants: VariantsSelection[]) => void;
    total?: number;
    totalDiscounted?: number;
    variants?: VariantType[];
    style?: object;
    credit?: number;
    withoutCredits?: number;
    lastPrice?: number;
    reset?: boolean;
    selector?: string;
}

interface StateToProps {
    badges: TransactionDryRunBenefit[];
    entries: EntryBase[];
    singleVariants: Record<string, EntryBase>;
    season: Season;
}

interface DispatchToProps {
    validateSingleVariant: (items: SingleVariant) => void;
}

type Props = OwnProps & StateToProps & DispatchToProps;

const DiscountComponent = (props: Props) => {
    const [variants, setVariants] = useState<VariantsSelection[]>([]);
    const [visible, setVisible] = useState(false);
    const [usedCredit, setUsedCredit] = useState(0);
    const [disabled, setDisabled] = useState(false);

    function getSelectionCredit() {
        let max = Number(oc(props).benefit.meta.max()) * variants.reduce((acc, val) => val.count + acc, 0);
        let lastItemCreditValue;

        if (max === 0) {
            max = oc(props).withoutCredits(0);
            lastItemCreditValue = oc(props).lastPrice(0);
        }
        else {
            lastItemCreditValue = Number(oc(props.benefit).meta.max('0'));
        }

        if (props.credit && props.credit >= max) {
            return Math.max(max, Number(oc(props).benefit.meta.min('0')));
        } else if (
            props.credit &&
            props.benefit.meta &&
            props.benefit.meta.min !== undefined &&
            props.credit >= max - lastItemCreditValue + Number(props.benefit.meta.min)
        ) {
            return props.credit;
        }

        return max - lastItemCreditValue + Number(oc(props).benefit.meta.min('0'));
    }

    useEffect(
        () => {
            if (props.benefit.type === 'credit' && variants.length) {
                setUsedCredit(getSelectionCredit());
            }
        },
        [variants, props.credit, props.total]
    );

    useEffect(
        () => {
            setDisabled(
                props.benefit.type === 'credit' &&
                !(props.credit && props.benefit.meta && props.credit >= usedCredit)
            );
        },
        [usedCredit]
    );

    function getValidityString(validity) {
        if (validity && validity.from && validity.to) {
            return `${i18n.t('discountCard.validity')} ${moment(validity.from).format(
                'DD.MM.YYYY'
            )} - ${moment(validity.to).format('DD.MM.YYYY')}`;
        } else {
            return i18n.t('discountCard.withoutValidity');
        }
    }

    // send selected variants to parent component
    function handleVariantsChange(values: VariantsSelection[]) {
        setVariants(values);
        if (props.onSelectUpdate && props.product) {
            props.onSelectUpdate(props.product, values);
        }
    }

    function validateSingleVariants() {
        oc(props.product)
            .variants([])
            .filter(v => isProductVisible(v))
            .forEach(v =>
                props.validateSingleVariant({variant: v._id, arrival: new Date().toISOString()})
            );
    }

    function getReuseCount() {
        // if we have no other information than maxCount
        if (
            props.benefit.meta &&
            props.benefit.meta.maxNumberOfUses &&
            props.benefit.token &&
            !props.benefit.token.meta.count
        ) {
            return `${props.benefit.token.meta.maxNumberOfUses}x `;
        }

        if (props.benefit.meta && props.benefit.meta.maxNumberOfUses && !props.benefit.token) {
            return `${props.benefit.meta.maxNumberOfUses}x `;
        }

        // if we need to do arithmetic with number of already burned tokens
        if (
            props.benefit.meta &&
            props.benefit.meta.maxNumberOfUses &&
            props.benefit.token &&
            props.benefit.token.meta.count
        ) {
            return `${Number(props.benefit.token.meta.maxNumberOfUses) -
            Number(props.benefit.token.meta.count)}x `;
        }

        return '';
    }

    function handleMouseEnter() {
        if (disabled) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }

    function handleMouseLeave() {
        setVisible(false);
    }

    function calculatePrice() {
        if (props.benefit.meta && props.benefit.meta.max) {
            return props.withoutCredits
                ? // max is used in case of wrong settings i.e. max/min are higher than the value of variant
                Math.max(props.withoutCredits - usedCredit, 0)
                : 0;
        } else {
            return props.lastPrice !== undefined &&
            props.benefit.meta &&
            props.lastPrice > Number(props.benefit.meta.min)
                ? props.lastPrice - Number(props.benefit.meta.min)
                : 0;
        }
    }

    return (
        <div className="discount">
            <Card
                style={props.style ? props.style : {}}
                className={props.toCart ? 'cart' : ''}
                cover={
                    <DynamicImg
                        srcs={[
                            `${environment.mediaUrl}/benefit/${props.benefit._id}`,
                            `${environment.mediaUrl}/static/eshop/benefit.season-${props.season.type}`,
                            `${environment.mediaUrl}/static/eshop/benefit`,
                        ]}
                        alt="benefit"
                        style={{ margin: '0 auto' }}
                    />
                }
            >
                <Card.Meta
                    title={
                        <Truncate lines={2} ellipsis="...">
                            {!props.toCart && getReuseCount()}{' '}
                            <LocalizedString value={props.benefit.name} />
                        </Truncate>
                    }
                    description={<BenefitValidity validity={props.benefit.validity} />}
                />
                <p>
                    <Truncate lines={5} ellipsis="...">
                        <LocalizedString value={props.benefit.description} />
                    </Truncate>
                </p>

                {props.toCart && props.product && (
                    <div className={'ant-card-add-cart'}>
                        <TotalPrice
                            type="default"
                            pricePosition="bottom"
                            withDiscount={
                                <Price
                                    value={
                                        props.benefit.type === 'credit' && disabled
                                            ? calculatePrice()
                                            : props.totalDiscounted
                                    }
                                />
                            }
                            withoutDiscount={
                                <Fragment>
                                    {props.benefit.type === 'credit'
                                        ? i18n.t('ticketSelect.credit.priceWithoutDiscount')
                                        : i18n.t('ticketSelect.priceWithoutDiscount')}{' '}
                                    <Price
                                        value={
                                            props.benefit.type === 'credit' && props.withoutCredits
                                                ? props.withoutCredits
                                                : props.total
                                        }
                                    />
                                </Fragment>
                            }
                            displayDiscount
                        />
                        <VariantsDropdown
                            badges={props.badges}
                            product={props.product}
                            onChange={handleVariantsChange}
                            entries={props.entries}
                            variants={props.variants}
                            maxVariants={oc(props.benefit).meta.maxNumberOfUses()}
                            onDropdownOpen={validateSingleVariants}
                            initialState={
                                props.variants
                                    ? props.variants
                                        .filter(variant => variant.countMin !== -1)
                                        .map(variant => {
                                            return {
                                                id: variant.variant,
                                                count: variant.countMin,
                                            };
                                        })
                                    : undefined
                            }
                            reset={props.reset}
                            singleVariants={props.singleVariants}
                        />
                        <Tooltip title={'discount.notEnoughCredit'} visible={visible}>
                            {/*awful hack, because antd breaks hover functionality with disabled buttons*/}
                            <Button
                                block
                                size="small"
                                type={disabled || !variants || !variants.some(v => v.count > 0) ? 'primary' : 'default'}
                                // tslint:disable-next-line jsx-no-lambda
                                onClick={() =>
                                    props.onButtonClick && !disabled && variants && variants.some(v => v.count > 0)
                                        ? props.onButtonClick(variants, props.product)
                                        : undefined
                                }
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                {props.benefit.type !== 'credit'
                                    ? i18n.t('discount.cartButton')
                                    : (
                                        <>
                                            {i18n.t('discount.credit.cartButton')}{' '}
                                            -
                                            <Price value={props.withoutCredits && Math.min(props.withoutCredits, usedCredit)} />
                                        </>
                                    )
                                }
                            </Button>
                        </Tooltip>
                    </div>
                )}
            </Card>
        </div>
    );
};

const mapDispatchToProps: DispatchToProps = {
    validateSingleVariant: validateSingleVariantActions.request,
};

// need to check if it's a discount with a bound product
const mapStateToProps = (state: AppState, ownProps: OwnProps): StateToProps => ({
    badges: selectDiscountBadges(state, ownProps.selector),
    entries: selectDiscountEntries(state, ownProps.selector),
    singleVariants: selectEshopSingleEntries(state),
    season: selectSeason(state),
});

// needed for ownProps to propagate to mapStateToProps
const Discount = connect(mapStateToProps, mapDispatchToProps)(DiscountComponent);
export default Discount;
