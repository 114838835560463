import React, { useState } from 'react';

import { getLogo } from '../../utils/project-helpers';

interface Props {
    srcs: string[];
    alt: string;
    [key: string]: any;
}

export default ({ srcs, alt, ...bag }: Props) => {
    const [index, setIndex] = useState(0);

    const onImageLoadFailed = (e) => {
        const next = index + 1;

        if (next >= srcs.length) {
            e.target.className = `${e.target.className} image-missing`;
        }

        setIndex(next);
    };

    return (
        <img src={srcs[index] || getLogo()} alt={alt} onError={onImageLoadFailed} {...bag}  />
    );
};
