import { Field, FieldProps } from 'formik';
import React, { ReactNode } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { DatePicker } from 'antd';
import _ from 'lodash';

import { Control } from '../../components/Form/Control';

interface Props {
    name: string;
    label?: ReactNode;
    placeholder?: string;
    disabled?: boolean;
}

export default ({ name, label, ...bag }: Props) => (
    <Field name={name}>
        {({ form: { errors, setFieldValue, values } }: FieldProps<any>) => {
            return (
                <Control label={label} error={_.get(errors, name)}>
                    <DatePicker
                        allowClear={false}
                        className={cn({ 'ant-input-warning': _.get(errors, name) })}
                        format={'DD.MM.YYYY'}
                        defaultPickerValue={moment('01.01.2000', 'DD.MM.YYYY')}
                        onChange={(_, dateString) => setFieldValue(name, dateString)}
                        value={_.get(values, name) ? moment(_.get(values, name), 'DD.MM.YYYY') : undefined}
                        {...bag}
                    />
                </Control>
            );
        }}
    </Field>
);
