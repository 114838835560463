import React from 'react';
import { oc } from 'ts-optchain';

import { LocalizedString, ProductValidity } from '../../../../components';
import cn from 'classnames';
import { Entry } from '../../../../models/NewTransaction';
import { getEntryMethod } from '../../../../utils/getEntryMethod';

interface Props {
    entry: Entry;
    validFrom?: string;
    time?: string;
    status?: string;
}

export default function TransactionItem(props: Props) {
    const validFrom = props.validFrom ? `${props.validFrom}T12:00:00.000Z` : undefined;

    return (
        <div
            className={
                cn(
                    'transaction-item',
                    {
                        'transaction-item__requested': props.validFrom && props.status === 'requested',
                        'transaction-item__success': props.status === 'success',
                        'transaction-item__error': props.status === 'error',
                    }
                )
            }
        >
            <div className="transaction-item-item">
                <div className="transaction-item-icon">
                    <img src="/mock/skipass/icon.png" alt="" />
                </div>
                <div className="transaction-item-desc">
                    <p>
                        <strong>
                            <LocalizedString value={props.entry.product.name} />{' '}
                            <LocalizedString value={props.entry.product.variant} />
                        </strong>
                    </p>
                    <p>{getEntryMethod(props.entry)}</p>
                </div>
                <div className="transaction-item-right">
                    <div className="transaction-item-validity">
                        <ProductValidity
                            from={validFrom || oc(props).entry.meta.validFrom('')}
                            time={props.time || oc(props).entry.meta.timeSlot.from('')}
                            unit={oc(props).entry.product.meta.tokenValidityUnit()}
                            validityAmount={oc(props).entry.product.meta.tokenValidityAmount(1)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
