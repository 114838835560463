import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import cn from 'classnames';
import React, { ReactNode } from 'react';

import { Control } from '../Control';

interface Props extends InputProps {
    label?: ReactNode;
    error?: ReactNode;
    type?: 'text' | 'password';
}

export default ({ type, label, error, addonAfter, ...bag }: Props) => (
    <Control label={label} error={error}>
        <Input
            type={type}
            addonAfter={addonAfter}
            className={cn({ 'ant-input-warning': error, 'ant-input-with-addon': addonAfter })}
            {...bag}
        />
    </Control>
);
