import React from 'react';
import { ReactNode } from 'react-use/node_modules/@types/react';

import { Modal } from '../../components';
import { ModalSizes } from '../../constants/modal';
import { i18n } from '../../services/i18n';

interface Props {
    visible?: boolean;
    children?: ReactNode;
    onCancel?(): void;
}

const RegistrationModal = ({ visible, children, onCancel }: Props) => {
    return (
        <Modal
            visible={visible}
            width={ModalSizes.large}
            title={i18n.t('register.modalTitle')}
            onCancel={onCancel}
            footer={null}
            closable
        >
            <div className="registration-modal">{children}</div>
        </Modal>
    );
};

export default RegistrationModal;
