import React, { FunctionComponent } from 'react';

import { Breadcrumb } from '../../../../common/components';
import { Route } from '../../../../common/components/Breadcrumb';
import { i18n } from '../../../../common/services/i18n';
import { environment } from '../../../../environments/environment';

const Subheader: FunctionComponent<any> = () => {
    const items = [
        {
            path: 'eshop',
            breadcrumbName: i18n.t('shopping.breadcrumbs.eshop'),
        },
        (environment.config.dualPrices ?
            {
                path: 'discount',
                breadcrumbName: i18n.t('shopping.breadcrumbs.registration'),
            } :
            null
        ),
        {
            path: 'informations',
            breadcrumbName: i18n.t('shopping.breadcrumbs.informations'),
        },
        {
            path: 'checkout',
            breadcrumbName: i18n.t('shopping.breadcrumbs.checkout'),
        },
        {
            path: 'confirmation',
            breadcrumbName: i18n.t('shopping.breadcrumbs.confirmation'),
        },
    ].filter(i => i);

    function setDisabled(items: Route[]) {
        const path = window.location.pathname;
        const index = items.findIndex(i => i.path === path.substring(path.lastIndexOf('/') + 1));
        const enabled = items.slice(0, index + 1);
        const disabled = items.slice(index + 1).map(item => ({ ...item, disabled: true }));
        return [...enabled, ...disabled];
    }

    return (
        <div className="shopping-subheader">
            <Breadcrumb routes={setDisabled(items as Route[])} />
        </div>
    );
};

export default Subheader;
