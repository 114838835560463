import React from 'react';
import cn from 'classnames';

enum LipnoIconTypes {
    Left = 'icon-arrow-left',
    Calendar = 'icon-calendar',
    Right = 'icon-arrow-right',
    Cart = 'icon-cart',
    ChevronDown = 'icon-chevron-down',
    ChevronLeft = 'icon-chevron-left',
    ChevronRight = 'icon-chevron-right',
    ChevronUp = 'icon-chevron-up',
    Delete = 'icon-delete',
    Cards = 'icon-karty',
    Logout = 'icon-logout',
    Menu = 'icon-menu',
    Discounts = 'icon-zlavy',
    Options = 'icon-options',
    OkCheck = 'icon-okcheck',
    Orders = 'icon-objednavky',
    Avatar = 'icon-noavatar',
    Settings = 'icon-nastavenia',
    Minus = 'icon-minus',
    Close = 'icon-close',
    Wrong = 'icon-wrong',
    Plus = 'icon-plus',
}

type Props = {
    type: LipnoIconTypes;
};

export default function LipnoIcon({ type }: Props) {
    return <i className={cn('icon', type)} />;
}

LipnoIcon.types = LipnoIconTypes;
