import { Icon } from 'antd';
import cn from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { Input } from '../../../components/Form';

interface Props {
    onChange?(value: number): void;
    value?: number;
    warning?: boolean;
    disabled?: boolean;
    upperBound?: number;
    lowerBound?: number;
}

export default ({ value = 0, onChange, upperBound, lowerBound }: Props) => {
    const [count, setCount] = useState(value ? value : 0);

    useEffect(
        () => {
            setCount(value);
        },
        [value]
    );

    const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const val = Number(e.target.value);

        if (isNaN(val)) {
            return;
        }

        if (upperBound !== undefined) {
            updateCount(Math.min(val, upperBound));
            return;
        }

        if (lowerBound !== undefined) {
            updateCount(Math.max(val, lowerBound));
            return;
        }

        updateCount(Number(e.target.value));
    };

    function updateCount(value: number) {
        setCount(value);
        onChange && onChange(value);
    }

    function handlePlusClick() {
        updateCount(upperBound !== undefined ? Math.min(count + 1, upperBound) : count + 1);
    }

    function handleMinusClick() {
        if (count > 0) {
            updateCount(lowerBound !== undefined ? Math.max(count - 1, lowerBound) : count - 1);
        }
    }

    return (
        <div className="qty-picker">
            <div className="qty-picker__control" onClick={handleMinusClick}>
                <Icon type="minus" />
            </div>
            <Input
                className={cn('qty-picker__input', { 'qty-picker__input--active': count > 0 })}
                value={count}
                onChange={handleValueChange}
            />
            <div className="qty-picker__control" onClick={handlePlusClick}>
                <Icon type="plus" />
            </div>
        </div>
    );
};
