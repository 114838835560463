import React, { ReactNode } from 'react';

interface Props {
    img?: string | ReactNode;
    title?: ReactNode;
    description?: ReactNode;
}

export default ({ img, title, description }: Props) => (
    <div className="testimonial">
        {img}
        <h3>{title}</h3>
        <p>{description}</p>
    </div>
);
