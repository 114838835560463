export const breakpoints = (small?: boolean) => ({
    xl: {
        breakpoint: { max: 10000, min: 1200 },
        items: small ? 5 : 8,
        slidesToSlide: small ? 5 : 8,
    },
    lg: {
        breakpoint: { max: 1200, min: 991 },
        items: small ? 5 : 7,
        slidesToSlide: small ? 5 : 7,
    },
    md: {
        breakpoint: { max: 991, min: 768 },
        items: small ? 5 : 6,
        slidesToSlide: small ? 5 : 6,
    },
    sm: {
        breakpoint: { max: 768, min: 575 },
        items: small ? 4 : 5,
        slidesToSlide: small ? 4 : 5,
    },
    xs: {
        breakpoint: { max: 575, min: 400 },
        items: 4,
        slidesToSlide: 4,
    },
    xxs: {
        breakpoint: { max: 400, min: 0 },
        items: 3,
        slidesToSlide: 3,
    }
});
