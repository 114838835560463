import React, { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

import { Select } from '../../../../components';
import { Season } from '../../../../models/AppState';
import { SelectOption } from '../../../Form/Select';

interface Props {
    onChange?(season: Season): void;
    value?: Season;
    seasons: Season[];
}

const SeasonPicker: FunctionComponent<Props> = ({ value, onChange, seasons }) => {
    function handleSeasonChange(value) {
        const changedSeason =
            seasons.find(season => {
                return season._id === value;
            }) || ({} as Season);
        onChange && onChange(changedSeason);
    }

    const opts = [] as SelectOption[];
    for (const season of seasons) {
        opts.push({
            label: <Trans i18nKey={'seasons.' + season.type} />,
            value: season._id
        });
    }

    function renderSelect() {
        if (value && value.name) {
            return (
                <Select
                    className="season-picker"
                    onSelect={handleSeasonChange}
                    value={value ? value._id : ''}
                    options={opts}
                />
            );
        } else {
            return <div/>;
        }
    }

    return renderSelect();
};

export default SeasonPicker;
