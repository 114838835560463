import { Icon } from 'antd';
import cn from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
    title?: ReactNode;
    items?: ReactNode[];
    type?: 'positive' | 'negative';
}

export default ({ type = 'positive', items = [], title }: Props) => {
    return (
        <div className={cn('info-list', { 'info-list--negative': type === 'negative' })}>
            <div className="info-list__title">{title}</div>
            <div className="info-list__list">
                {items.map((item, index) => (
                    <div key={index} className="info-list__list__item">
                        <Icon type={type === 'positive' ? 'check' : 'close'} />
                        <span>{item}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};
