import React, { FunctionComponent } from 'react';

import { i18n } from '../../../../../services/i18n';

const Contact: FunctionComponent = () => (
    <div className="footer-contact">
        <div dangerouslySetInnerHTML={{__html: i18n.t('footer.contact')}} />
    </div>
);

export default Contact;
