import React, { Fragment } from 'react';

import { Layout } from '../../../../common/components';
import { i18n } from '../../../../common/services/i18n';
import { TermsSubheader } from '../../components';
import GDPR from './components/GDPR';

export function GDPRPage() {
    return (
        <Fragment>
            <TermsSubheader title={i18n.t('terms.gdpr')} />
            <Layout.Container>
                <GDPR />
            </Layout.Container>
        </Fragment>
    );
}
