import { ConfigProvider, LocaleProvider } from 'antd';
import React, { Component } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import CookieBot from 'react-cookiebot';

import { Empty } from '../common/components';
import '../common/components/Message';
import { i18n, LANGUAGES } from '../common/services/i18n';

import { ErrorBoundary } from './components/ErrorBoundary';
import { persistor, store } from './store/configure-store';
import { ConfigWrapper } from './components/ConfigWrapperComponent';
import { environment } from '../environments/environment';

interface State {
    i18nInitialized: string | null;
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false
        }
    }
});

export class Root extends Component<{}, State> {
    public state = {
        i18nInitialized: null,
    };

    public componentDidMount() {
        i18n.on('languageChanged', lang => {
            this.setState({ i18nInitialized: lang });
        });
    }

    public componentWillUpdate(
        nextProps: Readonly<{}>,
        nextState: Readonly<State>,
        nextContext: any
    ): void {}

    public renderEmpty = () => {
        return <Empty />;
    };

    public render() {
        const { i18nInitialized } = this.state;

        return i18nInitialized ? (
            <ErrorBoundary>
                <QueryClientProvider client={queryClient}>
                    <I18nextProvider i18n={i18n}>
                        <Helmet htmlAttributes={{ lang : i18n.language }} />
                        {environment.cookieBotDomainGroupId && (
                            <CookieBot domainGroupId={environment.cookieBotDomainGroupId} language={i18n.language} />
                        )}
                        <ConfigProvider renderEmpty={this.renderEmpty}>
                            <LocaleProvider locale={LANGUAGES[i18n.language].localeProvider}>
                                <Provider store={store}>
                                    <PersistGate loading={<div />} persistor={persistor}>
                                        <ConfigWrapper />
                                    </PersistGate>
                                </Provider>
                            </LocaleProvider>
                        </ConfigProvider>
                    </I18nextProvider>
                </QueryClientProvider>
            </ErrorBoundary>
        ) : null;
    }
}
