import { Languages } from '../common/constants/language';
import moment from 'moment-timezone';

export const environment = {
    production: false,
    protocol: 'https',
    host: 'eshop-api.lipno.amcef.sk',
    basicAuthToken: 'bGlwbm9lc2hvcDpsaXBub2VzaG9w',
    mediaUrl: 'https://lipno-images.s3.eu-central-1.amazonaws.com',
    debug: false,

    languages: [Languages.Czech, Languages.German, Languages.English],
    defaultLanguage: Languages.Czech,

    gtmIds: ['GTM-K5DGXRX', 'GTM-WV873SD'],
    gtagIds: [],
    ga4Ids: ['G-85H3JMT22X', 'G-425LKZ18ZP'],
    cookieBotDomainGroupId: '39f20fac-d56f-415b-a0f7-ccc81a0da88a',

    project: 'lipno',

    config: {
        timeZone: 'Europe/Prague',
        dualPrices: true,
        cartButtonUrl: '/shopping/discount',
        registrationCheckbox: false,

        showBannerShoppingModuleOnTop: true,
        aboutCard: false,
        registerCardNumber: true,
        registerVOPCheckbox: false,
        birthdayEditDisabled: false,
        showB2BAllCategories: false,
        downloadPrintTickets: true,

        addCardType: false,
        exchangeTicketCardBirthday: false,
        addExternalCard: false,
        addCardModalImage: undefined,
        allowShopOwnedCard: false,
        allowAdultExternal: false,
        removeCard: true,
        showTimeSlotRange: false,
        showDatePrices: true,
        showTicketOrigin: true,
        cardPatternMask: [
            ...[...(new Array(8)).keys()].map(() => /[A-Z0-9]/i),
            '-',
            ...[...(new Array(3)).keys()].map(() => /[A-Z0-9]/i),
            '-',
            ...[...(new Array(3)).keys()].map(() => /[A-Z0-9]/i),
        ],
        cardPattern: /^[A-Z0-9]{8}-[A-Z0-9]{3}-[A-Z0-9]{3}/g,
        validateLuhnNumber: false,

        userInfo: ['firstName', 'lastName', 'birthday'],

        paths: {
            kod: true,
            karta: true,
            tablet: true,
            registerRedirect: 'registrace',
        },

        priorityCountriesOptions: ['CZ', 'AT', 'DE', 'NL'],

        ticketSystem: 'axess',
        priceDecimals: 0,

        personalHeaderItems: ['cards', 'orders', 'discounts', 'settings'],

        cookiesLink: '/terms/cookies',

        productCountLimit: 100,
    },

    social: {
        facebook: 'https://www.facebook.com/skiareallipno/',
        instagram: 'https://www.instagram.com/areallipno/',
        youtube: 'https://www.youtube.com/user/LipnoTV'
    },

    getPriceWithCurrency: (price) => {
        return `${price} Kč`;
    },

    getGlobalDisabledDates: (current) => {
        const d = moment(current).tz('Europe/Prague', true);
        const b = moment().tz('Europe/Prague').add(-1, 'day');

        return d.isSameOrBefore(b, 'day');
    }
};
