import React, { useState } from 'react';
import { message } from 'antd';

import { Modal, Button } from '../../components';
import { ModalSizes } from '../../constants/modal';
import { i18n } from '../../services/i18n';
import { api } from '../../../features/user/api';

interface Props {
    visible?: boolean;
    email: string;
    onConfirm?(): void;
}

const NewPasswordModal = ({ visible, email, onConfirm}: Props) => {
    const [loading, setLoading] = useState(false);

    const handleResendEmail = async () => {
        setLoading(true);
        try {
            const resp = await api.resendEmail(email);
            if (resp.status === 200) {
                message.success(i18n.t('newPasswordModal.resendSuccess'), 3);
                setLoading(false);
                onConfirm && onConfirm();
            }
        } catch (err) {
            message.error(i18n.t('newPasswordModal.resendError'), 3);
            setLoading(false);
        }
    };

    return (
        // zIndex = 1051 because @zindex-dropdown is 1050
        <Modal visible={visible} footer={null} width={ModalSizes.default} maskClosable={true} zIndex={1051}>
            <div className="new-password-modal">
                <h1>{i18n.t('newPasswordModal.title')}</h1>
                <p>{i18n.t('newPasswordModal.content')}</p>
                <p>{email}</p>
                <div className="btns">
                    <Button loading={loading} type="default" onClick={handleResendEmail}>{i18n.t('newPasswordModal.resendEmail')}</Button>
                    <Button type="primary" onClick={onConfirm}>{i18n.t('newPasswordModal.cancel')}</Button>
                </div>
            </div>
        </Modal>
    );
};

export default NewPasswordModal;
