import { oc } from 'ts-optchain';

import { LipnoCardTypes } from '../../common/models/Token';
import { LoginFormValues, RegisterFormValues, UserProfile } from '../../common/models/User';
import { apiClient } from '../../common/services/api-client';
import { environment } from '../../environments/environment';
import moment from 'moment';

export const api = {
    getCards: () =>
        apiClient.get(`/tokens/my`, {
            params: {
                type: 'keycard',
                $limit: '-1',
            },
        }),
    updateUser: (profile: UserProfile, body: Partial<RegisterFormValues>) => {
        return apiClient.patch(`/users/${profile.id}`, transformUpdateBody(profile, body));
    },
    verifyToken: (id: string, subTypes?: LipnoCardTypes[]) => {
        const params = {
            $limit: '-1',
            status: 'active',
        };

        if (environment.config.ticketSystem === 'axess') {
            params['extIdentifiers.axess.wtpNumber'] = id;
        } else {
            const [chipId, serialNumber, luhnNumber] = id.split('-');

            params['extIdentifiers.skidata.chipId'] = chipId;
            params['extIdentifiers.skidata.luhnNumber'] = luhnNumber;
            params['extIdentifiers.skidata.serialNumber'] = serialNumber;
        }

        if (subTypes) {
            if (environment.config.allowAdultExternal && subTypes.includes('adult')) {
                subTypes.push('external');
            }

            params['$or'] = [
                {
                    'meta.subType': {
                        $in: subTypes
                    }
                }
            ];

            if (environment.config.addCardType) {
                params['$or'].push({
                    'meta.subType': 'null',
                });
            }
        }

        return apiClient.get(`/tokens/card`, {
            params,
        });
    },
    createExternalToken: (id: string, owner?: string) => {
        const body = {
            status: 'active',
            meta: {
                subType: 'external',
            },
            owner: owner,
            extIdentifiers: {},
            type: 'keycard',
        };

        if (environment.config.ticketSystem === 'axess') {
            body['extIdentifiers.axess'] = {
                wtpNumber: id
            };
        } else {
            const [chipId, serialNumber, luhnNumber] = id.split('-');

            body['extIdentifiers.skidata'] = {
                chipId,
                serialNumber,
                luhnNumber,
            };
        }

        return apiClient.post(`/tokens/card`, body);
    },
    updateTokenOwner: (id: string, owner: string, birthday?: string, subType?: string) =>
        apiClient.patch(`/tokens/card/${id}`, {
            owner,
            birthday,
            'meta.subType': subType,
        }),
    login: (body: LoginFormValues) =>
        apiClient.post('/users/login', {
            ...body,
            applicationUrl: window.location.protocol + '//' + window.location.hostname,
        }),
    getProfile: () => apiClient.get('/users/profile'),
    logout: () => apiClient.post('/users/logout'),
    logoutWithFetch: () =>
        fetch(`${environment.protocol}://${environment.host}/users/logout`, {
            method: 'GET',
            headers: {
                Authorization: `Basic ${environment.basicAuthToken}`,
            },
        }),
    register: (body: RegisterFormValues, benefitKey?: string) =>
        apiClient.post(`/users/registration`, transformRegisterBody(body, benefitKey)),
    updateUserAgreements: (id: string) =>
        apiClient.patch(`/users/${id}/meta`, {
            terms: {
                marketing: new Date().toISOString(),
            },
        }),
    hasPassword: (email: string) => apiClient.get(`/users/haspassword`, {
        params: {
            email: email.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        }
    }),
    userWithEmail: (email: string) => apiClient.get(`/users`, {
        params: {
            email: email.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            $limit: 1,
        }
    }),
    resendEmail: (email: string) => apiClient.post('/users/create-password', { email }),
};

function transformUpdateBody(profile: UserProfile, values: Partial<RegisterFormValues>): Partial<UserProfile> {
    return {
        firstName: values.firstName,
        lastName: values.lastName,
        title: values.title,
        email: values.email,
        birthday: oc(values).birthday(''),
        city: values.city,
        country: values.country,
        postalCode: values.postalCode,
        password: values.password ? values.password : undefined,
        meta: {
            ...(profile.meta || {}),
            terms: {
                ...oc(profile).meta.terms({}),
                marketing: values.agreements ? new Date().toISOString() : undefined,
            },
        },
    };
}

function getRegistrationSource(path, benefit) {
    if (path === '/kod' && benefit) {
        return '/kod';
    } else if (path === '/tablet' && benefit) {
        return '/tablet';
    } else if (path === '/karta' && benefit) {
        return '/karta';
    } else if (path === '/shopping/discount') {
        return 'shopping';
    } else {
        return 'eshop';
    }
}

function transformRegisterBody(
    values: RegisterFormValues,
    benefitKey?: string
): Partial<UserProfile & { password: string }> {
    const benefitsAddon = {
        benefits: [benefitKey],
    };

    const base = {
        firstName: values.firstName,
        lastName: values.lastName,
        title: values.title,
        email: values.email.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        birthday: oc(values).birthday(''),
        city: values.city,
        country: values.country,
        password: values.password,
        postalCode: values.postalCode,
        tokens: values.cardNumbers
            .filter(cn => cn.number !== '')
            .map(cn => ({
                number: cn.number,
                birthday: environment.config.addCardType && cn.birthday
                    ? moment(cn.birthday, 'DD.MM.YYYY').format()
                    : undefined,
                subType: environment.config.addCardType && cn.birthday
                    ? moment(cn.birthday, 'DD.MM.YYYY').isBefore(moment().subtract(15, 'years'))
                        ? 'adult'
                        : 'child'
                    : undefined,
            })),
        applicationUrl: window.location.protocol + '//' + window.location.hostname,
        meta: {
            registration: {
                channel:
                    'eshop - ' + getRegistrationSource(window.location.pathname, benefitsAddon),
                country: 'CZ',
            },
            terms: {
                tc: new Date().toISOString(),
                privacy: new Date().toISOString(),
                marketing: values.agreements ? new Date().toISOString() : undefined,
                vop: values.vop ? new Date().toISOString() : undefined,
            },
        },
    };
    return Object.assign(base, benefitKey ? benefitsAddon : {});
}
