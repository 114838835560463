import { environment } from '../../../../environments/environment';

export const menu = [
    {
        link: '/personal/cards',
        key: 'header.loggedDropdown.lipnoCards',
        icon: 'idcard',
        tag: 'cards',
    },
    {
        link: '/personal/orders',
        key: 'header.loggedDropdown.myOrders',
        icon: 'book',
        tag: 'orders',
    },
    {
        link: '/personal/discounts',
        key: 'header.loggedDropdown.discountCenter',
        icon: 'fall',
        tag: 'discounts',
    },
    {
        link: '/personal/settings',
        key: 'header.loggedDropdown.settings',
        icon: 'setting',
        tag: 'settings',
    },
].filter(i => environment.config.personalHeaderItems.includes(i.tag));
