import { Col, Divider, Row } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { oc } from 'ts-optchain';

import { AddCardModal } from '../../../../common/modals';
import { AppState } from '../../../../common/models/AppState';
import { LipnoCardTypes, Token } from '../../../../common/models/Token';
import { i18n } from '../../../../common/services/i18n';
import {
    cardUpdateActions,
    CardValidationState,
    cardVerifyActions,
    cardVerifyResetAction,
    loadCardsActions,
    resetTemporaryCardsAction,
    selectCardValidationStatus,
    selectUserCards,
} from '../../../user/ducks';
import {
    Button,
    Layout,
    LipnoCard,
    ReferenceWrapper,
    SideWizard,
} from '../.././../../common/components';
import { createRefs } from '../../../../common/components/ReferenceWrapper';
import { getCardNumber } from '../../../../common/utils/project-helpers';
import { environment } from '../../../../environments/environment';

interface DispatchToProps {
    removeCard: (payload: { tokenId: string; owner: string | null }) => void;
    verifyCard: (payload: {
        wtpNumber: string;
        addToUser?: boolean;
        birthday?: string;
        subType?: string;
    }) => void;
    resetVerifyCard: () => void;
    resetTemporaryCards: () => void;
    fetchCards: () => void;
}

interface StateToProps {
    cards: Token[];
    validationStatus: CardValidationState;
}

type Props = DispatchToProps & StateToProps;

function CardsComponent(props: Props) {
    const [addCardModal, setAddCardModal] = useState(false);
    const [refs, setRefs] = useState({});

    useEffect(() => {
        props.fetchCards();
    }, []);

    useEffect(
        () => {
            setRefs(createRefs(props.cards));
        },
        [props.cards]
    );

    function handleCardRemoveClick(id: string) {
        props.removeCard && props.removeCard({ tokenId: id, owner: null });
    }

    function handleModalOkClick() {
        setAddCardModal(false);
        props.resetVerifyCard();
        props.resetTemporaryCards();
    }

    function handleModalCancel() {
        setAddCardModal(false);
        props.resetVerifyCard();
    }

    function handleAddCardClick() {
        props.resetVerifyCard();
        setAddCardModal(true);
    }

    function getWizardTitle(card: Token) {
        return `${i18n.t('cardName')} #${getCardNumber(card.extIdentifiers)}`;
    }

    function handleCardValidate(wtpNumber: string, addToUser?: boolean, birthday?: string, subType?: string) {
        props.verifyCard({ wtpNumber, addToUser, birthday, subType });
    }

    return (
        <Fragment>
            <AddCardModal
                visible={addCardModal}
                error={props.validationStatus.error}
                exists={props.validationStatus.exists}
                verified={props.validationStatus.verified}
                card={props.validationStatus.card}
                onValidate={handleCardValidate}
                onCancel={handleModalCancel}
                onOk={handleModalOkClick}
            />
            <Layout.Content>
                <Layout.Container>
                    <Row className="cards">
                        <Col xs={0} md={6} className="orders__left">
                            {props.cards.length !== 0 && (
                                <SideWizard
                                    refs={refs}
                                    title={`${i18n.t('personal.cards.overviewTitle')} (${
                                        props.cards.length
                                    })`}
                                    items={props.cards.map(card => ({
                                        title: getWizardTitle(card),
                                        description: LipnoCard.transformTitle(oc(
                                            card
                                        ).meta.subType() as LipnoCardTypes),
                                    }))}
                                />
                            )}
                            <Divider />
                            <Button size="small" onClick={handleAddCardClick} type="primary" block>
                                {i18n.t('cards.addNew')}
                            </Button>
                        </Col>
                        <Col xs={24} md={18} className="orders__right">
                            <h3>{i18n.t('personal.cards.title')}</h3>
                            {props.cards.map((card, index) => (
                                <ReferenceWrapper key={card._id} reference={refs[index]}>
                                    <LipnoCard
                                        id={getCardNumber(card.extIdentifiers)}
                                        token={card._id}
                                        type={oc(card).meta.subType() as LipnoCardTypes}
                                        birthday={oc(card).birthday()}
                                        enableRemove={environment.config.removeCard}
                                        onRemove={handleCardRemoveClick}
                                    />
                                </ReferenceWrapper>
                            ))}
                            <Button onClick={handleAddCardClick} type="primary" block>
                                {i18n.t('cards.addNew')}
                            </Button>
                        </Col>
                    </Row>
                </Layout.Container>
            </Layout.Content>
        </Fragment>
    );
}

const mapDispatchToProps: DispatchToProps = {
    verifyCard: cardVerifyActions.request,
    resetVerifyCard: cardVerifyResetAction,
    resetTemporaryCards: resetTemporaryCardsAction,
    removeCard: cardUpdateActions.request,
    fetchCards: loadCardsActions.request,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    cards: selectUserCards(state),
    validationStatus: selectCardValidationStatus(state),
});

export const Cards = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardsComponent);
