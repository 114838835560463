import { combineReducers } from 'redux';
import { AppState } from '../../common/models/AppState';
import {
    createActionCreator, createActionType,
    createApiActionCreators,
    createReducer,
    RequestActionTypes
} from '../../common/utils/redux-helpers';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { api } from './api';
import { Product } from '../../common/models/Product';
import moment from 'moment';
import { delay } from 'redux-saga';
import { fetchOrdersActions } from '../personal/ducks';
import { Entry } from '../../common/models/NewTransaction';

export interface UpdateInfo {
    validFrom: string;
    inclusiveValidity: string[];
    timeSlot?: string;
    timeSlotEnd?: string;
    timeSlotGroup?: string;
    status?: string;
}

export interface UpdateValidityAction {
    id: string;
    validFrom: string;
    timeSlot: string;
    timeSlotGroup: string;
    inclusiveValidity: string[];
}

export interface UpdateStatusAction {
    id: string;
    status: string;
}

export interface StartProcessAction {
    transactionId: string;
    email?: string;
    items: Entry[];
}

export interface DateChangeState {
    transactionId: string | null;
    email: string | null;
    items: Entry[];
    updateInfo: Record<string, UpdateInfo>;
    activeStep: number;

    activeProduct: Product | null;

    changeId: string | null;
    status: string;
}

/**
 * ACTION TYPES
 */
export enum DateChangeActionTypes {
    StartProcess = '@@TransactionDateChange/START_PROCESS',
    CancelProcess = '@@TransactionDateChange/CANCEL_PROCESS',
    SetEmail = '@@TransactionDateChange/SET_EMAIL',
    SetStep = '@@TransactionDateChange/SET_STEP',
    UpdateItemValidity = '@@TransactionDateChange/UPDATE_ITEM_VALIDITY',
    UpdateItemStatus = '@@TransactionDateChange/UPDATE_ITEM_STATUS',
    SubmitProcess = '@@TransactionDateChange/SUBMIT_PROCESS',
    SetProcessStatus = '@@TransactionDateChange/SET_PROCESS_STATUS',
}

/**
 * ACTIONS
 */
export const startProcessAction = createActionCreator(DateChangeActionTypes.StartProcess);
export const cancelProcessAction = createActionCreator(DateChangeActionTypes.CancelProcess);
export const setEmailAction = createActionCreator(DateChangeActionTypes.SetEmail);
export const setStepAction = createApiActionCreators(DateChangeActionTypes.SetStep);
export const updateItemValidityAction = createActionCreator(DateChangeActionTypes.UpdateItemValidity);
export const updateItemStatusAction = createActionCreator(DateChangeActionTypes.UpdateItemStatus);
export const submitProcessAction = createApiActionCreators(DateChangeActionTypes.SubmitProcess);
export const setProcessStatusAction = createActionCreator(DateChangeActionTypes.SetProcessStatus);

/**
 * REDUCERS
 */
const initialState: DateChangeState = {
    transactionId: null,
    email: null,
    items: [],
    updateInfo: {},
    activeStep: 0,

    activeProduct: null,

    changeId: null,
    status: 'idle',
};

const transactionId = createReducer(initialState.transactionId, {
    [DateChangeActionTypes.StartProcess]: (state: string, payload: StartProcessAction) => payload.transactionId,
    [DateChangeActionTypes.CancelProcess]: () => null,
});

const email = createReducer(initialState.email, {
    [DateChangeActionTypes.StartProcess]: (state: string, payload: StartProcessAction) => payload.email || null,
    [DateChangeActionTypes.CancelProcess]: () => null,
    [DateChangeActionTypes.SetEmail]: (state: string, payload: string) => payload,
});

const items = createReducer(initialState.items, {
    [DateChangeActionTypes.StartProcess]: (state: Entry[], payload: StartProcessAction) => payload.items,
    [DateChangeActionTypes.CancelProcess]: () => [],
});

const updateInfo = createReducer(initialState.updateInfo, {
    [DateChangeActionTypes.StartProcess]: () => ({}),
    [DateChangeActionTypes.CancelProcess]: () => ({}),
    [DateChangeActionTypes.UpdateItemValidity]: (state: Record<string, UpdateInfo>, payload: UpdateValidityAction) => ({
        ...state,
        [payload.id]: {
            validFrom: payload.validFrom,
            inclusiveValidity: payload.inclusiveValidity,
            timeSlot: payload.timeSlot,
            timeSlotEnd: payload.timeSlot && payload.timeSlot !== '00:00'
                ? moment(payload.timeSlot, 'HH:mm')
                    .add(30, 'minutes')
                    .format('HH:mm')
                : undefined,
            timeSlotGroup: payload.timeSlotGroup,
        }
    }),
    [DateChangeActionTypes.UpdateItemStatus]: (state: Record<string, UpdateInfo>, payload: UpdateStatusAction) => ({
        ...state,
        [payload.id]: {
            ...(state[payload.id] || {}),
            status: payload.status,
        }
    }),
});

const activeStep = createReducer(initialState.activeStep, {
    [DateChangeActionTypes.StartProcess]: () => 0,
    [DateChangeActionTypes.CancelProcess]: () => 0,
    [DateChangeActionTypes.SetStep]: {
        [RequestActionTypes.REQUEST]: (state: number, payload: number) => payload,
    }
});

const activeProduct = createReducer(initialState.activeProduct, {
    [DateChangeActionTypes.StartProcess]: () => null,
    [DateChangeActionTypes.CancelProcess]: () => null,
    [DateChangeActionTypes.SetStep]: {
        [RequestActionTypes.REQUEST]: () => null,
        [RequestActionTypes.SUCCESS]: (state: Product | null, payload: Product) => payload,
    }
});

const changeId = createReducer(initialState.changeId, {
    [DateChangeActionTypes.StartProcess]: () => null,
    [DateChangeActionTypes.CancelProcess]: () => null,
    [DateChangeActionTypes.SubmitProcess]: {
        [RequestActionTypes.REQUEST]: () => null,
        [RequestActionTypes.SUCCESS]: (state: string | null, payload: string) => payload,
    }
});

const status = createReducer(initialState.status, {
    [DateChangeActionTypes.StartProcess]: () => 'idle',
    [DateChangeActionTypes.CancelProcess]: () => 'idle',
    [DateChangeActionTypes.SetProcessStatus]: (state: string, payload: string) => payload,
    [DateChangeActionTypes.SubmitProcess]: {
        [RequestActionTypes.REQUEST]: () => 'loading',
        [RequestActionTypes.SUCCESS]: () => 'created',
        [RequestActionTypes.FAILURE]: () => 'failed',
    }
});

export default combineReducers<DateChangeState>({
    transactionId,
    email,
    items,
    updateInfo,
    activeStep,
    activeProduct,
    changeId,
    status,
});

/**
 * SELECTORS
 */
const selectDateChange = (state: AppState) => state.dateChange;

export const selectIsVisible = (state: AppState) => selectDateChange(state).items.length > 0;

export const selectActiveStep = (state: AppState) => selectDateChange(state).activeStep;

export const selectChangeItems = (state: AppState) => selectDateChange(state).items;

export const selectEmail = (state: AppState) => selectDateChange(state).email;

export const selectTransactionId = (state: AppState) => selectDateChange(state).transactionId;

export const selectUpdateInfo = (state: AppState) => selectDateChange(state).updateInfo;

export const selectChangeStatus = (state: AppState) => selectDateChange(state).status;

export const selectChangeId = (state: AppState) => selectDateChange(state).changeId;

export const selectActiveItem = (state: AppState) => {
    const items = selectChangeItems(state);
    const step = selectActiveStep(state);

    if (step < 1 || step > items.length) {
        return null;
    }

    return items[step - 1];
};

export const selectActiveUpdateInfo = (state: AppState) => {
    const items = selectChangeItems(state);
    const step = selectActiveStep(state);
    const update = selectUpdateInfo(state);

    if (step < 1 || step > items.length) {
        return null;
    }

    return update[items[step - 1].uuid];
};

export const selectOccupiedTimeSlots = (state: AppState) => {
    const items = selectChangeItems(state);
    const updateInfo = selectUpdateInfo(state);
    const activeItem = selectActiveItem(state);

    return items.reduce((acc, item) => {
        const { timeSlot, timeSlotGroup, inclusiveValidity } = updateInfo[item.uuid] || {} as any;

        if (timeSlot && timeSlotGroup && (!activeItem || item.uuid !== activeItem.uuid)) {
            acc[timeSlotGroup] = inclusiveValidity
                .reduce((times, date) => {
                    times[date] = times[date] || {};
                    times[date][timeSlot] = (times[date][timeSlot] || 0) + 1;

                    return times;
                }, acc[timeSlotGroup] || {});
        }

        return acc;
    }, {});
};

export const selectActiveProduct = (state: AppState) => selectDateChange(state).activeProduct;

/**
 * SAGAS
 */
function* loadStepData() {
    const activeItem: Entry = yield select(selectActiveItem);

    if (!activeItem) {
        return;
    }

    const product = yield call(api.getProductById, activeItem.product.productId);
    const variant = yield call(api.getProductById, activeItem.product.variantId);

    if (product.ok && variant.ok) {
        product.data.variants = [variant.data];

        yield put(setStepAction.success(product.data));
    }
}

function* checkChangeStatus() {
    const txId = yield select(selectTransactionId);
    const changeId = yield select(selectChangeId);

    if (!changeId) {
        return;
    }

    const resp = yield call(api.getChangeStatus, txId, changeId);

    if (resp.ok) {
        const { updates } = resp.data;

        for (const item of updates) {
            yield put(updateItemStatusAction({ id: item.uuid, status: item.status }));
        }

        if (!updates.some(i => i.status === 'requested')) {
            const success = updates.every(i => i.status === 'success');
            yield put(setProcessStatusAction(success ? 'success' : 'failed'));

            if (success) {
                yield put(fetchOrdersActions.request());
            }

            return;
        }
    }

    yield delay(1000);
    yield call(checkChangeStatus);
}

function* submitChangeRequest() {
    const items = yield select(selectUpdateInfo);
    const email = yield select(selectEmail);
    const txId = yield select(selectTransactionId);

    const resp = yield call(api.submitChangeProcess, txId, email, items);

    if (resp.ok) {
        yield put(submitProcessAction.success(resp.data.id));
        yield call(checkChangeStatus);
    } else {
        yield put(submitProcessAction.failure());
    }
}

export function* dateChangeSaga() {
    yield takeLatest(
        createActionType(DateChangeActionTypes.SetStep, RequestActionTypes.REQUEST),
        loadStepData
    );

    yield takeLatest(
        createActionType(DateChangeActionTypes.SubmitProcess, RequestActionTypes.REQUEST),
        submitChangeRequest
    );
}
