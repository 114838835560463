import { Icon } from 'antd';
import React, { FunctionComponent, ReactNode } from 'react';

import { Dropdown, Menu } from '../../components';

interface MenuItem {
    title: ReactNode;
    onClick?: () => void;
}

interface Props {
    menuItems?: MenuItem[];
    placeholder?: string;
    children?: ReactNode;
}

const DropdownMenu: FunctionComponent<Props> = ({
    menuItems = [],
    placeholder,
    children,
    ...bag
}) => {
    const renderMenu = () => (
        <Menu>
            {menuItems.map((item, index) => (
                <Menu.Item onClick={item.onClick} key={index}>
                    <React.Fragment>{item.title}</React.Fragment>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown overlay={renderMenu()} placeholder={placeholder} {...bag}>
            {children} <Icon type="down" />
        </Dropdown>
    );
};

export default DropdownMenu;
