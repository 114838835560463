import { Layout } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';
import React from 'react';

import { Container } from './Container';

const EnhancedLayout = (props: BasicProps) => <Layout {...props} />;

EnhancedLayout.Header = Layout.Header;
EnhancedLayout.Content = Layout.Content;
EnhancedLayout.Footer = Layout.Footer;
EnhancedLayout.Container = Container;

export { Footer } from './Footer';
export default EnhancedLayout;
