import { apiClient } from '../../common/services/api-client';

export const api = {
    validateToken: (token: string, userId: string) =>
        apiClient.post(`/benefits/event?dryRun=true`, {
            benefits: [token],
            user: {
                id: userId,
            },
        }),
    runBenefitEvent: (token: string, userId: string) =>
        apiClient.post(`/benefits/event`, {
            benefits: [token],
            user: {
                id: userId,
            },
        }),
};
