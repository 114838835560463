import { SelectValue } from 'antd/lib/select';
import { Field, FieldProps } from 'formik';
import React, { ReactNode } from 'react';
import { useRegisteredRef } from 'react-register-nodes';

import { Select } from '../../components';
import { SelectOption } from '../../components/Form/Select';
import { useIsMobileWide } from '../../hooks/useIsMobileWide';

interface Props {
    name: string;
    options: SelectOption[];
    placeholder?: ReactNode;
    label?: ReactNode;
    disabled?: boolean;
    noFoundContent?: ReactNode;
    priorityOptions?: string[];
}

export default ({ name, options, disabled = false, noFoundContent, ...bag }: Props) => {
    const isMobile = useIsMobileWide();
    const ref = useRegisteredRef(name);

    return (
        <Field name={name}>
            {({ field: { onChange, value, ...fieldBag }, form }: FieldProps<any>) => {
                function handleChange(value: SelectValue) {
                    form.setFieldValue(name, value);
                }

                function handleFilterOption(input: string, option: any) {
                    return option.props.children
                        .toString()
                        .toLowerCase()
                        .startsWith(input.toLowerCase());
                }

                return (
                    <div ref={form.touched[name] && form.errors[name] ? ref : null}>
                        <Select
                            value={value ? value : undefined}
                            onChange={handleChange}
                            options={options}
                            error={form.errors[name]}
                            disabled={disabled}
                            showSearch={isMobile ? false : true}
                            filterOption={!isMobile ? handleFilterOption : false}
                            notFoundContent={noFoundContent}
                            {...fieldBag}
                            {...bag}
                        />
                    </div>
                );
            }}
        </Field>
    );
};
