import { i18n } from '../../../../../../common/services/i18n';
import { Statistic } from 'antd';
import React from 'react';
import { TicketItem } from '../../../../../../common/models/NewTransaction';

interface Props {
    item: TicketItem;
}

const Item = ({ item }: Props) => {
    return (
        <div className="b2b-ticket">
            <div className="b2b-ticket__content">
                <div className="b2b-ticket__content__left">
                    <h3>
                        {i18n.t('b2b.confirmation.item.title')}
                    </h3>
                    <div className="b2b-ticket__info">
                        <Statistic title={i18n.t('b2b.confirmation.item.product')} value={item.name}/>
                        {item.type !== 'no-card-item' && (
                            <>
                                <Statistic title={i18n.t('b2b.confirmation.item.type')} value={item.variant}/>
                                <Statistic title={i18n.t('b2b.confirmation.item.date')} value={item.validity}/>
                            </>
                        )}
                    </div>
                    <p>
                        {i18n.t('b2b.confirmation.item.description')}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Item;
