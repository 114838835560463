import * as faker from 'faker';

export const skipases = [
    {
        label: '1 dňový skipas',
        value: '1',
    },
    {
        label: '2 dňový skipas',
        value: '2',
    },
    {
        label: '3 dňový skipas',
        value: '3',
    },
    {
        label: '4 dňový skipas',
        value: '4',
    },
    {
        label: '5 dňový skipas',
        value: '5',
    },
    {
        label: '6 dňový skipas',
        value: '6',
    },
    {
        label: 'Týždňový skipas',
        value: '7',
    },
    {
        label: 'Mesačný skipas',
        value: '8',
    },
];

export const tradingProducts = [
    {
        title: 'Zľava na Aqua world s Lipno card',
        description:
            'Spojili sme pre vás s výhodnou cenou Stezku korunami stromov a Království lesa. Užite si návštevu u nás dvojnásobne a ešte ušetrite.',
        price: `${faker.random.number({ min: 100, max: 200 })} CZK`,
        familyPrice: `${faker.random.number({ min: 100, max: 200 })} CZK`,
        bgImage: '/mock/trending/1.png',
    },
    {
        title: 'Stezka korunami stromov pre rodiny ',
        description:
            'Spojili sme pre vás s výhodnou cenou Stezku korunami stromov a Království lesa. Užite si návštevu u nás dvojnásobne a ešte ušetrite.',
        price: `${faker.random.number({ min: 100, max: 200 })} CZK`,
        familyPrice: `${faker.random.number({ min: 100, max: 200 })} CZK`,
        bgImage: '/mock/trending/2.jpg',
    },
    {
        title: 'Královský balíček pre celú rodinu',
        description:
            'Spojili sme pre vás s výhodnou cenou Stezku korunami stromov a Království lesa. Užite si návštevu u nás dvojnásobne a ešte ušetrite.',
        price: `${faker.random.number({ min: 100, max: 200 })} CZK`,
        familyPrice: `${faker.random.number({ min: 100, max: 200 })} CZK`,
        bgImage: '/mock/trending/3.png',
    },
    {
        title: 'Zľava na lyžiarsku školu',
        description:
            'Spojili sme pre vás s výhodnou cenou Stezku korunami stromov a Království lesa. Užite si návštevu u nás dvojnásobne a ešte ušetrite.',
        price: `${faker.random.number({ min: 100, max: 200 })} CZK`,
        familyPrice: `${faker.random.number({ min: 100, max: 200 })} CZK`,
        bgImage: '/mock/trending/4.png',
    },
];
