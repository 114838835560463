import { Col, Row } from 'antd';
import { isEqual } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { ModalSizes } from '../../../../common/constants/modal';
import { AppState } from '../../../../common/models/AppState';
import { RegisterFormValues, UserProfile } from '../../../../common/models/User';
import { i18n } from '../../../../common/services/i18n';
import {
    selectMarketingAgreement,
    selectUserProfile,
    updateProfileActions,
} from '../../../user/ducks';
import { Layout, Modal, SettingsForm } from '../.././../../common/components';

interface DispatchToProps {
    updateProfile: (values: Partial<RegisterFormValues>) => void;
}
interface StateToProps {
    profile?: UserProfile;
    marketingAgreement?: boolean;
}

type Props = DispatchToProps & StateToProps;

export function SettingsComponent({ profile, marketingAgreement, updateProfile }: Props) {
    function handleSubmit(values: Partial<RegisterFormValues>) {
        if (!values.agreements && marketingAgreement) {
            Modal.confirm({
                title: i18n.t('personal.agrrementsConfirm.title'),
                okText: i18n.t('personal.agrrementsConfirm.confirm'),
                cancelText: i18n.t('personal.agrrementsConfirm.cancel'),
                okButtonProps: { block: true, type: 'default' },
                cancelButtonProps: { block: true, type: 'primary' },
                width: ModalSizes.default,
                onOk() {},
                onCancel() {
                    updateProfile(values);
                },
            });
        } else {
            updateProfile(values);
        }
    }

    return (
        <Layout.Content>
            <Layout.Container>
                <Row className="settings">
                    <Col span={24}>
                        <SettingsForm
                            initialValues={{ ...profile, agreements: marketingAgreement }}
                            onSubmit={handleSubmit}
                        />
                    </Col>
                </Row>
            </Layout.Container>
        </Layout.Content>
    );
}

const mapStateToProps = (state: AppState): StateToProps => ({
    profile: selectUserProfile(state),
    marketingAgreement: selectMarketingAgreement(state),
});

const mapDispatchToProps: DispatchToProps = {
    updateProfile: updateProfileActions.request,
};

export const Settings = connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsComponent);
