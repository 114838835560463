import React from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../components';
import { ModalSizes } from '../../constants/modal';
import Intro from './components/Intro';
import Step from './components/Step';
import { AppState } from '../../models/AppState';
import {
    cancelProcessAction,
    selectActiveStep, selectChangeItems, selectChangeStatus,
    selectIsVisible, selectUpdateInfo,
    setStepAction, submitProcessAction, UpdateInfo
} from '../../../features/dateChange/ducks';
import Confirmation from './components/Confirmation';
import { Entry } from '../../models/NewTransaction';

const ChangeTransactionDateModal = ({
    activeStep,
    visible,
    items,
    setStep,
    cancelProcess,
    submitProcess,
    status,
    updates,
}: StateToProps & DispatchToProps) => {
    const handleBackClick = () => {
        setStep(activeStep - 1);
    };

    const handleCancelClick = () => {
        cancelProcess();
    };

    const handleNextClick = () => {
        setStep(activeStep + 1);
    };

    const handleSubmitClick = () => {
        if (['success', 'failed'].includes(status)) {
            cancelProcess();
        } else {
            submitProcess();
        }
    };

    return (
        <Modal
            visible={visible}
            width={ModalSizes.large}
            footer={null}
            closable={false}
        >
            <div className="change-transaction-date-modal">
                {activeStep === 0 && (
                    <Intro items={items} onBackClick={handleCancelClick} onNextClick={handleNextClick} />
                )}
                {activeStep > 0 && activeStep <= items.length && (
                    <Step total={items.length} onBackClick={handleBackClick} onNextClick={handleNextClick} />
                )}
                {activeStep === items.length + 1 && (
                    <Confirmation
                        items={items}
                        updates={updates}
                        status={status}
                        onBackClick={handleBackClick}
                        onSubmitClick={handleSubmitClick}
                    />
                )}
            </div>
        </Modal>
    );
};

interface StateToProps {
    visible: boolean;
    items: Entry[];
    activeStep: number;
    status: string;
    updates: Record<string, UpdateInfo>;
}

interface DispatchToProps {
    cancelProcess: () => void;
    setStep: (step: number) => void;
    submitProcess: () => void;
}

const mapStateToProps = (state: AppState): StateToProps => ({
    visible: selectIsVisible(state),
    items: selectChangeItems(state),
    activeStep: selectActiveStep(state),
    status: selectChangeStatus(state),
    updates: selectUpdateInfo(state),
});

const mapDispatchToProps: DispatchToProps = {
    cancelProcess: cancelProcessAction,
    setStep: setStepAction.request,
    submitProcess: submitProcessAction.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTransactionDateModal);
