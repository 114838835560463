import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';

import { i18n } from '../../../../services/i18n';
import TransactionItem from '../TransactionItem';
import { Button, Input } from '../../../../components';
import * as Yup from 'yup';
import { AppState } from '../../../../models/AppState';
import { selectEmail, setEmailAction } from '../../../../../features/dateChange/ducks';
import { Entry } from '../../../../models/NewTransaction';

interface Props {
    items: Entry[];
    onBackClick: () => void;
    onNextClick: () => void;
}

const Intro = ({ items, email, setEmail, onBackClick, onNextClick }: Props & StateToProps & DispatchToProps) => {
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    return (
        <>
            <div className="intro">
                <h3 className="intro__header">
                    {i18n.t('changeTransactionDateModal.intro.title')}
                </h3>
                <p className="intro__description">
                    {i18n.t('changeTransactionDateModal.intro.description')}
                </p>
                {items.map(item => (<TransactionItem key={item.uuid} entry={item} />))}
                <div className="intro__email">
                    <Input
                        error={email !== null && !Yup.string().required().email().isValidSync(email)}
                        value={email || ''}
                        onChange={handleEmailChange}
                        placeholder={i18n.t('changeTransactionDateModal.intro.email.placeholder')}
                        label={i18n.t('changeTransactionDateModal.intro.email.label')}
                    />
                </div>
            </div>
            <div className="change-transaction-date-modal__footer">
                <Button onClick={onBackClick} type="primary">
                    {i18n.t('changeTransactionDateModal.footer.cancel')}
                </Button>
                <Button
                    onClick={onNextClick}
                    type="success"
                    disabled={!email || !Yup.string().required().email().isValidSync(email)}
                >
                    {i18n.t('changeTransactionDateModal.footer.next')}
                </Button>
            </div>
        </>
    );
};

interface StateToProps {
    email: string | null;
}

interface DispatchToProps {
    setEmail: (email: string) => void;
}

const mapStateToProps = (state: AppState): StateToProps => ({
    email: selectEmail(state),
});

const mapDispatchToProps: DispatchToProps = {
    setEmail: setEmailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Intro);
