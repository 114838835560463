import cn from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

interface Props {
    icon: ReactNode;
    content?: ReactNode;
    extra?: ReactNode;
    disabled?: boolean;
}

const SelectLabel: FunctionComponent<Props> = ({ content, icon, extra, disabled }) => (
    <div
        className={cn('assigner-select-label', {
            'assigner-select-label--disabled': disabled,
        })}
    >
        <div className="assigner-select-label__icon">{icon}</div>
        <div className="assigner-select-label__content">{content}</div>
        <div className="assigner-select-label__extra">{extra}</div>
    </div>
);

export default SelectLabel;
