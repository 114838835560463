import { Col, Row } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import {
    Footer,
    Header,
    Layout,
    TopImage,
    PageDescription,
    Savings,
    ImageSideDescription,
    SavingsArray,
} from '../../common/components';
import { categories } from '../personal/features/discounts/categories';

import { connect } from 'react-redux';

import React, { Fragment, useEffect, useState } from 'react';
import Carousel from '../../common/components/Carousel';

import Card from 'antd/es/card';
import { AppState, Season } from '../../common/models/AppState';
import { DiscountsState, fetchDiscountsAction, selectDiscounts } from '../personal/ducks';
import { i18n } from '../../common/services/i18n';
import { Link } from 'react-router-dom';
import DynamicImg from '../../common/components/DynamicImg';
import { oc } from 'ts-optchain';
import PartnerBenefit from '../../common/components/PartnerBenefit';
import { selectSeason } from '../shopping/ducks';
import { environment } from '../../environments/environment';

type Props = RouteComponentProps & StateToProps & DispatchToProps;

function AboutCardComponent({ season, ...props }: Props) {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized) {
            props.fetchDiscounts();
            setInitialized(true);
        }
    });

    function renderCarousel() {
        const category = categories.find(category => category.key === 'partner');

        return (
            <React.Fragment>
                <div style={{ backgroundColor: '#F6F6F6' }}>
                    <div className={'container'}>
                        <Row type="flex" justify="center" className="aboutCard-carousel">
                            <Col xs={24} md={18}>
                                {props.discounts.partner.length > 0 && (
                                    <Carousel
                                        title={i18n.t(oc(category).title(''))}
                                        description={i18n.t(oc(category).description(''))}
                                        content={
                                            <Fragment>
                                                {props.discounts.partner.map((benefit) => (
                                                    <PartnerBenefit
                                                        key={benefit.benefitId}
                                                        benefit={benefit}
                                                    />
                                                ))}
                                            </Fragment>
                                        }
                                    />
                                )}

                                <p className="center">
                                    <Link to={'/eshop/shopping'}>
                                        {i18n.t('aboutLipno.backToHome')}
                                    </Link>
                                </p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    function renderContent() {
        return (
            <React.Fragment>
                <div className="content-section container">
                    <ImageSideDescription
                        title={i18n.t('aboutLipno.pt1.header')}
                        description={i18n.t('aboutLipno.pt1.description')}
                        image={
                            <DynamicImg
                                srcs={[
                                    `${environment.mediaUrl}/static/eshop/about-card.pt1.season-${season.type}`,
                                    `${environment.mediaUrl}/static/eshop/about-card.pt1`,
                                    '/images/about-card-paragraph.jpg'
                                ]}
                                alt={i18n.t('aboutLipno.pt1.header')}
                                className="rounded-image"
                            />
                        }
                        imgLocation={'right'}
                        children={
                            <Savings
                                basePrice={i18n.t('aboutLipno.pt1.basePrice')}
                                discountPrice={i18n.t('aboutLipno.pt1.discountPrice')}
                                type="numeric"
                            />
                        }
                    />

                    <ImageSideDescription
                        title={i18n.t('aboutLipno.pt2.header')}
                        description={i18n.t('aboutLipno.pt2.description')}
                        image={
                            <DynamicImg
                                srcs={[
                                    `${environment.mediaUrl}/static/eshop/about-card.pt2.season-${season.type}`,
                                    `${environment.mediaUrl}/static/eshop/about-card.pt2`,
                                    '/images/about-card-paragraph.jpg'
                                ]}
                                alt={i18n.t('aboutLipno.pt2.header')}
                                className="rounded-image"
                            />
                        }
                        imgLocation={'left'}
                        children={
                            <SavingsArray
                                basePrice={[
                                    Number(i18n.t('aboutLipno.pt2.basePrice1')),
                                    Number(i18n.t('aboutLipno.pt2.basePrice2')),
                                ]}
                                discountPrice={[
                                    Number(i18n.t('aboutLipno.pt2.discountPrice1')),
                                    Number(i18n.t('aboutLipno.pt2.discountPrice2')),
                                ]}
                                prefix={[
                                    i18n.t('aboutLipno.firstEntry'),
                                    i18n.t('aboutLipno.secondEntry'),
                                ]}
                            />
                        }
                    />

                    <ImageSideDescription
                        title={i18n.t('aboutLipno.pt3.header')}
                        description={i18n.t('aboutLipno.pt3.description')}
                        image={
                            <DynamicImg
                                srcs={[
                                    `${environment.mediaUrl}/static/eshop/about-card.pt3.season-${season.type}`,
                                    `${environment.mediaUrl}/static/eshop/about-card.pt3`,
                                    '/images/about-card-paragraph.jpg'
                                ]}
                                alt={i18n.t('aboutLipno.pt3.header')}
                                className="rounded-image"
                            />
                        }
                        imgLocation={'right'}
                    />

                    <Row type="flex" justify="center">
                        <Col sm={22} md={20}>
                            <Card className={'aboutCard-card'}>
                                <PageDescription
                                    title={i18n.t('aboutLipno.bottomCard.header')}
                                    description={i18n.t('aboutLipno.bottomCard.description')}
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }

    return (
        <Layout>
            <Header />
            <Layout.Content style={{ backgroundColor: 'white' }}>
                <TopImage
                    img={
                        <DynamicImg
                            srcs={[
                                `${environment.mediaUrl}/static/eshop/about-card.header.season-${season.type}`,
                                `${environment.mediaUrl}/static/eshop/about-card.header`,
                                '/images/about-card-header.png'
                            ]}
                            alt={i18n.t('aboutLipno.headline.header')}
                            className="top-image__img"
                        />
                    }
                    alt={'alt'}
                    title={i18n.t('aboutLipno.headline.header')}
                    description={i18n.t('aboutLipno.headline.description')}
                />
                <div style={{ backgroundColor: 'white' }}> {renderContent()} </div>
                {renderCarousel()}
            </Layout.Content>
            <Footer />
        </Layout>
    );
}

const mapStateToProps = (state: AppState): StateToProps => ({
    discounts: selectDiscounts(state),
    season: selectSeason(state),
});

const mapDispatchToProps: DispatchToProps = {
    fetchDiscounts: fetchDiscountsAction,
};

interface StateToProps {
    discounts: DiscountsState;
    season: Season;
}

interface DispatchToProps {
    fetchDiscounts: () => void;
}

export const AboutCard = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AboutCardComponent)
);
