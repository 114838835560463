import { Entry } from '../models/NewTransaction';
import { oc } from 'ts-optchain';
import { i18n } from '../services/i18n';
import { getCardNumber } from './project-helpers';

export const getEntryMethod = (entry: Entry) => {
    const token = oc(entry).token();

    if (!!oc(entry).meta.giftTemplate()) {
        return i18n.t('order.method.gift');
    } else if (oc(entry).meta.method() === 'for-print') {
        return i18n.t('order.method.forPrint');
    }else if (oc(entry).ticketSystem.output.isPrepaid() && !token) {
        return i18n.t('order.method.forCardPrepaid');
    } else if (oc(entry).ticketSystem.output.isPrepaid() && oc(token)._id()) {
        return `${i18n.t('order.method.forCardPrepaidIssued')} ${getCardNumber(oc(token).extIdentifiers())}`;
    } else if (oc(token)._id()) {
        return `${i18n.t('order.method.forCard')} ${getCardNumber(oc(token).extIdentifiers())}`;
    } else  {
        return '';
    }
};
