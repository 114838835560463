import { Col, Row } from 'antd';
import React from 'react';
import { i18n } from '../../services/i18n';
import Price from '../Price';

interface Props {
    basePrice: number[];
    discountPrice: number[];
    prefix: string[];
}

export default function SavingsArray(props: Props) {
    return (
        <div className="saving-section">
            <Row className="saving-description">
                <Col span={7} offset={3}>
                    {i18n.t('aboutLipno.normalPrice')}
                </Col>
                <Col span={7}>{i18n.t('aboutLipno.eshopPrice')}</Col>
                <Col xs={6} sm={7}>
                    {i18n.t('aboutLipno.savings')}
                </Col>
            </Row>
            {props.basePrice.map((price, index) => {
                return (
                    <Row className="saving-array" gutter={20} key={index}>
                        <Col span={3}>
                            <p className="saving-section-prefix">{props.prefix[index]} </p>
                        </Col>
                        <Col span={7}>
                            <p className="saving-section-standard">
                                <Price value={price} />
                            </p>
                        </Col>
                        <Col span={7}>
                            <p className="saving-section-package">
                                <Price value={props.discountPrice[index]} />
                            </p>
                        </Col>
                        <Col span={7} style={{ paddingLeft: '20px' }}>
                            <p className="saving-section-discount">
                                <Price value={props.discountPrice[index] - price} />
                            </p>
                        </Col>
                    </Row>
                );
            })}
        </div>
    );
}
