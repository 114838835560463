import React, { FunctionComponent, ReactNode } from 'react';

interface Props {
    left: ReactNode;
    right: ReactNode;
}

const HeaderLayout: FunctionComponent<Props> = ({ left, right }) => {
    return (
        <div className="header">
            <div className="header-left">{left}</div>
            <div className="header-right">{right}</div>
        </div>
    );
};

export default HeaderLayout;
