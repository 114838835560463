import { Card } from 'antd';
import React from 'react';
import Truncate from 'react-truncate';
import { connect } from 'react-redux';

import { PartnerBenefit as IPartnerBenefit } from '../../models/Benefit';
import DynamicImg from '../DynamicImg';
import { environment } from '../../../environments/environment';
import { AppState, Season } from '../../models/AppState';
import { selectSeason } from '../../../features/shopping/ducks';
import LocalizedString from '../LocalizedString';
import BenefitValidity from '../BenefitValidity/BenefitValidity';


interface Props {
    benefit: IPartnerBenefit;
    wide?: boolean;
}

const PartnerBenefit = ({ benefit, wide, season }: Props & StateToProps) => {
    const getReuseCount = () => {
        if (benefit.maxUsage) {
            return `${benefit.maxUsage - benefit.count}x `;
        }

        return '';
    };

    return (
        <div className="partner-benefit">
            <Card
                style={wide ? { width: '100%' } : { }}
                cover={
                    <DynamicImg
                        srcs={[
                            `${environment.mediaUrl}/benefit/${benefit.benefitId}`,
                            `${environment.mediaUrl}/static/eshop/benefit.season-${season.type}`,
                            `${environment.mediaUrl}/static/eshop/benefit`,
                        ]}
                        alt="benefit"
                        style={{ margin: '0 auto' }}
                    />
                }
            >
                <Card.Meta
                    title={
                        <Truncate lines={2} ellipsis="...">
                            {getReuseCount()}{' '}
                            <LocalizedString value={benefit.name} />
                        </Truncate>
                    }
                    description={<BenefitValidity validity={benefit.validity} />}
                />
                <p>
                    <Truncate lines={5} ellipsis="...">
                        <LocalizedString value={benefit.description} />
                    </Truncate>
                </p>
            </Card>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateToProps => ({
    season: selectSeason(state),
});

interface StateToProps {
    season: Season;
}

export default connect(mapStateToProps)(PartnerBenefit);
