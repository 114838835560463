import { environment } from '../../../../environments/environment';

let footerSectionsValues: any = [];

if (environment.project === 'lipno') {
    footerSectionsValues = [
        {
            heading: 'footer.maps.title',
            items: [
                {
                    heading: 'footer.maps.activePark',
                    external: true,
                    link: 'https://www.lipno.info/novy-active-park-lipno/mapa-active-parku.html',
                },
                {
                    heading: 'footer.maps.experience',
                    external: true,
                    link: 'https://www.lipno.info/zazitky/mapa-zazitku-na-lipne.html',
                },
                {
                    heading: 'footer.maps.bikeRoads',
                    external: true,
                    link: 'https://www.lipno.info/novy-active-park-lipno/cyklostezky-na-lipne.html',
                },
            ],
        },
        {
            heading: 'footer.info.title',
            items: [
                {
                    heading: 'footer.info.lipno',
                    external: true,
                    link: 'https://www.lipno.info/',
                },
                {
                    heading: 'footer.info.lipnoExperiences',
                    external: true,
                    link: 'https://www.lipno.info/zazitky.html',
                },
                {
                    heading: 'footer.info.accomodation',
                    external: true,
                    link: 'https://www.lipno.info/ubytovani.html',
                },
                {
                    heading: 'footer.info.food',
                    external: true,
                    link: 'https://www.lipno.info/gastro.html',
                },
                {
                    heading: 'footer.info.rentals',
                    external: true,
                    link: 'https://www.lipno.info/pujcovny.html',
                },
                {
                    heading: 'footer.info.media',
                    external: true,
                    link: 'https://www.lipno.info/pro-media.html',
                },
            ],
        },
        {
            heading: 'footer.servis.title',
            items: [
                {
                    heading: 'footer.servis.infoCenter',
                    external: true,
                    link: 'https://www.lipno.info/infocentrum.html',
                },
                {
                    heading: 'footer.servis.cameras',
                    external: true,
                    link: 'https://www.lipno.info/webkamery-na-lipne.html',
                },
                {
                    heading: 'footer.servis.weather',
                    external: true,
                    link: 'https://www.lipno.info/pocasi-na-lipne.html',
                },
                {
                    heading: 'footer.servis.transportation',
                    external: true,
                    link: 'https://www.lipno.info/infocentrum/jak-a-kudy-na-lipno-v-lete.html',
                },
                {
                    heading: 'footer.servis.help',
                    external: true,
                    link: 'https://www.lipno.info/infocentrum/pomoc-v-nouzi.html',
                },
            ],
        },
        {
            heading: 'footer.legal.title',
            items: [
                {
                    heading: 'footer.legal.claim',
                    link: '/terms/claim',
                    external: false,
                },
                {
                    heading: 'footer.legal.cookies',
                    link: '/terms/cookies',
                    external: false,
                },
                {
                    heading: 'footer.legal.VOP',
                    link: '/terms/general',
                    external: false,
                },
                {
                    heading: 'footer.legal.GDPR',
                    link: '/terms/gdpr',
                    external: false,
                },
            ],
        },
    ];
}

if (environment.project === 'annaberg') {
    footerSectionsValues = [
        {
            heading: '',
            items: []
        },
        {
            heading: '',
            items: []
        },
        {
            heading: '',
            items: []
        },
        {
            heading: 'footer.legal.title',
            items: [
                {
                    heading: 'footer.legal.claim',
                    link: '/terms/claim',
                    external: false,
                },
                {
                    heading: 'footer.legal.cookies',
                    link: environment.config.cookiesLink,
                    external: true,
                },
                {
                    heading: 'footer.legal.VOP',
                    link: 'footer.legal.VOPLink',
                    linkKey: true,
                },
                {
                    heading: 'footer.legal.GDPR',
                    link: 'footer.legal.GDPRLink',
                    linkKey: true,
                },
                {
                    heading: 'footer.legal.covidInformation',
                    link: 'https://www.annaberg.info/info-covid-19',
                    external: true,
                },
                {
                    heading: 'footer.legal.zipline',
                    link: '/2020_Benutzerregeln_Zipline.pdf',
                    external: true,
                },
            ],
        },
    ];
}


if (environment.project === 'morava') {
    footerSectionsValues = [
        {
            heading: 'footer.maps.title',
            items: [
                {
                    heading: 'footer.maps.winterMap',
                    external: true,
                    link: 'https://www.dolnimorava.cz/mapy-zima',
                },
                {
                    heading: 'footer.maps.summerMap',
                    external: true,
                    link: 'https://www.dolnimorava.cz/mapy-leto',
                },
            ],
        },
        {
            heading: 'footer.info.title',
            items: [
                {
                    heading: 'footer.info.morava',
                    external: true,
                    link: 'https://www.dolnimorava.cz/',
                },
                {
                    heading: 'footer.info.moravaExperiences',
                    external: true,
                    link: 'https://www.dolnimorava.cz/leto',
                },
                {
                    heading: 'footer.info.accomodation',
                    external: true,
                    link: 'https://www.dolnimorava.cz/ubytovani',
                },
                {
                    heading: 'footer.info.food',
                    external: true,
                    link: 'https://www.dolnimorava.cz/gastronomie',
                },
                {
                    heading: 'footer.info.rentals',
                    external: true,
                    link: 'https://www.dolnimorava.cz/pujcovny-a-cyklo-servis',
                },
                {
                    heading: 'footer.info.skiSchool',
                    external: true,
                    link: 'https://www.dolnimorava.cz/lyzarska-skola',
                },
            ],
        },
        {
            heading: 'footer.servis.title',
            items: [
                {
                    heading: 'footer.servis.infoCenter',
                    external: true,
                    link: 'https://www.dolnimorava.cz/kontakty',
                },
                {
                    heading: 'footer.servis.cameras',
                    external: true,
                    link: 'https://www.dolnimorava.cz/web-kamery',
                },
                {
                    heading: 'footer.servis.weather',
                    external: true,
                    link: 'https://www.dolnimorava.cz/pocasi',
                },
                {
                    heading: 'footer.servis.transportation',
                    external: true,
                    link: 'https://www.dolnimorava.cz/kudy-k-nam',
                },
                {
                    heading: 'footer.servis.calendar',
                    external: true,
                    link: 'https://www.dolnimorava.cz/kalendar-akci',
                },
            ],
        },
        {
            heading: 'footer.legal.title',
            items: [
                {
                    heading: 'footer.legal.VOP',
                    link: 'https://www.dolnimorava.cz/gdpr-a-dokumenty',
                    external: true,
                },
                {
                    heading: 'footer.legal.operatingRules',
                    link: 'https://www.dolnimorava.cz/gdpr-a-dokumenty',
                    external: true,
                },
                {
                    heading: 'footer.legal.GDPR',
                    link: 'https://www.dolnimorava.cz/gdpr-a-dokumenty',
                    external: true,
                },
            ],
        },
    ];
}

export const footerSections = footerSectionsValues;
