import React from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { Col, Row } from 'antd';
import moment from 'moment';
import { oc } from 'ts-optchain';

import { Button, Modal } from '../../components';
import { ModalSizes } from '../../constants/modal';
import { countries, i18n } from '../../services/i18n';
import { UserEntryFormValues } from '../../models/User';
import {
    DateField,
    CheckBoxField,
    InputField,
    SelectField,
} from '../../fields';
import { environment } from '../../../environments/environment';
import { isYoungerThan } from '../../utils/isYoungerThan';
import { EntryMethod } from '../../models/NewTransaction';

interface Props {
    data?: UserEntryFormValues;
    method?: EntryMethod;
    visible?: boolean;

    onSubmit(values: UserEntryFormValues): void;

    onCancel?(): void;
}

const getAttributes = () => ({
    firstName: Yup.string().required(i18n.t('form.required')),
    lastName: Yup.string().required(i18n.t('form.required')),
    birthday: Yup.string()
        .required(i18n.t('form.required'))
        .test(
            'IsDateValid',
            i18n.t('form.dateValid'),
            value => value &&
                !value.includes('_') &&
                moment(value, 'YYYY-MM-DD').isValid() &&
                moment(value, 'YYYY-MM-DD').isBefore(moment())
        ),
        // .test(
        //     'IsOlderThan8',
        //     i18n.t('form.olderThan8'),
        //     value => !isYoungerThan(value, 8)
        // ),
    legalFirstName: Yup.string().when('birthday', {
        is: (val) => isYoungerThan(val, 15),
        then: Yup.string().required(i18n.t('form.required')),
        otherwise: Yup.string(),
    }),
    legalLastName: Yup.string().when('birthday', {
        is: (val) => isYoungerThan(val, 15),
        then: Yup.string().required(i18n.t('form.required')),
        otherwise: Yup.string(),
    }),
    legalBirthday: Yup.string().when('birthday', {
        is: value => isYoungerThan(value, 15),
        then: Yup.string()
            .required(i18n.t('form.required'))
            .test(
                'IsDateValid',
                i18n.t('form.dateValid'),
                value => value &&
                    moment(value, 'YYYY-MM-DD').isValid() &&
                    moment(value, 'YYYY-MM-DD').isBefore(moment())
            )
            .test(
                'IsOlderThan18',
                i18n.t('form.olderThan18'),
                value => !isYoungerThan(value, 18)
            ),
        otherwise: Yup.string(),
    }),
    address: Yup.string().required(i18n.t('form.required')),
    city: Yup.string().required(i18n.t('form.required')),
    postalCode: Yup.string().max(10, i18n.t('form.postalCode')).required(i18n.t('form.required')),
    country: Yup.string().required(i18n.t('form.required')),
    email: Yup.string().email(i18n.t('form.errors.email')).required(i18n.t('form.required')),
    phone: Yup.string().required(i18n.t('form.required')),
    agreements: Yup.boolean()
        .required(i18n.t('form.required'))
        .oneOf([true], i18n.t('form.required')),
});

const EntryUserInfoModal = ({ visible, onSubmit, onCancel, data, method }: Props) => {
    const showKey = (key: string) => {
        return method && oc(environment).config.userInfo([]).includes(key);
    };

    const schema = Yup.object<UserEntryFormValues>().shape<UserEntryFormValues>(
        Object.keys(getAttributes()).reduce((acc, key) => {
            if (showKey(key)) {
                acc[key] = getAttributes()[key];
            }

            return acc;
        }, {})
    );

    const renderForm = (formikBag: FormikProps<UserEntryFormValues>) => {
        function handleSubmit() {
            formikBag.submitForm();
        }

        return (
            <Form>
                <div className="entry-user-info-form">
                    <Row>
                        {showKey('firstName') && (
                            <Col sm={24} md={8} className="entry-user-info-form__col">
                                <InputField
                                    name="firstName"
                                    label={i18n.t('timeSlots.modal.firstName.label')}
                                    placeholder={i18n.t('timeSlots.modal.firstName.placeholder')}
                                />
                            </Col>
                        )}
                        {showKey('lastName') && (
                            <Col sm={24} md={8} className="entry-user-info-form__col">
                                <InputField
                                    name="lastName"
                                    label={i18n.t('timeSlots.modal.lastName.label')}
                                    placeholder={i18n.t('timeSlots.modal.lastName.placeholder')}
                                />
                            </Col>
                        )}
                        {showKey('birthday') && (
                            <Col sm={24} md={8} className="entry-user-info-form__col">
                                <DateField
                                    name="birthday"
                                    label={i18n.t('timeSlots.modal.birthday.label')}
                                    placeholder={i18n.t('timeSlots.modal.birthday.placeholder')}
                                />
                            </Col>
                        )}
                    </Row>
                    {formikBag.values['birthday'] && isYoungerThan(formikBag.values['birthday'], 15) && (
                        <Row>
                            {showKey('legalFirstName') && (
                                <Col sm={24} md={8} className="entry-user-info-form__col">
                                    <InputField
                                        name="legalFirstName"
                                        label={i18n.t('timeSlots.modal.legalFirstName.label')}
                                        placeholder={i18n.t('timeSlots.modal.legalFirstName.placeholder')}
                                    />
                                </Col>
                            )}
                            {showKey('legalLastName') && (
                                <Col sm={24} md={8} className="entry-user-info-form__col">
                                    <InputField
                                        name="legalLastName"
                                        label={i18n.t('timeSlots.modal.legalLastName.label')}
                                        placeholder={i18n.t('timeSlots.modal.legalLastName.placeholder')}
                                    />
                                </Col>
                            )}
                            {showKey('legalBirthday') && (
                                <Col sm={24} md={8} className="entry-user-info-form__col">
                                    <DateField
                                        name="legalBirthday"
                                        label={i18n.t('timeSlots.modal.legalBirthday.label')}
                                        placeholder={i18n.t('timeSlots.modal.legalBirthday.placeholder')}
                                    />
                                </Col>
                            )}
                        </Row>
                    )}
                    <Row>
                        {showKey('address') && (
                            <Col sm={24} md={12} className="entry-user-info-form__col">
                                <InputField
                                    name="address"
                                    label={i18n.t('timeSlots.modal.address.label')}
                                    placeholder={i18n.t('timeSlots.modal.address.placeholder')}
                                />
                            </Col>
                        )}
                        {showKey('city') && (
                            <Col sm={24} md={12} className="entry-user-info-form__col">
                                <InputField
                                    name="city"
                                    label={i18n.t('timeSlots.modal.city.label')}
                                    placeholder={i18n.t('timeSlots.modal.city.placeholder')}
                                />
                            </Col>
                        )}
                        {showKey('postalCode') && (
                            <Col sm={24} md={12} className="entry-user-info-form__col">
                                <InputField
                                    name="postalCode"
                                    label={i18n.t('timeSlots.modal.postalCode.label')}
                                    placeholder={i18n.t('timeSlots.modal.postalCode.placeholder')}
                                />
                            </Col>
                        )}
                        {showKey('country') && (
                            <Col sm={24} md={12} className="entry-user-info-form__col">
                                <SelectField
                                    options={countries}
                                    priorityOptions={environment.config.priorityCountriesOptions}
                                    name="country"
                                    label={i18n.t('timeSlots.modal.country.label')}
                                    placeholder={i18n.t('timeSlots.modal.country.placeholder')}
                                    noFoundContent={i18n.t('timeSlots.modal.country.noContent')}
                                />
                            </Col>
                        )}
                        {showKey('phone') && (
                            <Col sm={24} md={12} className="entry-user-info-form__col">
                                <InputField
                                    name="phone"
                                    label={i18n.t('timeSlots.modal.phone.label')}
                                    placeholder={i18n.t('timeSlots.modal.phone.placeholder')}
                                />
                            </Col>
                        )}
                        {showKey('email') && (
                            <Col sm={24} md={12} className="entry-user-info-form__col">
                                <InputField
                                    name="email"
                                    label={i18n.t('timeSlots.modal.email.label')}
                                    placeholder={i18n.t('timeSlots.modal.email.placeholder')}
                                />
                            </Col>
                        )}
                        {showKey('email') && (
                            <Col span={24} className="entry-user-info-form__col">
                                <CheckBoxField
                                    name="agreements"
                                    label={
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: i18n.t('timeSlots.modal.agreements.label')
                                            }}
                                        />
                                    }
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button onClick={handleSubmit} className="entry-user-info-form__submit">
                                {i18n.t('timeSlots.modal.submit')}
                            </Button>
                            <Button onClick={onCancel} ghost className="entry-user-info-form__submit">
                                {i18n.t('timeSlots.modal.cancel')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        );
    };

    return (
        <Modal
            visible={visible && !!method}
            width={ModalSizes.large}
            title={i18n.t('timeSlots.modal.title')}
            onCancel={onCancel}
            footer={null}
            closable
        >
            <div className="entry-user-info-modal">
                <Row>
                    <Col span={24} className="entry-user-info-modal__intro">
                        {i18n.t('timeSlots.modal.intro')}
                    </Col>
                </Row>
                <Formik
                    initialValues={{
                        firstName: oc(data).firstName(),
                        lastName: oc(data).lastName(),
                        birthday: oc(data).birthday(),
                        legalFirstName: oc(data).legalFirstName(),
                        legalLastName: oc(data).legalLastName(),
                        legalBirthday: oc(data).legalBirthday(),
                        address: oc(data).address(),
                        city: oc(data).city(),
                        postalCode: oc(data).postalCode(),
                        country: oc(data).country(),
                        phone: oc(data).phone(),
                        email: oc(data).email(),
                        agreements: !!data && data.agreements,
                    }}
                    onSubmit={onSubmit}
                    render={renderForm}
                    validationSchema={schema}
                    validateOnBlur={false}
                    validateOnChange={false}
                />
            </div>
        </Modal>
    );
};

export default EntryUserInfoModal;
