import { Col, Row } from 'antd';
import { Form, Formik, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { oc } from 'ts-optchain';
import * as Yup from 'yup';

import { Button } from '..';
import {
    BirthdayField,
    CheckBoxField,
    InputField,
    RadioField,
    SelectField,
} from '../../fields';
import { SettingsFormValues } from '../../models/User';
import { countries, i18n } from '../../services/i18n';
import { Trans } from 'react-i18next';
import PasswordInput from '../../fields/PasswordInput';
import { environment } from '../../../environments/environment';

interface Props {
    initialValues?: Partial<SettingsFormValues>;
    onSubmit?(values: SettingsFormValues): void;
}

const SettingsForm: FunctionComponent<Props> = ({ onSubmit, initialValues }) => {
    const titleOptions = environment.project === 'annaberg' ?
        [
            { label: i18n.t('register.title.options.mr'), value: 'mr' },
            { label: i18n.t('register.title.options.mrs'), value: 'mrs' },
        ] :
        [
            { label: i18n.t('register.title.options.mr'), value: 'mr' },
            { label: i18n.t('register.title.options.mrs'), value: 'mrs' },
            { label: i18n.t('register.title.options.ms'), value: 'ms' },
        ];

    const schema = Yup.object<SettingsFormValues>().shape<SettingsFormValues>({
        email: Yup.string().required(i18n.t('form.required')),
        password: Yup
            .string()
            .nullable()
            .matches(
                new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'),
                i18n.t('form.errors.password')
            ),
        passwordRepeat: Yup
            .string()
            .nullable()
            .matches(
                new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'),
                i18n.t('form.errors.password')
            )
            .test(
                'passwords-match',
                i18n.t('form.passwordMatch'),
                function(value) {
                    return this.parent.password === value;
                }
            ),
        title: Yup.string().required(i18n.t('form.required')),
        firstName: Yup.string().required(i18n.t('form.required')),
        lastName: Yup.string().required(i18n.t('form.required')),
        postalCode: Yup.string().max(10, i18n.t('form.postalCode')).required(i18n.t('form.required')),
        country: Yup.string().required(i18n.t('form.required')),
        city: Yup.string().required(i18n.t('form.required')),
        birthday: Yup.string().required(i18n.t('form.required')),
        agreements: Yup.boolean().required(i18n.t('form.required')),
    });

    function handleSubmit(values: SettingsFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm(formikBag: FormikProps<SettingsFormValues>) {
        return (
            <Form>
                <div className="settings-form">
                    <Row>
                        <Col span={24}>
                            <RadioField
                                options={titleOptions}
                                name="title"
                                label={i18n.t('register.title.label')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24} md={12}>
                            <InputField
                                name="firstName"
                                label={i18n.t('register.name.label')}
                                placeholder={i18n.t('register.name.placeholder')}
                            />
                        </Col>
                        <Col sm={24} md={12}>
                            <InputField
                                name="lastName"
                                label={i18n.t('register.surname.label')}
                                placeholder={i18n.t('register.surname.placeholder')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24} md={12}>
                            <SelectField
                                options={countries}
                                priorityOptions={environment.config.priorityCountriesOptions}
                                name="country"
                                label={i18n.t('register.country.label')}
                                placeholder={i18n.t('register.country.placeholder')}
                            />
                        </Col>
                        <Col sm={24} md={12}>
                            <InputField
                                name="city"
                                label={i18n.t('register.city.label')}
                                placeholder={i18n.t('register.city.placeholder')}
                            />
                        </Col>
                        <Col sm={24} md={12}>
                            <InputField
                                name="postalCode"
                                label={i18n.t('register.postalCode.label')}
                                placeholder={i18n.t('register.postalCode.placeholder')}
                            />
                        </Col>
                        <Col sm={24} md={12}>
                            <BirthdayField
                                disabled={environment.config.birthdayEditDisabled}
                                name="birthday"
                                label={i18n.t('register.dateOfBirth.label')}
                                placeholder={i18n.t('register.dateOfBirth.placeholder')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <InputField
                                name="email"
                                label={i18n.t('register.email.label')}
                                placeholder={i18n.t('register.email.placeholder')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <PasswordInput
                                label={i18n.t('register.password.label')}
                                placeholder={i18n.t('register.password.placeholder')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <PasswordInput
                                name="passwordRepeat"
                                label={i18n.t('register.passwordRepeat.label')}
                                placeholder={i18n.t('register.password.placeholder')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <CheckBoxField
                                name="agreements"
                                label={
                                    <Trans i18nKey="register.agreements.label">
                                        Chci získat speciální, zvýhodněné nabídky v rámci
                                        věrnostního programu Lipno.card. Vyjadřuji
                                        <a href="/terms/gdpr" target="_blank">
                                            souhlas se zpracováním svých osobních údajů
                                        </a>
                                        pro marketingové účely a posíláním informací e-mailem, SMS
                                        nebo dalšími způsoby přímé marketingové komunikace. Zasílání
                                        zpráv je možné kdykoliv zrušit.
                                    </Trans>
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button block onClick={formikBag.submitForm}>
                                {i18n.t('settings.submit')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        );
    }

    return (
        <Formik
            initialValues={{
                email: oc(initialValues).email(''),
                password: '',
                passwordRepeat: '',
                title: oc(initialValues)
                    .title('')
                    .toLowerCase(),
                firstName: oc(initialValues).firstName(''),
                lastName: oc(initialValues).lastName(''),
                country: oc(initialValues).country(''),
                postalCode: oc(initialValues).postalCode(''),
                city: oc(initialValues).city(''),
                birthday: oc(initialValues).birthday(''),
                agreements: oc(initialValues).agreements() as boolean,
            }}
            onSubmit={handleSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
};

export default SettingsForm;
