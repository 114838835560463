import { environment as defaultEnv } from './environment.lipno';

export const environment = {
    ...defaultEnv,
    production: true,
    protocol: 'https',
    host: 'api.lipnocard.cz/eshop-api',
    basicAuthToken: 'bGlwbm86Wk1jRTY1RU9Wbm9xRktsTXBoU04=',
    mediaUrl: 'https://lipno-images.s3.eu-central-1.amazonaws.com',
    debug: false,
};
