import React, { Fragment, FunctionComponent } from 'react';

import { Product } from '../../../../../../../../common/models/Product';
import { i18n } from '../../../../../../../../common/services/i18n';

interface Props {
    product: Product;
}

export const SelectLabel: FunctionComponent<Props> = ({ product }) => {
    if (product.description && product.description[i18n.language]) {
        return (
            <div className="select-multi-item">
                <p className="select-multi-item__title">{product.name[i18n.language]}</p>
                <p className="select-multi-item__info">{product.description[i18n.language]}</p>
            </div>
        );
    } else {
        return <Fragment>{product.name[i18n.language]}</Fragment>;
    }
};
