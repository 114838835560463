import { Field, FieldProps } from 'formik';
import React, { ReactNode } from 'react';

import { WtpInput } from '../../components/Form';

interface Props {
    name: string;
    error: ReactNode;
    label?: ReactNode;
    placeholder?: string;
    onKeyUp?: (value) => void;
}

export default ({ name, error, label, ...bag }: Props) => {
    return (
        <Field name={name}>
            {({ field, form: { errors } }: FieldProps<any>) => (
                <WtpInput error={error || errors[name]} {...field} {...bag} />
            )}
        </Field>
    );
};
