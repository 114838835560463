import React, { Fragment } from 'react';

import { Layout } from '../../../../common/components';
import { i18n } from '../../../../common/services/i18n';
import { TermsSubheader } from '../../components';
import VOP from './components/VOP';

export function GeneralTermsPage() {
    return (
        <Fragment>
            <TermsSubheader title={i18n.t('terms.vop')} />
            <Layout.Container>
                <VOP />
            </Layout.Container>
        </Fragment>
    );
}
