import cn from 'classnames';
import React, { ReactNode } from 'react';
import MaskedInput from 'react-text-mask';

import { Control } from '../Control';
import { environment } from '../../../../environments/environment';

interface Props extends React.InputHTMLAttributes<HTMLElement> {
    label?: ReactNode;
    error?: ReactNode;
    verified?: boolean;
    type?: 'text' | 'password';
    inputSize?: 'large';
}

export default ({ type, label, error, verified, inputSize, ...bag }: Props) => {
    function upperCasePipe(conformedValue) {
        return conformedValue.toUpperCase();
    }

    return (
        <Control label={label} error={error}>
            <MaskedInput
                mask={environment.config.cardPatternMask}
                className={cn('ant-input', {
                    'ant-input-warning': error,
                    'ant-input-verified': verified,
                    'ant-input-lg': inputSize === 'large',
                })}
                pipe={upperCasePipe}
                {...bag}
            />
        </Control>
    );
};
