import React from 'react';
import ReactDOM from 'react-dom';
import { NodeManager } from 'react-register-nodes';

import packg from '../package.json';

import { Root } from './root';
import { registerGtm } from './common/utils/gtm-helpers';
import './root/styles/main.css';

registerGtm();

ReactDOM.render(
    // <React.StrictMode>
    <NodeManager>
        <Root />
    </NodeManager>,
    // </React.StrictMode>,
    document.getElementById('root')
);
(window as any).version = packg.version;
