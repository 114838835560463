import { Card, Tooltip, Button } from 'antd';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Truncate from 'react-truncate';
import { oc } from 'ts-optchain';
import { connect } from 'react-redux';

import { Price, TotalPrice } from '../../components';
import { CreditBenefit, TotalValue, VariantType } from '../../models/Benefit';
import { i18n } from '../../services/i18n';
import { Product } from '../../models/Product';
import {
    VariantsDropdown,
    VariantsSelection,
} from '../../../features/shopping/features/eshop/components/ProductSelect/components/VariantsDropdown';
import {
    selectDiscountBadges,
    selectDiscountEntries, selectEshopSingleEntries, selectSeason, selectTransactionPrice, SingleVariant,
    validateSingleVariantActions
} from '../../../features/shopping/ducks';
import { AppState, Season } from '../../models/AppState';
import { environment } from '../../../environments/environment';
import { isProductVisible } from '../../utils/visibility-helpers';
import DynamicImg from '../DynamicImg';
import { selectCartCredit, selectUserCredit } from '../../../features/personal/ducks';
import { EntryBase, TransactionDryRunBenefit } from '../../models/NewTransaction';
import LocalizedString from '../LocalizedString';
import BenefitValidity from '../BenefitValidity/BenefitValidity';

interface Props {
    benefit: CreditBenefit;
    product: Product;
    variants: VariantType[];
    reset: boolean;
    onAddToCart: (value: VariantsSelection[], product: Product) => void;
    onSelectUpdate: (value: VariantsSelection[], product: Product) => void;
}

const CreditDiscountComponent = (props: Props & StateToProps & DispatchToProps) => {
    const [variants, setVariants] = useState<VariantsSelection[]>([]);
    const [visible, setVisible] = useState(false);
    const [usedCredit, setUsedCredit] = useState(0);
    const [disabled, setDisabled] = useState(false);

    const credit = useMemo(
        () => Math.max(props.credit - props.creditInCart, 0),
        [props.credit, props.creditInCart]
    );

    function getSelectionCredit() {
        let max = props.benefit.max * variants.reduce((acc, val) => val.count + acc, 0);
        let lastItemCreditValue;

        if (max === 0) {
            max = props.total.withoutCredits || 0;
            lastItemCreditValue = props.total.lastPrice || 0;
        } else {
            lastItemCreditValue = props.benefit.max;
        }

        if (credit >= max) {
            return Math.max(max, props.benefit.min);
        } else if (credit >= max - lastItemCreditValue + props.benefit.min) {
            return credit;
        }

        return max - lastItemCreditValue + props.benefit.min;
    }

    useEffect(
        () => {
            if (variants.length) {
                setUsedCredit(getSelectionCredit());
            }
        },
        [variants, credit, props.total]
    );

    useEffect(
        () => setDisabled(credit < usedCredit),
        [usedCredit]
    );

    const handleSelectionUpdate = (variants: VariantsSelection[]) => {
        setVariants(variants);
        props.onSelectUpdate(variants, props.product);
    };

    const validateSingleVariants = () => {
        oc(props.product)
            .variants([])
            .filter(v => isProductVisible(v))
            .forEach(v =>
                props.validateSingleVariant({ variant: v._id, arrival: new Date().toISOString() })
            );
    };

    const handleAddToCart = () => {
        if (!disabled && variants && variants.some(v => v.count > 0)) {
            props.onAddToCart(variants, props.product);
        }
    };

    function handleMouseEnter() {
        if (disabled) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }

    function handleMouseLeave() {
        setVisible(false);
    }

    function calculatePrice() {
        if (props.benefit.max) {
            return  props.total.withoutCredits
                ? Math.max( props.total.withoutCredits - usedCredit, 0)
                : 0;
        } else {
            return props.total.lastPrice !== undefined && props.total.lastPrice > props.benefit.min
                ? props.total.lastPrice - props.benefit.min
                : 0;
        }
    }

    return (
        <div className="credit-discount">
            <Card
                className="cart"
                cover={
                    <DynamicImg
                        srcs={[
                            `${environment.mediaUrl}/benefit/${props.benefit.benefitId}`,
                            `${environment.mediaUrl}/static/eshop/benefit.season-${props.season.type}`,
                            `${environment.mediaUrl}/static/eshop/benefit`,
                        ]}
                        alt="benefit"
                        style={{ margin: '0 auto' }}
                    />
                }
            >
                <Card.Meta
                    title={
                        <Truncate lines={2} ellipsis="...">
                            <LocalizedString value={props.benefit.name} />
                        </Truncate>
                    }
                    description={<BenefitValidity validity={props.benefit.validity} />}
                />
                <p>
                    <Truncate lines={5} ellipsis="...">
                        <LocalizedString value={props.benefit.description} />
                    </Truncate>
                </p>

                <div className={'ant-card-add-cart'}>
                    <TotalPrice
                        type="default"
                        pricePosition="bottom"
                        withDiscount={
                            <Price value={disabled ? calculatePrice() : props.total.discounted || 0} />
                        }
                        withoutDiscount={
                            <Fragment>
                                {i18n.t('ticketSelect.credit.priceWithoutDiscount')}{' '}
                                <Price value={props.total.withoutCredits || 0} />
                            </Fragment>
                        }
                        displayDiscount
                    />
                    <VariantsDropdown
                        badges={props.badges}
                        product={props.product}
                        onChange={handleSelectionUpdate}
                        entries={props.entries}
                        variants={props.variants}
                        onDropdownOpen={validateSingleVariants}
                        reset={props.reset}
                        singleVariants={props.singleVariants}
                    />
                    <Tooltip title={'discount.notEnoughCredit'} visible={visible}>
                        {/*awful hack, because antd breaks hover functionality with disabled buttons*/}
                        <Button
                            block
                            size="small"
                            type={disabled || !variants || !variants.some(v => v.count > 0) ? 'primary' : 'default'}
                            onClick={handleAddToCart}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            {i18n.t('discount.credit.cartButton')}{' '}
                            -
                            <Price value={props.total.withoutCredits && Math.min(props.total.withoutCredits, usedCredit)} />
                        </Button>
                    </Tooltip>
                </div>
            </Card>
        </div>
    );
};

interface StateToProps {
    badges: TransactionDryRunBenefit[];
    entries: EntryBase[];
    singleVariants: Record<string, EntryBase>;
    credit: number;
    creditInCart: number;
    total: TotalValue;
    season: Season;
}

const mapStateToProps = (state: AppState, props: Props): StateToProps => ({
    badges: selectDiscountBadges(state, props.benefit.tokenId + props.benefit.product),
    entries: selectDiscountEntries(state, props.benefit.tokenId + props.benefit.product),
    singleVariants: selectEshopSingleEntries(state),
    credit: selectUserCredit(state),
    creditInCart: selectCartCredit(state),
    total: selectTransactionPrice(state, props.benefit.tokenId + props.benefit.product),
    season: selectSeason(state),
});

interface DispatchToProps {
    validateSingleVariant: (items: SingleVariant) => void;
}

const mapDispatchToProps: DispatchToProps = {
    validateSingleVariant: validateSingleVariantActions.request,
};

const CreditDiscount = connect(mapStateToProps, mapDispatchToProps)(CreditDiscountComponent);
export default CreditDiscount;
