import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { AppState, Season } from '../../../../common/models/AppState';
import { ProductSelection, ProductSelectionPopulated } from '../../../../common/models/Cart';
import { JournalBenefit } from '../../../../common/models/Journal';
import { Product } from '../../../../common/models/Product';
import { FeatureFlag } from '../../../../root/components/FeatureFlag';
import { CategoriesTabs, Layout, TradingProduct } from '../.././../../common/components';
import { Fatured, Testimonials } from '../../components';
import {
    fetchBannersActions,
    resetEshopTransactionAction,
    selectCategories,
    selectEshopBadges,
    selectEshopBanners,
    selectEshopEntries, selectEshopSingleEntries,
    selectEshopTotal,
    selectEshopTotalDiscounted,
    selectProducts,
    selectSeason, SingleVariant,
    validateProductSelectionActions, validateSingleVariantActions,
} from '../../ducks';
import { openCartAction, setCartItemsAction, selectCartPopulatedItems } from '../../ducks/cart';
import { tradingProducts } from '../../mock';

import ProductsSelect from './components/ProductSelect';
import { RouteComponentProps } from 'react-router';
import { ChangePasswordModal } from '../../../../common/modals';
import { resetPasswordActions } from '../../../user/ducks';
import BannerView from '../../../../common/components/BannerView';
import { BannerEntry } from '../../../../common/models/Banner';

import { oc } from 'ts-optchain';
import { isProductVisible } from '../../../../common/utils/visibility-helpers';
import { sendGtmEvent } from '../../../../common/utils/gtm-helpers';
import { EntryBase, TransactionDryRunBenefit } from '../../../../common/models/NewTransaction';

interface StateToProps {
    products: Product[];
    categories: Product[];
    season: Season;
    total: number;
    totalDiscounted: number;
    badges: TransactionDryRunBenefit[];
    validatedEntries: EntryBase[];
    banners: BannerEntry[];
    cartItems: ProductSelectionPopulated[];
    singleVariants: Record<string, EntryBase>;
}

interface DispatchToProps {

    validateSelection: (items: ProductSelection[]) => void;
    validateSingleVariant: (item: SingleVariant) => void;
    resetValidateSelection: () => void;

    setCartItems?(items: ProductSelection[]);

    getBanners();

    openCart?();

    resetPasssword: (payload: { token: string; password: string }) => void;
}

type Props = DispatchToProps & StateToProps & RouteComponentProps;

function EshopComponent(props: Props) {
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const [token, setToken] = useState('');
    const [cartChange, setCartChange] = useState(false);

    useEffect(() => {
        const { token } = queryString.parse(props.history.location.search);
        if (token) {
            setToken(token as string);
            setResetPasswordModal(true);
        }
        props.getBanners();
    }, []);

    function handleChangePassword(password: string) {
        props.resetPasssword({
            token,
            password,
        });
        setToken('');
        setResetPasswordModal(false);
    }

    function handleCancelChangePassword() {
        setResetPasswordModal(false);
    }

    function handleSelectionValidate(selection: ProductSelection[]) {
        props.validateSelection(selection);
    }

    function handleSingleVariantsValidate(product: Product, arrival: string) {
        oc(product)
            .variants([])
            .filter(v => isProductVisible(v))
            .forEach(v =>
                props.validateSingleVariant({variant: v._id, arrival: arrival})
            );
    }

    function handleAddToCart(items: ProductSelection[]) {
        props.setCartItems && props.setCartItems(items);
        props.openCart && props.openCart();
        setCartChange(true);
    }

    // TODO: when deletion gets added to GTM, this should move to Cart component
    useEffect(
        () => {
            if (cartChange) {
                setCartChange(false);

                sendGtmEvent({
                    event: 'add_to_cart',
                    action: props.cartItems.map(item => item.product.name.cz).join(', '),
                    products: props.cartItems.map(item => item.product.name.cz),
                });
            }
        },
        [cartChange]
    );

    return (
        <Layout.Content>
            <ChangePasswordModal
                visible={resetPasswordModal}
                onConfirm={handleChangePassword}
                onCancel={handleCancelChangePassword}
            />
            <CategoriesTabs season={props.season} categories={props.categories}>
                {(category) => (
                    <ProductsSelect
                        season={props.season}
                        category={category}
                        products={props.products}
                        onAddToCart={handleAddToCart}
                        onSelectionValidate={handleSelectionValidate}
                        onSelectionValidateReset={props.resetValidateSelection}
                        total={props.total}
                        totalDiscounted={props.totalDiscounted}
                        badges={props.badges}
                        entries={props.validatedEntries}
                        singleVariants={props.singleVariants}
                        onSingleVariantsValidate={handleSingleVariantsValidate}
                    />
                )}
            </CategoriesTabs>
            <BannerView banners={props.banners} />
            <FeatureFlag flagKey="featuredProducts">
                <Fatured title={<Trans i18nKey="home.featuredProductsTitle" />}>
                    <Row gutter={4}>
                        {tradingProducts.map((product, index) => (
                            <Col sm={12} md={12} key={index}>
                                <TradingProduct
                                    bgImage={product.bgImage}
                                    description={product.description}
                                    price={product.price}
                                    title={product.title}
                                    familyPrice={product.familyPrice}
                                />
                            </Col>
                        ))}
                    </Row>
                </Fatured>
            </FeatureFlag>
            <Testimonials season={props.season} />
        </Layout.Content>
    );
}

const mapDispatchToProps: DispatchToProps = {
    setCartItems: setCartItemsAction,
    openCart: openCartAction,
    validateSelection: validateProductSelectionActions.request,
    validateSingleVariant: validateSingleVariantActions.request,
    resetValidateSelection: resetEshopTransactionAction,
    resetPasssword: resetPasswordActions.request,
    getBanners: fetchBannersActions.request,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    season: selectSeason(state),
    products: selectProducts(state),
    categories: selectCategories(state),
    total: selectEshopTotal(state),
    totalDiscounted: selectEshopTotalDiscounted(state),
    badges: selectEshopBadges(state),
    validatedEntries: selectEshopEntries(state),
    banners: selectEshopBanners(state),
    cartItems: selectCartPopulatedItems(state),
    singleVariants: selectEshopSingleEntries(state),
});

export const Eshop = connect(
    mapStateToProps,
    mapDispatchToProps
)(EshopComponent);
