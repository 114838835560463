import { i18n } from '../../../../../common/services/i18n';
import { Button } from '../../../../../common/components';
import { Card } from 'antd';
import React from 'react';

interface Props {
    paymentDisabled?: boolean;
    onSubmitPayment: () => void;
}

const ZeroPayment = ({ paymentDisabled, onSubmitPayment }: Props) => (
    <Card className={'payment-option'}>
        <h3>{i18n.t('checkout.payment.zero.title')}</h3>
        <p style={{ margin: '13px' }}>
            {i18n.t('checkout.payment.zero.description')}
        </p>
        <Button
            onClick={() => onSubmitPayment()}
            type="success"
            block
            disabled={paymentDisabled}
        >
            {i18n.t('checkout.payment.zero.button')}
        </Button>
    </Card>
);

export default ZeroPayment;
