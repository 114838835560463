import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import cn from 'classnames';
import React, { ReactNode } from 'react';

interface Props extends CheckboxProps {
    warning?: boolean;
    label?: ReactNode;
    htmlLabel?: string;
    error?: ReactNode;
}

export default ({ warning, label, htmlLabel, error, className, ...bag }: Props) => (
    <div className={cn('checkbox-control', className)}>
        <div className="checkbox-control__child">
            <Checkbox className={cn({ 'ant-checkbox-warning': error })} {...bag} />
        </div>
        {!htmlLabel && <div className="checkbox-control__label">{label}</div>}
        {htmlLabel && <div className="checkbox-control__label" dangerouslySetInnerHTML={{ __html: htmlLabel }}/>}
        {error && <div className="checkbox-control__error">{error}</div>}
    </div>
);
