import { Collapse } from 'antd';
import { CollapseProps } from 'antd/lib/collapse';
import { BasicProps } from 'antd/lib/layout/layout';
import React from 'react';

type Props = CollapseProps & BasicProps;

const EnhancedCollapse = (props: Props) => <Collapse {...props} />;

EnhancedCollapse.Panel = Collapse.Panel;

export default EnhancedCollapse;
