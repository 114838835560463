import { Icon } from 'antd';
import cn from 'classnames';
import React from 'react';

interface Props {
    count?: number;
    onClick?: () => void;
}

export default ({ count = 0, onClick = () => {} }: Props) => (
    <div className={cn('cart-button', { 'cart-button--has-items': count > 0 })} onClick={onClick}>
        <Icon type="shopping-cart" />({count})
    </div>
);
