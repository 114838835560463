import cn from 'classnames';
import React, { ChangeEvent, Fragment, ReactNode, useState } from 'react';
import { oc } from 'ts-optchain';

import { Alert, Button, Input } from '../../components';
import { Token } from '../../models/Token';
import { i18n } from '../../services/i18n';
import { WtpInput } from '../Form';

interface Props {
    children?: ReactNode;
    isLogged?: boolean;
    validated: boolean;
    error: boolean;
    card: Token | null;
    noValidation?: boolean;
    onVerify?: (code: string) => void;
}

const CardInput = (props: Props) => {
    const [code, setCode] = useState('');

    const isVerified = () => props.validated && props.card && !props.error;
    const isError = () => props.error && !props.card;

    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        setCode(e.currentTarget.value);
    }

    function handleInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            handleVerifyClick();
        }
    }

    function handleVerifyClick() {
        props.onVerify && props.onVerify(code);
    }

    function renderFailure() {
        return (
            <Alert
                message={i18n.t('cardInputSection.invalid.title')}
                description={i18n.t('cardInputSection.invalid.description')}
                type="error"
                showIcon
            />
        );
    }

    function renderSuccess() {
        return (
            <Fragment>
                <Alert
                    message={`${i18n.t('cardInputSection.valid.title')} ${oc(
                        props
                    ).card.extIdentifiers.axess.wtpNumber()}`}
                    description={i18n.t('cardInputSection.valid.description')}
                    type="success"
                    showIcon
                />
                {props.children && props.children}
            </Fragment>
        );
    }

    return (
        <div
            className={cn('card-input', {
                'card-input--verified': isVerified(),
                'card-input--error': isError(),
            })}
        >
            <div className="card-input-code">
                {props.noValidation ? (
                    <Input
                        placeholder={i18n.t('cardInput.placeholder')}
                        value={code}
                        onKeyDown={handleInputKeyDown}
                        onChange={handleInputChange}
                    />
                ) : (
                    <WtpInput
                        placeholder={i18n.t('cardInput.placeholder')}
                        value={code}
                        onKeyDown={handleInputKeyDown}
                        onChange={handleInputChange}
                    />
                )}
                {isVerified() ? (
                    <Button onClick={handleVerifyClick} size="small" type="success">
                        {i18n.t('cardInput.button.verified')}
                    </Button>
                ) : (
                    <Button onClick={handleVerifyClick} size="small">
                        {i18n.t('cardInput.button.verify')}
                    </Button>
                )}
            </div>
            {props.validated && (
                <div className="card-input-content">
                    {isVerified() ? renderSuccess() : renderFailure()}
                </div>
            )}
        </div>
    );
};

export default CardInput;
