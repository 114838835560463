import validator from 'validator';
import React, { useState, ChangeEvent } from 'react';

import { Button, CheckBox, Modal, Price, Input } from '../../components';
import { ModalSizes } from '../../constants/modal';
import { i18n } from '../../services/i18n';

interface Props {
    visible?: boolean;
    onConfirm: (email: string) => void;
    onCancel?: () => void;
}

export default function ForgotPasswordModal({ visible, onConfirm, onCancel }: Props) {
    const [email, setEmail] = useState('');

    function isValid() {
        return validator.isEmail(email);
    }

    function handleConfirmClick() {
        onConfirm && onConfirm(email);
    }

    function handleInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            onConfirm && onConfirm(email);
        }
    }

    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        setEmail(e.currentTarget.value);
    }

    return (
        <Modal
            visible={visible}
            footer={null}
            width={ModalSizes.default}
            closable
            onCancel={onCancel}
        >
            <div className="marketing-modal">
                <h1>{i18n.t('forgotPasswordModal.title')}</h1>
                <p className="forgot-password-desc">{i18n.t('forgotPasswordModal.content')}</p>
                <Input
                    className="forgot-password-input"
                    placeholder={i18n.t('forgotPasswordModal.inputPlaceholder')}
                    onKeyDown={handleInputKeyDown}
                    onChange={handleInputChange}
                />
                <div className="marketing-modal-btns">
                    <Button onClick={handleConfirmClick} disabled={!isValid()}>
                        {i18n.t('forgotPasswordModal.confirmButton')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
