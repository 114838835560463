import { Icon, Statistic } from 'antd';
import cn from 'classnames';
import QRCode from 'qrcode.react';
import React, { FunctionComponent } from 'react';

import { cardAdult, cardChild, cardMini, cardMorava, cardAnnaberg } from '../../../resources/images';
import { useIsMobileWide } from '../../hooks/useIsMobileWide';
import { i18n } from '../../services/i18n';

import TicketFooter from './components/Footer';
import { environment } from '../../../environments/environment';
import { TicketItem } from '../../models/NewTransaction';

interface Props {
    transactionId: string;
    item: TicketItem;
    origin: string;
}

const Ticket: FunctionComponent<Props> = ({ transactionId, item, origin }) => {
    const isMobile = useIsMobileWide();

    function isPass() {
        return item.type === 'pass';
    }

    function isExchangePass() {
        return item.type === 'exchange-pass';
    }

    function isInfo() {
        return item.type === 'info';
    }

    function renderCard() {
        if (environment.project === 'morava') {
            return <img src={cardMorava} />;
        }

        if (environment.project === 'annaberg') {
            return <img src={cardAnnaberg} />;
        }

        switch (item.exhangeType) {
            case 'adult':
                return <img src={cardAdult} />;
            case 'child':
                return <img src={cardChild} />;
            case 'mini':
                return <img src={cardMini} />;
            default:
                return <img src={cardAdult} />;
        }
    }

    function handleDownloadTicket() {
        window.open(
            `${environment.protocol}://${environment.host}/transactions/${transactionId}/ticket?entryId=${item.uuid}&lang=${i18n.language}`
        );
    }

    const keyBase = item.isGift ? 'ticket.gift' : 'ticket';

    return (
        <div
            className={cn('ticket', {
                'ticket--exchange-pass': isExchangePass(),
                'ticket--pass': isPass(),
                'ticket--info': isInfo(),
            })}
        >
            <div className="ticket__content">
                <div className="ticket__content__left">
                    <h3>
                        {isPass() && i18n.t(`${keyBase}.pass.title`)}
                        {isExchangePass() && i18n.t(`${keyBase}.exchangePass.title`)}
                        {isInfo() && i18n.t(`${keyBase}.info.title`)}
                        {item.userName && ` - ${item.userName}`}
                    </h3>
                    <div className="ticket-informations">
                        <Statistic title={i18n.t('ticket.infoLabels.product')} value={item.name} />
                        <Statistic title={i18n.t('ticket.infoLabels.type')} value={item.variant} />
                        <Statistic title={i18n.t('ticket.infoLabels.date')} value={item.validity} />
                    </div>
                    <p>
                        {isPass() && i18n.t(`${keyBase}.pass.description`)}
                        {isExchangePass() && i18n.t(`${keyBase}.exchangePass.description`)}
                        {isInfo() && i18n.t(`${keyBase}.info.description`)}
                    </p>
                    {item.smallTicketInfo.map(row => (<p className="ticket-info">{row}</p>))}
                </div>
                <div className="ticket__content__right">
                    {isInfo() ? renderCard() : <QRCode size={isMobile ? 150 : 120} value={item.code} includeMargin />}
                    {!isInfo() && item.isGift && (
                        <div className="ticket__content__right--download" onClick={handleDownloadTicket}>
                            <Icon type="download" />
                        </div>
                    )}
                </div>
            </div>
            <TicketFooter item={item} origin={origin} />
        </div>
    );
};

export default Ticket;
