import React from 'react';

import { i18n } from '../../../../services/i18n';
import TransactionItem from '../TransactionItem';
import { UpdateInfo } from '../../../../../features/dateChange/ducks';
import { oc } from 'ts-optchain';
import { Button } from '../../../../components';
import { Entry } from '../../../../models/NewTransaction';

interface Props {
    items: Entry[];
    updates: Record<string, UpdateInfo>;
    status: string;
    onBackClick: () => void;
    onSubmitClick: () => void;
}

const Confirmation = ({ items, updates, status, onSubmitClick, onBackClick }: Props) => {
    return (
        <>
            <div className="change-confirmation">
                <h3 className="change-confirmation__header">
                    {i18n.t('changeTransactionDateModal.confirmation.title')}
                </h3>
                <p className="change-confirmation__description">
                    {i18n.t('changeTransactionDateModal.confirmation.description')}
                </p>
                {items.map(item => (
                    <TransactionItem
                        key={item.uuid}
                        entry={item}
                        validFrom={oc(updates[item.uuid]).validFrom()}
                        time={oc(updates[item.uuid]).timeSlot()}
                        status={oc(updates[item.uuid]).status('requested')}
                    />
                ))}
                {status === 'success' && (
                    <p className="change-confirmation__description change-confirmation__description--bottom">
                        {i18n.t('changeTransactionDateModal.confirmation.success')}
                    </p>
                )}
                {status === 'failed' && (
                    <p className="change-confirmation__description change-confirmation__description--bottom">
                        {i18n.t('changeTransactionDateModal.confirmation.failed')}
                    </p>
                )}
            </div>
            <div className="change-transaction-date-modal__footer">
                <Button onClick={onBackClick} type="primary" disabled={status !== 'idle'}>
                    {i18n.t('changeTransactionDateModal.footer.back')}
                </Button>
                <Button
                    onClick={onSubmitClick}
                    type="success"
                    loading={['loading', 'created'].includes(status)}
                    disabled={Object.keys(updates).length === 0}
                >
                    {['success', 'failed'].includes(status)
                        ? i18n.t('changeTransactionDateModal.footer.close')
                        : i18n.t('changeTransactionDateModal.footer.submit')
                    }
                </Button>
            </div>
        </>
    );
};

export default Confirmation;
