import { message } from 'antd';
import { push } from 'connected-react-router';
import { combineReducers } from 'redux';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { oc } from 'ts-optchain';

import { AppState } from '../../common/models/AppState';
import { Token } from '../../common/models/Token';
import { RegisterFormValues, UserProfile } from '../../common/models/User';
import { i18n } from '../../common/services/i18n';
import {
    createActionCreator,
    createActionType,
    createApiActionCreators,
    createReducer,
    RequestActionTypes,
} from '../../common/utils/redux-helpers';
import { registerBenefitEventAction, runBenefitEventActions } from '../code/ducks';
import {
    CartActionTypes, removeItemFromCartAction,
    resetCartAction,
    selectCartItems,
    validateTransactionActions,
} from '../shopping/ducks/cart';

import { api } from './api';
import { userApi } from '../../common/api/user';
import { delay } from 'redux-saga';
import { environment } from '../../environments/environment';
import { setAsyncUserIdAction, clearEmailStateAction } from '../shopping/ducks/order';
import _ from 'lodash';
import { getCardNumber } from '../../common/utils/project-helpers';
import { sendGtmEvent } from '../../common/utils/gtm-helpers';
import { ProductSelection } from '../../common/models/Cart';
import { transactionsApi } from '../../common/api/transaction';

export interface UserState {
    isLogged: boolean;
    sessionError: boolean;
    cookies: boolean;
    profile?: UserProfile | {};
    cards: Token[];
    temporaryCards: Token[];
    segments: any[];
    cardValidation: CardValidationState;
    asyncRegisterInProgress: boolean;
    showRegisterModal: boolean;
}

export interface CardValidationState {
    verified: boolean;
    error: boolean;
    card: Token | null;
    validated: boolean;
    exists: string | null;
}

/**
 * ACTION TYPES
 */
export enum UserActionTypes {
    Login = '@@User/LOGIN',
    SaveProfile = '@@User/SAVE_PROFILE',
    Logout = '@@User/LOGOUT',
    HandleSessionError = '@@User/HANDLE_SESSION_ERROR',
    CheckSession = '@@User/CHECK_SESSION',
    Register = '@@User/REGISTER',
    UpdateTerms = '@@User/UPDATE',
    UpdateProfile = '@@User/UPDATE_PROFILE',
    RegisterAndLogin = '@@User/REGISTER_AND_LOGIN',
    ChangeLocale = '@@User/CHANGE_LOCALE',
    SetLocales = '@@User/SET_LOCALES',
    AgreeWithCookies = '@@User/AGREE_COOKIES_POLICY',
    FetchSegments = '@@User/FETCH_SEGMENTS',
    LoadCards = '@@User/LOAD_CARDS',
    CardVerify = '@@CardValidation/VERIFY',
    CardVerifyReset = '@@CardValidation/VERIFY_RESET',
    CardOwnerUpdate = '@@CardValidation/CARD_OWNER_UPDATE',
    AddTemporaryCard = '@@User/ADD_TEMPORARY_CARD',
    RemoveTemporaryCard = '@@User/REMOVE_TEMPORARY_CARD',
    ResetTemporaryCards = '@@User/RESET_TEMPORARY_CARDS',
    ForgotPassword = '@@User/FORGOT_PASSWORD',
    ResetPassword = '@@User/FReset_PASSWORD',
    ActivateAccount = '@@User/ACTIVATE',
    RegisterShoppingAsync = '@@User/REGISTER_SHOPPING_ASYNC',
    SetShowRegisterModal = '@@User/SET_SHOW_REGISTER_MODAL',
}

/**
 * ACTIONS
 */
export const loginActions = createApiActionCreators(UserActionTypes.Login);
export const saveProfileActions = createActionCreator(UserActionTypes.SaveProfile);
export const updateProfileActions = createApiActionCreators(UserActionTypes.UpdateProfile);
export const loadCardsActions = createApiActionCreators(UserActionTypes.LoadCards);
export const registerActions = createApiActionCreators(UserActionTypes.Register);
export const updateUserActions = createApiActionCreators(UserActionTypes.UpdateTerms);
export const forgotPasswordActions = createApiActionCreators(UserActionTypes.ForgotPassword);
export const activateAccountActions = createApiActionCreators(UserActionTypes.ActivateAccount);
export const resetPasswordActions = createApiActionCreators(UserActionTypes.ResetPassword);

export const registerAndLoginAction = createActionCreator(UserActionTypes.RegisterAndLogin);
export const logoutAction = createActionCreator(UserActionTypes.Logout);
export const changeLocaleAction = createActionCreator(UserActionTypes.Logout);
export const setLocalesAction = createActionCreator(UserActionTypes.SetLocales);
export const agreeWithCookiesAction = createActionCreator(UserActionTypes.AgreeWithCookies);

export const cardVerifyActions = createApiActionCreators(UserActionTypes.CardVerify);
export const cardUpdateActions = createApiActionCreators(UserActionTypes.CardOwnerUpdate);
export const cardVerifyResetAction = createActionCreator(UserActionTypes.CardVerifyReset);
export const addTemporaryCardAction = createActionCreator(UserActionTypes.AddTemporaryCard);
export const removeTemporaryCardAction = createActionCreator(UserActionTypes.RemoveTemporaryCard);
export const resetTemporaryCardsAction = createActionCreator(UserActionTypes.ResetTemporaryCards);

export const handleSessionErrorAction = createActionCreator(UserActionTypes.HandleSessionError);
export const checkSessionActions = createApiActionCreators(UserActionTypes.CheckSession);

export const registerShoppingAsyncAction = createApiActionCreators(UserActionTypes.RegisterShoppingAsync);

export const setShowRegisterModalAction = createActionCreator(UserActionTypes.SetShowRegisterModal);

/**
 * REDUCERS
 */
const initialState: UserState = {
    cookies: false,
    profile: {},
    cards: [],
    temporaryCards: [],
    segments: [],
    isLogged: false,
    sessionError: false,
    cardValidation: {
        validated: false,
        verified: false,
        exists: null,
        error: false,
        card: null,
    },
    showRegisterModal: false,
    asyncRegisterInProgress: false,
};

const profile = createReducer(initialState.profile, {
    [UserActionTypes.Login]: {
        [RequestActionTypes.SUCCESS]: (state: UserProfile, payload: UserProfile) => payload,
    },
    [UserActionTypes.SaveProfile]: (state: UserProfile, payload: UserProfile) => payload,
    [UserActionTypes.UpdateTerms]: {
        [RequestActionTypes.SUCCESS]: (state: UserProfile, payload: UserProfile) => ({
            ...state,
            meta: payload,
        }),
    },
    [UserActionTypes.Logout]: () => null,
    [UserActionTypes.UpdateProfile]: {
        [RequestActionTypes.SUCCESS]: (state: UserProfile, payload: UserProfile) => payload,
    },
});

const cookies = createReducer(initialState.cookies, {
    [UserActionTypes.AgreeWithCookies]: () => true,
});

const cards = createReducer(initialState.cards, {
    [UserActionTypes.LoadCards]: {
        [RequestActionTypes.SUCCESS]: (state: Token[], payload: any) => payload,
    },
});

const temporaryCards = createReducer(initialState.temporaryCards, {
    [UserActionTypes.AddTemporaryCard]: (state, payload) => {
        const newState = [...state];
        if (!state.some(card => card._id === payload._id)) {
            newState.push(payload);
        }
        return newState;
    },
    [UserActionTypes.RemoveTemporaryCard]: (state, payload) => {
        return state.filter(card => card._id !== payload._id);
    },
    [UserActionTypes.ResetTemporaryCards]: () => initialState.temporaryCards,
});

const segments = createReducer(initialState.cards, {
    [UserActionTypes.FetchSegments]: {
        [RequestActionTypes.SUCCESS]: (state: any[], payload: any) => payload,
    },
});

const isLogged = createReducer(initialState.isLogged, {
    [UserActionTypes.Login]: {
        [RequestActionTypes.SUCCESS]: () => true,
    },
    [UserActionTypes.Logout]: () => false,
});

const sessionError = createReducer(initialState.sessionError, {
    [UserActionTypes.HandleSessionError]: (state: boolean, payload: boolean) => payload,
});

const cardValidation = createReducer(initialState.cardValidation, {
    [UserActionTypes.CardVerify]: {
        [RequestActionTypes.SUCCESS]: (state, payload) => ({
            error: false,
            verified: true,
            card: payload,
            validated: true,
            exists: null,
        }),
        [RequestActionTypes.FAILURE]: (state, { exists }) => ({
            verified: false,
            error: true,
            card: null,
            validated: true,
            exists,
        }),
    },
    [UserActionTypes.CardVerifyReset]: () => initialState.cardValidation,
});

const asyncRegisterInProgress = createReducer(initialState.asyncRegisterInProgress, {
    [UserActionTypes.RegisterShoppingAsync]: {
        [RequestActionTypes.REQUEST]: () => true,
        [RequestActionTypes.SUCCESS]: () => false,
        [RequestActionTypes.FAILURE]: () => false,
    },
    [UserActionTypes.Register]: {
        [RequestActionTypes.REQUEST]: () => true,
        [RequestActionTypes.SUCCESS]: () => false,
        [RequestActionTypes.FAILURE]: () => false,
    },
    [UserActionTypes.RegisterAndLogin]: () => true,
});

const showRegisterModal = createReducer(initialState.showRegisterModal, {
    [UserActionTypes.SetShowRegisterModal]: (state: boolean, payload: boolean) => payload,
});

export default combineReducers<UserState>({
    cookies,
    profile,
    sessionError,
    cards,
    isLogged,
    segments,
    cardValidation,
    temporaryCards,
    asyncRegisterInProgress,
    showRegisterModal,
});

/**
 * SELECTORS
 */
export const selectUser = (state: AppState) => state.user;
export const selectUserProfile = (state: AppState) => selectUser(state).profile as UserProfile;
export const selectUserCards = (state: AppState) => selectUser(state).cards;
export const selectUserTemporaryCards = (state: AppState) => selectUser(state).temporaryCards;

export function selectUserCardsWithAvailability(state: AppState) {
    return selectUserCards(state).map(card => ({
        ...card,
        isUsed: selectCartItems(state).some(item => oc(item).assigment.token._id() === card._id),
    }));
}

export function selectUserTemporaryCardsWithAvailability(state: AppState) {
    return selectUserTemporaryCards(state).map(card => ({
        ...card,
        isUsed: selectCartItems(state).some(item => oc(item).assigment.token._id() === card._id),
    }));
}

export function selectCardsWithAvailability(state: AppState): Token[] {
    if (!selectIsLogged(state)) {
        return selectUserTemporaryCardsWithAvailability(state);
    }

    return [...selectUserCardsWithAvailability(state), ...selectUserTemporaryCardsWithAvailability(state)];
}

export const selectIsLogged = (state: AppState) => selectUser(state).isLogged;
export const selectSessionError = (state: AppState) => selectUser(state).sessionError;
export const selectCookiesAgreement = (state: AppState) => selectUser(state).cookies;

export function selectMarketingAgreement(state: AppState) {
    return !!oc(selectUserProfile(state)).meta.terms.marketing();
}

export function selectCardValidationStatus(state: AppState) {
    return selectUser(state).cardValidation;
}

export function selectAsyncRegisterInProgress(state: AppState) {
    return selectUser(state).asyncRegisterInProgress;
}

export function selectShowRegisterModal(state: AppState) {
    return selectUser(state).showRegisterModal;
}

/**
 * SAGAS
 */
function* getAndSaveProfile() {
    const user: UserProfile = yield select(selectUserProfile);

    const resp = yield call(api.getProfile);

    yield put(handleSessionErrorAction(!resp.ok));

    if (resp.ok) {
        yield put(saveProfileActions(resp.data));
    } else if (!resp.ok || (resp.ok && oc(user).id !== resp.data.id)) {
        yield put(logoutAction());
        yield delay(500);
        yield put(handleSessionErrorAction(true));
    }
}

function* login({ payload }: any) {
    const resp = yield call(api.login, {
        ...payload,
        email: payload.email.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    });
    if (resp.ok) {
        yield put(loginActions.success(resp.data));
        yield getAndSaveProfile();
        yield call(loadCards);
        yield put(validateTransactionActions.request());

        if (oc(resp.data as UserProfile).roles.b2b() && !environment.config.showB2BAllCategories) {
            yield put(resetCartAction.request());
        }
    } else {
        message.error(i18n.t('header.auth.loginFailed'), 3);
    }
}

function* register({ payload }: any) {
    if ((payload as RegisterFormValues).benefitKey) {
        const { benefitKey, ...values } = payload;
        const resp = yield call(api.register, values, benefitKey);
        if (resp.ok) {
            yield put(registerActions.success(resp.data));
            yield put(registerBenefitEventAction());
            // @TODO: Remove later used like this because register without login is used only /tablet feature
            yield put(push('/'));
            yield put(push('/tablet'));
            message.success(i18n.t('header.auth.registrationSuccesful'), 3);
        } else {
            message.error(i18n.t('header.auth.registrationFailed'), 5);
        }
    } else {
        const resp = yield call(api.register, payload);
        if (resp.ok) {
            yield put(registerActions.success(resp.data));
            message.success(i18n.t('header.auth.registrationSuccesful'), 3);
            // @TODO: Remove later used like this because register without login is used only /tablet feature
            yield put(push('/'));
            yield put(push('/tablet'));
        } else {
            message.error(i18n.t('header.auth.registrationFailed'), 5);
        }
    }
}

function* registerAndLogin({ payload }: any) {
    let resp;
    if ((payload as RegisterFormValues).benefitKey) {
        const { benefitKey, ...values } = payload;
        resp = yield call(api.register, values, benefitKey);
        if (resp.ok) {
            yield put(registerActions.success(resp.data));
            yield put(runBenefitEventActions.success());
            yield call(login, {
                payload: {
                    email: payload.email,
                    password: payload.password,
                    registrationLogin: true,
                },
            });
            message.success(i18n.t('header.auth.registrationSuccesful'), 3);
        } else {
            message.error(i18n.t('header.auth.registrationFailed'), 5);
        }
    } else {
        resp = yield call(api.register, payload);
        if (resp.ok) {
            yield put(registerActions.success(resp.data));
            yield call(login, {
                payload: {
                    email: payload.email,
                    password: payload.password,
                    registrationLogin: true,
                },
            });
            message.success(i18n.t('header.auth.registrationSuccesful'), 3);
        } else {
            message.error(i18n.t('header.auth.registrationFailed'), 5);
        }
    }
    // log registration success

    sendGtmEvent({
        event: 'registration',
        action: 'successful',
        success: resp.ok,
    });
}

function* loadCards() {
    const user = yield select(selectUserProfile);

    if (!user || !user.id) {
        return;
    }

    const resp = yield call(api.getCards);

    if (resp.ok) {
        yield put(loadCardsActions.success(resp.data));
    } else {
        message.error(i18n.t('header.auth.loginFailed'), 3);
    }
}

function* updateUserAgreements() {
    const user = yield select(selectUserProfile);
    const resp = yield call(api.updateUserAgreements, user.id);
    if (resp.ok) {
        yield put(updateUserActions.success(resp.data));
    } else {
        message.error(i18n.t('header.auth.userUpdateFailed'), 3);
    }
}

function* updateCardOwner({ payload: { tokenId, owner, birthday, subType } }: any) {
    const resp = yield call(api.updateTokenOwner, tokenId, owner, birthday, subType);

    if (resp.ok) {
        yield put(cardUpdateActions.success(resp.data));
        yield put(cardVerifyActions.success(resp.data));
        yield call(loadCards);
    } else {
        message.error(i18n.t('header.auth.userUpdateFailed'), 3);
    }
}

function* verifyCard({ payload: {
    wtpNumber, addToUser = false, subTypes = null, birthday = undefined, subType = undefined
} }: any) {
    const items: ProductSelection[] = yield select(selectCartItems);

    if (
        items.some(item => getCardNumber(oc(item).assigment.token.extIdentifiers()) === wtpNumber) &&
        (
            (environment.config.addCardType && !addToUser) ||
            (!environment.config.addCardType && addToUser)
        )
    ) {
        yield put(cardVerifyActions.failure({ exists: 'cardUsed' }));
        return;
    }

    const resp = yield call(api.verifyToken, wtpNumber);

    const user: UserProfile = yield select(selectUserProfile);
    const isLogged: boolean = yield select(selectIsLogged);

    const allowedSubtypes = subTypes;

    if (environment.config.allowAdultExternal && subTypes && subTypes.includes('adult')) {
        allowedSubtypes.push('external');
    }

    if (resp.ok) {
        const [card] = resp.data;
        if (card) {
            if (
                ((!card.meta || !card.meta.subType) && !environment.config.addCardType) ||
                (card.meta.subType && allowedSubtypes && !allowedSubtypes.includes(card.meta.subType))
            ) {
                yield put(cardVerifyActions.failure({ exists: 'cardWrongSubType' }));
                return;
            }

            if (oc(user).roles.b2b()) {
                yield put(addTemporaryCardAction(card));
                yield put(cardVerifyActions.success(card));
                return;
            }

            if (!card.owner || ['anonymous', 'unregistered'].includes(card.owner)) {
                if (addToUser) {
                    if (isLogged && user) {
                        yield call(updateCardOwner, {
                            payload: { tokenId: card._id, owner: user.id, birthday, subType },
                        });
                        yield put(removeTemporaryCardAction(card));
                        yield call(loadCards);
                    } else {
                        if (birthday || subType) {
                            yield call(updateCardOwner, {
                                payload: { tokenId: card._id, birthday, subType },
                            });

                            _.set(card, 'meta.subType', subType);
                            _.set(card, 'birthday', birthday);

                            yield put(addTemporaryCardAction(card));
                        } else {
                            yield put(addTemporaryCardAction(card));
                        }
                    }
                } else if (card.meta.subType && card.birthday) {
                    yield put(addTemporaryCardAction(card));
                }
                yield put(cardVerifyActions.success(card));
            } else if (environment.config.allowShopOwnedCard && subTypes !== null) {
                yield put(addTemporaryCardAction(card));
                yield put(cardVerifyActions.success(card));
            } else {
                yield put(cardVerifyActions.failure({ exists: 'differentCardOwner' }));
            }
        } else {
            if (environment.config.addExternalCard) {
                if (!wtpNumber.startsWith('01-16147133')) {
                    yield put(cardVerifyActions.failure({ exists: 'unknownCard' }));
                    return;
                }

                if (subTypes && !subTypes.includes('adult')) {
                    yield put(cardVerifyActions.failure({ exists: 'cardWrongSubType' }));
                    return;
                }

                const newCard = yield call(api.createExternalToken, wtpNumber, addToUser && user && user.id);

                if (!newCard.ok) {
                    yield put(cardVerifyActions.failure({ exists: 'unableCreateCard' }));
                    return;
                }

                if (addToUser && isLogged && user) {
                    yield call(loadCards);
                } else {
                    yield put(addTemporaryCardAction(newCard.data));
                }

                yield put(cardVerifyActions.success(newCard.data));
            } else {
                yield put(cardVerifyActions.failure({ exists: 'cardNotFound' }));
            }
        }
    } else {
        message.error(i18n.t('tokenVerification.failed'), 3);
    }
}

function* clearTemporaryCards() {
    const cards = yield select(selectUserTemporaryCards);
    const items = yield select(selectCartItems);

    const usedCards = items
        .filter(item => item.assigment && item.assigment.value && item.assigment.value !== 'no-card')
        .map(item => item.assigment.value);

    for (const card of cards) {
        if (!usedCards.includes(getCardNumber(card.extIdentifiers))) {
            yield put(removeTemporaryCardAction(card));
        }
    }
}

function* updateProfile({ payload }: any) {
    const profile = yield select(selectUserProfile);
    const resp = yield call(
        api.updateUser,
        profile,
        {
            ...payload,
            email: payload.email.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        }
    );

    if (resp.ok) {
        yield getAndSaveProfile();
        message.success(i18n.t('profile.updateSuccess'), 3);
    } else {
        if (resp.response && resp.response.status === 400) {
            yield put(updateProfileActions.failure());
            message.error(i18n.t('profile.emailInUse'), 3);
        } else {
            yield put(updateProfileActions.failure());
            message.error(i18n.t('profile.updateFailed'), 3);
        }
    }
}

function* handleLogout() {
    const resp = yield call(api.logout);
    if (resp.ok) {
        yield put(resetCartAction.request());
        yield put(validateTransactionActions.request());
        yield put(loadCardsActions.success([]));
        yield put(resetTemporaryCardsAction());
        yield put(clearEmailStateAction());
        yield put(push('/'));
    }
}

function* watchResetPassword({ payload: { token, password } }: any) {
    const resp = yield call(userApi.passwordReset, token, password);
    if (resp.ok) {
        yield put(resetPasswordActions.success());
        message.success(i18n.t('header.auth.changePasswordSuccess'), 3);
        yield put(loginActions.request({ email: resp.data.email, password }));
    } else {
        // const resp = yield call(userApi.createNewPassword, token, password);
        // if(resp.ok)
        // {
        //     yield put(resetPasswordActions.success());
        //     message.success(i18n.t('header.auth.changePasswordSuccess'), 3);
        // } else {
            yield put(resetPasswordActions.failure());
            message.error(i18n.t('header.auth.changePasswordFailed'), 3);
       // }
    }
}

function* watchForgotPassword({ payload }: any) {
    const resp = yield call(
        userApi.passwordForgot,
        payload,
        i18n.language,
        `${window.location.origin}/shopping/eshop`
    );
    if (resp.ok) {
        yield put(forgotPasswordActions.success());
        message.success(i18n.t('header.auth.resetPasswordSuccess'), 3);
    } else {
        yield put(forgotPasswordActions.failure());
        message.error(i18n.t('header.auth.resetPasswordFailed'), 3);
    }
}

function* watchActivateAccount({ payload }: any) {
    const resp = yield call(userApi.activateAccount, payload);

    if (resp.ok) {
        message.success(i18n.t('header.auth.accountActivationSuccess'), 3);
    } else {
        message.error(i18n.t('header.auth.accountActivationFailed'), 3);
    }
}

function* handleCheckSession() {
    const isLogged = yield select(selectIsLogged);

    if (isLogged) {
        yield getAndSaveProfile();
    }
}

function* registerShoppingAsync({ payload }: any) {

    try {
        const userWithEmail = yield call(api.userWithEmail, payload.email);

        if (!userWithEmail.ok) {
            yield put(registerShoppingAsyncAction.failure());
            yield put(setAsyncUserIdAction());
            return;
        }

        if (userWithEmail.data.length) {
            yield put(setAsyncUserIdAction(userWithEmail.data[0].id));
            yield put(registerShoppingAsyncAction.success());
        } else {
            if (!payload.data) {
                console.warn('User not found in entries');
                yield put(registerShoppingAsyncAction.failure());
                yield put(setAsyncUserIdAction());
                return;
            }

            payload = payload.data;
            payload.email = payload.email.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

            if (payload.legalFirstName) {
                payload.firstName = payload.legalFirstName;
                payload.lastName = payload.legalLastName;
                payload.birthday = payload.legalBirthday;
            }

            const data = _.omit(payload, ['legalFirstName', 'legalLastName', 'legalBirthday', 'address']);

            const resp = yield call(api.register, data);

            if (!resp.ok) {
                yield put(registerShoppingAsyncAction.failure());
                yield put(setAsyncUserIdAction());
                return;
            }

            if (resp.ok && resp.data && resp.data.id) {
                yield put(setAsyncUserIdAction(resp.data.id));
                yield put(registerShoppingAsyncAction.success());
            } else {
                yield put(registerShoppingAsyncAction.failure());
                yield put(setAsyncUserIdAction());
            }
        }
    } catch (e) {
        yield put(registerShoppingAsyncAction.failure());
        yield put(setAsyncUserIdAction());
    }
}

function* removeReservation({ payload: entryUuid }: any) {
    yield put(removeItemFromCartAction.success(entryUuid));

    yield call(transactionsApi.deletePriceReservations, [entryUuid]);
}

function* resetCart() {
    const items: ProductSelection[] = yield select(selectCartItems);

    yield put(resetCartAction.success());

    yield call(
        transactionsApi.deletePriceReservations,
        items
            .filter(item => !!item.id)
            .map(item => item.id) as string[]
    );
}

export function* userSaga() {
    yield takeLatest(UserActionTypes.RegisterAndLogin, registerAndLogin);

    yield takeLatest(createActionType(UserActionTypes.Login, RequestActionTypes.REQUEST), login);

    yield takeLatest(
        createActionType(UserActionTypes.ForgotPassword, RequestActionTypes.REQUEST),
        watchForgotPassword
    );

    yield takeLatest(
        createActionType(UserActionTypes.ResetPassword, RequestActionTypes.REQUEST),
        watchResetPassword
    );

    yield takeLatest(
        createActionType(UserActionTypes.UpdateProfile, RequestActionTypes.REQUEST),
        updateProfile
    );

    yield takeLatest(
        createActionType(UserActionTypes.CardVerify, RequestActionTypes.REQUEST),
        verifyCard
    );

    yield takeLatest(
        createActionType(UserActionTypes.UpdateTerms, RequestActionTypes.REQUEST),
        updateUserAgreements
    );

    yield takeLatest(
        createActionType(UserActionTypes.Register, RequestActionTypes.REQUEST),
        register
    );

    yield takeLatest(
        createActionType(UserActionTypes.CardOwnerUpdate, RequestActionTypes.REQUEST),
        updateCardOwner
    );

    yield takeLatest(UserActionTypes.Logout, handleLogout);

    yield takeEvery(
        [
            createActionType(UserActionTypes.CheckSession, RequestActionTypes.REQUEST),
            CartActionTypes.OpenCart,
        ],
        handleCheckSession
    );

    yield takeLatest(
        createActionType(UserActionTypes.ActivateAccount, RequestActionTypes.REQUEST),
        watchActivateAccount
    );

    yield takeLatest(
        createActionType(UserActionTypes.RegisterShoppingAsync, RequestActionTypes.REQUEST),
        registerShoppingAsync
    );

    yield takeLatest(
        createActionType(UserActionTypes.LoadCards, RequestActionTypes.REQUEST),
        loadCards
    );

    yield takeLatest(
        createActionType(CartActionTypes.RemoveFromCart, RequestActionTypes.SUCCESS),
        clearTemporaryCards
    );

    yield takeEvery(
        createActionType(CartActionTypes.RemoveFromCart, RequestActionTypes.REQUEST),
        removeReservation
    );

    yield takeLatest(
        createActionType(CartActionTypes.ResetCart, RequestActionTypes.REQUEST),
        resetCart
    );
}
