import React, { Fragment } from 'react';
import { Layout } from '../../../../../common/components';
import { i18n } from '../../../../../common/services/i18n';
import { environment } from '../../../../../environments/environment';

export default function GDPR() {
    if (environment.project === 'lipno') {
        return (
            <Layout.Container>
                {i18n.language === 'cz' && (
                    <React.Fragment>
                        <h3>Zásady zpracování osobních údajů společnosti LIPNO SERVIS s.r.o.</h3>
                        <p>Tyto zásady vycházejí ze Směrnice pro nakládání s osobními údaji,  přijaté společností LIPNO SERVIS s.r.o. (dále jen „Společnost“) pro zpracování a ochranu osobních údajů ve smyslu obecného nařízení GDPR a v souladu s platným právním řádem České republiky, zejména Zákonem č. 110/2019 Sb., o zpracování osobních údajů s účinností od 24.4.2019, v platném znění. Zásady poskytují informace o základních principech, podle kterých Společnost zpracovává osobní údaje, kandidátů na pracovní pozice, fyzických osob podnikajících, klientů, zájemců, zástupců právnických osob, dodavatelů, zákazníků (odběratelů zboží a služeb, hostů, ubytovaných), obchodních a smluvních partnerů, nájemníků, držitelů karet, členů programu LIPNOCARD, uživatelů výhod zaměstnaneckého programu LIPNOCARD, klubových členů, zaměstnanců, brigádníků, sezónních pracovníků, instruktorů a studentů na praxi, přepravců, návštěvníků, hostů, a dalších osob (dále jen subjekt údajů), a o přístupu Společnosti ke zpracování, ochraně a zajištění bezpečnosti osobních údajů subjektů údajů.</p>
                        <p>1. Úvodem</p>
                        <p>1.1. Společnost v roli správce osobních údajů zpracovává osobní údaje subjektů údajů v rámci všech svých hlavních činností a to na základě členění úseků Společnosti a to nejen formou bezpečnostního opatření, jakým je řízený přístup k takovýmto informacím. Dále zpracovává osobní údaje zaměstnanců pro oprávněné účely související s jejich pracovním poměrem ke Společnosti. Společnost dále zpracovává, v roli zpracovatele osobních, osobní údaje zaměstnanců klientů/zaměstnavatelů, pokud dochází k využívání zaměstnaneckého programu členů LIPNOCARD.</p>
                        <p>1.2. Společnost, ode dne 25.05.2018, zpracovává osobní údaje subjektů v souladu s účinností Nařízení Evropského parlamentu a Rady (EU) č. 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů), které je přímo použitelné ve všech členských státech EU (General Data Protection Regulation „GDPR“). Dále Společnost zpracovává osobní údaje subjektů údajů dle zákona č. 110/2019 Sb., o zpracování osobních údajů a s účinností od 24.4.2019.</p>
                        <p>1.3 Společnost, jako právnická osoba, zpracovává osobní údaje a plní při tom povinnosti správce těchto údajů, případně jejich zpracovatele, jak jsou stanoveny platnými právními předpisy. </p>
                        <p>1.4. Subjekt osobních údajů poskytuje Společnosti své osobní údaje v závislosti na účelu jejich zpracování, které je v celé své šíři obsaženo formou Přílohy č. 1 vydané Směrnice pro ochranu osobních údajů Společnosti, přičemž jedná se zejména a výhradně o definici účelů zpracování osobních údajů:</p>
                        <p>a) výběrové řízení na pracovní pozici;</p>
                        <p>b) pro zajištění poskytovaných služeb Společnosti vč. poskytnutí „LIPNOCARD“ a provozu dráhy;</p>
                        <p>c) pro účely oprávněných zájmů Společnosti nebo třetí osoby, pro ochranu vlastních práv a nároků;</p>
                        <p>d) pro splnění smlouvy mezi subjektem a Společnosti a plnění zákonných povinností vč. potřeb evidence a statistik;</p>
                        <p>e) pro účely registrace a členství v programu LIPNOCARD, zaměstnaneckého programu LIPNOCARD, pro účely věrnostního programu LIPNOCARD a souvisejících služeb s aktivací, vydáním a poskytnutím LIPNOCARD, internetový obchod a samoobslužné pokladny Skiareálu LIPNO;</p>
                        <p>f) pro komunikaci s zákazníky, dodavateli, smluvními a obchodními partnery;</p>
                        <p>g) pro zajištění účelu zprostředkování pronájmu, ubytování, rezervací;</p>
                        <p>h) pro školící činnost;</p>
                        <p>i) pro propagační a marketingovou činnost;</p>
                        <p>j) pro zajištění interních procesů bezpečnostni a ochrany osobních údajů, vedení mzdové a personální agendy a vyplývající povinnosti vedení účetnictví Společnosti;</p>
                        <p>k) pro jiné účely se souhlasem subjektu se zpracováním jeho osobních údajů.</p>
                        <p>1.5. Souhlas se zpracováním osobních údajů je subjektem osobních údajů udělován v případě, kdy nelze pro účel zpracování použít jiný právní titul.</p>
                        <p>Společnost akceptuje souhlas subjektu údajů jako svobodný, konkrétní, informovaný a jednoznačný projev vůle, kterým subjekt údajů dává prohlášením či jiným zjevným potvrzením své svolení ke zpracování svých osobních údajů. Subjekt údajů má právo svůj souhlas kdykoli odvolat. Odvoláním souhlasu není dotčena zákonnost předcházejícího zpracování osobních údajů subjektu, vycházejícího z jím dříve uděleného souhlasu. Před udělením souhlasu o tom bude subjekt údajů informován.</p>
                        <p>2. Účel zpracování osobních údajů subjektů osobních údajů</p>
                        <p>2.1. Osobní údaje subjektů osobních údajů musí být shromažďovány pouze pro určité, výslovně vyjádřené a prokazatelné účely a nesmějí být dále zpracovávány způsobem, který je s těmito účely neslučitelný. Zpracování osobních údajů se uskutečňuje zejména k pokrytí hlavních  předmětných činností Společnosti a pro účely zpracování uvedené v bodě 1 těchto Zásad zpracování osobních údajů, kterými jsou zejména poskytování tělovýchovných a sportovních služeb v oblasti provozování lyžařské školy, provozování dráhy a drážní dopravy na lanové dráze, výroba, obchod a služby neuvedené v přílohách 1 až 3 živnostenského zákona, hostinská činnost, činnost účetních poradců, vedení účetnictví, vedení daňové evidence a dalších  </p>
                        <p>2.2. Společnost dále zpracovává osobní údaje subjektů k zabezpečení svých smluvních a jiných vztahů, vedení účetnictví, personální a mzdové agendy, náborové agendy a jiných činností, které přímo souvisí s hlavními činnostmi Společnosti,  a jsou definovány v interní Směrnici pro ochranu osobních údajů Společnosti.</p>
                        <p>3. Kategorie zpracovávaných osobních údajů</p>
                        <p>3.1. Společnost shromažďuje, zpracovává a uchovává následující kategorie osobních údajů subjektů, <strong>jejichž skladba je vždy dána nezbytností zpracování osobních údajů vždy dle definovaného účelu zpracování osobních údajů pro daný subjekt údajů</strong>:</p>
                        <p>3.1.1. adresné, kontaktní a identifikační osobní údaje – zejména: jméno, příjmení, datum narození, rodné číslo, bydliště, telefonní číslo, e-mail, doručovací adresa, státní občanství, č. OP, č. CP, č. ŘP, č. bankovního účtu, datová schránka, osobní zkušenosti; vzdělání,</p>
                        <p>3.1.2. popisné osobní údaje – zejména: údaje subjektu vztahující se ke smluvnímu vztahu, jakými jsou zejména a kromě již uvedených osobních údajů v bodě 3.1.1. DIČ, adresa sídla a provozovny, aj.;</p>
                        <p>3.1.4. zvláštní kategorie osobních údajů, zejména citlivé údaje subjektu údajů;</p>
                        <p>3.1.5. jiné personifikované údaje – zejména fotografie nebo kamerové záznamy, biometrické údaje, fyziologické údaje, platební údaje a údaje spojené s registrací a členstvím v programu LIPNOCARD, jakými jsou autentizační údaje, údaje spojené s využitím zaměstnaneckého programu LIPNOCARD, údaje o prostředcích na kontě LIPNOCARD a další nezbytné údaje.</p>
                        <p>4. Způsob zpracování a uchování osobních údajů a doba jejich uložení u Společnosti</p>
                        <p>4.1. Společnost zpracovává osobní údaje subjektu manuálně či automatizovaným způsobem a zabezpečeně je uchovává v listinné či elektronické podobě po dobu stanovenou archivačním,  spisovým a skartačním řádem. V návaznosti na účel zpracování jsou některé osobní údaje subjektu vedeny v informačním systému Společnosti (např. personální systém, ekonomický informační systém, archivační systém, systém pro zápůjčky, rezervační portál, aj.).</p>
                        <p>4.2. Společnost zpracovává osobní údaje způsobem, který zajistí jejich náležité zabezpečení pomocí zavedených bezpečnostních opatření před neoprávněným či protiprávním zpracováním a před náhodnou ztrátou, zničením nebo poškozením a to např. formou řízeného přístupu k těmto informacím, prováděním šifrování a anonymizace osobních údajů, schopnost obnovení dostupnosti osobních údajů nebo například formou pravidelných auditů zavedených bezpečnostních opatření.</p>
                        <p>5. Předání osobních údajů</p>
                        <p>5.1. Společnost nepředává osobní údaje jiným osobám, kromě zpracovatelů osobních údajů, nebo správců osobních údajů (kde je tato povinnost uložena smluvního vztahem se správcem, zpracovatelem nebo podzpracovatelem), pokud povinnost jejich předání orgánům, úřadům či institucím není Společnosti uložena právním předpisem anebo k tomu nebyl udělen souhlas subjektu údajů.</p>
                        <p>5.2. Při zpracování osobních údajů u Společnosti, správce osobních údajů, nedochází k automatizovanému rozhodování, na jehož základě by byly činěny úkony či rozhodnutí, jejichž obsahem by byl zásah do práv či oprávněných zájmů subjektů údajů. </p>
                        <p>6. Práva subjektu osobních údajů</p>
                        <p>6.1. Subjekt údajů na základě žádosti obdrží od Společnosti, není-li v žádosti blíže uvedeno, veškeré informace o zpracování jeho údajů, a to stručným, srozumitelným a snadno přístupným způsobem za použití jasných a jednoduchých jazykových prostředků.</p>
                        <p>6.2. Žádost lze podat elektronickými prostředky, podáním učiněným prostřednictvím datové schránky nebo poskytovatele poštovních služeb anebo ústním podáním učiněným do záznamu u Společnosti; žádost nelze podat telefonicky.</p>
                        <p>6.3. Pokud se osobní údaje, týkající se subjektu údajů, získávají přímo od něho, poskytne mu Společnost v okamžiku získávání osobních údajů tyto informace:</p>
                        <p>a) totožnost a kontaktní údaje správce,</p>
                        <p>b) kontaktní údaje na pověřence pro ochranu osobních údajů (DPO),</p>
                        <p>c) účely zpracování, pro které jsou osobní údaje určeny a zpracovávány, a právní základ pro jejich zpracování,</p>
                        <p>d) oprávněné zájmy správce nebo třetí strany v případě, že je zpracování založeno na tomto zákonném titulu,</p>
                        <p>e) případné příjemce nebo kategorie příjemců osobních údajů, včetně případného zpracovatele,</p>
                        <p>f) případný úmysl správce předat osobní údaje do třetí země nebo mezinárodní organizaci včetně odkazu na vhodné záruky,</p>
                        <p>g) dobu, po kterou budou osobní údaje uloženy u Společnosti, nebo není-li ji možné určit, budou použitá kritéria pro stanovení této doby,</p>
                        <p>h) existenci práva požadovat od Společnosti přístup k osobním údajům, týkajících se subjektu údajů, jejich opravu nebo výmaz, popřípadě omezení zpracování, a vznést námitku proti zpracování, jakož i práva na přenositelnost údajů,</p>
                        <p>i) pokud je zpracování založeno na souhlasu subjektu údajů, existenci práva odvolat  kdykoli souhlas, aniž je tím dotčena zákonnost zpracování založená na souhlasu uděleném před jeho odvoláním,</p>
                        <p>j) možnost podat stížnost u dozorového úřadu,</p>
                        <p>k) skutečnost, zda poskytování osobních údajů je zákonným či smluvním požadavkem, nebo požadavkem, který je nutné uvést do budoucí smlouvy, a zda má subjekt údajů povinnost osobní údaje poskytnout, a ohledně možných důsledků neposkytnutí těchto údajů,</p>
                        <p>l) skutečnost, zda dochází k automatizovanému rozhodování, včetně profilování, a přinejmenším v těchto případech smysluplné informace týkající se použitého postupu, jakož i významu a předpokládaných důsledků takového zpracování pro subjekt údajů.</p>
                        <p>6.4. Pokud Společnost hodlá osobní údaje dále zpracovávat pro jiný účel, než je účel, pro který byly shromážděny, poskytne subjektu údajů ještě před uvedeným dalším zpracováním, informace o tomto jiném účelu.</p>
                        <p>6.5. Společnost nemusí subjektu osobních údajů informace o zpracování poskytovat tehdy, pokud subjekt údajů již uvedené informace má, a do té míry, v níž je má.</p>
                        <p>6.6. Jestliže osobní údaje nebyly získány od subjektu údajů, poskytne mu Společnost shodné informace a navíc zdroj, ze kterého osobní údaje pocházejí, a případně informace o tom, zda údaje pocházejí z veřejně dostupných zdrojů.</p>
                        <p>6.7. Společnost nebude v případě získání osobních údajů od někoho jiného, než je subjekt údajů, uplatňovat informační povinnost, pokud je získávání nebo zpřístupnění výslovně stanoveno právním předpisem, který se na Společnost vztahuje a v němž jsou stanovena bezpečnostní a organizační opatření na ochranu oprávněných zájmů subjektu údajů.</p>
                        <p>6.8. Subjekt osobních údajů, který zjistí nebo se domnívá, že Společnost jako správce nebo jiná osoba, která pro Společnost zpracovává osobní údaje, provádí zpracování jeho osobních údajů v rozporu s nařízením v oblasti ochrany osobních údajů nebo v rozporu s legislativními povinnostmi v oblasti ochrany osobních údajů, může požádat o vysvětlení nebo požadovat, aby Společnost nebo zpracovatel odstranil takto vzniklý stav. Nevyhoví-li Společnost nebo příslušný zpracovatel žádosti, může se subjekt osobních údajů obrátit na Úřad pro ochranu osobních údajů, přičemž právo subjektu obrátit se přímo na Úřad pro ochranu osobních údajů tím není dotčeno.</p>
                        <p>6.9. Subjekt osobních údajů má tato další práva:</p>
                        <p>1. získat od Společnosti, jsou-li pro to splněny podmínky, informace o zpracování jeho osobních údajů (informace o totožnosti a kontaktní údaje správce a jeho případného zástupce; případně kontaktní údaje pověřence pro ochranu osobních údajů (DPO), účely zpracování, pro které jsou osobní údaje určeny, a právní základ pro zpracování, případné příjemce nebo kategorie příjemců osobních údajů a další informace, nezbytné pro zajištění transparentního a korektního zpracování jeho osobních údajů),</p>
                        <p>2. získat od Společnosti přístup k osobním údajům, tj. získat od Společnosti potvrzení o tom, zda tato zpracovává osobní údaje, které se ho týkají, a je-li tomu tak, má subjekt osobních údajů právo získat přístup k těmto osobním údajům a na další informace v zákonném rozsahu,</p>
                        <p>3. na opravu svých nesprávných osobních údajů, resp. na doplnění neúplných osobních údajů,</p>
                        <p>4. na výmaz svých osobních údajů, pokud byly splněny zákonné podmínky, např. pokud osobní údaje již nejsou potřeba k účelům, pro něž byly získány nebo se jinak zpracovávaly, nebo např. pokud subjekt odvolá svůj souhlas, na jehož základě byly osobní údaje zpracovány,</p>
                        <p>5. na omezení zpracování osobních údajů Společností, jsou-li splněny zákonné podmínky,</p>
                        <p>6. na přenositelnost údajů, tj. na získání osobních údajů, které se jej týkají, a které Společnosti poskytl, a to v strukturovaném, běžně používaném a strojově čitelném formátu,</p>
                        <p>7. vznést kdykoli námitku z důvodů jeho konkrétní situace proti zpracování osobních údajů, které se jej týkají;</p>
                        <p>8. nebýt předmětem automatizovaného individuálního rozhodování, včetně profilování, neudělí-li k tomu subjekt souhlas, kromě případů, kdy automatizované zpracování umožňuje právní předpis;</p>
                        <p>9. podat stížnost dozorovému úřadu.</p>
                        <p>6.10. Společnost je oprávněna vyžadovat od subjektu osobních údajů při podání žádosti o naplnění některého z výše uvedených práv, jeho osobní identifikaci ověřením u příslušného zaměstnance, nebo identifikaci ověřením jinými dostupnými metodami (např. datová schránka, notářské ověření/Czechpoint ověření podpisu na žádosti subjektu, popř. osobně v sídle Společnosti).</p>
                        <p>6.11. Společnost je oprávněna v případech, které vymezuje právní předpis, za poskytnutí informace o zpracovávaných osobních údajů subjektu požadovat přiměřenou úhradu, nepřevyšující náklady nezbytné na poskytnutí informace.</p>
                        <p>7. Závěrečná ustanovení</p>
                        <p>7.1. Subjekt osobních údajů může veškeré informace ke zpracování svých osobních údajů získat osobně, nebo prostřednictvím e-mailu. Aktuální kontaktní údaje na osobu Pověřence pro ochranu osobních údajů jsou uvedeny na webových stránkách naší Společnosti <a href="https://www.lipno.info/infocentrum/kontakty.html">https://www.lipno.info/infocentrum/kontakty.html</a>. Uplatnění práv plynoucích z platné právní úpravy oblasti zpracování a ochrany osobních údajů může subjekt údajů uplatnit na emailové adrese pověřence pro ochranu osobních údajů: <a href="mailto:gdpr@lipnoservis.cz">gdpr@lipnoservis.cz</a></p>
                        <p>7.2. Subjekt údajů má právo obrátit se na Úřad pro ochranu osobních údajů se sídlem Praha 7, Pplk. Sochora 27, 170 00, tel. ústředna +420 234 665 111, www: <a href="https://www.uoou.cz">https://www.uoou.cz</a> a to zejména v případě, že Společnost nevyhoví žádosti o vysvětlení či odstranění stavu vzniklého zpracováním osobních údajů, které je v rozporu s platnou právní úpravou oblasti zpracování a ochrany osobních údajů.</p>
                    </React.Fragment>
                )}
                {i18n.language === 'de' && (
                    <React.Fragment>
                        <h3>Prinzipien der Verarbeitung personenbezogener Daten der Gesellschaft LIPNO SERVIS s.r.o.</h3>
                        <p>Diese Prinzipien ergeben sich aus der Richtlinie für Umgang mit personenbezogenen Daten, angenommen von der Gesellschaft LIPNO SERVIS s.r.o. (nachfolgend nur „Gesellschaft“) für Verarbeitung und Schutz personenbezogener Daten im Sinne der allgemeinen GDPR-Verordnung und im Einklang mit der geltenden Rechtsordnung der Tschechischen Republik, besonders dann mit dem Gesetz Nr. 110/2019 GBl., über Verarbeitung personenbezogener Daten mit der Wirksamkeit seit 24.4.2019, in geltender Fassung. Die Prinzipien gewähren Informationen über die Grundsätze, nach denen die Gesellschaft personenbezogene Daten der Kandidaten für Arbeitsstellen, der unternehmenden natürlichen Personen, Klienten, Interessenten, Vertreter der juristischen Personen, Lieferanten, Kunden (Abnehmer von Waren und Dienstleistungen, Gäste, der Untergebrachten), Handels- und Vertragspartner, Mieter, Karteninhaber, Mitglieder des Programms LIPNOCARD, Benutzer der Vorteile des Mitarbeiterprogramms LIPNOCARD, der Klubmitglieder, der Angestellten, Jobber, Saisonarbeiter, Instruktoren und Studenten in Praktikum, Spediteuren, Besucher, Gäste und weiterer Personen (nachfolgend nur Subjekt der Daten), und über das Herantreten der Gesellschaft zu der Verarbeitung, dem Schutz und der Sicherstellung der Sicherheit personenbezogener Daten der Subjekte der Daten.</p>
                        <p>1. Einleitung</p>
                        <p>1.1. Die Gesellschaft in der Rolle des Verwalters personenbezogener Daten verarbeitet die personenbezogenen Daten der Subjekte im Rahmen ihrer Haupttätigkeiten, und dies aufgrund der Gliederung der Abteilungen der Gesellschaft, und dies nicht nur in Form der Sicherheitsmaßnahmen, was der gesteuerte Zutritt zu solchen Informationen ist. Ferner verarbeitet sie die personenbezogenen Daten der Angestellten für berechtigte Zwecke, die mit ihrem Arbeitsverhältnis zu der Gesellschaft zusammenhängen. Die Gesellschaft verarbeitet ferner, in der Rolle des Verarbeiters, die personenbezogenen Daten der Angestellten der Klienten/Arbeitgeber, falls es zu der Nutzung des Mitarbeiterprogramms der Mitglieder LIPNOCARD kommt.</p>
                        <p>1.2. Die Gesellschaft verarbeitet seit 25.05.2018 die personenbezogenen Daten der Subjekte im Einklang mit der Wirksamkeit der Verordnung des Europäischen Parlaments und des Rates (EU) 2016/679 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten und zum freien Datenverkehr, und über Aufhebung der Richtlinie 95/46/EG (allgemeine Verordnung zum Schutz personenbezogener Daten), die in allen Mitgliederstaaten der EU direkt anwendbar ist (General Data Protection Regulation „GDPR“). Ferner verarbeitet die Gesellschaft die personenbezogenen Daten der Subjekte der Daten laut Gesetz Nr. 110/2019 GBl., über Verarbeitung personenbezogener Daten, und mit der Wirksamkeit seit 24.04.2019.</p>
                        <p>1.3. Die Gesellschaft, als juristische Person, verarbeitet personenbezogene Daten und erfüllt dabei die Pflichten des Verwalters dieser Angaben, beziehungsweise ihres Verarbeiters, so wie sie von geltenden Rechtsvorschriften festgesetzt sind. </p>
                        <p>1.4. Das Subjekt der personenbezogenen Daten gewährt der Gesellschaft seine personenbezogenen Daten in der Abhängigkeit von dem Zweck ihrer Verarbeitung, die in ihrer ganzen Breite in Form des Anhangs Nr. 1 der ausgegebenen Richtlinie zum Schutz personenbezogenen Daten der Gesellschaft enthalten ist, wobei es sich besonders und ausschließlich um die Definition der Zwecke der Verarbeitung personenbezogenen Daten handelt:</p>
                        <p>a) Auswahlverfahren für Arbeitsstelle;</p>
                        <p>b) zur Sicherstellung der Dienstleistungen der Gesellschaft inkl. Gewährung der „LIPNOCARD“ und des Betriebs der Bahn;</p>
                        <p>c) für Zwecke der berechtigten Interessen der Gesellschaft oder der Dritten, zum Schutz eigener Rechte und Ansprüche;</p>
                        <p>d) zur Erfüllung des Vertrags zwischen dem Subjekt und der Gesellschaft und Erfüllung der gesetzlichen Pflichten inkl. Der Anforderungen der Erfassung und Statistik;</p>
                        <p>e) für Zwecke der Registrierung und Mitgliedschaft in dem Programm LIPNOCARD, des Mitarbeiterprogramms LIPNOCARD, für Zwecke des Treuprogramms LIPNOCARD und der zusammenhängenden Dienstleistungen mit der Aktivierung, Ausgabe und Gewährung der LIPNOCARD, Internetgeschäft und Selbstbedienungskassen des Skiareals LIPNO;</p>
                        <p>f) zur Kommunikation mit Kunden, Lieferanten, Vertrags- und Handelspartnern;</p>
                        <p>g) zur Sicherung des Zwecks der Vermittlung der Vermietung, der Unterkunft, der Reservierungen;</p>
                        <p>h) für Schulungstätigkeit;</p>
                        <p>i) für Propagation- und Marketingtätigkeit;</p>
                        <p>j) zur Sicherstellung interner Prozesse der Sicherheit und des Schutzes personenbezogener Daten, Führung der Lohn- und Personalagenda und daraus folgende Pflichten des Rechnungswesens der Gesellschaft;</p>
                        <p>k) für andere Zwecke mit der Zustimmung des Subjekts mit der Verarbeitung seiner personenbezogenen Daten.</p>
                        <p>1.5. Die Zustimmung mit der Verarbeitung der personenbezogenen Daten wird von dem Subjekt der Daten in dem Fall erteilt, wann für den Zweck der Verarbeitung kein anderer Rechtstitel verwendet werden kann.</p>
                        <p>Die Gesellschaft akzeptiert die Zustimmung des Subjekts der Daten als eine freie, konkrete, informierte und eindeutige Willenserklärung, mit der das Subjekt der Daten durch eine Erklärung oder andere offenbare Bestätigung seine Zustimmung zu der Verarbeitung seiner personenbezogenen Daten gibt. Das Subjekt der Daten hat das Recht, seine Zustimmung jederzeit zurückzunehmen. Mit dem Zurücknehmen der Zustimmung wird die Gesetzlichkeit der vorangehenden Verarbeitung der personenbezogenen Daten des Subjekts nicht berührt, die sich aus der von ihm früher erteilten Zustimmung ergibt. Vor der Erteilung der Zustimmung wird darüber das Subjekt der Daten informiert.</p>
                        <p>2. Zweck der Verarbeitung personenbezogener Daten der Subjekte personenbezogener Daten</p>
                        <p>2.1. Die personenbezogenen Daten des Subjekts der Daten müssen nur für bestimmte, ausdrücklich geäußerte und nachweisliche Zwecke gesammelt werden, und sie dürfen nicht weiter auf die Weise verarbeitet werden, die mit diesen Zwecken unvereinbar ist. Die Verarbeitung der personenbezogenen Daten wird besonders zur Deckung der gegenständlichen Haupttätigkeiten der Gesellschaft verwirklicht, und für Zwecke der Verarbeitung, die im Punkt 1 dieser Prinzipien der Verarbeitung personenbezogener Daten angegeben sind, was sind besonders  Gewährung von Turn- und Sportdiensten auf Gebiet des Betreibens der Skischule, Betreiben der Bahn und Bahnbeförderung auf der Seilbahn, Herstellung, Handel und Dienste, die in Anhängen 1 bis 3 des Gewerbegesetzes nicht angegeben sind, Gastwirtschaftstätigkeit, Tätigkeit der Buchhaltungsberater, Buchhaltung, Führung der Steuerevidenz, und anderer.  </p>
                        <p>2.2. Die Gesellschaft verarbeitet ferner personenbezogene Daten der Subjekte zur Sicherstellung ihrer vertraglichen und anderen Verhältnisse, Buchhaltung, der Personal- und Lohnagenda, Werbeagenda, und anderer Tätigkeiten, die mit den Haupttätigkeiten der Gesellschaft direkt zusammenhängen, und in der Richtlinie zum Schutz personenbezogenen Daten der Gesellschaft definiert sind.</p>
                        <p>3. Kategorien der zu verarbeitenden personenbezogenen Daten</p>
                        <p>3.1. Die Gesellschaft sammelt, verarbeitet und bewahrt folgende Kategorien der personenbezogenen Daten der Subjekte: <strong>deren Struktur durch die Notwendigkeit der Verarbeitung der personenbezogenen Daten immer gegeben ist, immer nach dem definierten Zweck der Verarbeitung der personenbezogenen Daten für das gegebene Subjekt der Daten</strong>:</p>
                        <p>3.1.1. Adressen-, Kontakt- und Identifizierungsangaben - besonders: Vorname, Nachname, Geburtsdatum, Geburtsnummer, Wohnsitz, Telefonnummer, E-Mail, Zustellungsadresse, Staatsbürgerschaft, Nummer des Personalausweises, Nummer des Reisepasses, Nummer des Führerscheins, Nummer des Bankkontos, Datenpostfach, persönliche Erfahrungen; Ausbildung,</p>
                        <p>3.1.2. beschreibende Angaben - besonders: Daten des Subjekts, die sich auf das Vertragsverhältnis beziehen, was sind besonders und außer den im Punkt 3.1.1. bereits angeführten Daten UID-Nr., Adresse des Sitzes und der Betriebsstätte, u.a.;</p>
                        <p>3.1.3. besondere Kategorien der personenbezogenen Daten, vor allem empfindliche Daten des Subjekts der Daten;</p>
                        <p>3.1.4. andere personifizierte Angaben - besonders Fotografien oder Kameraaufnahmen, biometrische Angaben, physiologische Angaben, Zahlungsangaben und Angaben, die mit der Registrierung und Mitgliedschaft in dem Programm LIPNOCARD verbunden sind, wie Authentisierungsangaben, Angaben, die mit der Ausnutzung des Mitarbeiterprogramms LIPNOCARD verbunden sind, Angaben über Mittel auf dem Konto LIPNOCARD und andere notwendige Angaben.</p>
                        <p>4. Weise der Verarbeitung und Bewahrung personenbezogener Daten und die Dauer ihrer Bewahrung bei der Gesellschaft</p>
                        <p>4.1. Die Gesellschaft verarbeitet personenbezogene Daten des Subjekts manuell oder auf automatisierte Weise und bewahrt sie gesichert in der urkundlichen oder elektronischen Form während der Dauer, die von der Archivierungs-, Akten- und Skartierungsordnung festgesetzt ist. In der Anknüpfung an den Zweck der Verarbeitung werden einige personenbezogene Daten des Subjekts in dem Informationssystem der Gesellschaft geführt (z.B. Personalsystem, ökonomischen Informationssystem, Archivierungssystem, System für Anleihen, Reservierungsportal, u.a.).</p>
                        <p>4.2. Die Gesellschaft verarbeitet personenbezogene Daten auf die Weise, die ihre gehörige Sicherung mittels eingeführten Sicherheitsmaßnahmen vor unbefugter oder widerrechtlicher Verarbeitung sichert, sowie vor dem zufälligen Verlust, der Vernichtung oder Beschädigung, und dies z.B. in Form des gesteuerten Zutrittes zu diesen Informationen, durch Durchführung der Chiffrierung und Anonymisierung personenbezogener Daten, Fähigkeit der Erneuerung der Zugänglichkeit personenbezogener Daten oder zum Beispiel in Form der regelmäßigen Überprüfungen der eingeführten Sicherheitsmaßnahmen. </p>
                        <p>5. Übergabe der personenbezogenen Daten</p>
                        <p>5.1. Die Gesellschaft übergibt personenbezogene Daten an andere Personen nicht, außer Verarbeiter personenbezogener Daten, oder Verwalter personenbezogener Daten (wo diese Pflicht durch das vertragliche Verhältnis mit dem Verwalter, Verarbeiter oder Subverarbeiter auferlegt ist), wenn die Pflicht ihrer Übergabe an Organe, Ämter oder Institutionen der Gesellschaft nicht durch eine Rechtsvorschrift auferlegt ist, oder dazu keine Zustimmung des Subjekts der Daten erteilt wurde. </p>
                        <p>5.2. Bei der Verarbeitung personenbezogener Daten bei der Gesellschaft, dem Verwalter der personenbezogenen Daten, kommt es nicht zu dem automatischen Entscheiden, auf dessen Grunde Handlungen oder Entscheidungen getroffen wären, deren Inhalt der Eingriff in Rechte oder berechtigte Interessen der Subjekte der Daten wäre. </p>
                        <p>6. Rechte des Subjekts der personenbezogenen Daten:</p>
                        <p>6.1. Das Subjekt der Daten erhält aufgrund eines Ersuchens von der Gesellschaft, falls es in dem Antrag nicht näher angegeben ist, sämtliche Informationen über Verarbeitung seiner Daten, und dies auf kurze, verständliche und einfach zugängliche Weise unter der Verwendung von klaren und einfachen Sprachmitteln.</p>
                        <p>6.2 Der Antrag kann per elektronischer Mittel eingereicht werden, per Einreichung mittels Datenpostfach oder per Post oder per mündlicher Einreichung, die in Protokoll bei der Gesellschaft getan wird; der Antrag kann nicht telefonisch eingereicht werden.</p>
                        <p>6.3 Wenn die personenbezogenen Daten, die das Subjekt der Daten betreffen, direkt von ihm erhalten werden, gewährt ihm die Gesellschaft in dem Augenblick der Erhaltung der personenbezogenen Daten folgende Informationen:</p>
                        <p>a) Identität und Kontaktangaben des Verwalters,</p>
                        <p>b) Kontaktangaben des Beauftragten für Schutz personenbezogener Daten,</p>
                        <p>c) Zwecke der Verarbeitung, für die die personenbezogenen Daten bestimmt und verarbeitet werden, und die rechtliche Grundlage für ihre Verarbeitung,</p>
                        <p>d) berechtigte Interessen des Verwalters oder der dritten Partei, falls die Verarbeitung auf diesem gesetzlichen Titel basiert,</p>
                        <p>e) eventuelle Empfänger oder Kategorien der Empfänger von personenbezogenen Daten, einschließlich des eventuellen Verarbeiters,</p>
                        <p>f) eventuelle Absicht des Verwalters, die personenbezogenen Daten in drittes Land oder an internationale Organisation zu übergeben, einschließlich Verweis auf passende Garantien,</p>
                        <p>g) Dauer, während der die personenbezogenen Daten bei der Gesellschaft bewahrt werden, oder wenn es nicht möglich ist zu bestimmen, werden die Kriterien für Festsetzung dieser Zeitdauer eingesetzt,</p>
                        <p>h) die Existenz des Rechtes, von der Gesellschaft den Zutritt zu personenbezogenen Daten zu verlangen, die das Subjekt der Daten betreffen, ihre Korrektur oder Löschung, beziehungsweise die Beschränkung der Verarbeitung, und die Einwendung gegen Verarbeitung zu erheben, sowie des Rechtes auf die Übertragbarkeit der Angaben,</p>
                        <p>i) wenn die Verarbeitung auf der Zustimmung des Subjekts der Daten basiert, auf der Existenz des Rechtes, die Zustimmung jederzeit zurückzunehmen, ohne damit die Gesetzlichkeit der Verarbeitung berührt wird, die auf der Zustimmung basiert, die vor ihrem Zurücknehmen erteilt wurde,</p>
                        <p>j) Möglichkeit, eine Beschwerde bei der Aufsichtsbehörde zu erheben,</p>
                        <p>k) Tatsache, ob die Gewährung der personenbezogenen Daten eine gesetzliche oder vertragliche Anforderung ist, oder eine Anforderung, die in dem künftigen Vertrag anzugeben ist, und ob das Subjekt der Daten die Pflicht hat, personenbezogene Daten zu gewähren, und bezüglich möglicher Folgen der Nichtgewährung dieser Daten,</p>
                        <p>l) Tatsache, ob es zu einer automatisierten Entscheidung nicht kommt, einschließlich Profilierung, und mindestens in diesen Fällen sinnvolle Informationen betreffend der verwendeten Vorgangsweise, sowie der Bedeutung und der vorausgesetzten Folgen solcher Verarbeitung für das Subjekt der Daten.</p>
                        <p>6.4 Hat die Gesellschaft vor, personenbezogene Daten zu einem anderen Zweck weiter zu verarbeiten, als der Zweck ist, zu dem sie gesammelt wurden, gewährt sie dem Subjekt der Daten noch vor der angegebenen weiteren Verarbeitung die Informationen über diesen anderen Zweck.</p>
                        <p>6.5 Die Gesellschaft muss dem Subjekt der personenbezogenen Daten die Informationen über Verarbeitung nur dann nicht gewähren, wenn das Subjekt der Daten die angegebenen Informationen bereits hat, und dies in dem Maß, wie er sie hat.</p>
                        <p>6.6. Falls die personenbezogenen Daten von dem Subjekt der Daten nicht gewonnen wurden, gewährt ihm die Gesellschaft gleichlautende Informationen und darüber hinaus die Quelle, aus der die personenbezogenen Daten stammen, und beziehungsweise Informationen darüber, ob die Daten aus öffentlich zugänglichen Quellen stammen.</p>
                        <p>6.7. Die Gesellschaft wird beim Gewinnen der personenbezogenen Daten von jemandem anderen, als das Subjekt der Daten ist, die Informationspflicht nicht geltend machen, wenn das Gewinnen oder Zugänglichmachen ausdrücklich von der Rechtsvorschrift festgesetzt ist, die sich auf die Gesellschaft bezieht, und in der die Sicherheits- und organisatorische Maßnahmen zum Schutz berechtigter Interessen des Subjekts der Daten festgesetzt sind.</p>
                        <p>6.8. Das Subjekt der personenbezogenen Daten, das feststellt oder vermutet, dass die Gesellschaft als der Verwalter oder eine andere Person, die für die Gesellschaft personenbezogene Daten verarbeitet, führt die Verarbeitung seiner personenbezogenen Daten im Widerspruch zu der Anordnung auf Gebiet des Schutzes der personenbezogenen Daten durch, oder im Widerspruch zu legislativen Pflichten auf Gebiet des Schutzes der personenbezogenen Daten, kann die Erklärung beantragen oder verlangen, dass die Gesellschaft oder der Verarbeiter diesen so entstandenen Zustand behebt. Kommt die Gesellschaft oder der zuständige Verarbeiter diesem Antrag nicht nach, kann sich das Subjekt der personenbezogenen Daten an das Amt für den Schutz personenbezogener Daten wenden, wobei das Recht des Subjekts, sich direkt an das Amt für den Schutz personenbezogener Daten damit nicht berührt wird.</p>
                        <p>6.9. Das Subjekt der personenbezogenen Daten hat folgende weitere Rechte:</p>
                        <p>1. von der Gesellschaft, wenn dafür Bedingungen erfüllt sind, die Informationen über Verarbeitung seiner personenbezogenen Daten zu erhalten (Informationen über Identität und Kontaktangaben des Verwalters und seines eventuellen Vertreters; beziehungsweise Kontaktangaben des Beauftragten für Schutz personenbezogener Daten, Zwecke der Verarbeitung, für die die personenbezogenen Daten bestimmt und verarbeitet werden, und die rechtliche Grundlage für ihre Verarbeitung, eventuelle Empfänger oder Kategorien der Empfänger von personenbezogenen Daten, und weitere Informationen, die für Sicherstellung der transparenten und korrekten Verarbeitung seiner personenbezogenen Daten notwendig sind),</p>
                        <p>2. von der Gesellschaft den Zutritt zu personenbezogenen Daten zu bekommen, d.h. von der Gesellschaft die Bestätigung darüber zu erhalten, ob diese die personenbezogenen Daten verarbeitet, die ihn betreffen, und wenn es so ist, hat das Subjekt der Daten das Recht, den Zutritt zu diesen personenbezogenen Daten zu bekommen, sowie auf weitere Informationen in gesetzlichem Umfang,</p>
                        <p>3. auf die Korrektur seiner unrichtigen personenbezogenen Daten, bzw. auf die Ergänzung inkompletter personenbezogener Daten,</p>
                        <p>4. auf die Löschung seiner personenbezogenen Daten, wenn gesetzliche Bedingungen erfüllt wurden, z.B. wenn personenbezogene Daten zu den Zwecken, zu denen sie erhalten oder anders verarbeitet wurden, nicht mehr nötig sind, oder z.B. wenn das Subjekt seine Zustimmung zurücknimmt, auf deren Basis seine personenbezogenen Daten verarbeitet wurden,</p>
                        <p>5. auf die Beschränkung der Verarbeitung der personenbezogenen Daten durch die Gesellschaft, wenn gesetzliche Bedingungen erfüllt werden,</p>
                        <p>6. auf die Übertragbarkeit der Daten, d.h. auf das Gewinnen der personenbezogenen Daten, die ihn betreffen, und die er der Gesellschaft gewährte, und dies in strukturiertem, üblich genutztem und maschinell lesbarem Format,</p>
                        <p>7. eine Einwendung aufgrund seiner konkreten Situation gegen die Verarbeitung seiner personenbezogenen Daten jederzeit zu erheben, die ihn betreffen,</p>
                        <p>8. das Subjekt der automatisierten individuellen Entscheidung nicht sein, einschließlich Profilieren, wenn dazu das Subjekt seine Zustimmung nicht erteilt, außer Fällen, wann die automatische Verarbeitung eine Rechtsvorschrift ermöglicht,</p>
                        <p>9. eine Beschwerde bei der Aufsichtsbehörde zu erheben.</p>
                        <p>6.10. Die Gesellschaft ist berechtigt, von dem Subjekt der personenbezogenen Daten bei der Einreichung des Antrags auf Geltendmachung eines der oben genannten Rechtes, seine persönliche Identifizierung zu verlangen, durch die Überprüfung bei dem zuständigen Angestellten, oder die Identifizierung durch Beglaubigung mittels anderer zugänglicher Methoden (z.B. Datenpostfach, notarielle Beglaubigung/Czechpoint, Beglaubigung der Unterschrift auf dem Antrag des Subjekts, bzw. persönlich in dem Sitz der Gesellschaft).</p>
                        <p>6.11. Die Gesellschaft ist berechtigt in den Fällen, die die Rechtsvorschrift beschränkt, für die Gewährung der Information über Verarbeitung der personenbezogenen Daten des Subjekts eine angemessene Entlohnung zu verlangen, die die Kosten nicht übersteigt, die für die Gewährung der Information unvermeidlich waren.</p>
                        <p>7. Abschließende Bestimmungen</p>
                        <p>7.1 Das Subjekt der personenbezogenen Daten kann die Informationen zu der Verarbeitung seiner personenbezogenen Daten persönlich oder mittels E-Mail gewinnen. Aktuelle Kontaktangaben des Beauftragten für Schutz personenbezogener Daten sind auf der Webseite unserer Gesellschaft  <a href="https://www.lipno.info/infocentrum/kontakty.html">https://www.lipno.info/infocentrum/kontakty.html</a> angegeben. Die Geltendmachung der Rechte, die aus der geltenden rechtlichen Regelung auf Gebiet der Verarbeitung und des Schutzes personenbezogener Daten folgen, kann das Subjekt der Daten an der E-Mail-Adresse des Beauftragten für Schutz personenbezogener Daten geltend machen: <a href="mailto:gdpr@lipnoservis.cz">gdpr@lipnoservis.cz</a></p>
                        <p>7.2 Das Subjekt der Daten hat das Recht, sich an das Amt für den Schutz personenbezogener Daten mit Sitz in Praha 7, Pplk. Sochora 27, 170 00, Telefonzentrale +420 234 665 111, www: <a href="https://www.uoou.cz">https://www.uoou.cz</a> zu wenden, und dies besonders in dem Fall, dass die Gesellschaft seinem Antrag auf Erklärung oder Behebung des Zustands nicht nachkommt, der durch diejenige Verarbeitung personenbezogener Daten entstand, die im Widerspruch zu der geltenden rechtlichen Regelung auf Gebiet der Verarbeitung und des Schutzes personenbezogener Daten steht.</p>
                    </React.Fragment>
                )}
                {i18n.language === 'en' && (
                    <React.Fragment>
                        <p>Principles of Personal Data Processing of LIPNO SERVIS s.r.o.</p>
                        <p>These principles are based on the Regulation on the Handling of Personal Data, adopted by company LIPNO SERVIS s.r.o. (hereinafter as the “Company”) for the processing and protection of personal data, in the sense of GDPR and in compliance with the valid legal order of the Czech Republic, chiefly Act No. 110/2019 Coll., on Personal Data Processing, effective from 24 April 2019, as amended. These principles provide information as to the essential rules followed by the Company when processing personal data of job seekers, natural business persons, clients, interested persons, representatives of legal persons, suppliers, customers (buyers of goods and services, guests, accommodated persons), business and contracting partners, lessees, card holders, members of the LIPNOCARD Programme, users of benefits of the LIPNOCARD Employee Programme, club members, employees, voluntary workers, seasonal workers, instructors and students completing practical training, forwarders, visitors, guests and other persons (hereinafter as “Data Subjects”), and information relating to the Company’s approach to the processing, processing and security of personal data of Data Subjects.</p>
                        <p>1. Introduction</p>
                        <p>1.1. The Company, as a personal data controller, processes personal data of Data Subjects in the scope of the Company’s main activities, based on the division of the Company’s branches, while applying various measures, including security measures such as a controlled access to such information. In addition, the Company processes personal data of its employees for legitimate purposes related to their full employment relation with the Company. In the role of a processor of personal data, the Company also processes personal data of employees of clients/employers if the employee programme of LIPNOCARD members is used.  </p>
                        <p>1.2. Since 25.05.2018, the Company has processed personal data of Data Subjects in compliance with the effect of Regulation (EU) No. 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation), directly applicable in all members states of the EU (General Data Protection Regulation, “GDPR”). Moreover, the Company processes personal data of Data Subjects as per Act No. 110/2019 Coll., on Personal Data Processing, effective from 24 April 2019.</p>
                        <p>1.3 The Company, as a legal person, processes personal data while fulfilling obligations of the controller or the processor of such data, as stipulated in valid legislation. </p>
                        <p>1.4. A Data Subject provides the Company with personal data depending on the purpose of processing hereof, delineated in detail in Annex No. 1 to the Company’s Regulation on the Protection of Personal Data, whereas the purposes of personal data processing are defined exclusively as follows:</p>
                        <p>a) selection procedure for the given position;</p>
                        <p>b) to ensure the provision of services rendered by the Company, including the provision of a “LIPNOCARD” and the operation of the chairlift;</p>
                        <p>c) for the purposes of legitimate interests of the Company or third persons, for protection of own rights and claims;</p>
                        <p>d) to perform a contract concluded between the Data Subject and the Company and compliance with legal obligations, including for the purposes of record keeping and statistics;</p>
                        <p>e) for the purposes of registration and members in the LIPNOCARD Programme, LIPNOCARD Employee Programme, for the purposes of the LIPNOCARD Loyalty Programme and services related to activation, issuance and provision of LIPNOCARD, online shop and self-service ticket offices of LIPNO Ski Resort;</p>
                        <p>f) to communicate with customers, suppliers, contracting and business partners;</p>
                        <p>g) to perform the purpose of intermediating the rental, accommodation, bookings;</p>
                        <p>h) for educational activities;</p>
                        <p>i) for promotional and marketing activities;</p>
                        <p>j) to arrange for internal security processes and protection of personal data, upkeep of payroll and personnel administration and the relating obligation to maintain the Company’s accounting records;</p>
                        <p>k) for other purposes conditional on the Data Subject’s consent to processing to his/her personal data.</p>
                        <p>1.5. The consent to personal data processing is given by Data Subjects in case when another legal title cannot be used for the given purpose of processing.</p>
                        <p>The Company accepts consent granted by Data Subjects as a free, specific, informed and definite expression of will, which is given by a Data Subject, in the form of his/her declaration or another clear confirmation, as his/her approval of the processing of his/her personal data. A Data Subject has a right to withdraw his/her consent at any time. The withdrawal of such consent shall not affect the legitimacy of prior processing of the given Data Subject’s personal data, based on consent previously given by the Data Subject. The Data Subject shall be informed in this respect before giving his/her consent.</p>
                        <p>2. Purpose of the processing of Data Subjects’ personal data</p>
                        <p>2.1. Data Subjects’ personal data must be collected only for certain, explicitly expressed and evidentiary provable purposes and may not be processed in a manner incompatible with such purposes. Personal data processing is carried out especially in order to cover the Company’s main scope of business and for the purposes of the processing delineated in para. 1 hereof, which chiefly consists in the provision of physical education and sports services in the area of operating a skiing school, operation of a cableway and a railway transport by chairlift, production, trade and services not listed in Annexes 1 to 3 to the Trade Licensing Act, innkeeper’s activities, activities of accounting advisors, upkeep of accounting records, upkeep of tax records, and other activities.   </p>
                        <p>2.2. Further, the Company processes Data Subjects’ personal data to address to its contractual and other relations, for upkeep of accounting records, personnel and payroll administration, recruitment administration and other activities directly connected with the Company’s main scope of business, as defined in the Company’s internal Regulation on the Protection of Personal Data.</p>
                        <p>3. Categories of personal data processed</p>
                        <p>3.1. The Company collects, processes and retains the following categories of Data Subjects’ personal data, the composition of which is at all times subject to the necessity to process personal data according to the defined purpose of personal data processing for the given Data Subject:</p>
                        <p>3.1.1. address, contact and identification personal data - in particular: name, surname, date of birth, birth reg. no., residence, telephone number, e-mail, delivery address, citizenship, Identity Card no., Passport no., Driving Licence no., bank account no., data box, personal experience; education,</p>
                        <p>3.1.2. descriptive personal data - in particular: the Data Subject’s data as to the contractual relation, in addition to the personal data referred to in subpara. 3.1.1., these data include especially the Tax ID, address of the registered office and branch offices, etc.;</p>
                        <p>3.1.3. special categories of personal data, especially the Data Subject’s sensitive data;</p>
                        <p>3.1.4. other personified data - especially photographs or camera recordings, biometric data, physiological data, payment data and data associated with the registration and membership in the LIPNOCARD Programme, such as authentication data, data relating to the use of the LIPNOCARD Employee Programme, data concerning the funds on the LIPNOCARD Account, and other necessary data.</p>
                        <p>4. Manner of processing and retaining of personal data and the period of retention of same with the Company </p>
                        <p>4.1. The Company processes Data Subjects’ personal data manually or in an automated manner and retains the same securely in the paper or electronic form during a period determined by the archiving, filing and shredding rules. In connection with the purpose of processing, certain personal data of Data Subjects are kept in the Company’s information system (e.g. the personnel system, economic information system, archiving system, system for loans, booking portal, etc.).</p>
                        <p>4.2. The Company processes personal data in a manner ensuring a proper security of the same by means of security measures implemented against unauthorized or illegal processing and incidental loss, destruction or damage, specifically in the form of controlled access to such information, by encrypting and anonymization of persons data and the capability to recover the accessibility of personal data, or in the form of regular audits of the security measures implemented.</p>
                        <p>5. Transfer of personal data</p>
                        <p>5.1. The Company shall not transfer personal data to persons other than personal data processors or personal data controllers (where such obligation results from a contractual relation with a controller, processor or sub-processor), unless an obligation to transfer the same to authorities, competent bodies or institutions is imposed on the Company by a legal regulation, or the given Data Subject has given his/her consent to such transfer. </p>
                        <p>5.2. When personal data are processed in the Company, i.e. the personal data controller, automated decision-making is not conducted, on the basis of which any acts or decisions would be done or made that might interfere in rights or legitimate interests of Data Subjects.</p>
                        <p>6. Rights of Data Subjects</p>
                        <p>6.1. At his/her request, a Data Subject shall receive from the Company all information, unless further specified in the request, on the processing of all of his/her data, in a concise and easily accessible manner, using clear and plain language.</p>
                        <p>6.2. The request may be lodged electronically, by a filing sent through a data box or a postal service provider, or verbally using a record in the Company’s office; the request cannot be placed by telephone.</p>
                        <p>6.3. Where personal data relating to the given Data Subject are obtained directly from the Data Subject, the Company shall provide him/her with the following information at the moment when the personal data are obtained:</p>
                        <p>a) identity and contact data of the controller,</p>
                        <p>b) contact data of the Data Protection Officer (DPO),</p>
                        <p>c) purposes of processing for which the personal data are intended and processed, and the legal basis for processing thereof,</p>
                        <p>d) legitimate interests of the controller or a third party in case the processing is based on such legal title,</p>
                        <p>e) any recipients or categories of recipients of the personal data, including a processor, if any,</p>
                        <p>f) the controller’s intent, if any, to transfer the personal data to a third country or an international organization, including a reference to suitable safeguards,</p>
                        <p>g) the period during which the personal data are retained by the Company, and where this period cannot be established, this period shall be determined according to appropriate criteria,</p>
                        <p>h) the existence of the right to require that the Company provide the Data Subject with access to personal data concerning him/her, the rectification or erasure thereof, or restriction of processing, and to object to processing, as well as the right to data portability,</p>
                        <p>i) if the processing is based on the Data Subject’s consent, the existence of the right to withdraw the consent at any time, without prejudice to the legitimacy of the processing established by consent given before the withdrawal,</p>
                        <p>j) an option to lodge a complaint with a supervisory authority,</p>
                        <p>k) information whether the provision of the personal data constitutes a statutory or contractual requirement or a requirement that must be incorporated in a future contract, and whether the Data Subject is obliged to provide the personal data, and possible consequences of a failure to provide such data,</p>
                        <p>l) information whether automated decision-making, including profiling, is done, and at least in these cases, reasonable information must be given in relation to a procedure applied, as well as the meaning and contemplated consequences of such processing for the Data Subject.</p>
                        <p>6.4. If the Company intends to process the personal data for a purpose different from that for which the personal data have been collected, the Company shall provide the Data Subject with information on that different purpose before the other processing commences.</p>
                        <p>6.5. The Company need not provide the Data Subject with information on the processing in case such information is already available to the Data Subject, insofar as the Data Subject already has the information.</p>
                        <p>6.6. If personal data were not obtained from the Data Subject, the Company shall supply him/her with identical information and the source of the personal data, and, as the case may be, information whether the data were derived from publicly accessible sources.</p>
                        <p>6.7. In case personal data were obtained from persons other than the Data Subject, the Company shall not exercise the obligation of information if the obtaining or disclosure is expressly stipulated in a legal regulation applicable to the Company and imposing security and organizational measures to protect the Data Subject’s legitimate interests.</p>
                        <p>6.8. The Data Subject who discovers or believes that the Company, as a controller, or another person who processes personal data on behalf of the Company, processes his/her personal data in conflict with regulations concerning personal data protection or in contradiction with legislative obligations in personal data protection, may ask for an explanation or require that the Company or the processor remedy such situation. Unless the Company or the relevant controller complies with the application, the Data Subject may contact the Office for Personal Data Protection, without prejudice to the Data Subject’s right to contact the Office for Personal Data Protection directly.</p>
                        <p>6.9. In addition, the Data Subject has the following rights:</p>
                        <p>1. if the relevant conditions have been met, to obtain from the Company the information on processing of his/her personal data (information on identity and contact data of the controller and the controller’s representative, if any; or, as the case may be, contact data of the Data Protection Officer (DPO), processing purposes for which the personal data are intended, and the legal basis for processing, any possible recipients or categories of recipients of personal data and other information necessary to ensure the transparent and correct processing of his/her personal data),</p>
                        <p>2. to obtain from the Company an access to his/her personal data, i.e. to obtain from the Company a confirmation as to whether or not personal data concerning him/her are being processed, and, where that is the case, access to the personal data and other information in the scope as prescribed by the law,</p>
                        <p>3. to obtain rectification of his/her incorrect personal data, or to have incomplete personal data completed,</p>
                        <p>4. to obtain the erasure of his/her personal data, upon meeting statutory conditions, e.g. in case the personal data are no longer necessary for the purposes for which they have been collected or processed, e.g. where the Data Subject withdraws his/her consent on the basis of which the personal data have been processed,</p>
                        <p>5. to obtain the restriction of processing of his/her personal data by the Company, where statutory conditions have been met,</p>
                        <p>6. the right to data portability, i.e. to receive the personal data concerning him/her, which he/she has provided to the Company, in a structured, commonly used and machine-readable format,</p>
                        <p>7. the right to object on grounds relating to his/her particular situation to processing of personal data concerning him/her;</p>
                        <p>8. the right not to be subject to automated individual decision-making, including profiling, unless the Data Subject gives his/her consent to the above, except when automated processing is permitted by legal regulations;</p>
                        <p>9. lodge a complaint at a supervisory authority.</p>
                        <p>6.10. The Company may require that the Data Subject, when filing an application for asserting any of the above rights, identify himself/herself by verification with the respective employee, or identify himself/herself by verification using other accessible methods (e.g. the data box, notarization/Czechpoint, signature authentication at the Data Subject’s request, or in person in the Company’s registered office).</p>
                        <p>6.11. In cases determined by legal regulations, the Company has a right to require a reasonable compensation for providing the information, not exceeding the costs necessarily expended on providing the information.</p>
                        <p>7. Final provisions</p>
                        <p>7.1. A Data Subject may obtain all information on processing of his/her personal data in person or by e-mail. Current contact data of the Data Protection Officer are available on the Company’s website <a href="https://www.lipno.info/infocentrum/kontakty.html">https://www.lipno.info/infocentrum/kontakty.html</a>. The rights arising from valid legislation in the area of processing and protection of personal data may be exercised by Data Subjects by writing to the e-mail address of the Data Protection Officer: <a href="mailto:gdpr@lipnoservis.cz">gdpr@lipnoservis.cz</a></p>
                        <p>7.2. Data Subjects have a right to contact the Office for Personal Data Protection, seated at Prague 7, Pplk. Sochora 27, 170 00, tel. exchange +420 234 665 111, www: <a href="https://www.uoou.cz">https://www.uoou.cz</a>, especially in the case when the Company fails to comply with a request for clarification or remedy of a situation caused by personal data processing that violates the valid legislation in the area of processing and protection of personal data.</p>
                    </React.Fragment>
                )}
            </Layout.Container>
        );
    } else if (environment.project === 'annaberg') {
        return (
            <React.Fragment>
                <p dangerouslySetInnerHTML={{__html: i18n.t('legal.gdpr')}}/>
                {/*<h3>DSGVO – Datenschutz Grundverordnung</h3>
                <p><u>Datenschutzerklärung</u></p>
                <p>Erfüllung der Informationspflicht gemäß Art 13 DSGVO</p>
                <p>Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir, die Annaberger Liftbetriebs- GmbH, verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, DSG, TKG 2003). Mit diesen Datenschutzinformationen informieren wir Sie über
                    die wichtigsten Aspekte der Datenverarbeitungen in unserem Unternehmen.</p>
                <p><u>Datenspeicherung</u></p>
                <p>Zum Zweck der Vertragsabwicklung werden folgende Daten bei uns gespeichert:</p>
                <p>Vorname, Familienname, Geburtsdatum, Titel, Adresse, Bankdaten/Kreditkartendaten, Telefonnummer, E-Mail-Adresse</p>
                <p>Der Zweck der Verarbeitung und Speicherung dieser Daten liegt in der Vertragserfüllung bzw. sind diese zur Durchführung vorvertraglicher Maßnahmen erforderlich. Ohne diese Daten können wir den Vertrag mit Ihnen nicht abschließen.</p>
                <p>Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des § 96 TKG sowie Art 6 Abs 1 lit a (Einwilligung) und/oder lit b
                    (notwendige Vertragserfüllung) DSGVO.</p>
                <p>Die Löschung kann grundsätzlich jederzeit begehrt werden.</p>
                <p><u>Datenübermittlungen:</u></p>
                <p>Eine Weitergabe von Daten erfolgt nur im Rahmen der gesetzlichen Vorgaben. Wir geben die Daten nur weiter, wenn dies z.B. auf Grundlage des Art
                    6 Abs 1 lit b DSGVO für die Durchführung des Ver-trages erforderlich ist oder aufgrund eines überwiegenden berechtigten Interesses gemäß Art 6
                    Abs 1 lit f DSGVO.</p>
                <p>Zur Vertragsabwicklung kann die Datenweitergabe (je nach Auftrag) an folgende Unternehmen erforderlich sein:</p>
                <p><u>Datenübermittlungen innerhalb der EU/EWR</u></p>
                <p>Versicherungen bei Schadensabwicklung</p>
                <p>Bankinstitute/Zahlungsdienstleister (Kreditkartendaten zur Abbuchung der Rechnungsbeträge)</p>
                <p>Steuerberater</p>
                <p>Rechtsanwälte</p>
                <p><u>Rechte</u></p>
                <p>Weiters sind Sie unter anderem berechtigt, (i) zu überprüfen, ob und welche personenbezogenen Daten wir über Sie gespeichert haben und Kopien
                    dieser Daten zu erhalten, (ii) die Berichtigung, Ergänzung oder die Löschung Ihrer personenbezogenen Daten, die falsch sind oder nicht rechtskonform verarbeitet werden, zu verlangen, (iii) von uns zu verlangen, die Verarbeitung einzu-schränken, und (iv) unter bestimmten Umständen der
                    Verarbeitung Ihrer personenbezogenen Daten zu widersprechen oder die für das Verarbeiten zuvor gegebene Einwilligung zu widerrufen, (v) Datenübertragung zu verlangen, (vi) die Empfänger oder Kategorien von Empfängern, an welche Ihre personenbezogenen Daten übermittelt werden, zu
                    erfahren, und (vii) bei der zuständigen Behörde Beschwerde (in Österreich bei der Datenschutzbehörde) zu erheben.</p>
                <p><u>Fotos</u></p>
                <p>Die Annaberger Liftbetriebs-Ges.m.b.H. fertigt im Rahmen der von ihr erbrachten Leistungen immer wieder Fotos dieser Leistungen an. Dabei werden auch Abbildungen von beförderten Personen gemacht. Diese Fotos werden von der Annaberger Liftbetriebs-Ges.m.b.H. zur Darstellung ihrer
                    Aktivitäten auf der Website und auf Social Media Kanälen sowie in Printmedien veröffentlicht. Sollten ein Nutzer eine Veröffentlichung seiner Bilder
                    nicht wünschen, so ist die durch Nachricht an die Emailadresse office@annabergerlifte.at schriftlich bekannt zu geben..</p>

                <h3>Datenschutz</h3>
                <p>Auf dieser Seite informieren wir Sie über die Verarbeitung Ihrer personenbezogenen Daten</p>
                <p>1. Unternehmen/verantwortliche Stelle:</p>
                <p>Annaberger Liftbetriebs-Ges.m.b.H. Annarotte 126, 3222 Annaberg, FN 92557 p, Tel T +43 2728 8477,
                    office@annabergerlifte.at, vertreten durch den Geschäftsführer Mag. Karl Weber. Diese Gesellschaft betreibt
                    einerseits
                    die Liftanlagen und die Pisten, verwaltet andererseits aber auch den Vorteilsclub „einfach Annaberg“.</p>
                <p>2. Datenschutzerklärung</p>
                <p>Der Schutz Ihrer personenbezogenen Daten ist uns sehr wichtig. Wir halten uns daher bei der Verarbeitung Ihrer
                    personenbezogenen Daten an die gesetzlichen Bestimmungen der (insb. der DSGVO und des DSG). In der Folge wollen wir
                    Sie im Detail über den Umfang und den Zweck unserer Datenverarbeitung sowie Ihre Rechte als betroffene Person der
                    Datenverarbeitung informieren.</p>
                <p>2.1. Personenbezogene Daten</p>
                <p>Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche
                    Person (betroffene Person) beziehen. Dazu zählen etwa Ihr Name, Ihre E-Mail-Adresse und Ihre Anschrift.</p>
                <p>2.2. Verarbeitung personenbezogener Daten im Rahmen der „einfach Annaberg Mitglied“</p>
                <p>Im Rahmen der Mitgliedschaft „einfach Annaberg Mitglied“ haben Sie uns für die Registrierung folgende
                    personenbezogene
                    Daten zur Verfügung gestellt:</p>
                <p>• Geschlecht</p>
                <p>• Vorname und Nachname</p>
                <p>• Emailadresse</p>
                <p>• Postanschrift (Land, Ort, Postleitzahl, Straße und Orientierungsnummer)</p>
                <p>Durch den Kauf und die Verwendung der Schikarte verarbeiten wir folgende Daten:</p>
                <p>• Verkaufsdaten (Verkaufsdatum inkl. Uhrzeit, gekauftes Ticket)</p>
                <p>• Keycard Daten (Identifikationsnummer der Keycard)</p>
                <p>• Skidata Bewegungsdaten – Zugangsdaten bei Drehkreuz (Zugangspunkt, Uhrzeit des Durchschreitens</p>
                <p>Wenn wir Ihnen einen Newsletter senden, verarbeiten wir folgende Daten:</p>
                <p>• Öffnungsrate und Öffnungszeitpunkt des Newsletters</p>
                <p>• Bildschirmklicks</p>
                <p>Für die Nutzung des „einfach Annaberg Mitglied“ Clubs nach der Registrierung und Anlegung Ihres Mitgliederkontos
                    sind
                    diese Daten zur Vertragserfüllung, Administration und zum Versand von Werbung, die an ihre Bedürfnisse angepasst
                    ist,
                    erforderlich.</p>
                <p>2.3. Leistungsumfang des „einfach Annaberg Mitglied“ Clubs</p>
                <p>Mit der Registrierung werden Sie Mitglied im einfach Annaberg Club und genießen neben dem Erhalt des Newsletters
                    noch
                    viele weitere Vorteile, über die wir Sie wie folgt informieren:</p>
                <p>2.3.1. Rabatte, Gutscheine und Aktionen
                    Die Datenverarbeitung erfolgt auch zu dem Zweck, Einzel- und/oder Familienrabatte zu gewähren. Ebenfalls erhalten
                    Sie
                    Konsumationsgutscheine bzw -vergünstigungen und vergünstigte Kombiangebote.</p>
                <p>2.3.2. Werbung</p>
                <p>Ihre personenbezogenen Daten verarbeiten wir auch, um Ihnen relevante Wetterinformationen (inkl. der
                    Schneebedingungen) bereitzustellen. Ebenfalls informieren wir Sie über neue Attraktionen und verbesserte
                    Infrastruktur
                    in unserem Skigebiet. Schließlich erhalten Sie Informationen über interessante Veranstaltungen, Angebote und
                    Rabatte.</p>
                <p>2.3.3. Gewinnspiele</p>
                <p>Wir werden von Zeit zu Zeit Gewinnspiele durchführen. Über das konkrete Gewinnspiel werden wir Sie entweder in
                    unserem
                    Newsletter oder vor Ort durch entsprechende Werbung informieren. Bitte beachten Sie die jeweils geltenden
                    Gewinnspielbedingungen. Bei jedem Gewinnspiel werden wir Sie über die damit im Zusammenhang stehende
                    Datenverarbeitung
                    informieren und ggf. Ihre Zustimmung einholen.</p>
                <p>
                    2.3.4. Kundenbefragung/Feedback</p>
                <p>Ihre personenbezogenen Daten werden auch dazu verwendet, um Sie mittels den von Ihnen uns zur Verfügung gestellten
                    Daten zu kontaktieren, um herauszufinden, ob unsere Angebote für Sie passend sind, bzw. um Informationen zu
                    erhalten,
                    wie wir unsere Angbeote/Leistungen verbessern können.</p>
                <p>
                    2.3.5. Versand von Newslettern</p>
                <p>Sie haben in den Erhalt von Newslettern Annaberger Liftbetriebs-Ges.m.b.H., die als Betreiber der Liftanlagen und
                    Pisten in Annaberg fungiert und den Vorteilsclub „einfach Annaberg“ eingewilligt. Ebenfalls haben Sie den Erhalt des
                    Newsletters von der Mariazeller Bürgeralpe Seilbahnbetriebs GmbH, FN 424289f vertreten durch den Geschäftsführer
                    Johann Kleinhofer
                    Wiener Straße 32, A-8630 Mariazell, Tel.: +43 3882 2555, Fax: +43 3882 2555 15, office@buergeralpe.at,
                    www.buergeralpe.at bzw. Bergbahnen Mitterbach GmbH, FN 152869h,
                    vertreten durch den Prokuristen Andreas Markusich, Seestraße 28, 3224 Mitterbach am Erlaufsee Telefon: +43 (0)3882
                    417
                    20, Mail: office@gemeindealpe.at, www.gemeindealpe.at
                    eingewilligt. Die von Ihnen in Annaberg gekaufte Liftkarte gilt auch in den beiden anderen Schigebieten und wir
                    davon
                    ausgehen, dass Sie ein Interesse an Informationen zu Angeboten auch in diesen Schigebieten haben.</p>
                <p>Ihre Einwilligung erstreckt sich daher auch auf den Erhalt eines Newsletters der genannten verantwortlichen Stellen.
                    Darüber hinaus willigen Sie dazu ein, dass Ihre personenbezogenen Daten zu Werbe- und Informationszwecken per Email
                    innerhalb des Geltungsbereiches ihrer Schikarte, das ist das Schigebiet Mitterbach („Gemeindealpe Mitterbach“) und
                    das
                    Schigebiet Mariazell („Maria Zeller Bürgeralpe“) - Mariazeller Bürgeralpe Seilbahnbetriebs GmbH, Wiener
                    office@buergeralpe.at, www.buergeralpe.at weitergegeben und dort gespeichert, verarbeitet und zur Bewerbung deren
                    Leistungsangeboten per Email genutzt werden. Die Verarbeitung Ihrer Daten erfolgt nur durch die genannten
                    Betreibergesellschaften bzw. deren Auftragsverarbeiter.</p>
                <p>2.3.6. Kunden- und Mitgliederservice</p>
                <p>Im Rahmen des Kunden- und Mitgliederservice verarbeiten wir Ihre Daten um Ihre Mitgliedschaft zu verwalten und an
                    uns
                    gestellte Anfragen bearbeiten zu können. Ebenfalls verarbeiten wir Daten für Supportangelegenheiten.</p>
                <p>2.4. Speicherdauer</p>
                <p>Daten, die Sie uns bei der Registrierung als Clubmitglied zur Verfügung gestellt haben speichern wir für die Dauer
                    der
                    Clubmitgliedschaft – die Sie durch einen Widerruf gegen die Datenverarbeitung beenden können. Eine Löschung erfolgt
                    nur dann nicht, wenn die Speicherung der Daten auf Grund von gesetzlichen Vorgaben wie beispielsweise von
                    Vertragsdaten, die wir zu Verrechnungszwecken gemäß den Bestimmungen der BAO und des UGB für 7 Jahre ab
                    Vertragsabschluss speichern müssen, gesetzlich vorgesehen ist.</p>
                <p>2.5. Datenübermittlung</p>
                <p>Eine Übermittlung Ihrer Daten an Dritte findet grundsätzlich nicht statt, es sei denn, uns triff eine gesetzliche
                    Verpflichtung, die Datenweitergabe ist zur Durchführung des Vertragsverhältnisses erforderlich oder Sie haben
                    ausdrücklich in die Weitergabe Ihrer Daten eingewilligt. Externe Dienstleister und Partnerunternehmen, wie z.B. das
                    mit der Ausstellung und Verwaltung der Keycards betraute Unternehmen, oder mit der Betreuung der
                    Self-Service-Terminals beauftragte Unternehmen oder andere Kooperationspartner, erhalten Ihre Daten nur, soweit dies
                    zur Vertragsabwicklung bzw zur Abwicklung und Verwaltung Ihrer Mitgliedschaft erforderlich ist. Sofern und soweit
                    unsere Auftragsverarbeiter mit Ihren personenbezogenen Daten in Berührung kommen, stellen wir sicher, dass diese die
                    Vorschriften der Datenschutzbestimmungen in gleicher Weise einhalten wie wir.</p>
                <p>Eine Veräußerung oder Vermarktung Ihrer personenbezogenen Daten an Dritte erfolgt nicht.</p>
                <p>Die Einwilligung zur Weitergabe Ihrer Daten an die Bergbahnen Mitterbach GmbH, Firmenbuchnummer: FN 152869h, und das
                    Schigebiet Mariazell („Maria Zeller Bürgeralpe“) - Mariazeller Bürgeralpe Seilbahnbetriebs GmbH können Sie jederzeit
                    mit Wirkung für die Zukunft widerrufen.</p>
                <p>2.6. Datensicherheit</p>
                <p>Die verantwortliche Stelle setzt technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre Daten gegen
                    Verlust, Zerstörung, Manipulationen und gegen den Zugriff Dritter angemessen und dem Stand der Technik entsprechend,
                    schützen.</p>
                <p>Die von uns verarbeiteten Daten werden durch einen sicheren Server geschützt. Eine entsprechende Software
                    verschlüsselt die gespeicherten Daten. Ebenfalls ist ein Rollenberechtigungskonzept etabliert, um zu gewährleisten,
                    dass nur berechtigte Personen Zugriff auf Ihre Daten haben. Unser Datensicherungskonzept und der physische Schutz
                    des
                    Servers stellen die Verfügbarkeit sicher.</p>
                <p>Wir überprüfen die getroffenen Maßnahmen zur Datensicherheit in regelmäßigen Abständen und passen diese dem
                    technischen Fortschritt an.</p>
                <p>2.7. Betroffenenrechte</p>
                <p>Als betroffene Person haben Sie gemäß der Datenschutz-Grundverordnung folgende Rechte zu:
                    2.7.1. Auskunftsrecht</p>
                <p>Sie haben als von der Datenverarbeitung betroffene Person das Recht Auskunft darüber zu verlangen, ob und wenn ja,
                    welche personenbezogenen Daten über Sie verarbeitet werden. Wenn Sie Ihr Auskunftsrecht geltend machen wollen, kann
                    es
                    zu Ihrem eigenen Schutz und damit kein unbefugter Dritter Auskunft über Ihre Daten erhält, erforderlich sein, dass
                    wir
                    Ihre Identität in geeigneter Form überprüfen müssen.</p>
                <p>2.7.2. Recht auf Berichtigung</p>
                <p>Als betroffene Person haben Sie das Recht, von uns die unverzügliche Berichtigung Sie betreffender unrichtiger
                    personenbezogener Daten zu verlangen. Es ist uns wichtig, dass Ihre personenbezogenen Daten, die wir von Ihnen
                    verarbeiten, korrekt und aktuell sind. Wenn Sie daher einen Fehler bemerken, bitten wir Sie, uns davon zu
                    informieren.
                    So ist es uns leichter möglich unrichtige bzw unvollständige Daten zu berichtigen oder zu vervollständigen.</p>
                <p>2.7.3. Recht auf Löschung</p>
                <p>Als betroffene Person haben Sie das Recht, dass Ihre personenbezogenen Daten gelöscht werden, sofern kein Grund
                    vorliegt, der einer Löschung entgegensteht.</p>
                <p>2.7.4. Recht auf Einschränkung der Verarbeitung</p>
                <p>Als betroffene Person haben Sie das Recht auf Einschränkung der Verarbeitung sämtlicher erhobener personenbezogener
                    Daten. Diese Daten werden ab dem Zugang des Antrages auf Einschränkung nur mehr mit individueller Einwilligung bzw.
                    zur Geltendmachung und Durchsetzung bzw. Abwehr von Rechtsansprüchen verarbeitet.</p>
                <p>2.7.5. Recht auf Datenübertragbarkeit</p>
                <p>Als Betroffener haben Sie das Recht, Ihre personenbezogenen Daten, welche Sie uns bereitgestellt haben, in einem
                    strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie haben außerdem das Recht, dass wir diese
                    Daten
                    einem anderen Verantwortlichen übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1
                    Buchstabe
                    a) DSGVO oder Art. 9 Abs. 2 Buchstabe a) DSGVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b) DSGVO beruht
                    und die Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung
                    einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt,
                    welche dem Verantwortlichen übertragen wurde.</p>
                <p>2.7.6. Recht auf Widerspruch</p>
                <p>Wenn die Verarbeitung auf einer Einwilligung beruht, haben Sie als betroffene Person das Recht, die Einwilligung
                    jederzeit zu widerrufen. Das hat zur Folge, dass wir ihre Daten – sofern nicht eine andere Rechtsgrundlage für eine
                    rechtmäßige Verarbeitung vorliegt – nicht weiter verarbeiten dürfen. Ein solcher Widerruf berührt die Rechtmäßigkeit
                    der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht. Soweit wir Ihre personenbezogenen Daten
                    verarbeiten, um Direktwerbung zu betreiben, haben Sie als betroffene Person das Recht, jederzeit Widerspruch gegen
                    die
                    Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen, eine Interessenabwägung erfolgt
                    in
                    diesem Fall nicht</p>
                <p>2.8. Rechtsbehelfe</p>
                <p>Die Datenschutzbehörde ist für Anträge betreffend des Rechtes aus Auskunft, Verletzung der Rechte auf Geheimhaltung,
                    auf Richtigstellung oder auf Löschung zuständig.</p>
                <p>2.9. Ansprechperson</p>
                <p>Für Fragen zum Datenschutz und zur Datensicherheit, wenden Sie sich bitte gerne an den Geschäftsführer der
                    Annaberger
                    Lifte, Herrn Mag. Karl Weber. Sie erreichen Ihn unter den allgemeinen Kontaktinformationen der verantwortlichen
                    Stelle.</p>*/}
            </React.Fragment>
        );
    }

    return (<></>);
}
