import { Col, Row } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

import { Button, Layout, Testimonial } from '../../../../common/components';
import { i18n } from '../../../../common/services/i18n';
import { FeatureFlag } from '../../../../root/components/FeatureFlag';
import { environment } from '../../../../environments/environment';
import DynamicImg from '../../../../common/components/DynamicImg';
import { Season } from '../../../../common/models/AppState';

interface Props {
    season: Season;
}

export default ({ season }: Props) => {
    return (
        <Layout.Container className="testimonials-container">
            <Row>
                <Col span={8}>
                    <Testimonial
                        img={
                            <DynamicImg
                                srcs={[
                                    `${environment.mediaUrl}/static/eshop/testimonials.price.season-${season.type}`,
                                    `${environment.mediaUrl}/static/eshop/testimonials.price`,
                                    '/images/testimonials-price.svg'
                                ]}
                                alt={i18n.t('home.testimonials.lowestPrices.title')}
                            />
                        }
                        title={<Trans i18nKey="home.testimonials.lowestPrices.title" />}
                        description={<Trans i18nKey="home.testimonials.lowestPrices.description" />}
                    />
                </Col>
                <Col span={8}>
                    <Testimonial
                        img={
                            <DynamicImg
                                srcs={[
                                    `${environment.mediaUrl}/static/eshop/testimonials.waiting.season-${season.type}`,
                                    `${environment.mediaUrl}/static/eshop/testimonials.waiting`,
                                    '/images/testimonials-waiting.svg'
                                ]}
                                alt={i18n.t('home.testimonials.waiting.title')}
                            />
                        }
                        title={<Trans i18nKey="home.testimonials.waiting.title" />}
                        description={<Trans i18nKey="home.testimonials.waiting.description" />}
                    />
                </Col>
                <Col span={8}>
                    <Testimonial
                        img={
                            <DynamicImg
                                srcs={[
                                    `${environment.mediaUrl}/static/eshop/testimonials.card.season-${season.type}`,
                                    `${environment.mediaUrl}/static/eshop/testimonials.card`,
                                    '/images/testimonials-card.svg'
                                ]}
                                alt={i18n.t('home.testimonials.card.title')}
                            />
                        }
                        title={<Trans i18nKey="home.testimonials.card.title" />}
                        description={<Trans i18nKey="home.testimonials.card.description" />}
                    />
                </Col>
            </Row>
            <FeatureFlag flagKey="featuredAllDiscounts">
                <Row type="flex" justify="center">
                    <Col xs={22} md={8}>
                        <Button className="testimonials-all-advantages" block type="primary">
                            {i18n.t('eshop.allAdvantages')}
                        </Button>
                    </Col>
                </Row>
            </FeatureFlag>
        </Layout.Container>
    );
};
