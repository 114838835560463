import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { reducer as modal } from 'redux-modal';

import code from '../../features/code/ducks';
import personal from '../../features/personal/ducks';
import shopping from '../../features/shopping/ducks';
import user from '../../features/user/ducks';
import dateChange from '../../features/dateChange/ducks';
import { persistReducer } from 'redux-persist';
import localForage from 'localforage';
import { environment } from '../../environments/environment';
import { history } from './history';

const persistedUserReducer = persistReducer(
    {
        key: 'ski-user',
        version: 0,
        storage: localForage,
        debug: environment.debug,
        blacklist: ['asyncRegisterInProgress'],
    },
    user
);

const rootReducer = combineReducers({
    router: connectRouter(history),
    personal,
    modal,
    shopping,
    user: persistedUserReducer,
    code,
    dateChange,
});

export default (state: any, action: any) => {
    if (action.type === 'LOGOUT') {
        return rootReducer(undefined, action);
    }

    return rootReducer(state, action);
};
