import { DatePicker, Icon } from 'antd';
import React, { useState } from 'react';

import { Button, Modal } from '../../components';
import { ModalSizes } from '../../constants/modal';
import { i18n } from '../../services/i18n';
import moment from 'moment';
import { getSubTypeFromBirthday, isDateValid } from '../../utils/getSubTypeFromBirthday';

interface Props {
    visible?: boolean;
    allowedSubtypes?: any[];
    onCancel?: () => void;
    onOk?: (birthday, subType) => void;
}

const AddCardBirthdayModal = (props: Props) => {
    const [birthday, setBirthday] = useState('');

    const handleSubmit = () => {
        props.onOk && props.onOk(
            moment(birthday, 'DD.MM.YYYY').format(),
            getSubTypeFromBirthday(birthday),
        );
    };

    const subTypeMatch = !props.allowedSubtypes || props.allowedSubtypes.includes(getSubTypeFromBirthday(birthday));

    return (
        <Modal
            visible={props.visible}
            width={ModalSizes.small}
            onCancel={props.onCancel}
            footer={null}
        >
            <div className="add-card-modal">
                <div className="add-card-modal-header">
                    <h3>{i18n.t('addCardBirthdayModal.title')}</h3>
                    <p>{i18n.t('addCardBirthdayModal.subtitle')}</p>
                </div>
                <div className="add-card-modal-content">
                    <DatePicker
                        allowClear={false}
                        format={'DD.MM.YYYY'}
                        defaultPickerValue={moment('01.01.2000', 'DD.MM.YYYY')}
                        onChange={(_, dateString) => setBirthday(dateString)}
                        value={birthday !== '' ? moment(birthday, 'DD.MM.YYYY') : undefined}
                        placeholder={i18n.t('addCardBirthdayModal.datePlaceholder')}
                    />
                    {isDateValid(birthday) && !subTypeMatch &&
                        <div className="add-card-modal-content-message add-card-modal-content-message--error">
                            <Icon type="warning"/> {i18n.t('addCardBirthdayModal.subTypeNotMatch')}
                        </div>
                    }
                </div>
                <div className="add-card-modal-buttons">
                    <Button disabled={!isDateValid(birthday) || !subTypeMatch} onClick={handleSubmit}>
                        {i18n.t('addCardBirthdayModal.confirmButton')}
                    </Button>
                    <Button onClick={props.onCancel} type="primary">
                        {i18n.t('addCardBirthdayModal.cancelButton')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default AddCardBirthdayModal;
