import { Menu, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import cn from 'classnames';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import BodyClassName from 'react-body-classname';
import { oc } from 'ts-optchain';

import { Drawer } from '../../../components';
import { useIsMobileWide } from '../../../hooks/useIsMobileWide';
import { Control } from '../Control';
import LipnoIcon from '../../LipnoIcon';

export interface SelectOption {
    label: React.ReactNode;
    value: string | number;
    disabled?: boolean;
}

interface Props extends SelectProps {
    options: SelectOption[];
    priorityOptions?: string[];
    warning?: boolean;
    label?: ReactNode;
    error?: ReactNode;
    onSelectChange?: (value: number) => void;
    clickOnly?: boolean;
}

const SelectComponent: FunctionComponent<Props> = ({
    priorityOptions = [],
    options,
    warning,
    label,
    error,
    value,
    onChange,
    clickOnly,
    ...bag
}) => {
    const isMobile = useIsMobileWide();
    const [selectValue, setSelectValue] = useState(value);
    const [dropDownVisibility, setDropDownVisibility] = useState(false);

    useEffect(
        () => {
            if (selectValue !== undefined && !clickOnly) {
                onChange && onChange(selectValue as string, <div />);
            }
        },
        [selectValue]
    );

    useEffect(
        () => {
            setSelectValue(value);
        },
        [value]
    );

    function handleCloseDrawer() {
        setDropDownVisibility(false);
    }

    function renderDropdown(menu: ReactNode) {
        return isMobile ? <div /> : menu;
    }

    function handleDropdownVisisbilityChange(visible: boolean) {
        if (!clickOnly) {
            if (visible) {
                setDropDownVisibility(visible);
            } else {
                if (!isMobile) {
                    setDropDownVisibility(visible);
                }
            }
        } else if (visible) {
            onChange && onChange('true', <div/>);
        }
    }

    function handleSelectChange(value) {
        setSelectValue(value);
    }

    function renderPriorityMenuOptions() {
        return priorityOptions.map((option, index) => {
            function handleItemClick() {
                handleSelectChange(option);
                setDropDownVisibility(false);
            }
            return (
                <Menu.Item key={option} onClick={handleItemClick}>
                    {oc(options.find(i => i.value === option)).label('')}
                </Menu.Item>
            );
        });
    }

    function renderDrawerDropDown() {
        return (
            <BodyClassName className={cn({ 'blocked-scroll': dropDownVisibility })}>
                <Drawer
                    closable
                    title={label}
                    style={{ zIndex: 999999999999 }}
                    visible={dropDownVisibility}
                    placement="bottom"
                    height="auto"
                    type="dropdownMirror"
                    onClose={handleCloseDrawer}
                >
                    <Menu>
                        {renderPriorityMenuOptions()}
                        {priorityOptions.length > 0 && <Menu.Divider />}
                        {options
                            .filter(item => !priorityOptions.some(option => option === item.value))
                            .map(item => {
                                function handleItemClick() {
                                    handleSelectChange(item.value);
                                    setDropDownVisibility(false);
                                }

                                return (
                                    <Menu.Item
                                        onClick={handleItemClick}
                                        disabled={item.disabled}
                                        key={item.value}
                                    >
                                        {item.label}
                                    </Menu.Item>
                                );
                            })}
                    </Menu>
                </Drawer>
            </BodyClassName>
        );
    }

    function renderPriorityOptions() {
        return (
            <Select.OptGroup label="Prefered">
                {priorityOptions.map((option, index) => (
                    <Select.Option key={index.toString()} value={option}>
                        {oc(options.find(i => i.value === option)).label('')}
                    </Select.Option>
                ))}
            </Select.OptGroup>
        );
    }

    function renderOptions() {
        return options
            .filter(item => !priorityOptions.some(option => option === item.value))
            .map((option, index) => (
                <Select.Option
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled ? true : false}
                >
                    {option.label}
                </Select.Option>
            ));
    }

    function renderSelect() {
        return (
            <Select
                // @ts-ignore
                dropdownAlign={{ offset: [0, 0] }}
                onDropdownVisibleChange={handleDropdownVisisbilityChange}
                open={dropDownVisibility}
                className={cn({ 'ant-select-warning': warning, 'ant-select--with-label': label })}
                dropdownRender={renderDropdown}
                dropdownStyle={isMobile ? { display: 'none' } : {}}
                value={options.length > 0 && selectValue ? selectValue : undefined}
                onChange={handleSelectChange}
                suffixIcon={<LipnoIcon type={LipnoIcon.types.ChevronDown} />}
                {...bag}
            >
                {renderPriorityOptions()}
                {renderOptions()}
            </Select>
        );
    }

    return (
        <Control label={label} error={error}>
            {!isMobile && <BodyClassName className={cn({ 'masked-bg': dropDownVisibility })} />}
            {isMobile && renderDrawerDropDown()}
            {renderSelect()}
        </Control>
    );
};

export default SelectComponent;
