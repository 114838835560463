import { Col, Row } from 'antd';
import React, { ReactNode } from 'react';

interface Props {
    title: string | ReactNode;
    description: string | ReactNode;
    image: string | ReactNode;
    imgLocation: string;
    children?: ReactNode;
}

export default function ImageSideDescription(props: Props) {
    return (
        <React.Fragment>
            <Row type="flex" justify="center" className={`isd__container-${props.imgLocation}`}>
                <Col sm={24} md={8} className="isd__content">
                    <h3 className="isd__h3"> {props.title} </h3>
                    <p> {props.description} </p>
                    {props.children ? <div className="children-row"> {props.children} </div> : null}
                </Col>
                <Col md={8} className="isd__img">
                    {props.image}
                </Col>
            </Row>
        </React.Fragment>
    );
}
