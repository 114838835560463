import { Icon } from 'antd';
import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import { i18n } from '../../../services/i18n';
import { TicketItem } from '../../../models/NewTransaction';
import { getCardNumber } from '../../../utils/project-helpers';
import { oc } from 'ts-optchain';
import { environment } from '../../../../environments/environment';
import { translate } from '../../../utils/translate';

interface Props {
    item: TicketItem;
    origin: string;
}

const TicketFooter: FunctionComponent<Props> = ({ item, origin }) => {
    function isPass() {
        return item.type === 'pass';
    }

    function isExchangePass() {
        return item.type === 'exchange-pass';
    }

    function isInfo() {
        return item.type === 'info';
    }

    return (
        <div
            className={cn('ticket-footer', {
                'ticket-footer--exchange-pass': isExchangePass(),
                'ticket-footer--pass': isPass(),
                'ticket-footer--info': isInfo(),
            })}
        >
            <div className="ticket-footer__icon">
                {isExchangePass() && <Icon type="sync" />}
                {isPass() && <Icon type="check-circle" />}
                {isInfo() && <Icon type="info-circle" />}
            </div>
            <div className="ticket-footer__content">
                {isPass() && i18n.t('ticket.pass.footerTitle')}
                {isExchangePass() && i18n.t('ticket.exchangePass.footerTitle')}
                {isInfo() && i18n.t('ticket.info.footerTitle')}
            </div>
            <div className="ticket-footer__code">
                {environment.config.showTicketOrigin && (
                    <>
                        {origin === 'ssp' && translate('ticket.pass.originSSP')}
                        {origin === 'eshop' && translate('ticket.pass.originEshop')}
                        {' '}
                    </>
                )}
                {item.code || getCardNumber(oc(item).token.extIdentifiers())}
            </div>
        </div>
    );
};

export default TicketFooter;
