import React, { FunctionComponent, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { environment } from '../../../../../../../environments/environment';

interface Props {
    priceWithDiscount: ReactNode;
    price: ReactNode;
    displayDiscount: boolean;
}

const CartSummary: FunctionComponent<Props> = ({ price, priceWithDiscount, displayDiscount }) => {
    return (
        <div className="cart-summary">
            <div className="cart-summary__left">
                <Trans i18nKey="cart.priceTitle" />
            </div>
            <div className="cart-summary__right">
                <div className="cart-summary__right__with-discount">{priceWithDiscount}</div>
                {environment.config.dualPrices && displayDiscount &&
                    <div className="cart-summary__right__without-discount">
                        <Trans i18nKey="cart.priceOnPlace"/> {price}
                    </div>
                }
            </div>
        </div>
    );
};

export default CartSummary;
