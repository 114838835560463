import { Divider, Icon } from 'antd';
import React, { ReactNode, useRef } from 'react';
import LipnoIcon from '../LipnoIcon';

interface Props {
    title?: ReactNode;
    description?: ReactNode;
    content?: ReactNode;
    extra?: ReactNode;
    className?: string;
    headerContent?: ReactNode;
}

const Carousel = (props: Props) => {
    const scroller = useRef<HTMLDivElement>(null);
    const { title, description, content, extra } = props;

    function handleScrollLeft() {
        if (scroller && scroller.current) {
            scroller.current.scrollLeft -= 200;
        }
    }

    function handleScrollRight() {
        if (scroller && scroller.current) {
            scroller.current.scrollLeft += 200;
        }
    }

    function renderControls() {
        return (
            <div className="carousel-controls">
                <div onClick={handleScrollLeft} className="carousel-controls-control">
                    <LipnoIcon type={LipnoIcon.types.ChevronLeft} />
                </div>
                <div onClick={handleScrollRight} className="carousel-controls-control">
                    <LipnoIcon type={LipnoIcon.types.ChevronRight} />
                </div>
            </div>
        );
    }

    return (
        <div className={props.className ? 'carousel ' + props.className : 'carousel'}>
            <div className="carousel-description">
                <h2>{title}</h2>
                <p>{description}</p>
                {props.headerContent ? (
                    <div className="carousel-header-content">{props.headerContent}</div>
                ) : null}
            </div>
            <div className="carousel-extra">
                <div className="carousel-extra-content">{extra}</div>
                <div className="carousel-extra-controls">{renderControls()}</div>
            </div>
            <Divider />
            <div ref={scroller} className="carousel-content">
                {content}
            </div>
        </div>
    );
};

export default Carousel;
