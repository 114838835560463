import cn from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { I18nContextValues, withI18n } from 'react-i18next';

import { Layout } from '../../../components';
import { useIsMobileWide } from '../../../hooks/useIsMobileWide';
import { i18n } from '../../../services/i18n';

import { DesktopFooter, MobileFooter } from './components';

interface Props {
    theme?: 'light' | 'dark';
}

export const Footer: FunctionComponent<Props> = ({ theme = 'dark' }) => {
    const isMobile = useIsMobileWide();
    const [isVisible, setContentVisibility] = useState<boolean>(!isMobile);

    function handleToglerClick() {
        setContentVisibility(!isVisible);
    }

    function renderContent() {
        return isMobile ? <MobileFooter /> : <DesktopFooter />;
    }

    return (
        <Layout.Footer
            className={cn(
                { 'ant-layout-footer--light': theme === 'light' },
                { 'ant-layout-footer--dark': theme === 'dark' }
            )}
        >
            <div className="footer-content">{isVisible && renderContent()}</div>
            <div onClick={handleToglerClick} className="footer-togler">
                {i18n.t('footer.copyright')}
            </div>
        </Layout.Footer>
    );
};
