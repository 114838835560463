import React from 'react';
import { Link } from 'react-router-dom';

import { getLogo } from '../../utils/project-helpers';

export default () => {
    return (
        <Link to="/shopping/eshop">
            <img className="logo" src={getLogo()} alt="logo" />
        </Link>
    );
};
