import { AppState } from '../../../common/models/AppState';
import {
    createActionCreator,
    createApiActionCreators,
    createReducer,
    RequestActionTypes,
} from '../../../common/utils/redux-helpers';

import { selectShopping } from '.';

export enum OrderActionTypes {
    EmailValidate = '@@Order/EMAIL_VALIDATE',
    SetConfimationEmail = '@@Order/SET_CONFIRMATION_EMAIL',
    SetAsyncUserId = '@@Order/SET_ASYNC_USER_ID',
    ClearEmailState = '@@Order/CLEAR_EMAIL_STATE',
}

export const clearEmailStateAction = createActionCreator(OrderActionTypes.ClearEmailState);
export const emailValidateActions = createApiActionCreators(OrderActionTypes.EmailValidate);
export const setConfirmationEmailAction = createActionCreator(OrderActionTypes.SetConfimationEmail);
export const setAsyncUserIdAction = createActionCreator(OrderActionTypes.SetAsyncUserId);

export interface OrderState {
    steps: Step[];
    hasDiscount: boolean;
    email: {
        value: string;
        validated: boolean;
        isMember: boolean;
    };
    userId?: string;
}

interface Step {
    name: string;
    active: boolean;
    complete: boolean;
    route: string;
}

const initialState: OrderState = {
    hasDiscount: false,
    email: {
        value: '',
        validated: false,
        isMember: false,
    },
    steps: [
        {
            name: 'Eshop',
            active: true,
            complete: false,
            route: '/shopping/eshop',
        },
        {
            name: 'Discount',
            active: false,
            complete: false,
            route: '/shopping/registration',
        },
        {
            name: 'Informations',
            active: false,
            complete: false,
            route: '/shopping/informations',
        },
        {
            name: 'Checkout',
            active: false,
            complete: false,
            route: '/shopping/checkout',
        },
        {
            name: 'Confirmation',
            active: false,
            complete: false,
            route: '/shopping/confirmation',
        },
    ],
};

export function selectOrder(state: AppState) {
    return selectShopping(state).order;
}

export function selectRecipientEmail(state: AppState) {
    return selectOrder(state).email.value;
}

export function selectAsyncRegisteredId(state: AppState) {
    return selectOrder(state).userId;
}

export const order = createReducer(initialState, {
    [OrderActionTypes.ClearEmailState]: (state: OrderState) => ({
        ...state,
        email: initialState.email,
    }),
    [OrderActionTypes.EmailValidate]: {
        [RequestActionTypes.SUCCESS]: (state: OrderState, payload: number) => ({
            ...state,
            email: {
                ...state.email,
                validated: true,
                isMember: payload > 0,
            },
        }),
    },
    [OrderActionTypes.SetConfimationEmail]: (state: OrderState, payload: string) => ({
        ...state,
        email: {
            ...state.email,
            value: payload,
        },
    }),
    [OrderActionTypes.SetAsyncUserId]: (state: OrderState, payload?: string) => ({
        ...state,
        userId: payload,
    }),
});
