import { Card, Col, Row } from 'antd';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import validator from 'validator';

import { MarketingModal, NewPasswordModal } from '../../../../common/modals';
import { AppState } from '../../../../common/models/AppState';
import { LoginFormValues, RegisterFormValues } from '../../../../common/models/User';
import { i18n } from '../../../../common/services/i18n';
import {
    loginActions,
    registerAndLoginAction, selectAsyncRegisterInProgress,
    selectIsLogged,
    selectMarketingAgreement,
    updateUserActions,
} from '../../../user/ducks';
import {
    Button,
    InfoList,
    Input,
    Layout,
    LoginForm,
    PageDescription,
    Price,
    RegistrationForm,
    TotalPrice,
} from '../.././../../common/components';
import { Subheader } from '../../components';
import {
    selectCartBaseTotal,
    selectCartDiscountPrice,
    selectCartIsEmpty,
    selectCartPopulatedItems,
    selectEmailStatus,
    validateTransactionActions,
} from '../../ducks/cart';
import { clearEmailStateAction, emailValidateActions } from '../../ducks/order';
import { api } from '../../../user/api';

import { ProductSelectionPopulated } from '../../../../common/models/Cart';
import { environment } from '../../../../environments/environment';
import { sendGtmEvent } from '../../../../common/utils/gtm-helpers';

interface DispatchToProps {
    checkEmail?(email: string): void;
    resetEmailCheck?(): void;
    register?(values: RegisterFormValues): void;
    login?(values: LoginFormValues): void;
    updateAgreements?(): void;
    reloadTransaction?(): void;
}

interface StateToProps {
    total: number;
    totalDiscounted: number;
    cartEmpty: boolean;
    isLogged: boolean;
    emailStatus: { isMember: boolean; validated: boolean };
    marketing: boolean;
    items: ProductSelectionPopulated[];
    registerInProgress: boolean;
}

type Props = DispatchToProps & StateToProps & RouteComponentProps;

const RegistrationComponent: FunctionComponent<Props> = ({
    total,
    totalDiscounted,
    checkEmail,
    resetEmailCheck,
    emailStatus,
    isLogged,
    cartEmpty,
    login,
    register,
    marketing,
    updateAgreements,
    reloadTransaction,
    items,
    registerInProgress,
}) => {
    const [email, setEmail] = useState<string>('');
    const [nextStep, setNextStep] = useState(false);
    const [marketingModal, setMarketingModal] = useState(false);
    const [newPasswordModal, setNewPasswordModal] = useState(false);

    // GTM datalayer push
    useEffect(() => {
        resetEmailCheck && resetEmailCheck();
        sendGtmEvent({
            event: 'page_load',
            page: 'discount',
            products: items.map(item => item.product.name.cz),
        });
    }, []);

    useEffect(
        () => {
            reloadTransaction && reloadTransaction();
            if (isLogged && marketing) {
                setNextStep(true);
            } else if (isLogged && !marketing) {
                setMarketingModal(true);
            }
        },
        [isLogged, marketing]
    );

    function handleLoginSubmit(values: LoginFormValues) {
        login && login(values);
    }

    function handleRegisterSubmit(values: RegisterFormValues) {
        register && register(values);
    }

    function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
        setEmail(e.currentTarget.value);
    }

    function handleEmailKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            checkForPassword();
            handleCheckEmail();
        }
    }

    function handleCheckEmail() {
        checkEmail && checkEmail(email);
    }

    function checkForPassword() {
        if (validator.isEmail(email)) {
            api.hasPassword(email).then( res => {
                if (res.hasOwnProperty('data') && res.data === false) {
                    setNewPasswordModal(true);
                }
            }).catch(err => {
                console.error(err.resp);
            });
        }
    }

    function renderNewPasswordModal() {
        function handleConfirmClick() {
            setNewPasswordModal(false);
        }

        return <NewPasswordModal visible={newPasswordModal} onConfirm={handleConfirmClick} email={email} />;
    }
    function renderMarketingModal() {
        function handleCancelClick() {
            setNextStep(true);
        }

        function handleConfirmClick() {
            updateAgreements && updateAgreements();
        }

        return (
            <MarketingModal
                visible={marketingModal}
                total={total}
                totalDiscounted={totalDiscounted}
                onCancel={handleCancelClick}
                onConfirm={handleConfirmClick}
            />
        );
    }

    function renderValidated() {
        return emailStatus.isMember ? (
            <LoginForm onSubmit={handleLoginSubmit} initialValues={{ email }} />
        ) : (
            <RegistrationForm
                onSubmit={handleRegisterSubmit}
                type="in-flow"
                initialValues={{ email }}
                inProgress={registerInProgress}
            />
        );
    }

    function renderMemberSection() {
        return (
            <Card className="discount-testemonial">
                <Row type="flex">
                    <Col className="discount-testemonial__left" xs={24} md={17}>
                        <h3>{i18n.t('discount.memeber.title')}</h3>
                        {emailStatus.validated ? (
                            renderValidated()
                        ) : (
                            <Fragment>
                                <Input
                                    label={i18n.t('discount.memeber.input.label')}
                                    placeholder={i18n.t('discount.memeber.input.placeholder')}
                                    onChange={handleEmailChange}
                                    onKeyDown={handleEmailKeyDown}
                                    onBlur={checkForPassword}
                                />
                                <Button
                                    disabled={!validator.isEmail(email)}
                                    type="success"
                                    onClick={handleCheckEmail}
                                >
                                    {i18n.t('discount.memeber.continue')}
                                </Button>
                            </Fragment>
                        )}
                    </Col>
                    <Col className="discount-testemonial__right" xs={24} md={7}>
                        <InfoList
                            title={i18n.t('discount.memeber.benefits.title')}
                            items={[
                                i18n.t('discount.memeber.benefits.item1'),
                                i18n.t('discount.memeber.benefits.item2'),
                                i18n.t('discount.memeber.benefits.item3'),
                            ]}
                        />
                        <TotalPrice
                            withDiscount={<Price value={totalDiscounted} />}
                            withoutDiscount={<Price value={total} />}
                            addon={i18n.t('discount.memeber.benefits.priceInfo')}
                            displayDiscount={isLogged}
                        />
                    </Col>
                </Row>
            </Card>
        );
    }

    function renderNonMemberSection() {
        return (
            <Card className="discount-testemonial">
                <Row>
                    <Col className="discount-testemonial__left" xs={24} md={17}>
                        <h3>{i18n.t('discount.nonMember.title')}</h3>
                        <p>{i18n.t('discount.nonMember.description')}</p>
                        <Link to="informations">
                            <Button type="primary">{i18n.t('discount.nonMember.continue')}</Button>
                        </Link>
                    </Col>
                    <Col className="discount-testemonial__right" xs={24} md={7}>
                        <InfoList
                            type="negative"
                            items={[
                                i18n.t('discount.nonMember.benefits.item1'),
                                i18n.t('discount.nonMember.benefits.item2'),
                                i18n.t('discount.nonMember.benefits.item3'),
                            ]}
                        />
                        <TotalPrice
                            type="negative"
                            withDiscount={<Price value={total} />}
                            withoutDiscount={<Price value={totalDiscounted} />}
                            addon={i18n.t('discount.nonMember.benefits.priceInfo')}
                            displayDiscount={isLogged}
                        />
                    </Col>
                </Row>
            </Card>
        );
    }

    return (
        <Layout.Content>
            {cartEmpty && <Redirect to="/" />}
            {(nextStep || !environment.config.dualPrices) && <Redirect to="informations" />}
            {renderMarketingModal()}
            {renderNewPasswordModal()}
            <Subheader />
            <Layout.Container>
                <PageDescription
                    title={i18n.t('discount.title')}
                    description={i18n.t('discount.description')}
                />
                {renderMemberSection()}
                {renderNonMemberSection()}
            </Layout.Container>
        </Layout.Content>
    );
};

const mapDispatchToProps: DispatchToProps = {
    checkEmail: emailValidateActions.request,
    resetEmailCheck: clearEmailStateAction,
    login: loginActions.request,
    register: registerAndLoginAction,
    updateAgreements: updateUserActions.request,
    reloadTransaction: validateTransactionActions.request,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    total: selectCartBaseTotal(state),
    totalDiscounted: selectCartDiscountPrice(state),
    emailStatus: selectEmailStatus(state),
    isLogged: selectIsLogged(state),
    cartEmpty: selectCartIsEmpty(state),
    marketing: selectMarketingAgreement(state),
    items: selectCartPopulatedItems(state),
    registerInProgress: selectAsyncRegisterInProgress(state),
});

export const Discounts = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(RegistrationComponent)
);
