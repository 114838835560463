import { CodeStartState } from '../../features/code/ducks';
import { PersonalState } from '../../features/personal/ducks';
import { ShoppingState } from '../../features/shopping/ducks';
import { UserState } from '../../features/user/ducks';
import { DateChangeState } from '../../features/dateChange/ducks';

export enum EshopSeason {
    Summer = 'summer',
    Winter = 'winter',
}

export interface Season {
    _id: string;
    name: string;
    type: string;
    config: string;
    active: boolean;
}

export interface AppState {
    locale: string;
    season: Season;
    shopping: ShoppingState;
    user: UserState;
    personal: PersonalState;
    code: CodeStartState;
    dateChange: DateChangeState;
}
