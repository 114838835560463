import { i18n } from '../../../../../common/services/i18n';
import { environment } from '../../../../../environments/environment';
import { paymentOptions, paymentOptionsAnnaberg } from '../../../../../resources/images';
import { PaymentOption } from '../../../../../common/components';
import React from 'react';

interface Props {
    paymentDisabled?: boolean;
    onSubmitPayment: () => void;
}

const CardPayment = ({ paymentDisabled, onSubmitPayment }: Props) => (
    <PaymentOption
        title={i18n.t('checkout.payment.card.title')}
        buttonTitle={i18n.t('checkout.payment.card.button')}
        submitting={paymentDisabled}
        onSelect={() => onSubmitPayment()}
    >
        <img src={environment.project === 'annaberg' ? paymentOptionsAnnaberg : paymentOptions} />
    </PaymentOption>
);

export default CardPayment;
