import React, { Fragment, FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import validator from 'validator';

import { AppState } from '../../models/AppState';
import { LoginFormValues, RegisterFormValues } from '../../models/User';
import { i18n } from '../../../common/services/i18n';
import { CodeVerifcationTypes, setVerificationTypeAction } from '../../../features/code/ducks';
import { selectEmailStatus } from '../../../features/shopping/ducks/cart';
import { emailValidateActions } from '../../../features/shopping/ducks/order';
import {
    loginActions,
    registerAndLoginAction,
    selectIsLogged,
    selectMarketingAgreement,
    updateUserActions,
    registerActions, selectAsyncRegisterInProgress,
} from '../../../features/user/ducks';
import { Alert, Button, Input, LoginForm, RegistrationForm } from '../.././../common/components';
import { NewPasswordModal } from '../../modals';
import { api } from '../../../features/user/api';

interface OwnProps {
    benefitKey?: string;
    card?: string;
    birthday?: string;
    registerOnly?: boolean;
}

interface DispatchToProps {
    checkEmail?(email: string): void;
    registerAndLogin?(values: RegisterFormValues, benefitKey?: string): void;
    register?(values: RegisterFormValues, benefitKey?: string): void;
    login?(values: LoginFormValues): void;
    updateAgreements?(): void;
    setVerificationType: (type: CodeVerifcationTypes) => void;
}

interface StateToProps {
    isLogged: boolean;
    emailStatus: { isMember: boolean; validated: boolean };
    marketing: boolean;
    registerInProgress: boolean;
}

type Props = DispatchToProps & StateToProps & OwnProps;

function LoginValidatorComponent(props: Props) {
    const [email, setEmail] = useState<string>('');
    const [newPasswordModal, setNewPasswordModal] = useState<boolean>(false);

    function handleLoginSubmit(values: LoginFormValues) {
        props.login && props.login(values);
    }

    function handleRegisterSubmit(values: RegisterFormValues) {
        values.benefitKey = props.benefitKey;
        props.setVerificationType(CodeVerifcationTypes.FromLinkRegister);
        if (props.registerOnly === true) {
            props.register && props.register(values);
        } else {
            props.registerAndLogin && props.registerAndLogin(values);
        }
    }

    function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
        setEmail(e.currentTarget.value);
    }

    function handleEmailKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            checkForPassword();
            handleCheckEmail();
        }
    }

    function handleCheckEmail() {
        props.checkEmail && props.checkEmail(email);
    }

    function renderLoggedIn() {
        return (
            <Alert
                message={i18n.t('loginValidator.logged.title')}
                description={i18n.t('loginValidator.logged.description')}
                type="info"
                showIcon
            />
        );
    }

    function renderValidated() {
        return props.emailStatus.isMember ? (
            <LoginForm onSubmit={handleLoginSubmit} initialValues={{ email }} />
        ) : (
            <RegistrationForm
                onSubmit={handleRegisterSubmit}
                initialValues={{
                    email,
                    cardNumbers: props.card ? [{number: props.card, birthday: props.birthday || ''}] : [{number: '', birthday: ''}],
                    hasCard: !!props.card,
                }}
                inProgress={props.registerInProgress}
            />
        );
    }

    function checkForPassword() {
        if (validator.isEmail(email)) {
            api.hasPassword(email).then( res => {
                if (res.hasOwnProperty('data') && res.data === false) {
                    setNewPasswordModal(true);
                }
            }).catch(err => {
                console.error(err.resp);
            });
        }
    }

    function renderNewPasswordModal() {
        function handleConfirmClick() {
            setNewPasswordModal(false);
        }

        return <NewPasswordModal visible={newPasswordModal} onConfirm={handleConfirmClick} email={email} />;
    }

    return (
        <div className="login-validator">
            {renderNewPasswordModal()}
            {props.isLogged ? (
                renderLoggedIn()
            ) : props.emailStatus.validated ? (
                renderValidated()
            ) : (
                <Fragment>
                    <Input
                        label={i18n.t('discount.memeber.input.label')}
                        placeholder={i18n.t('discount.memeber.input.placeholder')}
                        onChange={handleEmailChange}
                        onKeyDown={handleEmailKeyDown}
                        onBlur={checkForPassword}
                    />
                    <Button disabled={!validator.isEmail(email)} block onClick={handleCheckEmail}>
                        {i18n.t('loginValidator.continue')}
                    </Button>
                </Fragment>
            )}
        </div>
    );
}

const mapDispatchToProps: DispatchToProps = {
    checkEmail: emailValidateActions.request,
    login: loginActions.request,
    registerAndLogin: registerAndLoginAction,
    register: registerActions.request,
    updateAgreements: updateUserActions.request,
    setVerificationType: setVerificationTypeAction,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    emailStatus: selectEmailStatus(state),
    isLogged: selectIsLogged(state),
    marketing: selectMarketingAgreement(state),
    registerInProgress: selectAsyncRegisterInProgress(state),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginValidatorComponent);
