import React, { ReactNode } from 'react';

interface Props {
    img: string | ReactNode;
    alt: string;
    title?: string;
    description?: string;
    children?: ReactNode;
}

export default function TopImage(props: Props) {
    return (
        <React.Fragment>
            <div className="top-image">
                {typeof props.img === 'string' &&
                    <img className="top-image__img" src={props.img} alt={props.alt}/>
                }
                {typeof props.img !== 'string' && props.img}
                <div className="top-image__text-container">
                    <h3> {props.title} </h3>
                    <p> {props.description} </p>
                </div>
            </div>
        </React.Fragment>
    );
}
