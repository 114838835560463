import { Card } from 'antd';
import React, { ReactNode } from 'react';

import { Button } from '../../components';

interface Props {
    buttonTitle?: ReactNode;
    title?: ReactNode;
    children?: ReactNode;
    submitting?: boolean;
    onSelect?(): void;
}

export default function PaymentOption({
    buttonTitle,
    title,
    children,
    onSelect,
    submitting,
}: Props) {
    function handleClick() {
        onSelect && onSelect();
    }

    return (
        <Card className="payment-option">
            <h3>{title}</h3>
            <div className="payment-option__content">{children}</div>
            <Button onClick={handleClick} type="success" block disabled={submitting}>
                {buttonTitle}
            </Button>
        </Card>
    );
}
