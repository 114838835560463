import { delay } from 'redux-saga';
import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { oc } from 'ts-optchain';

import { transactionsApi } from '../../../../common/api/transaction';
import { ProductValidity } from '../../../../common/components';
import { AppState } from '../../../../common/models/AppState';
import { createActionCreator, createReducer } from '../../../../common/utils/redux-helpers';
import { selectShopping, TimeSlotType } from '../../ducks';
import { resetCartAction } from '../../ducks/cart';
import i18next from 'i18next';
import { environment } from '../../../../environments/environment';
import { resetTemporaryCardsAction } from '../../../user/ducks';
import { clearEmailStateAction } from '../../ducks/order';
import { Entry, Transaction, TicketItem, EntryMethod } from '../../../../common/models/NewTransaction';
import { sendGA4PurchaseEvent } from '../../../../common/utils/gtm-helpers';

export enum B2BConfirmationPollingActionTypes {
    Start = '@@EshopB2BConfirmation-polling/START',
    Failed = '@@EshopB2BConfirmation-polling/FAILED',
    Error = '@@EshopB2BConfirmation-polling/ERROR',
    Cancel = '@@EshopB2BConfirmation-polling/CANCEL',
    Timeout = '@@EshopB2BConfirmation-polling/TIMEOUT',
    Success = '@@EshopB2BConfirmation-polling/SUCCESS',
}

export const startConfirmationPollingAction = createActionCreator(
    B2BConfirmationPollingActionTypes.Start
);

export const timeoutConfirmationPollingAction = createActionCreator(
    B2BConfirmationPollingActionTypes.Timeout
);

export const failedConfirmationPollingAction = createActionCreator(
    B2BConfirmationPollingActionTypes.Failed
);

export const errorConfirmationPollingAction = createActionCreator(
    B2BConfirmationPollingActionTypes.Error
);

export const successConfirmationPollingAction = createActionCreator(
    B2BConfirmationPollingActionTypes.Success
);

export interface B2bConfirmationState {
    transaction: Transaction | null;
    confirmed: boolean;
    error: boolean;
}

const initialState: B2bConfirmationState = {
    transaction: null,
    confirmed: false,
    error: false,
};

export const b2bConfirmation = createReducer(initialState, {
    [B2BConfirmationPollingActionTypes.Start]: (state) => ({
        ...state,
        transaction: null,
        confirmed: false,
    }),
    [B2BConfirmationPollingActionTypes.Success]: (state, payload) => ({
        ...state,
        transaction: payload,
        confirmed: true,
    }),
    [B2BConfirmationPollingActionTypes.Error]: (state) => ({
        ...state,
        error: true,
        confirmed: false,
    }),
});

// Selectors
export function selectConfirmation(state: AppState) {
    return selectShopping(state).b2bConfirmation;
}

export function selectConfirmedTransaction(state: AppState) {
    return selectConfirmation(state).transaction;
}

export function selectConfirmationError(state: AppState) {
    return selectConfirmation(state).error;
}

export function selectConfirmationStatus(state: AppState) {
    return selectConfirmation(state).confirmed;
}

export function selectTicketEntries(state: AppState): TicketItem[] {
    const transaction = selectConfirmedTransaction(state);

    const getName = (entry: Entry) => {
        const name = oc(entry).product.name[i18next.language]('');
        const description = oc(entry).product.description[i18next.language]('');

        return description ? `${name} ${description}` : name;
    };

    if (transaction) {
        return transaction.entries
            .filter(entry => entry.type !== 'transaction_voucher_product')
            .map(entry => {
                let time = '';

                if (oc(entry).meta.timeSlotType() === TimeSlotType.range) {
                    if (environment.config.showTimeSlotRange && oc(entry).meta.timeSlot.to()) {
                        time = `${oc(entry).meta.timeSlot.from()} - ${oc(entry).meta.timeSlot.to()}`;
                    } else {
                        time = oc(entry).meta.timeSlot.from('');
                    }
                }

                const validity = ProductValidity.format(
                    oc(entry).meta.validFrom(''),
                    oc(entry).product.meta.tokenValidityAmount(1),
                    oc(entry).product.meta.tokenValidityUnit() || 'days',
                    oc(entry).product.meta.seasonal(),
                    time,
                );

                return {
                    uuid: oc(entry).uuid(),
                    name: getName(entry),
                    variant: oc(entry).product.variant[i18next.language](),
                    validity,
                    isGift: !!oc(entry).meta.giftTemplate(),
                    ...getCodeTicketConfig(entry),
                } as TicketItem;
            });
    } else {
        return [];
    }
}

function* pollTransaction(id: string) {
    let jobSucceeded = false;

    while (!jobSucceeded) {
        const resp = yield call(transactionsApi.transactionLoad, id);

        if (resp.ok) {
            if (
              resp.data.status === 'unpaid' &&
              oc<Transaction>(resp.data).meta.invoice.depositPdfUrl()
            ) {
                jobSucceeded = true;
                return resp.data;
            } else if (resp.data.status === 'finished') {
                jobSucceeded = true;
                return resp.data;
            } else if (resp.data.status === 'failed') {
                yield put(failedConfirmationPollingAction());
                jobSucceeded = true;
            }
        } else {
            yield put(failedConfirmationPollingAction());
        }

        yield call(delay, 1000);
    }
}

function* startPollingSaga({ payload }: any) {
    const { response, failed, timeout } = yield race({
        response: call(pollTransaction, payload),
        cancel: take(B2BConfirmationPollingActionTypes.Cancel),
        failed: take(B2BConfirmationPollingActionTypes.Failed),
        timeout: call(delay, 30000),
    });

    if (failed) {
        yield put(errorConfirmationPollingAction());
    }

    if (response) {
        sendGA4PurchaseEvent(response);
        yield put(successConfirmationPollingAction(response));
        yield put(resetCartAction.request());
        yield put(resetTemporaryCardsAction());
        yield put(clearEmailStateAction());
    }

    if (timeout) {
        yield put(timeoutConfirmationPollingAction());
    }
}

export function* b2bConfirmationSaga() {
    yield takeLatest(B2BConfirmationPollingActionTypes.Start, startPollingSaga);
}

function getCodeTicketConfig(entry: Entry) {
    if (entry.meta.method === EntryMethod.Print) {
        return {
            type: 'pass',
            code: oc(entry).ticketSystem.output.printedData(''),
            serial: oc(entry).ticketSystem.output.permissionSerialNumber(''),
        };
    } else if (entry.type === 'card-product') {
        return { type: 'no-card-item', code: oc(entry).ticketSystem.output.printedData('') };
    } else if (entry.meta.method === EntryMethod.Prepaid || entry.meta.method === EntryMethod.Exchange) {
        return { type: 'exchange-pass', code: oc(entry).ticketSystem.output.prepaidData('') };
    } else {
        return { type: 'info' };
    }
}
