import cn from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { LocalizedString, Tabs } from '../../components';
import { environment } from '../../../environments/environment';
import { Season } from '../../models/AppState';
import { Product } from '../../models/Product';
import Hero from '../Hero';
import DynamicImg from '../DynamicImg';

interface Props {
    categories: Product[];
    season: Season;
    children(product: Product);
}

const CatgoriesTabs: FunctionComponent<Props> = ({ categories: tabs, children, season }) => {
    const [category, setCategory] = useState('');

    useEffect(
        () => {
            if (tabs && tabs[0] && tabs[0]._id) {
                setCategory(tabs[0]._id);
            }
        },
        [tabs]
    );

    function handleTabsChange(key: number) {
        if (tabs[key] && tabs[key]._id) {
            setCategory(tabs[key]._id);
        }
    }

    function getCategoryImg() {
        if (category) {
            return (
                <DynamicImg
                    key={category}
                    srcs={[
                        `${environment.mediaUrl}/category-eshop/${category}`,
                        `${environment.mediaUrl}/static/eshop/category.season-${season.type}`,
                        `${environment.mediaUrl}/static/eshop/category`,
                    ]}
                    alt="category"
                    className="categories-tabs__image"
                />
            );
        }

        return undefined;
    }

    function renderTab(tab: Product) {
        return <LocalizedString value={tab.name} />;
    }

    return (
        <Hero img={getCategoryImg()}>
            <div
                className={cn('categories-tabs', {
                    'categories-tabs--summer': season.type === 'summer',
                    'categories-tabs--winter': season.type === 'winter',
                })}
            >
                <Tabs tabs={tabs} season={season} tabRender={renderTab} onChange={handleTabsChange}>
                    {item => children(item)}
                </Tabs>
            </div>
        </Hero>
    );
};

export default CatgoriesTabs;
