import { apiClient } from '../../common/services/api-client';

export const api = {
    getUserOrders: (skip: number) =>
        apiClient.get(`/transactions/my`, {
            params: {
                status: 'finished',
                type: 'purchase',
                $sort: { key: 'createdAt', direction: 'desc' },
                $limit: 10,
                $skip: skip,
            },
        }),
    getUserCredits: () => apiClient.get(`/accounts`, {}),
    getVoucherBenefits: () =>
        apiClient.get('/benefits/voucher'),
    getVIPbenefits: () =>
        apiClient.get(`/benefits/segment`),
    getDiscountsBenefits: (my?: boolean) =>
        apiClient.get(
            '/benefits/partner',
            { params: { my } }
        ),
    getCreditBenefits: () =>
        apiClient.get(`/benefits/credit`),
};
