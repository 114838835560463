import React from 'react';
import cn from 'classnames';

import { LipnoIcon } from '../../../../../../../../common/components';

interface Props {
    onClick?: () => void;
    right?: boolean;
}

const CarouselArrow = ({ onClick, right }: Props) => (
    <div
        className={
            cn(
                'time-picker__button',
                {'time-picker__button--left': !right},
                {'time-picker__button--right': right},
            )
        }
        onClick={onClick}
    >
        <LipnoIcon type={right ? LipnoIcon.types.ChevronRight : LipnoIcon.types.ChevronLeft} />
    </div>
);

export default CarouselArrow;
