import { Radio } from 'antd';
import { RadioGroupProps } from 'antd/lib/radio';
import cn from 'classnames';
import React, { ReactNode } from 'react';

import { Control } from '../Control';

interface Props extends RadioGroupProps {
    label?: ReactNode;
    error?: ReactNode;
}

export default ({ options = [], error, label, className, ...bag }: Props) => (
    <Control label={label} error={error}>
        <Radio.Group className={cn(className)} options={options} {...bag} />
    </Control>
);
