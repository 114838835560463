import Modal, { ModalProps } from 'antd/lib/modal';
import React, { ReactNode } from 'react';

interface Props extends ModalProps {
    children: ReactNode;
}

const EnhancedModal = (props: Props) => <Modal {...props} />;
EnhancedModal.confirm = Modal.confirm;
EnhancedModal.info = Modal.info;
export default EnhancedModal;
