import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import { CookieConsent, Footer, Header, Layout } from '../../common/components';
import { AppState } from '../../common/models/AppState';
import {
    agreeWithCookiesAction,
    checkSessionActions,
    selectCookiesAgreement,
    selectIsLogged,
} from '../user/ducks';

import { fetchCategoriesActions, fetchSeasonsAction } from './ducks';
import { Checkout } from './features/checkout';
import { Confirmation } from './features/confirmation';
import { Discounts } from './features/discount';
import { Eshop } from './features/eshop';
import { Informations } from './features/informations';
import { B2BConfirmation } from './features/b2b-confirmation';

interface DispatchToProps {
    fetchCategories(): void;

    agreeWithCookies(): void;

    checkSession(): void;
}

interface StateToProps {
    cookieAgreement: boolean;
}

type Props = RouteComponentProps & DispatchToProps & StateToProps;

export const ShoppingComponent: FunctionComponent<Props> = ({
    match,
    fetchCategories,
    cookieAgreement,
    agreeWithCookies,
    checkSession,
    history,
}) => {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized) {
            fetchCategories && fetchCategories();
            setInitialized(true);
        }
    });

    useEffect(() => {
        checkSession();
    });

    function handleAgreeCookies() {
        agreeWithCookies();
    }

    return (
        <Layout>
            <Header />
            <Switch>
                <Route path={`${match.path}/eshop`} component={Eshop} />
                <Route exact path={`${match.path}/discount`} component={Discounts} />
                <Route exact path={`${match.path}/informations`} component={Informations} />
                <Route exact path={`${match.path}/checkout`} component={Checkout} />
                <Route exact path={`${match.path}/confirmation`} component={Confirmation} />
                <Route exact path={`${match.path}/b2b-confirmation`} component={B2BConfirmation} />
                <Redirect path={`${match.path}/`} to={`${match.path}/eshop`} />
            </Switch>
            <Footer />
        </Layout>
    );
};

const mapStateToProps = (state: AppState): StateToProps => ({
    cookieAgreement: selectCookiesAgreement(state),
});

const mapDispatchToProps: DispatchToProps = {
    fetchCategories: fetchCategoriesActions.request,
    agreeWithCookies: agreeWithCookiesAction,
    checkSession: checkSessionActions.request,
};

export const Shopping = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ShoppingComponent)
);
