import { Button, Icon, Tabs } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { oc } from 'ts-optchain';

import { Avatar, Dropdown, LoginForm, Menu } from '../../../../components';
import { LoginFormValues, UserProfile } from '../../../../models/User';
import { i18n } from '../../../../services/i18n';
import { menu } from '../../constants/menu';

enum TabsTypes {
    Registration = 'registration',
    Login = 'Login',
}

interface Props {
    children?: ReactNode;
    isLogged?: boolean;
    profile?: UserProfile;
    visible?: boolean;

    onLogin(values: any): void;

    onLogout?(): void;

    onRegistrationClick?(): void;

    onVisibilityChange?: (visible: boolean) => void;
    onPassswordForget?: () => void;
    isMobile: boolean;
}

function AuthDropDown(props: Props) {
    const [visibility, setVisibility] = useState(false);
    const [loginVisibility, setLoginVisibility] = useState(false);

    useEffect(
        () => {
            if (props.visible) {
                setVisibility(props.visible);
            }
        },
        [props.visible]
    );

    function handleLoginSubmit(values: LoginFormValues) {
        props.onLogin && props.onLogin(values);
        setVisibility(false);
    }

    function handleMenuItemClick() {
        setVisibility(false);
    }

    function handleVisibilityChange(visibility: boolean) {
        // if we open the menu, turn off login visibility
        if (visibility) {
            setLoginVisibility(false);
        }

        setVisibility(visibility);
        props.onVisibilityChange && props.onVisibilityChange(visibility);
    }

    function handleRegistrationClick() {
        props.onRegistrationClick && props.onRegistrationClick();
        setVisibility(false);
    }

    function handleLogoutClick() {
        props.onLogout && props.onLogout();
    }

    function handleForgotPasswordClick() {
        setVisibility(false);
        props.onPassswordForget && props.onPassswordForget();
    }

    function showLogin() {
        setLoginVisibility(true);
    }

    function renderUserInfo() {
        return (
            <div className="user-info">
                <Avatar icon="user" />
                {!props.isMobile && (
                    <span>
                        {props.isLogged
                            ? oc(props.profile).firstName(oc(props.profile).email(''))
                            : i18n.t('auth.notLogged')}
                    </span>
                )}
                <span className="ant-select-arrow" unselectable="on" style={{ userSelect: 'none' }}>
                    <i className="icon icon-chevron-down" />
                </span>
            </div>
        );
    }

    function renderDefaultOverlay() {
        return (
            <React.Fragment>
                {props.isMobile && (
                    <div className="auth-dropdown">
                        <div className={loginVisibility ? 'display-block' : 'display-none'}>
                            <LoginForm onSubmit={handleLoginSubmit} />
                        </div>
                        <Button
                            onClick={showLogin}
                            className={loginVisibility ? 'display-none' : 'display-block'}
                            block
                            type="default"
                            size="small"
                        >
                            {i18n.t('login.submit')}
                        </Button>
                        <Button onClick={handleRegistrationClick} block type="primary" size="small">
                            {i18n.t('header.auth.register')}
                        </Button>
                        <Button
                            onClick={handleForgotPasswordClick}
                            block
                            type="primary"
                            size="small"
                        >
                            {i18n.t('login.passwordForget')}
                        </Button>
                    </div>
                )}

                {!props.isMobile && (
                    <div className="auth-dropdown">
                        <Tabs
                            defaultActiveKey={TabsTypes.Login}
                            tabBarExtraContent={
                                <div
                                    onClick={handleRegistrationClick}
                                    className="ant-tabs-tab registration-button"
                                >
                                    {i18n.t('header.auth.register')}
                                </div>
                            }
                        >
                            <Tabs.TabPane tab={i18n.t('header.auth.login')} key={TabsTypes.Login}>
                                <LoginForm onSubmit={handleLoginSubmit} />
                                <Button
                                    onClick={handleForgotPasswordClick}
                                    block
                                    type="primary"
                                    size="small"
                                >
                                    {i18n.t('login.passwordForget')}
                                </Button>
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                )}
            </React.Fragment>
        );
    }

    function renderLoggedOverlay() {
        return (
            <Menu className="logged-menu">
                {menu.map((item, index) => (
                    <Menu.Item key={index}>
                        <Link onClick={handleMenuItemClick} to={item.link}>
                            <Icon type={item.icon} />
                            {i18n.t(item.key)}
                        </Link>
                    </Menu.Item>
                ))}
                <Menu.Item>
                    <a onClick={handleLogoutClick}>
                        <Icon type="poweroff" />
                        {i18n.t('header.loggedDropdown.logout')}
                    </a>
                </Menu.Item>
            </Menu>
        );
    }

    return (
        <Dropdown
            placeholder={
                !props.isLogged
                    ? i18n.t('header.auth.login')
                    : oc(props.profile).firstName(oc(props.profile).email(''))
            }
            trigger={['click']}
            overlay={props.isLogged ? renderLoggedOverlay() : renderDefaultOverlay()}
            onVisibleChange={handleVisibilityChange}
            visible={visibility}
        >
            {props.children ? props.children : renderUserInfo()}
        </Dropdown>
    );
}

export default AuthDropDown;
