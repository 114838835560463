import { Icon } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { ProductSelection } from '../../models/Cart';
import { changeSeasonAction, selectEshopConfig, selectSeason, selectSeasons } from '../../../features/shopping/ducks';
import { openCartAction, selectCartItems } from '../../../features/shopping/ducks/cart';
import {
    forgotPasswordActions,
    handleSessionErrorAction,
    loginActions,
    logoutAction,
    registerAndLoginAction, selectAsyncRegisterInProgress,
    selectIsLogged,
    selectSessionError, selectShowRegisterModal,
    selectUserProfile, setShowRegisterModalAction,
} from '../../../features/user/ducks';
import { FeatureFlag } from '../../../root/components/FeatureFlag';
import { CartButton, InfoModal, Layout, Logo, RegistrationForm } from '../../components';
import { useIsMobileWide } from '../../hooks/useIsMobileWide';
import { ForgotPasswordModal, RegistrationModal } from '../../modals';
import { AppState, Season } from '../../models/AppState';
import { LoginFormValues, RegisterFormValues, UserProfile } from '../../models/User';
import { i18n } from '../../services/i18n';

import {
    AuthDropDown,
    HeaderItem,
    HeaderLayout,
    LanguagePicker,
    MobileMenu,
    SeasonPicker,
} from './components';
import { Cart } from './containers';
import { environment } from '../../../environments/environment';
import { Config } from '../../models/Config';
import InvalidProductsModal from '../../modals/InvalidProductsModal';

interface OwnProps {
    type?: 'default' | 'step';
}

interface StateToProps {
    config: Config | null;
    cartVisisbility?: boolean;
    cartItems?: ProductSelection[];
    season?: Season;
    seasons: Season[];
    profile?: UserProfile;
    isLogged?: boolean;
    sessionError: boolean;
    total?: number;
    showRegisterModal: boolean;
    registerInProgress: boolean;
}

interface DispatchToProps {
    changeSeason?(season: Season): void;

    removeItemFromCart?(id: string): void;

    register?(values: RegisterFormValues): void;

    forgotPassword?(email: string): void;

    openCart?(): void;

    handleSessionError?(show: boolean): void;

    login?(values: LoginFormValues): void;

    logout?(): void;

    setShowRegisterModal(show: boolean): void;
}

type Props = RouteComponentProps<OwnProps> & OwnProps & DispatchToProps & StateToProps;

function Header(props: Props) {
    const isMobile = useIsMobileWide();
    const [mobileMenuVisibility, setMobileMenuVisibility] = useState(false);
    const [regFormVisibility, setRegFormVisibility] = useState(false);
    const [emailReminderVisibility, setEmailReminderVisibility] = useState(false);
    const [sessionErrorModalVisibility, setSessionErrorModalVisibility] = useState(false);
    const [passwordForgetVisibility, setPasswordForgetVisibility] = useState(false);
    const [loginStatusChange, setLoginStatusChange] = useState(false);
    const { pathname } = props.history.location;

    // TODO: Refactor this shit & linux building ts error
    const personal = props.match.path === '/personal';
    const code = props.match.path === '/kod';
    const banners = props.match.path.includes('/banner');
    const terms = props.match.path === '/terms';
    const card = props.match.path === '/karta';
    const tablet = props.match.path === '/tablet';
    const eshop = pathname === '/shopping/eshop';
    const activate = pathname === '/users/activation';
    const aboutCard = pathname === '/aboutCard';
    const partnerDisc = pathname === '/discounts/partner';
    const headerType =
        personal ||
        tablet ||
        code ||
        terms ||
        card ||
        eshop ||
        aboutCard ||
        banners ||
        partnerDisc ||
        activate
            ? 'default'
            : 'step';

    const showSeasonPicker = props.config && props.config.seasonPicker;

    useEffect(
        () => {
            if (props.isLogged && loginStatusChange) {
                setLoginStatusChange(false);
                if (props.profile && props.profile.roles && !props.profile.roles.active) {
                    setEmailReminderVisibility(true);
                }
            }
        },
        [props.isLogged]
    );

    useEffect(
        () => {
            if (props.showRegisterModal) {
                if (!props.isLogged) {
                    setRegFormVisibility(true);
                }
                props.setShowRegisterModal(false);
            }
        },
        [props.showRegisterModal]
    );

    useEffect(
        () => {
            setSessionErrorModalVisibility(props.sessionError);
        },
        [props.sessionError]
    );

    function handleCartClick() {
        // log cart click for analytics
        props.openCart && props.openCart();
    }

    function handleCloseModal() {
        setRegFormVisibility(false);
    }

    function handleOpenMobileMenu() {
        setMobileMenuVisibility(true);
    }

    function handleCloseMobileMenu() {
        setMobileMenuVisibility(false);
    }

    function handleRegistrationClick() {
        setRegFormVisibility(true);
    }

    function handlePasswordForgetClick() {
        setMobileMenuVisibility(false);
        setPasswordForgetVisibility(true);
    }

    function handleForgotPasswordCancelClick() {
        setPasswordForgetVisibility(false);
    }

    function handleForgotPasswordConfirmClick(email: string) {
        props.forgotPassword && props.forgotPassword(email);
        setPasswordForgetVisibility(false);
    }

    function handleLoginSubmit(values: LoginFormValues) {
        props.login && props.login({ ...values, email: values.email });
        setLoginStatusChange(true);
    }

    function handleLogoutClick() {
        props.logout && props.logout();
        setLoginStatusChange(false);
    }

    function handleRegisterClick(values: RegisterFormValues) {
        props.register && props.register({ ...values, email: values.email });
        setRegFormVisibility(false);
    }

    function handleMobileChangeSeason(season: Season) {
        props.changeSeason && props.changeSeason(season);
        props.history.push('/shopping/eshop');
    }

    function handleGoBack() {
        switch (pathname) {
            case '/shopping/checkout':
                props.history.push('/shopping/informations');
                break;
            case '/shopping/informations':
                if (environment.config.cartButtonUrl === '/shopping/informations') {
                    props.history.push('/shopping/eshop');
                } else {
                    props.history.push('/shopping/discount');
                }
                break;
            case '/shopping/discount':
                props.history.push('/shopping/eshop');
                break;
            default:
                props.history.goBack();
                break;
        }
    }

    function renderAboutLipno() {
        return (
            <FeatureFlag flagKey="aboutLipnoLink">
                <a className="about-lipno" href="https://www.lipno.info/" target="_blank">
                    {i18n.t('header.aboutLipno')}
                </a>
            </FeatureFlag>
        );
    }

    function renderMobileMenuTrigger() {
        return (
            <div onClick={handleOpenMobileMenu} className="mobile-menu-trigger">
                <Icon type="bars" />
            </div>
        );
    }

    function renderRegistrationModal() {
        return (
            <RegistrationModal visible={regFormVisibility} onCancel={handleCloseModal}>
                <RegistrationForm onSubmit={handleRegisterClick} inProgress={props.registerInProgress} />
            </RegistrationModal>
        );
    }

    function handleReminderVisibilityChange() {
        setEmailReminderVisibility(false);
    }

    function handleSessionVisibilityChange() {
        setSessionErrorModalVisibility(false);
        props.handleSessionError && props.handleSessionError(false);
    }

    function renderLayout() {
        return headerType === 'default' ? (
            <HeaderLayout
                left={
                    <Fragment>
                        {isMobile && renderMobileMenuTrigger()}
                        <Logo />
                        {!isMobile && showSeasonPicker && (
                            <SeasonPicker
                                value={props.season}
                                onChange={handleMobileChangeSeason}
                                seasons={props.seasons}
                            />
                        )}
                    </Fragment>
                }
                right={
                    <Fragment>
                        <HeaderItem>
                            <LanguagePicker />
                        </HeaderItem>
                        {!isMobile && (
                            <Fragment>
                                {props.season ? (
                                    props.season.type === 'summer' && environment.config.aboutCard && (
                                        <HeaderItem>
                                            <a onClick={() => props.history.push('/aboutCard')}>
                                                {i18n.t('header.menu.aboutLipno')}
                                            </a>
                                        </HeaderItem>
                                    )
                                ) : (
                                    <div />
                                )}
                            </Fragment>
                        )}
                        <HeaderItem>
                            <AuthDropDown
                                isLogged={props.isLogged}
                                profile={props.profile}
                                onLogin={handleLoginSubmit}
                                onLogout={handleLogoutClick}
                                onRegistrationClick={handleRegistrationClick}
                                onPassswordForget={handlePasswordForgetClick}
                                isMobile={isMobile}
                            />
                        </HeaderItem>
                        {!isMobile ? <HeaderItem>{renderAboutLipno()}</HeaderItem> : null}
                        <HeaderItem>
                            <CartButton
                                onClick={handleCartClick}
                                count={props.cartItems && props.cartItems.length}
                            />
                        </HeaderItem>
                    </Fragment>
                }
            />
        ) : (
            <div className="header-step">
                <div className="header-step__left">
                    <Icon onClick={handleGoBack} type="arrow-left" />
                </div>
                <div className="header-step__middle">
                    <Logo />
                    {i18n.t('header.shoppingZone')}
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            <InfoModal
                headerText={i18n.t('emailModal.title')}
                bodyText={i18n.t('emailModal.description')}
                buttonText={i18n.t('emailModal.button')}
                visible={emailReminderVisibility}
                onCancel={handleReminderVisibilityChange}
            />
            <InfoModal
                headerText={i18n.t('sessionModal.title')}
                bodyText={i18n.t('sessionModal.description')}
                buttonText={i18n.t('sessionModal.button')}
                visible={sessionErrorModalVisibility}
                onCancel={handleSessionVisibilityChange}
            />
            {renderRegistrationModal()}
            <ForgotPasswordModal
                visible={passwordForgetVisibility}
                onConfirm={handleForgotPasswordConfirmClick}
                onCancel={handleForgotPasswordCancelClick}
            />
            {isMobile && (
                <MobileMenu
                    season={props.season}
                    seasons={showSeasonPicker ? props.seasons : []}
                    visible={mobileMenuVisibility}
                    isLogged={props.isLogged ? props.isLogged : false}
                    onLoginSubmit={handleLoginSubmit}
                    onClose={handleCloseMobileMenu}
                    onRegister={handleRegistrationClick}
                    onLogout={handleLogoutClick}
                    onChangeSeason={handleMobileChangeSeason}
                    onPasswordForget={handlePasswordForgetClick}
                />
            )}
            <Cart />
            <InvalidProductsModal type="invalid-segment" />
            <Layout.Header>
                <Layout.Container fullHeight>{renderLayout()}</Layout.Container>
            </Layout.Header>
        </Fragment>
    );
}

const mapStateToProps = (state: AppState): StateToProps => ({
    config: selectEshopConfig(state),
    cartItems: selectCartItems(state),
    seasons: selectSeasons(state),
    season: selectSeason(state),
    profile: selectUserProfile(state),
    isLogged: selectIsLogged(state),
    sessionError: selectSessionError(state),
    showRegisterModal: selectShowRegisterModal(state),
    registerInProgress: selectAsyncRegisterInProgress(state),
});

const mapDispatchToProps: DispatchToProps = {
    openCart: openCartAction,
    changeSeason: changeSeasonAction,
    login: loginActions.request,
    register: registerAndLoginAction,
    logout: logoutAction,
    handleSessionError: handleSessionErrorAction,
    forgotPassword: forgotPasswordActions.request,
    setShowRegisterModal: setShowRegisterModalAction,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Header)
);
