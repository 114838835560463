import React, { useEffect, useState } from 'react';
import { AppState } from '../../common/models/AppState';
import { connect } from 'react-redux';
import { DiscountsState, selectDiscounts, fetchDiscountsAction } from '../personal/ducks';
import { Header, Layout, PageDescription, Footer, Button } from '../../common/components';
import { Col, Row } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { i18n } from '../../common/services/i18n';
import { checkSessionActions } from '../user/ducks';
import PartnerBenefit from '../../common/components/PartnerBenefit';

type Props = RouteComponentProps & DispatchToProps & StateToProps;

function PartnerDiscountsComponent(props: Props) {
    const [count, setCount] = useState<number>(6);

    useEffect(() => {
        props.fetchDiscounts();
        props.checkSession();
    }, []);

    function increaseElementCount() {
        setCount(count + 6);
    }

    function redirect() {
        props.history.push('/personal/discounts');
    }

    return (
        <Layout>
            <Header />
            <Layout.Content>
                {props.discounts.myPartner.length > 0 && (
                    <React.Fragment>
                        <PageDescription
                            title={i18n.t('partnerDiscounts.segement.title')}
                            description={i18n.t('partnerDiscounts.segment.description')}
                        />
                        <Layout.Container>
                            <Row gutter={0}>
                                {props.discounts.myPartner.map((benefit) => (
                                    <Col
                                        key={benefit.benefitId}
                                        xs={24}
                                        sm={12}
                                        md={8}
                                        className={'partnerDiscounts__col'}
                                    >
                                        <PartnerBenefit benefit={benefit} wide />
                                    </Col>
                                ))}
                            </Row>
                        </Layout.Container>
                    </React.Fragment>
                )}

                <PageDescription
                    title={i18n.t('partnerDiscounts.all.title')}
                    description={i18n.t('partnerDiscounts.all.description')}
                />
                <Layout.Container>
                    <Row gutter={0}>
                        {props.discounts.partner
                            .slice(0, count)
                            .map((benefit) => (
                                <Col
                                    key={benefit.benefitId}
                                    xs={24}
                                    sm={12}
                                    md={8}
                                    className={'partnerDiscounts__col'}
                                >
                                    <PartnerBenefit benefit={benefit} wide />
                                </Col>
                            ))
                        }
                    </Row>

                    {count < props.discounts.partner.length && (
                        <Button
                            className="partnerDiscounts__button"
                            type={'primary'}
                            onClick={increaseElementCount}
                        >
                            <p>{i18n.t('partnerDiscounts.button.load')} </p>
                        </Button>
                    )}

                    <Button
                        className="partnerDiscounts__button"
                        type={'default'}
                        onClick={redirect}
                    >
                        <p> {i18n.t('partnerDiscounts.button.back')}</p>
                    </Button>
                </Layout.Container>
            </Layout.Content>
            <Footer />
        </Layout>
    );
}

const mapStateToProps = (state: AppState): StateToProps => ({
    discounts: selectDiscounts(state),
});

const mapDispatchToProps: DispatchToProps = {
    fetchDiscounts: fetchDiscountsAction,
    checkSession: checkSessionActions.request,
};

interface StateToProps {
    discounts: DiscountsState;
}

interface DispatchToProps {
    fetchDiscounts: () => void;
    checkSession(): void;
}

export const PartnerDiscounts = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PartnerDiscountsComponent)
);
