import { Tabs } from 'antd';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import Truncate from 'react-truncate';

interface WizardItem {
    title: ReactNode;
    description: ReactNode;
}

interface Props {
    items: WizardItem[];
    title?: ReactNode;
    refs?: any;
}

const SideWizard: FunctionComponent<Props> = ({ items, title, refs }) => {
    const [activeTab, setActiveTab] = useState(0);
    let intervalId: number | undefined = undefined;

    useEffect(
        () => {
            window.clearInterval(intervalId);
            intervalId = window.setInterval(selectActiveTab, 300);
            return () => {
                window.clearInterval(intervalId);
            };
        },
        [refs]
    );

    function refsLoaded(activeTab: number) {
        return refs[0] && refs[0].current !== null && refs[activeTab] && refs[activeTab].current !== null;
    }

    const selectActiveTab = () => {
        // components are asynch loaded, we might need to wait for them to load
        if (!refsLoaded(activeTab)) {
            return;
        }

        // select the centermost component
        let selected = activeTab;
        let min = Math.abs(
            refs[activeTab].current.getBoundingClientRect().top - window.innerHeight / 2
        );
        for (let i = 0; i < items.length; i++) {
            if (!refs[i] || !refs[i].current) {
                continue;
            }

            const box = refs[i].current.getBoundingClientRect();
            // 70 is magic number for header height
            const distFromCenter = Math.abs(box.top - window.innerHeight / 2 + 70);
            if (distFromCenter < min) {
                selected = i;
                min = distFromCenter;
            }
        }
        setActiveTab(selected);
    };

    function scrollToTab(index: number) {
        // needed if we scroll too soon, before rendered components are async. loaded
        if (!refs[index]) {
            return;
        }

        refs[index].current.scrollIntoView({
            alignToTop: true,
            behavior: 'smooth',
            block: 'center',
        });
    }

    return (
        <div className="side-wizard">
            <h3 className="side-wizard__title">{title}</h3>
            <Tabs tabPosition="right" activeKey={activeTab.toString()} animated={true}>
                {items.length &&
                    items.map(({ title, description }, index) => (
                        <Tabs.TabPane
                            tab={
                                <div
                                    className="side-wizard-item"
                                    onClick={() => scrollToTab(index)}
                                >
                                    <div className="side-wizard-item__title">
                                        <Truncate width={165} ellipsis="...">
                                            {title}
                                        </Truncate>
                                    </div>
                                    <div className="side-wizard-item__description">
                                        {description}
                                    </div>
                                </div>
                            }
                            key={index.toString()}
                        />
                    ))}
            </Tabs>
        </div>
    );
};

export default SideWizard;
