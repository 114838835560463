import React, { ReactNode } from 'react';
import cn from 'classnames';

interface Props {
    title?: ReactNode;
    description?: ReactNode;
    withFeedback?: boolean;
}

export default ({ title, description, withFeedback }: Props) => {
    return (
        <div className={cn('page-description', {'page-description--feedback': withFeedback})}>
            <h1>{title}</h1>
            <p>{description}</p>
        </div>
    );
};
