import { i18n } from '../services/i18n';

export function unflatten(object) {
    const result = {};
    Object.keys(object).forEach(function(k) {
        setValue(result, k, object[k]);
    });
    return result;
}

function setValue(object, path, value) {
    const way = path.split('_'),
        last = way.pop();

    way.reduce(function(o, k, i, kk) {
        return (o[k] = o[k] || (isFinite(i + 1 in kk ? kk[i + 1] : last) ? [] : {}));
    }, object)[last] = value;
}

export function translationExists(key: string) {
    return ![key, '', ' '].includes(i18n.t(key));
}
