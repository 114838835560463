import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { ModalSizes } from '../../../../../../common/constants/modal';
import { AppState } from '../../../../../../common/models/AppState';
import { i18n } from '../../../../../../common/services/i18n';
import {
    CodeStartState,
    CodeVerifcationTypes,
    resetCodeVerificationAction,
    runBenefitEventActions,
    selectCodeVerification,
    selectCodeVerificationType,
    verifyTokenActions,
} from '../../../../../code/ducks';
import { Modal, VoucherInput } from '../../../.././../../common/components';
import { fetchDiscountsAction } from '../../../../ducks';

interface DispatchToProps {
    verifyCode: (code: string) => void;
    resetCodeVerification: () => void;
    runBenefitEvent: (payload: { code: string; type: CodeVerifcationTypes }) => void;
    fetchDiscounts: () => void;
}

interface StateToProps {
    code: CodeStartState;
    verificationType: CodeVerifcationTypes | null;
}

type Props = DispatchToProps & StateToProps;

export function VoucherVerifyContainer(props: Props) {
    const [code, setCode] = useState('');

    useEffect(
        () => {
            if (props.code.validated && props.code.benefit && !props.code.applied && code) {
                props.runBenefitEvent({ code, type: CodeVerifcationTypes.FromDiscountCenter });
            }
        },
        [props.code.validated, props.code.benefit, props.code.applied]
    );

    useEffect(
        () => {
            if (
                props.code.validated &&
                props.code.benefit &&
                props.code.applied &&
                props.verificationType === CodeVerifcationTypes.FromDiscountCenter
            ) {
                handleShowConfirmModal();
            }
        },
        [props.code.validated, props.code.benefit, props.code.applied, props.verificationType]
    );

    function handleShowConfirmModal() {
        Modal.info({
            title: i18n.t('voucherVerifyModal.success.title'),
            content: <p>{i18n.t('voucherVerifyModal.success.content')}</p>,
            okText: i18n.t('voucherVerifyModal.success.button'),
            okButtonProps: { block: true, type: 'default' },
            width: ModalSizes.default,
            onOk: () => {
                props.resetCodeVerification();
                props.fetchDiscounts();
            },
        });
    }

    function handleVerifyClick(code: string) {
        props.verifyCode(code);
        setCode(code);
    }

    return (
        <Fragment>
            <h3>{i18n.t('voucherInputSection.personal.title')}</h3>
            <VoucherInput
                onVerify={handleVerifyClick}
                validated={props.code.validated}
                benefit={props.code.benefit}
                error={props.code.error}
            />
        </Fragment>
    );
}

const mapDispatchToProps: DispatchToProps = {
    verifyCode: verifyTokenActions.request,
    resetCodeVerification: resetCodeVerificationAction,
    runBenefitEvent: runBenefitEventActions.request,
    fetchDiscounts: fetchDiscountsAction,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    code: selectCodeVerification(state),
    verificationType: selectCodeVerificationType(state),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VoucherVerifyContainer);
