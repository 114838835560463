import React, { Fragment, FunctionComponent } from 'react';


import { LocalizedObject } from '../../models/Product';
import { i18n } from '../../services/i18n';
import { CmsContent } from '../../models/NewTransaction';
import { oc } from 'ts-optchain';

interface Props {
    value?: CmsContent;
    html?: CmsContent;
}

const LocalizedString = ({ value, html }: Props) => {
    if (value) {
        return <Fragment>{oc(value)[i18n.language]('')}</Fragment>;
    } else if (html) {
        return <span dangerouslySetInnerHTML={{__html: html[i18n.language] || ''}} />;
    } else {
        return null;
    }
};

LocalizedString.format = (value?: CmsContent) => oc(value)[i18n.language]('');

export default LocalizedString;
