import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment';

import {
    RequestStatus, selectEshopConfig,
    selectTimeSlotsLoadStatus,
    selectTimeSlotsTimes,
    selectTimeSlotsType,
    TimeSlotTime,
    TimeSlotType,
} from '../../../../../../ducks';
import { AppState } from '../../../../../../../../common/models/AppState';
import { i18n } from '../../../../../../../../common/services/i18n';
import { environment } from '../../../../../../../../environments/environment';
import { Config } from '../../../../../../../../common/models/Config';
import { oc } from 'ts-optchain';

interface Props {
    arrival: string;
    time: string;
    count: number;

    setTime(time): void;
    setTimeSlotType?: (type: string) => void;
    isDateValid: boolean;
}

function TimeSlotPicker(props: Props & StateToProps) {

    useEffect(() => {
        if (props.type === TimeSlotType.day && Object.keys(props.times).length > 0) {
            props.setTimeSlotType && props.setTimeSlotType(TimeSlotType.day);

            if (isDayTimeSlotAvailable()) {
                props.setTime('00:00');
            } else {
                props.setTime('');
            }
        } else if (props.type === TimeSlotType.range && Object.keys(props.times).length > 0) {
            props.setTimeSlotType && props.setTimeSlotType(TimeSlotType.range);
        }
    }, [props.times, props.type, props.count]);

    const timeColumns = useMemo(
        () => Object
            .keys(props.times)
            .reduce((acc, time) => ({
                ...acc,
                [time.split(':')[0]]: [...(acc[time.split(':')[0]] || []), time],
            }), {}),
        [props.times]
    );

    const setTime = (time: string) => {
        const full = moment(`${moment(props.arrival).format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD HH:mm')
            .isBefore(moment().add(environment.project === 'morava' ? -30 : 15, 'minutes'));

        if (getAvailableTimeSlotsCount(time) - props.count >= 0 && !full && props.isDateValid) {
            props.setTime(time);
        }
    };

    const getTimeSlot = (time: string, freeSlots: number) => {
        const full = freeSlots < props.count ||
            moment(`${moment(props.arrival).format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD HH:mm')
                .isBefore(moment().add(environment.project === 'morava' ? -30 : 15, 'minutes')) ||
            !props.isDateValid;

        return (
            <div
                key={time}
                className={
                    cn(
                        'timeslots__cell slot',
                        {
                            'slot--full': full,
                            'slot--selected': time === props.time,
                        }
                    )
                }
                onClick={() => setTime(time)}
            >
                <div className="slot__time">
                    {time}
                </div>
                {props.isDateValid &&
                    <div
                        className={
                            cn(
                                'slot__free',
                                {
                                    'slot__free--full': full,
                                    'slot__free--available': !full,
                                }
                            )
                        }
                    >
                        {freeSlots}{' '}
                        {i18n.t('timeSlots.free')}
                    </div>
                }
                {!props.isDateValid &&
                    <div className="slot__free slot__free--full">
                        {i18n.t('timeSlots.notValid')}
                    </div>
                }
            </div>
        );
    };

    const getAvailableTimeSlotsCount = (time: string) => {
        return Math.max(0, props.times[time].available - props.times[time].occupied);
    };

    const getAvailableTimeSlots = () => {

        return Object
            .keys(timeColumns)
            .sort()
            .map((col: string) => (
                <div className="timeslots__col" key={col}>
                    {timeColumns[col]
                        .map(time => getTimeSlot(time, getAvailableTimeSlotsCount(time)))
                    }
                </div>
            ));
    };

    const isDayTimeSlotAvailable = () => {

        return getAvailableTimeSlotsCount('00:00') >= props.count;
    };

    return (
        <div className="timeslots">
            {props.requestState !== RequestStatus.Failed && Object.keys(props.times).length > 0 &&
                <>
                    {props.type === TimeSlotType.day &&
                        <div className="timeslots__text timeslots__day">
                            <p className="timeslots__day-text" dangerouslySetInnerHTML={{__html: i18n.t('timeSlots.beforeDayTimeSlot')}} />
                            {!oc(props.config).hideDayTimeslotCapacityLabel() && (
                                <h3
                                    className={
                                        cn(
                                            'timeslots__day-text',
                                            {
                                                'timeslots__day-text--full': !isDayTimeSlotAvailable(),
                                                'timeslots__day-text--available': isDayTimeSlotAvailable(),
                                            }
                                        )
                                    }
                                >
                                    <b>{i18n.t('timeSlots.dayFreeSlots')}: {getAvailableTimeSlotsCount('00:00')}</b>
                                </h3>
                            )}
                            {oc(props.config).hideDayTimeslotCapacityLabel() && !isDayTimeSlotAvailable() && (
                                <p
                                    className="timeslots__day-text timeslots__day-text--full"
                                    dangerouslySetInnerHTML={{__html: i18n.t('timeSlots.dayTimeSlotFull')}}
                                />
                            )}
                            <p className="timeslots__day-text" dangerouslySetInnerHTML={{__html: i18n.t('timeSlots.afterDayTimeSlot')}} />
                        </div>
                    }
                    {props.type === TimeSlotType.range && getAvailableTimeSlots()}
                    {props.requestState === RequestStatus.Loading &&
                        <div className="timeslots__overlay">
                            {i18n.t('timeSlots.timesLoading')}
                        </div>
                    }
                </>
            }
            {props.requestState === RequestStatus.Loaded && Object.keys(props.times).length === 0 &&
                <div className="timeslots__text">
                    {i18n.t('timeSlots.timesNotFoundForDay')}
                </div>
            }
            {props.requestState === RequestStatus.Failed &&
                <div className="timeslots__text">
                    {i18n.t('timeSlots.timesLoadError')}
                </div>
            }
            {props.requestState === RequestStatus.Loading && Object.keys(props.times).length === 0 &&
                <div className="timeslots__text">
                    {i18n.t('timeSlots.timesLoading')}
                </div>
            }
        </div>
    );
}

interface StateToProps {
    requestState: RequestStatus;
    times: {[key: string]: TimeSlotTime};
    type?: TimeSlotType;
    day?: { occupied?: number, available?: number };
    config: Config | null;
}

const mapStateToProps = (state: AppState): StateToProps => ({
    requestState: selectTimeSlotsLoadStatus(state),
    times: selectTimeSlotsTimes(state),
    type: selectTimeSlotsType(state),
    config: selectEshopConfig(state),
});

export default connect(
    mapStateToProps,
)(TimeSlotPicker);
