import React, { Fragment } from 'react';

import { Layout } from '../../../../common/components';
import { i18n } from '../../../../common/services/i18n';
import { TermsSubheader } from '../../components';

export function CookiesPage() {
    return (
        <Fragment>
            <TermsSubheader title={i18n.t('terms.cookies')} />
            <Layout.Container>
                <h3>Cookie</h3>
                <p>
                    Cookie jsou malé datové soubory, které jsou nezbytné pro správnou funkci
                    stránek, a které proto někdy umísťujeme na váš počítač , tak jako ostatně
                    většina velkých internetových stránek.
                </p>

                <h3>Co jsou cookie?</h3>
                <p>
                    Textové soubory, které internetové stránky ukládají na váš počítače nebo mobilní
                    zařízení v okamžiku, kdy tyto stránky začnete využívat. Stránky si tak na
                    určitou dobu zapamatují úkony, které jste na nich provedli, a preferencí (např.
                    přihlašovací údaje, jazyka, velikost písma a jiné zobrazovací preferencí), takže
                    tyto údaje pak nemusíte zadávat znovu a přeskakovat z jedné stránky na druhou.
                </p>

                <h3>Jak se cookie používají?</h3>
                <p>
                    Cookie provozovatelů reklamních systémů za účelem remarketingu, nebo pro
                    zobrazování reklamy, která je pro vás relevantnější. K anonymnímu statistickému
                    zaznamenávání. K uložení registračních údajů. Krátkodobé (session) cookie, které
                    potřebujeme k tomu, abyste se mohli přihlásit a objednat. Za účelem testování a
                    zlepšování kvality služeb využívá web analytický nástroj Hotjar, který slouží k
                    vyhodnocování chování uživatelů na webové stránce. Jeho pomocí jsou v anonymní
                    formě sbírána data např. o velikosti obrazovky, pohybu myši apod. Tato data
                    nemají povahu osobních údajů a nejsou dále předávána třetím osobám. Více o tom,
                    jak Hotjar s údaji nakládá, je možné nalézt na adrese{' '}
                    <a href="www.hotjar.com/privacy" target="_blank">
                        www.hotjar.com/privacy
                    </a>
                    .
                </p>

                <h3>Jak upravit využívání cookie</h3>
                <p>
                    Využívání cookie lze upravit podle toho, jak potřebujete (např. je můžete
                    vymazat). Můžete vymazat všechny cookie, které jsou již na vašem počítači a
                    většina prohlížečů také nabízí možnost zabránit tomu, aby byly cookie na váš
                    počítač ukládány. Pokud však tuto možnost využijete, budete zřejmě muset
                    manuálně upravovat některé preference při každé návštěvě daných stránek a nelze
                    ani vyloučit omezení některých služeb a funkcí stránek. V případě, že si
                    Uživatel nepřeje, aby byla data o jeho návštěvách pomocí nástroje Hotjar
                    shromažďována, má možnost využít tzv. „Do Not Track“ hlavičky, a to pomocí
                    nastavení svého internetového prohlížeče. Bližší informace jsou dostupné na
                    adrese{' '}
                    <a href="www.hotjar.com/opt-out." target="_blank">
                        www.hotjar.com/opt-out
                    </a>
                    .
                </p>

                <h3>Zásady ochrany soukromí Google Analytics</h3>
                <p>
                    Tato stránka používá službu Google Analytics, poskytovanou společností Google,
                    Inc. (dále jen "Google").
                </p>

                <p>
                    Služba Google Analytics používá souborů "cookies", které jsou textovými soubory
                    ukládanými do vašeho počítače umožňující analýzu způsobu užívání této stránky
                    jejími uživateli.
                </p>
                <p>
                    Informace vygenerované souborem cookie o užívání stránky (včetně vaší IP adresy)
                    bude společností Google přenesen a uložen na serverech ve Spojených státech.
                </p>
                <p>
                    Google bude užívat těchto informací pro účely vyhodnocování užívání stránky a
                    vytváření zpráv o její aktivitě, určených pro její provozovatele, a pro
                    poskytování dalších služeb týkajících se činností na stránce a užívání internetu
                    vůbec.
                </p>

                <p>
                    Google může také poskytnout tyto informace třetím osobám, bude-li to požadováno
                    zákonem nebo budu-li takovéto třetí osoby zpracovávat tyto informace pro Google.
                </p>
                <p>
                    Google nebude spojovat vaší IP adresu s jakýmikoli jinými daty, která má k
                    dispozici.
                </p>
                <p>
                    Můžete odmítnout používání souborů cookies volbou v příslušném nastavení ve
                    vašem prohlížeči, avšak uvědomte si, že jestliže tak učiníte, tak nebudete
                    schopni plně využívat veškeré funkce této stránky. Používáním této stránky
                    souhlasíte se zpracováváním údajů o vás společností Google, a to způsobem a k
                    účelu shora uvedeným.
                </p>
                <p>
                    Dodavatelé třetích stran včetně společnosti Google používají soubory cookie k
                    zobrazování reklam na základě předchozích návštěv uživatele na tomto webu.
                </p>
                <p>
                    Díky souboru cookie DoubleClick může společnost Google a její partneři
                    zobrazovat reklamy uživatelům na základě jejich návštěv na vašich webových
                    stránkách a dalších stránkách na internetu.
                </p>

                <p>
                    Uživatelé si mohou používání souboru cookie DoubleClick pro zájmově orientované
                    reklamy odhlásit prostřednictvím
                    <a href="https://adssettings.google.com/"> Nastavení reklam</a>
                </p>
            </Layout.Container>
        </Fragment>
    );
}
