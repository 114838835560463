import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { oc } from 'ts-optchain';

import { DatePicker, Select } from '../../../../../../../../common/components';
import { JournalBenefit } from '../../../../../../../../common/models/Journal';
import { Product } from '../../../../../../../../common/models/Product';
import { i18n } from '../../../../../../../../common/services/i18n';
import { SelectLabel } from '../SelectLabel';
import { VariantsDropdown, VariantsSelection } from '../VariantsDropdown';
import { Season } from '../../../../../../../../common/models/AppState';
import { environment } from '../../../../../../../../environments/environment';
import {
    isProductAndAnyVariantVisible,
    isProductValidAtDate
} from '../../../../../../../../common/utils/visibility-helpers';
import { EntryBase, TransactionDryRunBenefit } from '../../../../../../../../common/models/NewTransaction';

interface Props {
    season?: Season;
    category?: Product;
    product: string;
    arrival: string;
    products: Product[];
    variantsDropdown: boolean;
    badges: TransactionDryRunBenefit[];
    entries: EntryBase[];
    singleVariants: Record<string, EntryBase>;

    setVariants(values: VariantsSelection[]): void;
    setProduct(product: string): void;
    validateSingleVariants(): void;
    setDate(date): void;
    onVariantsClose?: () => void;
}

function FirstStep(props: Props) {
    const [datepickerVisibility, setDatePickerVisibility] = useState<boolean>(false);

    function selectActiveProduct(): Product {
        return props.products.find(item => item._id === props.product) as Product;
    }

    function handleProductSelect(value) {
        if (value) {
            props.setProduct(value);
            setDatePickerVisibility(true);
        }
    }

    function getMomentValue() {
        if (!props.product) {
            return undefined;
        } else if (props.arrival) {
            return moment(props.arrival).isValid() ? moment(props.arrival) : moment();
        }

        return undefined;
    }

    function getAllowedRanges(current) {
        return environment.getGlobalDisabledDates(current) || !isProductValidAtDate(selectActiveProduct(), current);
    }

    function getSelectOptions() {
        return props.products
            .filter(product =>
                oc(product).parents([]).includes(oc(props.category)._id('')) &&
                oc(product).season([]).includes(oc(props.season)._id('')) &&
                isProductAndAnyVariantVisible(product)
            )
            .sort(
                (a, b) =>
                    oc(a).placement.ranks({})[oc(props.category)._id('')].rank -
                    oc(b).placement.ranks({})[oc(props.category)._id('')].rank
            )
            .map(product => ({
                label: <SelectLabel product={product}/>,
                value: product._id,
            }));
    }

    function getSecondColumn() {
        return props.variantsDropdown ?
            (
                <VariantsDropdown
                    season={props.season}
                    badges={props.badges}
                    product={selectActiveProduct()}
                    onChange={props.setVariants}
                    entries={props.entries}
                    singleVariants={props.singleVariants}
                    disabled={!props.product}
                    open={datepickerVisibility}
                    onOpenChange={setDatePickerVisibility}
                    onDropdownOpen={props.validateSingleVariants}
                    onClose={props.onVariantsClose}
                />
            ) :
            (
                <DatePicker
                    open={datepickerVisibility}
                    onOpenChange={setDatePickerVisibility}
                    disabledDate={getAllowedRanges}
                    disabled={!props.product}
                    placeholder={i18n.t('ticketSelect.date.placeholder')}
                    label={i18n.t('ticketSelect.date.label')}
                    style={{ width: '100%' }}
                    value={getMomentValue()}
                    onChange={props.setDate}
                />
            );
    }

    return (
        <Row gutter={16}>
            <Col xs={24} md={12}>
                <Select
                    onChange={handleProductSelect}
                    placeholder={i18n.t('ticketSelect.product.placeholder')}
                    label={i18n.t('ticketSelect.product.label')}
                    value={props.product}
                    options={props.category ? getSelectOptions() : []}
                    style={{ width: '100%' }}
                    notFoundContent={i18n.t('ticketSelect.product.empty')}
                />
            </Col>
            <Col xs={24} md={12}>
                {getSecondColumn()}
            </Col>
        </Row>
    );
}

export default FirstStep;
