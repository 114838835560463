import { Season } from '../../common/models/AppState';
import { apiClient } from '../../common/services/api-client';

export const api = {
    getSeasonCategories: (season: Season) =>
        apiClient.get(
            `/products`,
            {
                params: {
                    type: 'CATEGORY',
                    $limit: -1,
                    $sort: {
                        'placement.row': 1,
                    },
                    season: season._id,
                }
            }
        ),
    getSeasons: () => apiClient.get('/season?parent=null&config=eshop'),
    getProducts: (seasons: Season[]) =>
        apiClient.get(
            `/products`,
            {
                params: {
                    type: 'PRODUCT',
                    $limit: -1,
                    season: {
                        $in: seasons.map(s => s._id)
                    },
                    'validity.to': {$gt: new Date()},
                }
            }
        ),
    getProductById: (id: string) => apiClient.get(`/products?_id=${id}`),
    getAllProductsVariants: (seasons: Season[]) =>
        apiClient.get(
            `/products`,
            {
                params: {
                    type: 'VARIANT',
                    $limit: -1,
                    season: {
                        $in: seasons.map(s => s._id)
                    },
                    'validity.to': {$gt: new Date()},
                }
            }
        ),
    userEmailCount: email => apiClient.get(`/users/count?email=${email.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')}`),
    getBanners: () =>
        apiClient.get(
            `/banner`,
            {
                params: {
                    select: ['eshop_banner', 'id', 'title', 'slug', 'isExternal'],
                    status: 'active',
                    visibleOnHomepage: { $ne: 'false' },
                    $sort: { updatedAt: 1 }
                }
            }
        ),
    getConfig: () => apiClient.get(`/content?key=eshop_config`),
    getBannerDetails: (slug: string) =>
        apiClient.get(`/banner`, {
            params: {
                slug: slug,
                status: 'active',
            },
        }),
    getTimeSlots: (dates: string[], groupId: string) =>
        apiClient.get(`/timeslots`, {
            params: { dates, groupId },
        }),
};
