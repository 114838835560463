import React, { useState, ReactNode, Fragment, useEffect } from 'react';
import cn from 'classnames';
import { Season } from '../../models/AppState';
import { oc } from 'ts-optchain';

interface Props {
    season: Season;
    tabs?: any[];
    onChange?: (index: number) => void;
    tabRender?: (tab: any) => ReactNode;
    children?: (tab: any) => ReactNode;
}

export default function Tabs(props: Props) {
    const [activeTab, setActiveTab] = useState<number>(0);

    useEffect(
        () => {
            props.onChange && props.onChange(activeTab);
        },
        [activeTab]
    );

    return (
        <Fragment>
            <div
                className={cn('tabs', {
                    'tabs--summer': props.season.type === 'summer',
                    'tabs--winter': props.season.type === 'winter',
                })}
            >
                <div className="tabs-content">
                    {oc(props)
                        .tabs([])
                        .sort((a, b) => (a.placement && a.placement.order || 9999) - (b.placement && b.placement.order || 9999))
                        .map((tab, index) => {
                            function handleTabClick() {
                                setActiveTab(index);
                            }

                            return (
                                <div
                                    className={cn('tabs-pane', {
                                        'tabs-pane--active': index === activeTab,
                                    })}
                                    key={index}
                                    onClick={handleTabClick}
                                >
                                    {props.tabRender ? props.tabRender(tab) : tab.name}
                                </div>
                            );
                        })}
                </div>
            </div>
            {props.children && props.tabs && props.tabs[0] && (
                <div className="tabs-children">{props.children(oc(props).tabs([])[activeTab])}</div>
            )}
        </Fragment>
    );
}
