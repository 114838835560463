import React, { Fragment } from 'react';

import { Price } from '../../components';

interface Props {
    original: string | number;
    discounted: string | number;
}

export default function CartPrice({ original, discounted }: Props) {
    function renderWithDifference() {
        return (
            <Fragment>
                <div className="cart-price-original">
                    <Price value={original} />
                </div>
                <div className="cart-price-discounted">
                    <Price value={discounted} />
                </div>
            </Fragment>
        );
    }

    function renderWithoutDifference() {
        return (
            <div className="cart-price-no-difference">
                <Price value={discounted} />
            </div>
        );
    }

    return (
        <div className="cart-price">
            {original > discounted ? renderWithDifference() : renderWithoutDifference()}
        </div>
    );
}
