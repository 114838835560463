import React, { createRef, ReactNode } from 'react';

interface Props {
    reference: any;
    children: ReactNode;
}

export function createRefs(refArray) {
    return refArray.reduce((acc, value, index) => {
        acc[index] = createRef();
        return acc;
    }, {});
}

export default function ReferenceWrapper(props: Props) {
    return <div ref={props.reference}>{props.children}</div>;
}
