import { Col, Row } from 'antd';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import { useOrderedNodes } from 'react-register-nodes';
import { oc } from 'ts-optchain';
import * as Yup from 'yup';
import moment from 'moment';
import validator from 'validator';

import { NewPasswordModal } from '../../modals';
import { Button } from '../../components';
import { api } from '../../../features/user/api';
import {
    BirthdayField,
    CheckBoxField,
    InputField,
    RadioField,
    SelectField,
    WtpNumberField,
} from '../../fields';
import { CardNumber, RegisterFormValues } from '../../models/User';
import { countries, i18n } from '../../services/i18n';
import PasswordInput from '../../fields/PasswordInput';
import { environment } from '../../../environments/environment';
import cn from 'classnames';
import { sendGtmEvent } from '../../utils/gtm-helpers';

interface Props {
    initialValues?: Partial<RegisterFormValues>;
    onSubmit?(values: RegisterFormValues): void;
    type?: 'default' | 'in-flow';
    inProgress: boolean;
}

const RegistrationForm: FunctionComponent<Props> = ({
    onSubmit,
    initialValues,
    type = 'default',
    inProgress,
}) => {
    const ordered = useOrderedNodes();
    const [shouldCheckForScroll, setShouldCheckForScroll] = useState(false);
    const [newPasswordModal, setNewPasswordModal] = useState(false);
    let previousAttempt = false;

    const titleOptions = environment.project === 'annaberg' ?
        [
            { label: i18n.t('register.title.options.mr'), value: 'mr' },
            { label: i18n.t('register.title.options.mrs'), value: 'mrs' },
        ] :
        [
            { label: i18n.t('register.title.options.mr'), value: 'mr' },
            { label: i18n.t('register.title.options.mrs'), value: 'mrs' },
            { label: i18n.t('register.title.options.ms'), value: 'ms' },
        ];

    const schema = Yup.object<RegisterFormValues>().shape<RegisterFormValues>({
        email: Yup.string()
            .email(i18n.t('form.errors.email'))
            .required(i18n.t('form.required')),
        password: Yup.string()
            .matches(
                new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'),
                i18n.t('form.errors.password')
            )
            .required(i18n.t('form.required')),
        hasCard: Yup.boolean().required(i18n.t('form.required')),
        cardNumbers: Yup.array().of(
            Yup.object<CardNumber>().shape({
                number: Yup.string()
                    .test(
                        'Is aviable',
                        i18n.t('form.wtp.cantBeUsed'),
                        (token: string) => {
                            return new Promise(async (resolve, reject) => {
                                if (token) {
                                    if (!token.match(/^[A-Z0-9]{8}-[A-Z0-9]{3}-[A-Z0-9]{3}/g)) {
                                        resolve(false);
                                    }

                                    try {
                                        const { data } = await api.verifyToken(token);
                                        const [card] = data;

                                        if (card && card.owner && !['anonymous', 'unregistered'].includes(card.owner)) {
                                            // Card already in use
                                            resolve(false);
                                        } else if (!card) {
                                            // Card doesn't exists
                                            resolve(false);
                                        } else {
                                            // Card ready to use
                                            resolve(true);
                                        }
                                    } catch (e) {
                                        // Unable to validate card
                                        resolve(false);
                                    }
                                } else {
                                    resolve(true);
                                }
                            });
                        }
                    ),
                birthday: environment.config.addCardType
                    ? Yup.string()
                        .when(
                            'number',
                            {
                                is: (val) => val !== '',
                                then: Yup
                                    .string()
                                    .required(i18n.t('form.required'))
                                    .test(
                                        'IsDateValid',
                                        i18n.t('form.dateValid'),
                                        value => value &&
                                            !value.includes('_') &&
                                            moment(value, 'DD.MM.YYYY').isValid() &&
                                            moment(value, 'DD.MM.YYYY').isBefore(moment())
                                    ),
                                otherwise: Yup
                                    .string,
                            }
                        )
                    : Yup.string(),
                birthdayEnabled: Yup.boolean()
            }),
        ),
        title: Yup.string().required(i18n.t('form.required')),
        firstName: Yup.string().required(i18n.t('form.required')),
        lastName: Yup.string().required(i18n.t('form.required')),
        postalCode: Yup.string().max(10, i18n.t('form.postalCode')).required(i18n.t('form.required')),
        country: Yup.string().required(i18n.t('form.required')),
        city: Yup.string().required(i18n.t('form.required')),
        birthday: Yup.string()
            .required(i18n.t('form.required'))
            .test(
                'IsDateValid',
                i18n.t('form.dateValid'),
                value => value &&
                    !value.includes('_') &&
                    moment(value, 'DD.MM.YYYY').isValid() &&
                    moment(value, 'DD.MM.YYYY').isBefore(moment())
            ),
        agreements: Yup.boolean().required(i18n.t('form.required')),
        vop: environment.config.registerVOPCheckbox
            ? Yup.boolean()
                .required(i18n.t('form.required'))
                .oneOf([true], i18n.t('form.required'))
            : Yup.boolean(),
    });

    useEffect(
        () => {
            if (shouldCheckForScroll && ordered.length > 0) {
                smoothScrollIntoView(ordered[0], {
                    scrollMode: 'if-needed',
                    block: 'center',
                    inline: 'start',
                }).then(() => {
                    // @ts-ignore
                    ordered[0].querySelector('input').focus();
                    setShouldCheckForScroll(false);
                });
            }
        },
        [shouldCheckForScroll, ordered]
    );

    function handleSubmit(values: RegisterFormValues) {
        onSubmit && onSubmit(values);
    }

    async function handleUnsuccessfulRegistration(formikBag: RegisterFormValues) {
        try {
            const tmp = await schema.validate(formikBag);
        } catch (e) {
            return false;
        }
        return true;
    }

    function sendUnsuccessfulTag() {
        sendGtmEvent({
            event: 'registration',
            action: 'unsuccessful',
            success: false,
        });
    }

    function renderNewPasswordModal(email) {
        function handleConfirmClick() {
            setNewPasswordModal(false);
        }

        return <NewPasswordModal visible={newPasswordModal} onConfirm={handleConfirmClick} email={email} />;
    }

    function renderForm(formikBag: FormikProps<RegisterFormValues>) {
        function handleSubmit() {
            handleUnsuccessfulRegistration(formikBag.values).then(success => {
                if (!success) {
                    sendUnsuccessfulTag();
                }
            });
            previousAttempt = true;
            setShouldCheckForScroll(true);
            formikBag.submitForm();
        }

        function checkForPassword() {
            if (validator.isEmail(formikBag.values.email)) {
                api.hasPassword(formikBag.values.email).then( res => {
                    if (res.hasOwnProperty('data') && res.data === false) {
                        setNewPasswordModal(true);
                    }
                }).catch(err => {
                    console.error(err.resp);
                });
            }
        }

        function renderCardNumbersArray(arrayHelpers) {

            function filterCardNumberErrors(index) {
                const errors = oc(formikBag).errors.cardNumbers([])
                    .map((e, i) =>
                        i === index ? undefined : e,
                    );

                return {
                    ...formikBag.errors,
                    cardNumbers: errors.length > 0 ? errors : undefined,
                };
            }

            async function validateCardNumber(index, token) {
                const name = `cardNumbers.${index}.number`;

                formikBag.setErrors(filterCardNumberErrors(index));
                formikBag.setFieldValue(`cardNumbers.${index}.birthdayEnabled`, false);
                formikBag.setFieldValue(`cardNumbers.${index}.birthday`, '');

                if (!token.match(/^[A-Z0-9]{8}-[A-Z0-9]{3}-[A-Z0-9]{3}/g)) {
                    return;
                }

                try {
                    const { data } = await api.verifyToken(token);
                    const [card] = data;

                    if (card && card.owner && !['anonymous', 'unregistered'].includes(card.owner)) {
                        formikBag.setFieldError(name, i18n.t('form.wtp.cardHasOwner'));
                    } else if (!card) {
                        formikBag.setFieldError(name, i18n.t('form.wtp.cardDoesNotExist'));
                    } else if (card.birthday && environment.config.addCardType) {
                        formikBag.setFieldValue(
                            `cardNumbers.${index}.birthday`,
                            moment(card.birthday).format('DD.MM.YYYY')
                        );
                    } else if (environment.config.addCardType) {
                        formikBag.setFieldValue(`cardNumbers.${index}.birthdayEnabled`, true);
                    }
                } catch (e) {
                    formikBag.setFieldError(name, i18n.t('form.wtp.unableToValidate'));
                }
            }

            return (
                <>
                    {oc(formikBag).values.cardNumbers([]).map((cardNumber, index) => (
                        <React.Fragment key={index}>
                            <div className="wtp-row">
                                <div className="wtp-row__col wtp-row__col--grow">
                                    <div className="wtp-row wtp-row--wrap">
                                        <div
                                            className={cn('wtp-row__col', {
                                                'wtp-row__col--card': environment.config.addCardType && type !== 'in-flow',
                                                'wtp-row__col--card-half': environment.config.addCardType && type === 'in-flow',
                                                'wtp-row__col--grow': !environment.config.addCardType,
                                            })}
                                        >
                                            <WtpNumberField
                                                name={`cardNumbers.${index}.number`}
                                                error={oc(formikBag).errors.cardNumbers[index]({}).number}
                                                label={i18n.t('register.lipnoCardNumber.label')}
                                                placeholder={i18n.t('register.lipnoCardNumber.placeholder')}
                                                onKeyUp={v => validateCardNumber(index, v.target.value)}
                                            />
                                        </div>
                                        {environment.config.addCardType &&
                                            <div className="wtp-row__col wtp-row__col--grow">
                                                <BirthdayField
                                                    name={`cardNumbers.${index}.birthday`}
                                                    placeholder={i18n.t('register.lipnoCardNumber.birthdayPlaceholder')}
                                                    disabled={!cardNumber.birthdayEnabled}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                                {oc(formikBag).values.cardNumbers([]).length > 1 &&
                                    <div className="wtp-row__col wtp-row__col--delete">
                                        <a
                                            className="wtp-row__btn"
                                            onClick={() => arrayHelpers.remove(index)}
                                        >
                                            {i18n.t('register.lipnoCardNumber.remove')}
                                        </a>
                                    </div>
                                }
                            </div>
                            {cardNumber.birthdayEnabled &&
                                <div className="wtp-row wtp-row__description">
                                    {i18n.t('register.lipnoCardNumber.addBirthday')}
                                </div>
                            }
                        </React.Fragment>
                    ))}
                    <div
                        className={cn('wtp-row', {
                            'wtp-row--half': environment.config.addCardType &&
                                type === 'in-flow' &&
                                oc(formikBag).values.cardNumbers([]).length > 1,
                        })}
                    >
                        <div
                            className={cn('wtp-row__col', {
                                'wtp-row__col--card': environment.config.addCardType && type !== 'in-flow',
                                'wtp-row__col--card-half': environment.config.addCardType && type === 'in-flow',
                                'wtp-row__col--grow': !environment.config.addCardType,
                            })}
                        >
                            <div
                                className={cn('wtp-row__add', {
                                    'wtp-row__add--no-date': !environment.config.addCardType &&
                                        oc(formikBag).values.cardNumbers([]).length > 1,
                                    'wtp-row__add--more': environment.config.addCardType &&
                                        oc(formikBag).values.cardNumbers([]).length > 1,
                                })}
                                onClick={() => {
                                    arrayHelpers.push({number: '', birthday: ''});
                                    formikBag.setErrors(filterCardNumberErrors(oc(formikBag).values.cardNumbers([]).length));
                                }}
                            >
                                {i18n.t('register.lipnoCardNumber.add')}
                            </div>
                        </div>
                    </div>
                </>
            );
        }

        return (
            <Form>
                 {renderNewPasswordModal(formikBag.values.email)}
                <div className="registration-form">
                    <Row>
                        <Col span={24}>
                            <InputField
                                name="email"
                                label={i18n.t('register.email.label')}
                                placeholder={i18n.t('register.email.placeholder')}
                                onBlur={checkForPassword}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <PasswordInput
                                label={i18n.t('register.password.label')}
                                placeholder={i18n.t('register.password.placeholder')}
                            />
                        </Col>
                    </Row>
                    {environment.config.registerCardNumber && !oc(initialValues).cardNumbers[0]() && (
                        <Row>
                            <Col span={24}>
                                <RadioField
                                    options={[
                                        {
                                            label: i18n.t('register.lipnoCard.options.yes'),
                                            value: true,
                                        },
                                        {
                                            label: i18n.t('register.lipnoCard.options.no'),
                                            value: false,
                                        },
                                    ]}
                                    name="hasCard"
                                    label={i18n.t('register.lipnoCard.label')}
                                />
                            </Col>
                        </Row>
                    )}
                    {environment.config.registerCardNumber && formikBag.values['hasCard'] && !oc(initialValues).cardNumbers[0]() && (
                        <FieldArray
                            name="cardNumbers"
                            render={renderCardNumbersArray}
                            validateOnChange={false}
                        />
                    )}
                    <Row>
                        <Col span={24}>
                            <RadioField
                                options={titleOptions}
                                name="title"
                                label={i18n.t('register.title.label')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24} md={12}>
                            <InputField
                                name="firstName"
                                label={i18n.t('register.name.label')}
                                placeholder={i18n.t('register.name.placeholder')}
                            />
                        </Col>
                        <Col sm={24} md={12}>
                            <InputField
                                name="lastName"
                                label={i18n.t('register.surname.label')}
                                placeholder={i18n.t('register.surname.placeholder')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24} md={12}>
                            <SelectField
                                options={countries}
                                priorityOptions={environment.config.priorityCountriesOptions}
                                name="country"
                                label={i18n.t('register.country.label')}
                                placeholder={i18n.t('register.country.placeholder')}
                                noFoundContent={i18n.t('register.country.noContent')}
                            />
                        </Col>
                        <Col sm={24} md={12}>
                            <InputField
                                name="city"
                                label={i18n.t('register.city.label')}
                                placeholder={i18n.t('register.city.placeholder')}
                            />
                        </Col>
                        <Col sm={24} md={12}>
                            <InputField
                                name="postalCode"
                                label={i18n.t('register.postalCode.label')}
                                placeholder={i18n.t('register.postalCode.placeholder')}
                            />
                        </Col>
                        <Col sm={24} md={12}>
                            <BirthdayField
                                name="birthday"
                                label={i18n.t('register.dateOfBirth.label')}
                                placeholder={i18n.t('register.dateOfBirth.placeholder')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <CheckBoxField
                                name="agreements"
                                label={<span dangerouslySetInnerHTML={{__html: i18n.t('register.agreements.label')}}/>}
                            />
                        </Col>
                    </Row>
                    {environment.config.registerVOPCheckbox &&
                        <Row>
                            <Col span={24}>
                                <CheckBoxField
                                    name="vop"
                                    label={<span dangerouslySetInnerHTML={{ __html: i18n.t('register.vop.label') }}/>}
                                />
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col span={24}>
                            {type === 'default' ? (
                                <Button block disabled={inProgress} onClick={handleSubmit}>
                                    {i18n.t('register.submit')}
                                </Button>
                            ) : (
                                <Button block disabled={inProgress} type="success" onClick={handleSubmit}>
                                    {i18n.t('register.inFlow.submit')}
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <p dangerouslySetInnerHTML={{__html: i18n.t('register.agreementsAddition')}}/>
                </div>
            </Form>
        );
    }
    return (
        <Formik
            key={i18n.language}
            initialValues={{
                email: oc(initialValues).email(''),
                password: '',
                hasCard: oc(initialValues).hasCard(false),
                cardNumbers: oc(initialValues).cardNumbers([{number: '', birthday: ''}]),
                title: oc(initialValues).title(''),
                firstName: oc(initialValues).firstName(''),
                lastName: oc(initialValues).lastName(''),
                country: oc(initialValues).country(''),
                postalCode: oc(initialValues).postalCode(''),
                city: oc(initialValues).city(''),
                birthday: oc(initialValues).birthday(''),
                agreements: false,
                vop: false,
            }}
            onSubmit={handleSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
};

export default RegistrationForm;
