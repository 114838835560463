import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { oc } from 'ts-optchain';
import { Col, Row } from 'antd';
import cn from 'classnames';

import {
    CookieConsent,
    ImageSideDescription,
    Layout,
    Header,
    Footer,
    Savings,
} from '../../common/components';
import { AppState, Season } from '../../common/models/AppState';
import { ProductSelection } from '../../common/models/Cart';
import { JournalBenefit } from '../../common/models/Journal';
import { Product } from '../../common/models/Product';
import {
    fetchProductsActions,
    resetEshopTransactionAction,
    selectCategories,
    selectEshopBadges,
    selectEshopEntries, selectEshopSingleEntries,
    selectEshopTotal,
    selectEshopTotalDiscounted,
    selectProducts,
    selectSeason, SingleVariant,
    validateProductSelectionActions, validateSingleVariantActions,
} from '../shopping/ducks';
import { openCartAction, setCartItemsAction } from '../shopping/ducks/cart';
import { agreeWithCookiesAction, selectCookiesAgreement, selectUserProfile } from '../user/ducks';
import { ShoppingModule } from './components';
import LocalizedString from '../../common/components/LocalizedString';
import { i18n } from '../../common/services/i18n';
import { api } from '../shopping/api';
import { VariantsSelection } from '../shopping/features/eshop/components/ProductSelect/components/VariantsDropdown';
import { environment } from '../../environments/environment';
import { isProductVisible } from '../../common/utils/visibility-helpers';
import DynamicImg from '../../common/components/DynamicImg';
import { CmsContent, EntryBase, TransactionDryRunBenefit } from '../../common/models/NewTransaction';
import { UserProfile } from '../../common/models/User';

interface OwnProps {}

interface StateToProps {
    user: UserProfile;
    products: Product[];
    categories: Product[];
    season: Season;
    total: number;
    totalDiscounted: number;
    badges: TransactionDryRunBenefit[];
    validatedEntries: EntryBase[];
    cookieAgreement: boolean;
    singleVariants: Record<string, EntryBase>;
}

interface DispatchToProps {
    agreeWithCookies(): void;

    fetchProducts?();

    validateSelection: (items: ProductSelection[]) => void;
    validateSingleVariant: (items: SingleVariant) => void;
    resetValidateSelection: () => void;

    setCartItems?(items: ProductSelection[]);

    openCart?();
}

interface BannerPageContent {
    _id: string;
    description: CmsContent;
    eshop_banner: {
        title: CmsContent;
    };
    page_content: {
        title: CmsContent;
        description: CmsContent;
        imgLocation: string;
    }[];
    shopping: {
        title: CmsContent;
        productId: string;
        variants: VariantsSelection[];
    };
    savings: {
        type: string;
        basePrice?: string;
        discountPrice?: string;
        tl?: CmsContent;
        bl?: CmsContent;
        tr?: CmsContent;
        br?: CmsContent;
    };
}

type Props = RouteComponentProps<{slug: string}> & DispatchToProps & StateToProps & OwnProps;

export const BannersComponent: FunctionComponent<Props> = ({
    cookieAgreement,
    fetchProducts,
    agreeWithCookies,
    validateSelection,
    setCartItems,
    openCart,
    history,
    products,
    resetValidateSelection,
    total,
    totalDiscounted,
    badges,
    validatedEntries,
    validateSingleVariant,
    singleVariants,
    match,
    season,
    user,
}) => {
    const [content, setContent] = useState({} as BannerPageContent);

    useEffect(() => {
        if (match.params.slug) {
            api.getBannerDetails(match.params.slug).then(resp => {
                if ((resp as any).ok) {
                    if (!resp.data.length) {
                        history.push('/shopping/eshop');
                        return;
                    }

                    setContent(resp.data[0]);
                }
            });
            fetchProducts && fetchProducts();
        } else {
            history.push('/shopping/eshop');
        }
    }, []);

    function handleSelectionValidate(selection: ProductSelection[]) {
        validateSelection(selection);
    }

    function handleAddToCart(items: ProductSelection[]) {
        setCartItems && setCartItems(items);
        openCart && openCart();
    }

    function handleAgreeCookies() {
        agreeWithCookies();
    }

    function handleSingleVariantsValidate(product: Product, arrival: string) {
        oc(product)
            .variants([])
            .filter(v => isProductVisible(v))
            .forEach(v =>
                validateSingleVariant({variant: v._id, arrival: arrival})
            );
    }

    function selectActiveProduct(): Product | undefined {
        return content.shopping && content.shopping.productId
            ? products.find(item => item._id === content.shopping.productId)
            : undefined;
    }

    function isTimeslotted(): boolean {
        return oc(selectActiveProduct()).hasTimeSlots(false) as boolean;
    }

    function renderDiscountBanner() {
        return (
            <React.Fragment>
                <div className="banner-section">
                    <Row type="flex" justify="center">
                        <Col span={24}>
                            <h3>
                                <LocalizedString html={content.eshop_banner.title} />
                            </h3>
                            <p style={{ maxWidth: '400px', margin: 'auto' }}>
                                <LocalizedString html={content.description} />
                            </p>
                        </Col>
                    </Row>
                </div>
                <div className="banner-saving-section">
                    <Row type="flex" justify="center">
                        <Col sm={24} md={8}>
                            <Savings
                                type={content.savings.type}
                                basePrice={content.savings.basePrice}
                                discountPrice={content.savings.discountPrice}
                                text={{
                                    tl: content.savings.tl,
                                    bl: content.savings.bl,
                                    tr: content.savings.tr,
                                    br: content.savings.br,
                                }}
                                className={'-banner'}
                            />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }

    function renderContent() {
        return (
            <Fragment>
                <Layout.Container>
                    <div className="content-section banner-content">
                        {content.page_content.map((i, index) => (
                            <ImageSideDescription
                                key={index}
                                title={<LocalizedString html={i.title} />}
                                description={<LocalizedString html={i.description} />}
                                image={
                                    <DynamicImg
                                        srcs={[
                                            `${environment.mediaUrl}/banner-paragraph/${content._id}-${index}`,
                                            `${environment.mediaUrl}/static/eshop/banners.paragraph.season-${season.type}`,
                                            `${environment.mediaUrl}/static/eshop/banners.paragraph`,
                                            '/images/banner-paragraph.jpg'
                                        ]}
                                        alt={i.title[i18n.language] || ''}
                                        className="rounded-image"
                                    />
                                }
                                imgLocation={i.imgLocation}
                            />
                        ))}
                    </div>
                </Layout.Container>
            </Fragment>
        );
    }

    function renderBackButton() {
        return (
            <React.Fragment>
                <div className="bottomPad">
                    <Layout.Container>
                        <Row type="flex" justify="center">
                            <p className="bottomLink">
                                <Link to={'/eshop/shopping'}>
                                    {i18n.t('aboutLipno.backToHome')}
                                </Link>
                            </p>
                        </Row>
                    </Layout.Container>
                </div>
            </React.Fragment>
        );
    }

    function renderShoppingModule() {
        if (oc(user).roles.b2b() && !environment.config.showB2BAllCategories) {
            return <></>;
        }

        return (
            <div
                className={
                    cn(
                        'banner-shopping banner-content',
                        { 'banner-shopping--timeslotted': isTimeslotted() }
                    )
                }
            >
                <ShoppingModule
                    products={products}
                    productId={content.shopping.productId}
                    onAddToCart={handleAddToCart}
                    onSelectionValidate={handleSelectionValidate}
                    onSelectionValidateReset={resetValidateSelection}
                    total={total}
                    totalDiscounted={totalDiscounted}
                    badges={badges}
                    entries={validatedEntries}
                    initialSelection={oc(content).shopping.variants([])}
                    title={oc(content).shopping.title({})}
                    singleVariants={singleVariants}
                    onSingleVariantsValidate={handleSingleVariantsValidate}
                />
            </div>
        );
    }

    return (
        <React.Fragment>
            {Object.entries(content).length > 0 && (
                <Layout>
                    <Header />
                    <Layout.Content>
                        <div className="banner-subpage-image">
                            <DynamicImg
                                srcs={[
                                    `${environment.mediaUrl}/banner-detail/${content._id}`,
                                    `${environment.mediaUrl}/static/eshop/banners.cover.season-${season.type}`,
                                    `${environment.mediaUrl}/static/eshop/banners.cover`,
                                    '/images/banner-cover.jpg'
                                ]}
                                alt="cover"
                                className="banner-subpage-image__img"
                            />
                            <div className="banner-subpage-image-overlay" />
                        </div>
                        {renderDiscountBanner()}
                        {!!selectActiveProduct() && environment.config.showBannerShoppingModuleOnTop && renderShoppingModule()}
                        <div> {renderContent()} </div>
                    </Layout.Content>
                    <Layout.Content>
                        {!!selectActiveProduct() && !environment.config.showBannerShoppingModuleOnTop && renderShoppingModule()}
                        {renderBackButton()}
                    </Layout.Content>
                    <Footer />
                </Layout>
            )}
        </React.Fragment>
    );
};

const mapDispatchToProps: DispatchToProps = {
    agreeWithCookies: agreeWithCookiesAction,
    fetchProducts: fetchProductsActions.request,
    setCartItems: setCartItemsAction,
    openCart: openCartAction,
    validateSelection: validateProductSelectionActions.request,
    validateSingleVariant: validateSingleVariantActions.request,
    resetValidateSelection: resetEshopTransactionAction,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    user: selectUserProfile(state),
    cookieAgreement: selectCookiesAgreement(state),
    season: selectSeason(state),
    products: selectProducts(state),
    categories: selectCategories(state),
    total: selectEshopTotal(state),
    totalDiscounted: selectEshopTotalDiscounted(state),
    badges: selectEshopBadges(state),
    validatedEntries: selectEshopEntries(state),
    singleVariants: selectEshopSingleEntries(state),
});

export const Banner = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(BannersComponent)
);
