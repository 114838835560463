import React, { FunctionComponent, useState } from 'react';

import { Select } from '../../../../components';
import { i18n, locales } from '../../../../services/i18n';
import { environment } from '../../../../../environments/environment';

const LanguagePicker: FunctionComponent = () => {
    const [language, setLanguage] = useState<string>(i18n.language || 'cz');

    function handleLanguageChange(value) {
        i18n.changeLanguage(value);
        setLanguage(value);
    }

    const getFlagLocale = (locale) => {
        if (locale === 'de' && environment.project === 'annaberg') {
            return 'at';
        }

        return locale;
    };

    return (
        <Select
            dropdownClassName="language-picker-dropdown"
            className="language-picker"
            dropdownMatchSelectWidth={false}
            value={language}
            onChange={handleLanguageChange}
            options={locales
                .filter(l => environment.languages.includes(l.locale))
                .map(l => ({
                    label: (
                        <>
                            {/*<span className={`flag-icon flag-icon-${getFlagLocale(l.locale)}`} />*/}
                            <span className={`language-picker__icon language-picker__icon-${getFlagLocale(l.locale)}`} />
                            <span className="language-picker__label-text">
                                {i18n.t(`languages.${l.locale === 'en' ? 'gb' : l.locale}`)}
                            </span>
                        </>
                    ),
                    value: l.locale,
                }))
            }
        />
    );
};

export default LanguagePicker;
