import React, { Component } from 'react';

interface State {
    hasError: boolean;
}

interface Props {}

export class ErrorBoundary extends Component<Props, State> {
    state = {
        hasError: false,
    };

    componentDidCatch(_error: any) {
        // TODO: Handle error with some error service
        this.setState({ hasError: true });
    }

    render() {
        return this.state.hasError ? (
            <div className="container">
                <div className="ant-row">
                    <h1 className="ant-col-24">500 : Unexpected Error</h1>
                    <p>
                        <a href="/">Back</a>
                    </p>
                </div>
            </div>
        ) : (
            this.props.children
        );
    }
}
