import { Col, Row } from 'antd';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { oc } from 'ts-optchain';
import uuid from 'uuid/v4';

import {
    Button,
    DatePicker,
    LocalizedString,
    Price,
    TotalPrice,
    ProductValidity,
} from '../../../../common/components';
import { ProductSelection } from '../../../../common/models/Cart';
import { JournalBenefit } from '../../../../common/models/Journal';
import { Product } from '../../../../common/models/Product';
import { i18n } from '../../../../common/services/i18n';
import {
    VariantsDropdown,
    VariantsSelection,
} from '../../../shopping/features/eshop/components/ProductSelect/components/VariantsDropdown';
import { TimeSlotType } from '../../../shopping/ducks';
import {
    findNextEnabledDate,
    isProductAndSelectionValidAtDate,
    isProductValidAtDate
} from '../../../../common/utils/visibility-helpers';
import { environment } from '../../../../environments/environment';
import TimeCarousel from '../../../shopping/features/eshop/components/ProductSelect/components/TimeCarousel';
import { CmsContent, EntryBase, TransactionDryRunBenefit } from '../../../../common/models/NewTransaction';

interface Props {
    products: Product[];
    productId: string;
    total: number;
    totalDiscounted: number;
    badges: TransactionDryRunBenefit[];
    entries: EntryBase[];
    onAddToCart: (items: ProductSelection[]) => void;
    onSelectionValidate: (items: ProductSelection[]) => void;
    onSelectionValidateReset: () => void;
    initialSelection: VariantsSelection[];
    title: CmsContent;
    singleVariants: Record<string, EntryBase>;
    onSingleVariantsValidate: (product: Product, arrival: string) => void;
}

function ShoppingModule(props: Props) {
    const [datepickerVisibility, setDatePickerVisibility] = useState<boolean>(false);
    const [arrival, setDate] = useState<string>('');
    const [oldArrival, setOldDate] = useState<string>('');
    const [time, setTime] = useState<string>('');
    const [timeSlotType, setTimeSlotType] = useState<TimeSlotType | undefined>(undefined);

    const [variants, setVariants] = useState<VariantsSelection[]>([]);
    const [submit, setSubmit] = useState<boolean>(false);

    const activeProduct = props.products.find(item => item._id === props.productId) || props.products[0];
    const isTimeslot = !!oc(activeProduct).hasTimeSlots();
    const isTimeSlotBase = isTimeslot || !!oc(activeProduct).dateLineView();

    useEffect(
        () => {
            if (arrival) {
                props.onSelectionValidate(selectProductSelection());
            }
            setSubmit(false);
        },
        [variants, arrival, time]
    );

    useEffect(
        () => {
            setTime('');
            setTimeSlotType(undefined);
        },
        [arrival]
    );

    useEffect(
        () => {
            function allowedFn(current) {
                return environment.getGlobalDisabledDates(current) ||
                    !isProductAndSelectionValidAtDate(
                        activeProduct,
                        props.initialSelection.filter(v => v.count).map(v => v.id),
                        current
                    );
            }

            const d = findNextEnabledDate(allowedFn, moment(oc(activeProduct).validity.to()));
            const date = d ? d.format() : '';

            setVariants(props.initialSelection.filter(vs =>
                isProductValidAtDate(oc(activeProduct).variants([]).find(v => vs.id === v._id), date)
            ));
            setDate(date);
        },
        [props.initialSelection]
    );

    useEffect(
        () => {
            if (getAllowedRanges(moment(arrival))) {
                const d = findNextEnabledDate(getAllowedRanges, moment(oc(activeProduct).validity.to()));
                const date = d ? d.startOf('day').format() : '';

                if (date !== arrival) {
                    setDate(date);
                    setTime('');
                    setTimeSlotType(undefined);
                }
            }
        },
        [variants]
    );

    function getAllowedRanges(current) {
        return environment.getGlobalDisabledDates(current) ||
            !isProductAndSelectionValidAtDate(
                activeProduct,
                variants.filter(v => v.count).map(v => v.id),
                current
            );
    }

    const validateSingleVariants = () => {
        if (arrival !== oldArrival) {
            setOldDate(arrival);
            props.onSingleVariantsValidate(activeProduct, arrival);
        }
    };

    function selectProductSelection(): ProductSelection[] {
        return variants.reduce<ProductSelection[]>((a, c) => {
            const arr = new Array(c.count).fill(0);
            const dayCount = oc(activeProduct).meta.tokenValidityUnit() === 'days'
                ? Number(oc(activeProduct).meta.tokenValidityAmount('1'))
                : 1;
            const inclusiveValidity = new Array(dayCount)
                .fill(1)
                .map((_, i) => moment(arrival).add(i, 'days').format('YYYY-MM-DD'));

            const v = arr.map(() => ({
                id: uuid(),
                createdAt: new Date().toISOString(),
                variant: c.id,
                product: props.productId,
                arrival,
                inclusiveValidity,
                time,
                timeSlotType,
                timeSlotGroup: oc(activeProduct).timeSlotGroup(''),
            }));

            a = [...a, ...v];

            return a;
        }, []);
    }

    function handleReset() {
        setVariants(props.initialSelection);
        setSubmit(true);
    }

    function handleAddToCartClick() {
        const data = selectProductSelection();
        props.onAddToCart && props.onAddToCart(data);
        handleReset();
    }

    function handleDateSelect(value) {
        setDate(value);
        setVariants(props.initialSelection.filter(vs =>
            isProductValidAtDate(oc(activeProduct).variants([]).find(v => vs.id === v._id), value)
        ));
    }

    function renderItemValidity() {
        return (oc(activeProduct).tags([]) as any).includes('seasonal') ? (
            i18n.t('cartItem.seasonal')
        ) : (
            <Fragment>
                {i18n.t('checkout.validity')}{' '}
                <ProductValidity
                    from={arrival}
                    unit={oc(activeProduct).meta.tokenValidityUnit('')}
                    validityAmount={Number(oc(activeProduct).meta.tokenValidityAmount() || 1)}
                />
            </Fragment>
        );
    }

    function renderInfo() {
        return (
            <div className="cart-detail">
                <Row gutter={16} type="flex" justify="center">
                    <Col xs={32} md={24} className="item-1">
                        <h5>{renderItemValidity()}</h5>
                        <p>
                            <LocalizedString value={activeProduct.descriptionDetail} />
                        </p>
                    </Col>
                </Row>
                <Row gutter={16} type="flex" justify="center">
                    <Col xs={24} md={12} className="item-1">
                        <TotalPrice
                            type="positive"
                            pricePosition="center"
                            withDiscount={<Price value={props.totalDiscounted} />}
                            badges={props.badges}
                            withoutDiscount={
                                <Fragment>
                                    {i18n.t('ticketSelect.priceWithoutDiscount')}{' '}
                                    <Price value={props.total} />
                                </Fragment>
                            }
                            displayDiscount={props.totalDiscounted !== props.total}
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    function getMomentValue() {
        if (!props.productId) {
            return undefined;
        } else {
            if (arrival && !getAllowedRanges(arrival)) {
                return moment(arrival);
            } else {
                return findNextEnabledDate(getAllowedRanges, moment(oc(activeProduct).validity.to()));
            }
        }
    }

    function isButtonDisabled(): boolean {
        return variants.length === 0 ||
            !arrival ||
            !isProductAndSelectionValidAtDate(activeProduct, variants.map(vs => vs.id), arrival) ||
            (isTimeslot && !time);
    }

    function renderFirstStep() {
        return (
            <Fragment>
                <div className="shopping-section">
                    <Row gutter={16} type="flex" justify="center">
                        <Col xs={32} md={24}>
                            <h3>
                                <LocalizedString value={props.title} />
                            </h3>
                            <p>
                                <LocalizedString value={activeProduct.name} /><br/>
                                <LocalizedString value={activeProduct.description} />
                            </p>
                        </Col>
                    </Row>
                </div>
                {!isTimeSlotBase &&
                    <Row gutter={16} type="flex" justify="center">
                        <Col xs={24} md={24} className="item-1">
                            <DatePicker
                                open={datepickerVisibility}
                                onOpenChange={setDatePickerVisibility}
                                disabledDate={getAllowedRanges}
                                disabled={!props.productId}
                                placeholder={i18n.t('ticketSelect.date.placeholder')}
                                label={i18n.t('ticketSelect.date.label')}
                                value={getMomentValue()}
                                onChange={handleDateSelect}
                            />
                        </Col>
                    </Row>
                }
                <Row gutter={16} type="flex" justify="center">
                    <Col xs={24} md={24} className="item-1">
                        <VariantsDropdown
                            badges={props.badges}
                            product={activeProduct}
                            onChange={setVariants}
                            entries={props.entries}
                            initialState={variants}
                            onSubmit={submit}
                            singleVariants={props.singleVariants}
                            onDropdownOpen={validateSingleVariants}
                            arrival={arrival}
                        />
                    </Col>
                </Row>
                {isTimeSlotBase &&
                    <Row gutter={16} type="flex" justify="center">
                        <Col xs={24} md={24} className="item-1">
                            <TimeCarousel
                                product={activeProduct}
                                selectedVariants={variants}
                                arrival={arrival}
                                setDate={setDate}
                                time={time}
                                variantCount={variants.reduce((acc, v) => acc + v.count, 0)}
                                setTime={setTime}
                                setTimeSlotType={setTimeSlotType}
                                timeSlotGroup={oc(activeProduct).timeSlotGroup('')}
                                onlyDateRow={activeProduct.dateLineView && !isTimeslot}
                                small
                            />
                        </Col>
                    </Row>
                }
                {renderInfo()}
                <Row gutter={16} type="flex" justify="center">
                    <Col xs={24} md={24} className="item-2">
                        <Button
                            disabled={isButtonDisabled()}
                            onClick={handleAddToCartClick}
                            type="success"
                            size="small"
                            block
                        >
                            {i18n.t('ticketSelect.addToCart')}
                        </Button>
                    </Col>
                </Row>
            </Fragment>
        );
    }

    return (
        <>
            {arrival &&
                <div className="package-select">
                    {renderFirstStep()}
                </div>
            }
        </>
    );
}
export default ShoppingModule;
