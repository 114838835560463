import axios from 'axios';
import qs from 'qs';

import { environment } from '../../environments/environment';

export const apiClient = axios.create({
    responseType: 'json',
    baseURL: `${environment.protocol}://${environment.host}`,
    withCredentials: true,
    paramsSerializer: params => {
        return qs.stringify(params);
    },
});

apiClient.interceptors.request.use(config => {
    config.headers.common['Authorization'] = `Basic ${environment.basicAuthToken}`;
    config.headers.common['Cache-Control'] = 'no-cache';
    return config;
});

apiClient.interceptors.response.use(
    response => normalizeSuccessResponse(response),
    error => normalizeErrorResponse(error)
);

function normalizeSuccessResponse(response: any) {
    return {
        ...response,
        ok: true,
    };
}

function normalizeErrorResponse(error: any) {
    return {
        ...error,
        ok: false,
    };
}
