import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import { Footer, Header, Layout } from '../../common/components';

import { CookiesPage } from './features/cookies';
import { GDPRPage } from './features/gdpr';
import { GeneralTermsPage } from './features/general';
import { ClaimTermsPage } from './features/claim';

type Props = RouteComponentProps;

function TermsComponent({ match }: Props) {
    return (
        <Layout>
            <Header />
            <Layout.Content>
                <div className="terms-section">
                    <Switch>
                        <Route path={`${match.path}/cookies`} component={CookiesPage} />
                        <Route exact path={`${match.path}/gdpr`} component={GDPRPage} />
                        <Route exact path={`${match.path}/general`} component={GeneralTermsPage} />
                        <Route exact path={`${match.path}/claim`} component={ClaimTermsPage} />
                        <Redirect path={`${match.path}/`} to={`/`} />
                    </Switch>
                </div>
            </Layout.Content>
            <Footer />
        </Layout>
    );
}

export const Terms = withRouter(TermsComponent);
