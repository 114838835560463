import React, { useMemo } from 'react';
import { oc } from 'ts-optchain';
import { Col, Divider, Row } from 'antd';
import { connect } from 'react-redux';

import { Button, Modal } from '../../components';
import { ModalSizes } from '../../constants/modal';
import { i18n } from '../../services/i18n';
import LocalizedString from '../../components/LocalizedString';
import ProductValidity from '../../components/ProductValidity';
import { AppState } from '../../models/AppState';
import { removeItemFromCartAction, selectCartPopulatedItems } from '../../../features/shopping/ducks/cart';
import { ProductSelectionPopulated } from '../../models/Cart';

const I18N_KEY: Record<string, string> = {
    'invalid-segment': 'invalidProductsModal',
    'missing-method': 'missingMethodProducts',
};

interface Props {
    type: 'invalid-segment' | 'missing-method';
}

const InvalidProductsModal = ({ cartItems, removeFromCartList, type }: Props & StateToProps & DispatchToProps) => {
    const items = useMemo(
        () => cartItems.filter(ci => ci.entryError === type),
        [cartItems]
    );

    if (items.length === 0) {
        return <></>;
    }

    const handleSubmitChanges = () => {
        items.forEach(i => removeFromCartList(i.id));
    };

    return (
        <Modal
            visible
            width={ModalSizes.large}
            title={i18n.t(`${I18N_KEY[type]}.title`)}
            footer={null}
            closable={false}
        >
            <div className="invalid-product-modal">
                <Row>
                    <Col span={24} className="invalid-product-modal__intro">
                        {i18n.t(`${I18N_KEY[type]}.intro`)}
                    </Col>
                </Row>
                {items.map((i, index) => (
                    <React.Fragment key={i.id}>
                        <div className="cart-entry">
                            <div className="cart-entry__desc">
                                {i.product && (
                                    <h3>
                                        <LocalizedString value={i.product.name} /><br/>
                                    </h3>
                                )}
                                <p>
                                    <LocalizedString value={oc(i).variant.name({})} />{' '}
                                    {i.userData && `(${i.userData.firstName} ${i.userData.lastName})`}
                                </p>
                                <p>
                                    {i18n.t('checkout.validity')}{' '}
                                    <ProductValidity
                                        from={i.arrival}
                                        unit={oc(i).product.meta.tokenValidityUnit('')}
                                        validityAmount={Number(oc(i).product.meta.tokenValidityAmount() || 1)}
                                    />
                                </p>
                            </div>
                            <div className="cart-entry__time">
                                {i18n.t(`${I18N_KEY[type]}.itemDescription`)}
                            </div>
                        </div>
                        {index !== items.length - 1 &&
                            <Divider/>
                        }
                    </React.Fragment>
                ))}
                <Button onClick={handleSubmitChanges} className="invalid-product-modal__submit">
                    {i18n.t(`${I18N_KEY[type]}.submit`)}
                </Button>
            </div>
        </Modal>
    );
};

interface DispatchToProps {
    removeFromCartList(id: string);
}

interface StateToProps {
    cartItems: ProductSelectionPopulated[];
}

const mapDispatchToProps: DispatchToProps = {
    removeFromCartList: removeItemFromCartAction.request,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    cartItems: selectCartPopulatedItems(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvalidProductsModal);
