import React, { FunctionComponent, ReactNode } from 'react';

import { i18n } from '../../services/i18n';

interface Props {
    price: ReactNode;
    familyPrice: ReactNode;
    title: string;
    bgImage: string;
    description: string;
}

const TrandingProduct: FunctionComponent<Props> = ({
    title,
    description,
    price,
    bgImage,
    familyPrice,
}) => {
    return (
        <div className="trading-product">
            <img className="trading-product-image" src={bgImage} />
            <div className="trading-product-content">
                <h1>{title}</h1>
                <p>{description}</p>
                <div className="trading-product-prices">
                    <div className="trading-product-price">
                        <div className="trading-product-price-text">
                            {i18n.t('trandingProduct.standartPrice')}
                        </div>
                        <div className="trading-product-price-value">{price}</div>
                    </div>
                    <div className="trading-product-price">
                        <div className="trading-product-price-text">
                            {i18n.t('trandingProduct.familyPrice')}
                        </div>
                        <div className="trading-product-price-value">{familyPrice}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrandingProduct;
