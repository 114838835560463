import { Icon, Tabs } from 'antd';
import cn from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState, EshopSeason, Season } from '../../../../common/models/AppState';
import { i18n } from '../../../../common/services/i18n';
import { selectSeason } from '../../../shopping/ducks';
import { environment } from '../../../../environments/environment';
import DynamicImg from '../../../../common/components/DynamicImg';
const TabPane = Tabs.TabPane;

interface StateToProps {
    season: Season;
}

type Props = RouteComponentProps & StateToProps;

function Subheader({ history, season }: Props) {

    const tabs = [
        {
            name: (
                <Fragment>
                    <Icon type="idcard" />
                    {i18n.t('header.loggedDropdown.lipnoCards')}
                </Fragment>
            ),
            route: 'cards',
            tag: 'cards',
        },
        {
            name: (
                <Fragment>
                    <Icon type="book" />
                    {i18n.t('header.loggedDropdown.myOrders')}
                </Fragment>
            ),
            route: 'orders',
            tag: 'orders',
        },
        {
            name: (
                <Fragment>
                    <Icon type="fall" />
                    {i18n.t('header.loggedDropdown.discountCenter')}
                </Fragment>
            ),
            route: 'discounts',
            tag: 'discounts',
        },
        {
            name: (
                <Fragment>
                    <Icon type="setting" />
                    {i18n.t('header.loggedDropdown.settings')}
                </Fragment>
            ),

            route: 'settings',
            tag: 'settings',
        },
    ].filter(i => environment.config.personalHeaderItems.includes(i.tag));

    const [activeTab, setActiveTab] = useState(tabs[0]);

    useEffect(
        () => {
            setActiveTab(tabs[getActiveTabIndex()]);
        },
        [history.location.pathname]
    );

    function getActiveTabIndex() {
        const lastSlash = history.location.pathname.lastIndexOf('/');
        const relativePath = history.location.pathname.substring(lastSlash + 1);
        return tabs.findIndex(tab => tab.route === relativePath);
    }

    function handleTabChange(key: string) {
        setActiveTab(tabs[Number(key)]);
        history.push(`/personal/${tabs[Number(key)].route}`);
    }

    return (
        <div
            className={cn('personal-subheader', {
                'personal-subheader--summer': season.type === EshopSeason.Summer,
                'personal-subheader--winter': season.type === EshopSeason.Winter,
            })}
        >
            <DynamicImg
                srcs={[
                    `${environment.mediaUrl}/static/eshop/personal.season-${season.type}`,
                    `${environment.mediaUrl}/static/eshop/personal`,
                    '/images/personal.jpg'
                ]}
                alt={activeTab.tag}
                className="personal-subheader__cover"
            />
            <h2 className="personal-subheader__title">{activeTab.name}</h2>
            <div className="personal-subheader__tabs">
                <Tabs
                    type="card"
                    onChange={handleTabChange}
                    activeKey={getActiveTabIndex().toString()}
                >
                    {tabs.map((item, index) => (
                        <TabPane tab={item.name} key={index.toString()} />
                    ))}
                </Tabs>
            </div>
        </div>
    );
}

const mapStateToProps = (state: AppState): StateToProps => ({
    season: selectSeason(state),
});

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(Subheader)
);
