import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import I18nXHR from 'i18next-xhr-backend';
import moment from 'moment';

import 'moment/locale/cs';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/pl';

import cz from 'antd/lib/locale-provider/cs_CZ';
import de from 'antd/lib/locale-provider/de_DE';
import en from 'antd/lib/locale-provider/en_GB';
import pl from 'antd/lib/locale-provider/pl_PL';

import numbro from 'numbro';
import { reactI18nextModule } from 'react-i18next';

import czCountries from '../../resources/locales/cz/countries.json';
import deCountries from '../../resources/locales/de/countries.json';
import enCountries from '../../resources/locales/en/countries.json';
import plCountries from '../../resources/locales/pl/countries.json';

import { environment } from '../../environments/environment';
import { Languages } from '../constants/language';

const COMMON_NAMESPACE = 'common';

interface AppLocale {
    locale: Languages;
    momentLocale: string;
    numbroLocale: string;
}

interface LanguagesConfig {
    [name: string]: {
        momentLocale: string;
        numbroLocale: string;
        localeProvider: any;
        countries: any;
    };
}

const locales: AppLocale[] = [
    {
        locale: Languages.Czech,
        momentLocale: 'cs',
        numbroLocale: 'cs-CZ',
    },
    {
        locale: Languages.English,
        momentLocale: 'en',
        numbroLocale: 'en-US',
    },
    {
        locale: Languages.German,
        momentLocale: 'de',
        numbroLocale: 'en-US',
    },
    {
        locale: Languages.Polish,
        momentLocale: 'pl',
        numbroLocale: 'pl_PL',
    },
];

export const LANGUAGES: LanguagesConfig = {
    [Languages.English]: {
        momentLocale: Languages.English,
        numbroLocale: 'en-US',
        localeProvider: en,
        countries: enCountries,
    },
    [Languages.Czech]: {
        momentLocale: 'cs',
        numbroLocale: 'cs-CZ',
        localeProvider: cz,
        countries: czCountries,
    },
    [Languages.German]: {
        momentLocale: 'de',
        numbroLocale: 'en-US',
        localeProvider: de,
        countries: deCountries,
    },
    [Languages.Polish]: {
        momentLocale: 'pl',
        numbroLocale: 'pl_PL',
        localeProvider: pl,
        countries: plCountries,
    },
};

let countries = Object
    .entries(LANGUAGES[environment.defaultLanguage].countries)
    .map(entry => ({
        value: entry[0],
        label: entry[1] as string,
    }));

const i18nXHR = new I18nXHR(null, {
    crossDomain: true,
    withCredentials: true,
    customHeaders: {
        Authorization: `Basic ${environment.basicAuthToken}`,
    },
    loadPath: () =>
        `${environment.protocol}://${environment.host}/content?key=translations&locale={{lng}}`,
    parse: data => JSON.parse(data)[0].value,
});

// see: https://www.i18next.com/overview/configuration-options
i18next
    .use(LanguageDetector)
    .use(reactI18nextModule)
    .use(i18nXHR)
    .init({
        debug: environment.debug,
        ns: COMMON_NAMESPACE,
        defaultNS: COMMON_NAMESPACE,
        nsSeparator: false,
        returnEmptyString: true,
        detection: {
            order: ['querystring', 'cookie'],
            lookupQuerystring: 'lng',
            lookupCookie: 'lipno-lang',
            caches: ['cookie'],
            cookieMinutes: 525600,
        },
        initImmediate: false,
        whitelist: environment.languages,
        fallbackLng: environment.defaultLanguage,
        react: {
            wait: true,
        },
        returnedObjectHandler: (key, value, options) => {
            const season = window['selectedSeason'];

            if (season && value[`__-${season}-__`] && value[`__-${season}-__`] !== '') {
                return value[`__-${season}-__`];
            }

            return value['__-default-__'];
        }
    });

// TODO: Refactor
moment.locale(LANGUAGES[environment.defaultLanguage].momentLocale);
numbro.setLanguage(LANGUAGES[environment.defaultLanguage].numbroLocale);

if (environment.debug) {
    (window as any).moment = moment;
    (window as any).numbro = numbro;
}

i18next.on('languageChanged', lng => {
    moment.locale(LANGUAGES[lng].momentLocale);
    numbro.setLanguage(LANGUAGES[lng].numbroLocale);
    countries = Object.entries(LANGUAGES[lng].countries).map(entry => ({
        value: entry[0],
        label: entry[1] as string,
    }));
});

(window as any).i18n = i18next;

export { i18next as i18n, locales, countries };
