import { Validity } from '../../models/Benefit';
import { i18n } from '../../services/i18n';
import moment from 'moment';
import React from 'react';

interface Props {
    validity?: Validity;
}

const BenefitValidity = ({ validity }: Props) => {
    if (
        validity && validity.from && validity.to &&
        validity.from !== '1970-01-01T00:00:00.000Z' && validity.to !== '4824-04-07T21:55:47.409Z'
    ) {
        return (
            <>
                {i18n.t('discountCard.validity')}{' '}
                {moment(validity.from).format('DD.MM.YYYY')}
                -
                {moment(validity.to).format('DD.MM.YYYY')}
            </>
        );
    } else {
        return i18n.t('discountCard.withoutValidity');
    }
};

export default BenefitValidity;
