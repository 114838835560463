import { environment } from '../../environments/environment';
import { logoAnnaberg, logoFooter, logoLipno, logoMorava, logoMoravaFooter } from '../../resources/images';

export const getLogo = () => {
    if (environment.project === 'lipno') {
        return logoLipno;
    } else if (environment.project === 'annaberg') {
        return logoAnnaberg;
    } else if (environment.project === 'morava') {
        return logoMorava;
    }

    return logoLipno;
};

export const getFooterLogo = () => {
    if (environment.project === 'lipno') {
        return logoFooter;
    } else if (environment.project === 'annaberg') {
        return logoAnnaberg;
    } else if (environment.project === 'morava') {
        return logoMoravaFooter;
    }

    return logoLipno;
};

export const getCardNumber = (extIdentifiers) => {
    if (extIdentifiers && extIdentifiers[environment.config.ticketSystem]) {
        const {chipId, serialNumber, luhnNumber, wtpNumber} = extIdentifiers[environment.config.ticketSystem];

        return wtpNumber || `${chipId}-${serialNumber}-${luhnNumber}`;
    }

    return '';
};
