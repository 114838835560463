import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { Layout } from '../../../../../components';
import { i18n } from '../../../../../services/i18n';
import { FooterContact, SocialIcons } from '../../components';
import { footerSections } from '../../sections';
import { getFooterLogo } from '../../../../../utils/project-helpers';

const DesktopFooter = () => {
    const getMenuItem = item => {
        if (item.linkKey) {
            return (
                <a href={i18n.t(item.link)} target="_blank">
                    {i18n.t(item.heading)}
                </a>
            );
        }

        if (item.external) {
            return (
                <a href={item.link} target="_blank">
                    {i18n.t(item.heading)}
                </a>
            );
        }

        return <Link to={item.link}>{i18n.t(item.heading)}</Link>;
    };

    return (
        <Layout.Container>
            <Row>
                <Col span={8}>
                    <img className="logo" src={getFooterLogo()} alt=""/>
                    <FooterContact/>
                    <SocialIcons/>
                </Col>
                {footerSections.map((section, index) => (
                    <Col key={index} span={4}>
                        <ul className="desktop-section">
                            <li>{i18n.t(section.heading)}</li>
                            {section.items.map((item, index) => (
                                <li key={index}>
                                    {getMenuItem(item)}
                                </li>
                            ))}
                        </ul>
                    </Col>
                ))}
            </Row>
        </Layout.Container>
    );
};

export default DesktopFooter;
