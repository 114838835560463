import { Dropdown } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';
import cn from 'classnames';
import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import BodyClassName from 'react-body-classname';

import { Drawer } from '../../components';
import { useIsMobileWide } from '../../hooks/useIsMobileWide';

type Props = DropDownProps & { children?: ReactNode; placeholder?: string };

const EnhancedDropdown = ({
    overlay,
    children,
    trigger,
    placeholder = 'Select...',
    visible,
    onVisibleChange,
    ...bag
}: Props) => {
    const isMobile = useIsMobileWide();
    const [overlayVisible, setOverlayVisibility] = useState(false);

    useEffect(
        () => {
            if (visible) {
                setOverlayVisibility(true);
                if (isMobile) {
                    onVisibleChange && onVisibleChange(true);
                }
            } else {
                setOverlayVisibility(false);
                if (isMobile) {
                    onVisibleChange && onVisibleChange(false);
                }
            }
        },
        [visible]
    );

    useEffect(
        () => {
            onVisibleChange && onVisibleChange(overlayVisible);
        },
        [overlayVisible]
    );

    const handleCloseDrawer = () => {
        setOverlayVisibility(false);
    };

    const handleVisibilityChange = (visible: boolean) => {
        if (visible) {
            setOverlayVisibility(visible);
        } else {
            if (!isMobile) {
                setOverlayVisibility(visible);
            }
        }
    };

    const getTrigger = (): ('click' | 'hover' | 'contextMenu')[] => {
        return trigger && trigger.some(t => t === 'click')
            ? ['click']
            : [isMobile ? 'click' : 'hover'];
    };

    const renderMobileOverlay = () => (
        <BodyClassName className={cn({ 'blocked-scroll': overlayVisible })}>
            <Drawer
                visible={overlayVisible}
                onClose={handleCloseDrawer}
                title={placeholder}
                placement="bottom"
                type="dropdownMirror"
                height="auto"
                closable
            >
                {overlay}
            </Drawer>
        </BodyClassName>
    );

    return (
        <Fragment>
            {!isMobile && <BodyClassName className={cn({ 'masked-bg': overlayVisible })} />}
            {isMobile && renderMobileOverlay()}
            <Dropdown
                overlay={isMobile ? <div hidden /> : overlay}
                trigger={getTrigger()}
                visible={overlayVisible}
                onVisibleChange={handleVisibilityChange}
                {...bag}
            >
                <span>{children}</span>
            </Dropdown>
        </Fragment>
    );
};

export default EnhancedDropdown;
