import cn from 'classnames';
import React, { ChangeEvent, Fragment, ReactNode, useEffect, useState } from 'react';

import { Alert, Button, Input } from '../../components';
import { i18n } from '../../services/i18n';

interface Props {
    children?: ReactNode;
    isLogged?: boolean;
    validated?: boolean;
    error?: string | null;
    benefit?: string | null;
    onVerify?: (code: string) => void;
}

const VoucherInput = (props: Props) => {
    const [code, setCode] = useState('');

    const isVerified = () => props.validated && props.benefit && !props.error;
    const isError = () => props.error && !props.benefit;

    useEffect(
        () => {
            if (isVerified()) {
                setCode('');
            }
        },
        [props.benefit, props.validated, props.error]
    );

    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        setCode(e.currentTarget.value);
    }

    function handleInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            handleVerifyClick();
        }
    }

    function handleVerifyClick() {
        props.onVerify && props.onVerify(code);
    }

    function renderFailure() {
        return (
            <Alert
                message={i18n.t(`voucherInputSection.invalid.${props.error}.title`)}
                description={i18n.t(`voucherInputSection.invalid.${props.error}.description`)}
                type="error"
                showIcon
            />
        );
    }

    function renderSuccess() {
        return (
            <Fragment>
                <Alert
                    message={`${i18n.t('voucherInputSection.valid.title')} "${i18n.t(
                        `${props.benefit}.name`
                    )}"`}
                    description={i18n.t('voucherInputSection.valid.description')}
                    type="success"
                    showIcon
                />
                {props.children && props.children}
            </Fragment>
        );
    }

    return (
        <div
            className={cn('voucher-input', {
                'voucher-input--verified': isVerified(),
                'voucher-input--error': isError(),
            })}
        >
            <div className="voucher-input-code">
                <Input
                    placeholder={i18n.t('voucherInput.placeholder')}
                    value={code}
                    onKeyDown={handleInputKeyDown}
                    onChange={handleInputChange}
                />
                {isVerified() ? (
                    <Button onClick={handleVerifyClick} size="small" type="success">
                        {i18n.t('voucherInput.button.verified')}
                    </Button>
                ) : (
                    <Button onClick={handleVerifyClick} size="small">
                        {i18n.t('voucherInput.button.verify')}
                    </Button>
                )}
            </div>
            {props.validated && (
                <div className="voucher-input-content">
                    {isVerified() ? renderSuccess() : renderFailure()}
                </div>
            )}
        </div>
    );
};

export default VoucherInput;
