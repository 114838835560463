import { ProductAssigment } from '../models/Cart';
import { getCardNumber } from './project-helpers';
import { i18n } from '../services/i18n';
import { EntryMethod } from '../models/NewTransaction';

export const formatAssigment = (assigment?: ProductAssigment): string | null => {
    if (!assigment) {
        return null;
    }

    if (assigment.method === EntryMethod.Print) {
        return i18n.t('productAssigment.options.send');
    }

    if (assigment.method === EntryMethod.Prepaid || assigment.method === EntryMethod.Exchange) {
        return i18n.t('productAssigment.options.dontHave');
    }

    if (assigment.method === EntryMethod.Card) {
        if (!assigment.token) {
            return i18n.t('productAssigment.options.dontHave');
        } else {
            return `${i18n.t('cardName')} #${getCardNumber(assigment.token.extIdentifiers)}`;
        }
    }

    return null;
};
