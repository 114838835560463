import { Button } from 'antd';
import { ButtonShape, ButtonSize } from 'antd/lib/button';
import React from 'react';

interface Props {
    type?: 'default' | 'primary' | 'ghost' | 'dashed' | 'danger' | 'success';
    icon?: string;
    shape?: ButtonShape;
    size?: ButtonSize;
    loading?:
        | boolean
        | {
              delay?: number;
          };
    prefixCls?: string;
    className?: string;
    ghost?: boolean;
    block?: boolean;
    children?: React.ReactNode;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
}

// for hover to work on disabled buttons
Button.__ANT_BUTTON = true;

export default ({ type, ...bag }: Props) =>
    type === 'success' ? (
        <Button className="ant-btn-success" {...bag} />
    ) : (
        <Button {...Object.assign(bag, { type })} />
    );
