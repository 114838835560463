import React from 'react';
import { BannerEntry } from '../../models/Banner';
import { RouteComponentProps, withRouter } from 'react-router';
import LocalizedString from '../LocalizedString';
import { environment } from '../../../environments/environment';
import Price from '../Price';
import DynamicImg from '../DynamicImg';
import { AppState, Season } from '../../models/AppState';
import { selectSeason } from '../../../features/shopping/ducks';
import { connect } from 'react-redux';

interface OwnProps {
    banner: BannerEntry;
}

type Props = RouteComponentProps & OwnProps & StateToProps;

function Banner({ season, ...props }: Props) {
    function changeHistory(url) {
        if (props.banner.isExternal) {
            window.open(url, '_blank');
        } else {
            props.history.push(`/banners/${url}`);
        }
    }

    return (
        <div className="banner-container" onClick={() => changeHistory(props.banner.slug)}>
            <div className="banner-container__image-box">
                <DynamicImg
                    srcs={[
                        `${environment.mediaUrl}/banner-hp/${props.banner.id}`,
                        `${environment.mediaUrl}/static/eshop/banners.homepage.season-${season.type}`,
                        `${environment.mediaUrl}/static/eshop/banners.homepage`,
                        '/images/banner-hp.jpg',
                    ]}
                    alt="banner"
                    className="banner-container__bg-image"
                />
            </div>
            <div className="banner-container__content">
                <h4>
                    <LocalizedString value={props.banner.title} />
                </h4>
                <p className="banner-container__description">
                    <LocalizedString value={props.banner.description} />
                </p>
                <div className="banner-container__price-container">
                    {props.banner.basePrice && props.banner.basePriceDescription && (
                        <p className="banner-container__left">
                            <LocalizedString value={props.banner.basePriceDescription} />{' '}
                            <span className="banner-container__base-price">
                                <Price value={props.banner.basePrice} />
                            </span>
                        </p>
                    )}
                    {props.banner.discountPrice && props.banner.discountPriceDescription && (
                        <React.Fragment>
                            <p className="banner-container__right-md">
                                <LocalizedString value={props.banner.discountPriceDescription} />{' '}
                                <span className="banner-container__discount-price">
                                    <Price value={props.banner.discountPrice} />
                                </span>
                            </p>
                            <p className="banner-container__right-xs">
                                <LocalizedString value={props.banner.discountPriceDescription} />{' '}
                                <span className="banner-container__discount-price">
                                    <Price value={props.banner.discountPrice} />
                                </span>
                            </p>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: AppState): StateToProps => ({
    season: selectSeason(state),
});

interface StateToProps {
    season: Season;
}

export default connect(mapStateToProps)(withRouter(Banner));
