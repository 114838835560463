import { Col, Divider, message, Row } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { oc } from 'ts-optchain';

import { AppState } from '../../../../common/models/AppState';
import { ProductSelectionPopulated } from '../../../../common/models/Cart';
import { i18n, LANGUAGES } from '../../../../common/services/i18n';
import {
    CartEntry,
    FeedbackBox,
    Layout,
    PageDescription,
    Price,
} from '../.././../../common/components';
import { Subheader } from '../../components';
import {
    selectCartDiscountPrice,
    selectCartPopulatedItems, validateTransactionActions,
} from '../../ducks/cart';

import {
    submitOrderActions,
    selectCheckoutSubmitting,
} from './ducks';

import { selectAsyncRegisterInProgress, selectUserProfile } from '../../../user/ducks';
import { ChangeTimeslotTimeModal } from '../../../../common/modals/ChangeTimeslotTimeModal';
import { sendGtmEvent } from '../../../../common/utils/gtm-helpers';
import { useLocation } from 'react-use';
import qs from 'qs';
import { history } from '../../../../root/store/history';
import { translationExists } from '../../../../common/utils/language-helpers';
import { UserProfile } from '../../../../common/models/User';
import ZeroPayment from './components/ZeroPayment';
import CardPayment from './components/CardPayment';
import B2BPayment from './components/B2BPayment';
import { isEntryErrorTimeRelated } from '../../../../common/utils/isEntryErrorTimeRelated';
import InvalidProductsModal from '../../../../common/modals/InvalidProductsModal';
import { B2BPaymentType, EntryExchangeMethods } from '../../../../common/models/NewTransaction';
import { formatAssigment } from '../../../../common/utils/formatAssignment';
import { needUserInfoForEntry } from '../../../../common/utils/needUserInfoForEntry';

interface StateToProps {
    user: UserProfile;
    cartItems?: ProductSelectionPopulated[];
    total: number;
    submitting: boolean;
    registerInProgress: boolean;
}

interface DispatchToProps {
    submitOrder: (b2bType?: B2BPaymentType) => void;
    validateTransaction: () => void;
}

type Props = DispatchToProps & StateToProps & RouteComponentProps;

function CheckoutComponent(props: Props) {
    const [timeModal, setTimeModal] = useState<boolean>(false);
    const location = useLocation();
    const error = qs.parse(location.search || '', { ignoreQueryPrefix: true }).error;

    useEffect(() => {
        if (error) {
            message.error(i18n.t('paymentFailed'), 5);
            history.replace('/shopping/checkout');
        }
    }, [error]);

    useEffect(() => {
        sendGtmEvent({
            event: 'page_load',
            page: 'checkout',
            products: props.cartItems ? props.cartItems.map(item => item.product.name.cz) : [],
        });
    }, []);

    useEffect(() => {
        props.validateTransaction();
    }, []);

    useEffect(
        () => {
            if (
                props.cartItems &&
                !timeModal &&
                props.cartItems.some(i => isEntryErrorTimeRelated(i.entryError) || (!!i.timeSlot && !i.timeSlot.available))
            ) {
                setTimeModal(true);
            } else if (
                props.cartItems &&
                timeModal &&
                !props.cartItems.some(i => isEntryErrorTimeRelated(i.entryError) || (!!i.timeSlot && !i.timeSlot.available))
            ) {
                setTimeModal(false);
            }
        },
        [props.cartItems]
    );

    useEffect(
        () => {
            if (oc(props).cartItems([]).length === 0) {
                props.history.push('/shopping/eshop');
            }
        },
        [props.cartItems]
    );

    const handleCardPayment = (b2bType?: B2BPaymentType) => {
        props.submitOrder(b2bType);
    };

    function renderTotalPrice() {
        return (
            <div className="checkout-total-price">
                <span>{i18n.t('checkout.totalPrice')}</span>
                <span>
                    <Price value={props.total} />
                </span>
            </div>
        );
    }

    const isPaymentDisabled = () => {
        return props.registerInProgress ||
            props.submitting ||
            (
                props.cartItems &&
                props.cartItems.some(e =>
                    !!e.entryError ||
                    (!!e.timeSlot && !e.timeSlot.available) ||
                    (needUserInfoForEntry(e) && !e.userData)
                )
            );
    };

    return (
        <Layout.Content>
            <Subheader />
            <Layout.Container>
                <PageDescription
                    title={i18n.t('checkout.title')}
                    description={i18n.t('checkout.description')}
                    withFeedback={translationExists('checkout.generalWarningBanner.title')}
                />
                {translationExists('checkout.generalWarningBanner.title') && (
                    <div className="checkout-feedback">
                        <FeedbackBox
                            type="negative"
                            title={i18n.t('checkout.generalWarningBanner.title')}
                            descriptionHtml={i18n.t('checkout.generalWarningBanner.description')}
                        />
                    </div>
                )}
                {oc(props.user).roles.b2b() && (
                    <div className="checkout-feedback">
                        <FeedbackBox
                            type="info"
                            title={i18n.t('checkout.b2bInvoicingData.title')}
                            description={
                                <>
                                    <p className="b2b-p">{i18n.t('checkout.b2bInvoicingData.description')}</p>
                                    <p>{props.user.firstName} {props.user.lastName}</p>
                                    <p>{props.user.address}</p>
                                    <p>{props.user.postalCode} {props.user.city}</p>
                                    <p>{LANGUAGES[i18n.language].countries[props.user.country || '']}</p>
                                    <p>IČO: {props.user.ico}</p>
                                    <p>DIČ: {props.user.dic}</p>
                                </>
                            }
                        />
                    </div>
                )}
                <InvalidProductsModal type="invalid-segment" />
                <InvalidProductsModal type="missing-method" />
                <ChangeTimeslotTimeModal
                    items={
                        oc(props).cartItems([])
                            .filter(ci => ci.timeSlot || isEntryErrorTimeRelated(ci.entryError))
                    }
                    visible={timeModal}
                />
                {props.cartItems &&
                    props.cartItems.map((item, index) => (
                        <Fragment key={index}>
                            <CartEntry
                                checkout
                                key={index}
                                selection={item}
                                minFormat={['transaction_voucher_product', 'card-product'].includes(item.product.type)}
                                extra={formatAssigment(item.assigment)}
                            />
                            <Divider />
                        </Fragment>
                    ))}
                {props.cartItems &&
                    !props.cartItems.some(item => item.product.type === 'card-product') &&
                    props.cartItems
                        .filter(item => EntryExchangeMethods.includes(oc(item).assigment.method()))
                        .map((item, index) => (
                            <Fragment key={index}>
                                <CartEntry.LipnoCard key={index} />
                                <Divider />
                            </Fragment>
                        ))
                }
                {renderTotalPrice()}
                {props.total > 0 ? (
                    <Row type="flex" gutter={16} justify="end">
                        {oc(props.user).roles.b2b() && oc(props.user).meta.b2bPayments.invoice() && (
                            <Col xs={24} sm={12}>
                                <B2BPayment
                                    type="invoice"
                                    onSubmitPayment={handleCardPayment}
                                    paymentDisabled={isPaymentDisabled()}
                                />
                            </Col>
                        )}
                        {oc(props.user).roles.b2b() && oc(props.user).meta.b2bPayments.order() && (
                            <Col xs={24} sm={12}>
                                <B2BPayment
                                    type="order"
                                    onSubmitPayment={handleCardPayment}
                                    paymentDisabled={isPaymentDisabled()}
                                />
                            </Col>
                        )}
                        {(!oc(props.user).roles.b2b() || oc(props.user).meta.b2bPayments.card()) && (
                            <Col xs={24} sm={12}>
                                <CardPayment
                                    onSubmitPayment={handleCardPayment}
                                    paymentDisabled={isPaymentDisabled()}
                                />
                            </Col>
                        )}
                    </Row>
                ) : (
                    <ZeroPayment
                        onSubmitPayment={handleCardPayment}
                        paymentDisabled={isPaymentDisabled()}
                    />
                )}
            </Layout.Container>
        </Layout.Content>
    );
}

const mapDispatchToProps: DispatchToProps = {
    submitOrder: submitOrderActions.request,
    validateTransaction: validateTransactionActions.request,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    user: selectUserProfile(state),
    cartItems: selectCartPopulatedItems(state),
    total: selectCartDiscountPrice(state),
    submitting: selectCheckoutSubmitting(state),
    registerInProgress: selectAsyncRegisterInProgress(state),
});

export const Checkout = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutComponent);
