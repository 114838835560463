export const categories = [
    {
        title: 'discounts.family.title',
        description: 'discounts.family.description',
        detail: {
            title: 'discounts.family.detail.title',
            description: `discounts.family.detail.description`,
        },
        key: 'voucher',
    },
    {
        title: 'discounts.credit.title',
        description: 'discounts.credit.description',
        detail: {
            title: 'discounts.credit.detail.title',
            description: `discounts.credit.detail.description`,
        },
        key: 'credit',
    },
    {
        title: 'discounts.vip.title',
        description: 'discounts.vip.description',
        detail: {
            title: 'discounts.vip.detail.title',
            description: 'discounts.vip.detail.description',
        },
        key: 'vip',
    },
    {
        title: 'discounts.segment.overview.title',
        description: 'discounts.segment.overview.description',
        detail: {
            title: 'discounts.segment.overview.detail.title',
            description: `discounts.segment.overview.detail.description`,
        },
        key: 'myPartner',
    },
    {
        title: 'discounts.overview.title',
        description: 'discounts.overview.description',
        detail: {
            title: 'discounts.overview.detail.title',
            description: `discounts.overview.detail.description`,
        },
        key: 'partner',
    },
];
