import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';

import {
    CardInput,
    Footer,
    Header,
    Hero,
    Layout,
    LoginValidator,
    PageDescription,
} from '../../common/components';
import { AppState } from '../../common/models/AppState';
import { Token } from '../../common/models/Token';
import { i18n } from '../../common/services/i18n';
import {
    CardValidationState,
    cardVerifyActions,
    cardVerifyResetAction,
    selectCardValidationStatus,
    selectIsLogged,
    selectUserCards,
} from '../user/ducks';
import { getCardNumber } from '../../common/utils/project-helpers';
import AddCardBirthdayModal from '../../common/modals/AddCardBirthdayModal';
import { environment } from '../../environments/environment';

interface DispatchToProps {
    verifyCard: (payload: {
        wtpNumber: string;
        addToUser?: boolean;
        birthday?: string;
        subType?: string;
    }) => void;
    resetVerifyCard: () => void;
}

interface StateToProps {
    isLogged: boolean;
    cards: Token[];
    cardValidation: CardValidationState;
}

type Props = RouteComponentProps & DispatchToProps & StateToProps;

interface CardSubTypeState {
    birthday?: string;
    subType?: string;
}

const StartWithCardComponent = (props: Props) => {
    const [wtpNumber, setWtpNumber] = useState('');
    const [showBirthdayModal, setShowBirthdayModal] = useState(false);
    const [cardSubType, setCardSubType] = useState<CardSubTypeState>({});
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized) {
            props.resetVerifyCard();
        }
    }, [initialized]);

    useEffect(
        () => {
            if (!initialized) {
                return;
            }

            const { verified, card } = props.cardValidation;

            if (props.isLogged && !verified) {
                props.history.push('/personal/cards');
            }

            if (!props.isLogged && verified && card && environment.config.addCardType && (!card.meta.subType || !card.birthday)) {
                setShowBirthdayModal(true);
            }

            if (props.isLogged && verified) {
                if (!props.cards.some(item => getCardNumber(item.extIdentifiers) === wtpNumber)) {
                    const { birthday, subType } = cardSubType;
                    props.verifyCard({ wtpNumber: wtpNumber, addToUser: true, birthday, subType });
                    props.history.push('/personal/cards');
                } else {
                    props.history.push('/personal/cards');
                }
            }
        },
        [props.isLogged, props.cardValidation.verified, props.cardValidation.card, props.cards]
    );

    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
        }
    }, [props.cardValidation]);

    function handleBackButtonClick() {
        props.history.push('/');
    }

    function handleVerifyClick(card: string) {
        props.verifyCard({ wtpNumber: card });
        setWtpNumber(card);
    }

    function handleAddBirthdate(birthday: string, subType: string) {
        setCardSubType({ birthday , subType });
        setShowBirthdayModal(false);
    }

    function handleCancelBirthdate() {
        setShowBirthdayModal(false);
        setWtpNumber('');
        props.resetVerifyCard();
    }

    function renderHeroOverlay() {
        return (
            <PageDescription
                title={i18n.t('cardInputSection.title')}
                description={i18n.t('cardInputSection.description')}
            />
        );
    }

    return (
        <Layout>
            <Header />
            <Layout.Content>
                <AddCardBirthdayModal
                    visible={showBirthdayModal}
                    onCancel={handleCancelBirthdate}
                    onOk={handleAddBirthdate}
                />
                <div className="voucher-input-section">
                    <Hero overlay={renderHeroOverlay()}>
                        <CardInput
                            onVerify={handleVerifyClick}
                            validated={props.cardValidation.validated}
                            card={props.cardValidation.card}
                            error={props.cardValidation.error}
                        >
                            <LoginValidator
                                card={wtpNumber}
                                birthday={moment(cardSubType.birthday).format('DD.MM.YYYY')}
                            />
                        </CardInput>
                    </Hero>
                    <Row type="flex" justify="center">
                        <Col xs={22} md={8}>
                            <Button
                                onClick={handleBackButtonClick}
                                className="voucher-input-section-button"
                                block
                                type="primary"
                            >
                                {i18n.t('cardInputSection.backButton')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Layout.Content>
            <Footer />
        </Layout>
    );
};

const mapDispatchToProps: DispatchToProps = {
    verifyCard: cardVerifyActions.request,
    resetVerifyCard: cardVerifyResetAction,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    isLogged: selectIsLogged(state),
    cardValidation: selectCardValidationStatus(state),
    cards: selectUserCards(state),
});

export const StartWithCard = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(StartWithCardComponent)
);
