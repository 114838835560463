import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import { Footer, Header, Layout } from '../../common/components';

import { PersonalHeader } from './components';
import { Cards } from './features/cards';
import { Discounts } from './features/discounts';
import { Orders } from './features/orders';
import { Settings } from './features/settings';
import { State } from 'react-use/lib/useSize';
import { AppState } from '../../common/models/AppState';
import {
    agreeWithCookiesAction,
    checkSessionActions,
    selectCookiesAgreement,
    selectIsLogged,
} from '../user/ducks';
import { fetchCategoriesActions } from '../shopping/ducks';

interface DispatchToProps {
    checkSession(): void;
}

type Props = RouteComponentProps & DispatchToProps;

export const PesonalComponent: FunctionComponent<Props> = ({ match, checkSession }) => {
    useEffect(() => {
        checkSession();
    });

    return (
        <Layout>
            <Header />
            <PersonalHeader />
            <Switch>
                <Route path={`${match.path}/cards`} component={Cards} />
                <Route exact path={`${match.path}/orders`} component={Orders} />
                <Route exact path={`${match.path}/discounts`} component={Discounts} />
                <Route exact path={`${match.path}/settings`} component={Settings} />
                <Redirect path={`${match.path}/`} to={`${match.path}/cards`} />
            </Switch>
            <Footer />
        </Layout>
    );
};

const mapDispatchToProps: DispatchToProps = {
    checkSession: checkSessionActions.request,
};

export const Pesonal = withRouter(
    connect(
        null,
        mapDispatchToProps
    )(PesonalComponent)
);
