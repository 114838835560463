import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
    Footer,
    Header,
    Hero,
    Layout,
    LoginValidator,
    PageDescription,
    VoucherInput,
} from '../../common/components';
import { AppState } from '../../common/models/AppState';
import { i18n } from '../../common/services/i18n';
import { selectIsLogged } from '../user/ducks';

import {
    CodeStartState,
    CodeVerifcationTypes,
    runBenefitEventActions,
    selectCodeVerification,
    selectCodeVerificationApplied,
    selectCodeVerificationType,
    verifyTokenActions,
} from './ducks';

interface DispatchToProps {
    verifyCode: (code: string) => void;
    runBenefitEvent: (payload: { code: string; type: CodeVerifcationTypes }) => void;
}

interface StateToProps {
    code: CodeStartState;
    type: CodeVerifcationTypes | null;
    isLogged: boolean;
    isApplied: boolean;
}

type Props = RouteComponentProps & DispatchToProps & StateToProps;

export const CodeComponent = (props: Props) => {
    const [code, setCode] = useState('');

    useEffect(
        () => {
            if (props.isLogged && !props.code.validated) {
                props.history.push('/personal/discounts');
            }

            if (props.isLogged && code && !props.type) {
                props.runBenefitEvent({ code, type: CodeVerifcationTypes.FromLink });
            }

            if (props.isLogged && code && props.type === CodeVerifcationTypes.FromLinkRegister) {
                props.history.push('/personal/discounts');
            }

            if (props.isLogged && props.isApplied) {
                props.history.push('/personal/discounts');
            }
        },

        [props.isLogged, props.isApplied]
    );

    function handleBackButtonClick() {
        props.history.push('/');
    }

    function handleVerifyClick(code: string) {
        props.verifyCode(code);
        setCode(code);
    }

    function renderHeroOverlay() {
        return (
            <PageDescription
                title={i18n.t('voucherInputSection.title')}
                description={i18n.t('voucherInputSection.description')}
            />
        );
    }

    return (
        <Layout>
            <Header />
            <Layout.Content>
                <div className="voucher-input-section">
                    <Hero overlay={renderHeroOverlay()}>
                        <VoucherInput
                            onVerify={handleVerifyClick}
                            validated={props.code.validated}
                            benefit={props.code.benefit}
                            error={props.code.error}
                        >
                            <LoginValidator benefitKey={code} />
                        </VoucherInput>
                    </Hero>
                    <Row type="flex" justify="center">
                        <Col xs={22} md={8}>
                            <Button
                                onClick={handleBackButtonClick}
                                className="voucher-input-section-button"
                                block
                                type="primary"
                            >
                                {i18n.t('voucherInputSection.backButton')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Layout.Content>
            <Footer />
        </Layout>
    );
};

const mapDispatchToProps: DispatchToProps = {
    verifyCode: verifyTokenActions.request,
    runBenefitEvent: runBenefitEventActions.request,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    code: selectCodeVerification(state),
    isLogged: selectIsLogged(state),
    isApplied: selectCodeVerificationApplied(state),
    type: selectCodeVerificationType(state),
});

export const StartWithCode = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CodeComponent)
);
