import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Field, FieldProps } from 'formik';
import React, { ReactNode } from 'react';

import { CheckBox } from '../../components';

interface Props {
    name: string;
    label: ReactNode;
}

export default ({ name, ...bag }: Props) => (
    <Field name={name}>
        {({ field: { onChange, value, ...fieldBag }, form }: FieldProps<any>) => {
            const handleChange = (e: CheckboxChangeEvent) => {
                form.setFieldValue(name, e.target.checked);
            };
            return (
                <CheckBox
                    checked={value}
                    error={form.errors[name]}
                    value={Boolean(value)}
                    onChange={handleChange}
                    {...fieldBag}
                    {...bag}
                />
            );
        }}
    </Field>
);
