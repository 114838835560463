import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import cn from 'classnames';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import BodyClassName from 'react-body-classname';

import { Control } from '../Control';
import LipnoIcon from '../../LipnoIcon';

interface Props extends DatePickerProps {
    warning?: boolean;
    label?: ReactNode;
    error?: ReactNode;
}

export default ({ label, error, warning, open, onOpenChange, ...bag }: Props) => {
    const [visible, setVisibility] = useState<boolean>(open ? open : false);

    useEffect(
        () => {
            open && setVisibility(open);
        },
        [open]
    );

    function handleOpenChange(value: boolean) {
        setVisibility(value);
        onOpenChange && onOpenChange(value);
    }

    return (
        <BodyClassName className={cn({ 'masked-bg': visible })}>
            <Control error={error} label={label}>
                <DatePicker
                    defaultPickerValue={moment()}
                    allowClear={false}
                    className={cn({ 'ant-input-warning': error })}
                    onOpenChange={handleOpenChange}
                    format={'DD.MM.YYYY'}
                    open={visible}
                    // suffixIcon={<LipnoIcon type={LipnoIcon.types.Calendar} />}
                    {...bag}
                />
            </Control>
        </BodyClassName>
    );
};
