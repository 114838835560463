import { Icon } from 'antd';
import cn from 'classnames';
import React, { ChangeEvent, useState } from 'react';

import { Button, Input, Modal, Radio, WtpInput } from '../../components';
import { ModalSizes } from '../../constants/modal';
import { i18n } from '../../services/i18n';

interface Props {
    visible?: boolean;
    error?: string | null;
    verified?: boolean;
    needMore?: boolean;
    initialValue?: string;
    exists?: boolean | null;
    onCancel?: () => void;
    onOk?: () => void;
    onValidate?: (code: string) => void;
}

export default function AddVoucherModal(props: Props) {
    const [code, setCode] = useState(props.initialValue ? props.initialValue : '');

    function isCodeValidated() {
        return props.error || props.verified;
    }

    function isCodeValid() {
        return code.length > 10;
    }

    function shouldRendredCancelButton() {
        return !isCodeValidated() || props.error;
    }

    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        setCode(e.currentTarget.value);
    }

    function handleVerifySubmit() {
        props.onValidate && props.onValidate(code);
    }

    function handleOkClick() {
        setCode('');
        props.onOk && props.onOk();
    }

    function handleCancelClick() {
        setCode('');
        props.onCancel && props.onCancel();
    }

    function handleInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            if (isCodeValid()) {
                props.onValidate && props.onValidate(code);
            }
        }
    }

    function renderStatusMessage() {
        return (
            <div
                className={cn('add-card-modal-content-message', {
                    'add-card-modal-content-message--error': props.error,
                    'add-card-modal-content-message--verified': props.verified,
                })}
            >
                {props.error && (
                    <>
                        <Icon type="warning" />
                        {props.exists === false
                            ? i18n.t('addVoucherModal.errorMessageNotExists')
                            : i18n.t(`addVoucherModal.${props.error}.errorMessage`)}
                    </>
                )}
                {props.verified && !props.needMore && (
                    <>
                        <Icon type="check" /> {i18n.t('addVoucherModal.verifiedMessage')}
                    </>
                )}
                {props.verified && props.needMore && (
                    <>
                        <Icon type="check" /> {i18n.t('addVoucherModal.verifiedMessageNeedMore')}
                    </>
                )}
            </div>
        );
    }

    return (
        <Modal
            visible={props.visible}
            width={ModalSizes.small}
            onCancel={props.onCancel}
            footer={null}
            closable
        >
            <div className="add-card-modal">
                <div className="add-card-modal-header">
                    <h3>{i18n.t('addVoucherModal.title')}</h3>
                </div>
                <div className="add-card-modal-content">
                    <Input
                        value={code}
                        error={!!props.error}
                        onChange={handleInputChange}
                        onKeyDown={handleInputKeyDown}
                        size="large"
                    />
                    {isCodeValidated() && renderStatusMessage()}
                </div>
                <div className="add-card-modal-buttons">
                    {!isCodeValidated() && (
                        <Button disabled={!isCodeValid()} onClick={handleVerifySubmit}>
                            {i18n.t('addVoucherModal.confirmButton')}
                        </Button>
                    )}
                    {shouldRendredCancelButton() && (
                        <Button onClick={handleCancelClick} type="primary">
                            {i18n.t('addVoucherModal.cancelButton')}
                        </Button>
                    )}
                    {isCodeValidated() && props.verified && (
                        <Button onClick={handleOkClick} type="success">
                            {i18n.t('addVoucherModal.okButton')}
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    );
}
