import moment from 'moment';

export const isDateValid = (birthday?: string) => {
  return birthday && birthday !== '' && moment(birthday, 'DD.MM.YYYY').isValid();
};

export const getSubTypeFromBirthday = (birthday?: string) => {
  if (!isDateValid(birthday)) {
    return undefined;
  }

  const date = moment(birthday, 'DD.MM.YYYY');

  if (date.isBefore(moment().subtract(15, 'years'))) {
    return 'adult';
  } else {
    return 'child';
  }
};
