import React, { Fragment } from 'react';

import { Layout } from '../../../../common/components';
import { i18n } from '../../../../common/services/i18n';
import { TermsSubheader } from '../../components';
import Claim from './components/Claim';

export function ClaimTermsPage() {
    return (
        <Fragment>
            <TermsSubheader title={i18n.t('terms.claim')} />
            <Layout.Container>
                <Claim />
            </Layout.Container>
        </Fragment>
    );
}
