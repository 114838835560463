import moment from 'moment';
import React, { Fragment } from 'react';
import { i18n } from '../../services/i18n';

interface Props {
    from?: string;
    unit?: string;
    validityAmount: number;
    utc?: boolean;
    time?: string;
}

export default function ProductValidity(props: Props) {
    if (!props.from) {
        return <>-</>;
    }

    const from = moment(props.from)
        .utc(!props.utc)
        .format('DD.MM.YYYY');
    const to = moment(props.from)
        .add(props.validityAmount - 1, 'days')
        .format('DD.MM.YYYY');

    return (
        <Fragment>
            {props.validityAmount === 1 || props.unit === 'hours'
                ? from
                : `${from} - ${to}`}
            {props.time && props.time !== '00:00' && ` ${props.time}`}
        </Fragment>
    );
}

ProductValidity.format = (from: string, validityAmount: number, unit: string, seasonal?: boolean, time?: string) => {
    const fromDate = moment(from, 'YYYY-MM-DD')
        .format('DD.MM.YYYY');
    const to = moment(from, 'YYYY-MM-DD')
        .add( validityAmount - 1, 'days')
        .format('DD.MM.YYYY');

    const timeSlot =  (time ? ` ${time}` : '');

    if (seasonal) {
        return i18n.t('cartItem.seasonal');
    } else if (validityAmount === 1 || unit === 'hours') {
        return `${fromDate} ${timeSlot}`;
    }

    return `${fromDate} - ${to} ${timeSlot}`;
};
