import cn from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
    type: 'positive' | 'negative' | 'info';
    title?: ReactNode;
    description?: ReactNode;
    descriptionHtml?: string;
}

export default ({ type, title, description, descriptionHtml }: Props) => {
    return (
        <div
            className={cn('feedback-box', {
                'feedback-box--negative': type === 'negative',
                'feedback-box--positive': type === 'positive',
                'feedback-box--info': type === 'info',
            })}
        >
            <div className="feedback-box__title">{title}</div>
            {description && <div className="feedback-box__description">{description}</div>}
            {descriptionHtml && <div className="feedback-box__description" dangerouslySetInnerHTML={{ __html: descriptionHtml }} />}
        </div>
    );
};
