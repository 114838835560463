import { Icon } from 'antd';
import React, { FunctionComponent } from 'react';
import { environment } from '../../../../../../environments/environment';

const Social: FunctionComponent = () => (
    <div className="social-icons">
        {environment.social.instagram &&
            <a href={environment.social.instagram} target="_blank">
                <Icon type="instagram" />
            </a>
        }
        {environment.social.facebook &&
            <a href={environment.social.facebook} target="_blank">
                <Icon type="facebook" />
            </a>
        }
        {environment.social.youtube &&
            <a href={environment.social.youtube} target="_blank">
                <Icon type="youtube"/>
            </a>
        }
    </div>
);

export default Social;
