import { Card, Button } from 'antd';
import React, { Fragment, useMemo, useState } from 'react';
import Truncate from 'react-truncate';
import { oc } from 'ts-optchain';
import { connect } from 'react-redux';

import { Price, TotalPrice } from '../../components';
import { TotalValue, VoucherBenefit } from '../../models/Benefit';
import { i18n } from '../../services/i18n';
import { Product } from '../../models/Product';
import {
    VariantsDropdown,
    VariantsSelection,
} from '../../../features/shopping/features/eshop/components/ProductSelect/components/VariantsDropdown';
import {
    selectDiscountBadges,
    selectDiscountEntries,
    selectEshopSingleEntries, selectSeason,
    selectTransactionPrice,
    SingleVariant,
    validateSingleVariantActions
} from '../../../features/shopping/ducks';
import { AppState, Season } from '../../models/AppState';
import { environment } from '../../../environments/environment';
import { isProductVisible } from '../../utils/visibility-helpers';
import DynamicImg from '../DynamicImg';
import { EntryBase, TransactionDryRunBenefit } from '../../models/NewTransaction';
import LocalizedString from '../LocalizedString';
import BenefitValidity from '../BenefitValidity/BenefitValidity';

interface Props {
    benefit: VoucherBenefit;
    product: Product;
    reset: boolean;
    onAddToCart: (value: VariantsSelection[], product: Product) => void;
    onSelectUpdate: (value: VariantsSelection[], product: Product) => void;
}

const VoucherDiscountComponent = (props: Props & StateToProps & DispatchToProps) => {
    const [variantsSelection, setVariantsSelection] = useState<VariantsSelection[]>([]);

    const variants = useMemo(() =>
        props.benefit.variants
            .filter(variant =>
                variant.countMax !== 0 &&
                oc(props.product).variants([]).some(v => v._id === variant.variant)
            ),
        [props.product, props.benefit.variants]
    );

    const initialVariants = useMemo(() =>
        variants
            .filter(variant => variant.countMin !== -1)
            .map(variant => ({
                id: variant.variant,
                count: variant.countMin,
            })),
        [variants]
    );

    const handleSelectionUpdate = (variants: VariantsSelection[]) => {
        setVariantsSelection(variants);
        props.onSelectUpdate(variants, props.product);
    };

    const validateSingleVariants = () => {
        oc(props.product)
            .variants([])
            .filter(v => isProductVisible(v))
            .forEach(v =>
                props.validateSingleVariant({ variant: v._id, arrival: new Date().toISOString() })
            );
    };

    const handleAddToCart = () => {
        props.onAddToCart(variantsSelection, props.product);
    };

    return (
        <div className="voucher-discount">
            <Card
                className="cart"
                cover={
                    <DynamicImg
                        srcs={[
                            `${environment.mediaUrl}/benefit/${props.benefit.benefitId}`,
                            `${environment.mediaUrl}/static/eshop/benefit.season-${props.season.type}`,
                            `${environment.mediaUrl}/static/eshop/benefit`,
                        ]}
                        alt="benefit"
                        style={{ margin: '0 auto' }}
                    />
                }
            >
                <Card.Meta
                    title={
                        <Truncate lines={2} ellipsis="...">
                            <LocalizedString value={props.benefit.name} />
                        </Truncate>
                    }
                    description={<BenefitValidity validity={props.benefit.validity} />}
                />
                <p>
                    <Truncate lines={5} ellipsis="...">
                        <LocalizedString value={props.benefit.description} />
                    </Truncate>
                </p>

                <div className={'ant-card-add-cart'}>
                    <TotalPrice
                        type="default"
                        pricePosition="bottom"
                        withDiscount={
                            <Price value={props.total.discounted || 0} />
                        }
                        withoutDiscount={
                            <Fragment>
                                {i18n.t('ticketSelect.priceWithoutDiscount')}{' '}
                                <Price value={props.total.price || 0} />
                            </Fragment>
                        }
                        displayDiscount
                    />
                    <VariantsDropdown
                        badges={props.badges}
                        product={props.product}
                        onChange={handleSelectionUpdate}
                        entries={props.entries}
                        variants={variants}
                        maxVariants={props.benefit.maxUsage}
                        onDropdownOpen={validateSingleVariants}
                        initialState={initialVariants}
                        reset={props.reset}
                        singleVariants={props.singleVariants}
                    />
                    <Button
                        block
                        size="small"
                        disabled={!variantsSelection || !variantsSelection.some(v => v.count > 0)}
                        onClick={handleAddToCart}
                    >
                        {i18n.t('discount.cartButton')}
                    </Button>
                </div>
            </Card>
        </div>
    );
};

interface StateToProps {
    badges: TransactionDryRunBenefit[];
    entries: EntryBase[];
    singleVariants: Record<string, EntryBase>;
    total: TotalValue;
    season: Season;
}

const mapStateToProps = (state: AppState, props: Props): StateToProps => ({
    badges: selectDiscountBadges(state, props.benefit.tokenId + props.benefit.product),
    entries: selectDiscountEntries(state, props.benefit.tokenId + props.benefit.product),
    singleVariants: selectEshopSingleEntries(state),
    total: selectTransactionPrice(state, props.benefit.tokenId + props.benefit.product),
    season: selectSeason(state),
});

interface DispatchToProps {
    validateSingleVariant: (items: SingleVariant) => void;
}

const mapDispatchToProps: DispatchToProps = {
    validateSingleVariant: validateSingleVariantActions.request,
};

const VoucherDiscount = connect(mapStateToProps, mapDispatchToProps)(VoucherDiscountComponent);

export default VoucherDiscount;
