import { Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { Layout } from '../../../../../components';
import { i18n } from '../../../../../services/i18n';
import { FooterContact, SocialIcons } from '../../components';
import { footerSections } from '../../sections';
import { getFooterLogo } from '../../../../../utils/project-helpers';

const MobileFooter = () => {
    const getMenuItem = item => {
        if (item.linkKey) {
            return (
                <a href={i18n.t(item.link)} target="_blank">
                    {i18n.t(item.heading)}
                </a>
            );
        }

        if (item.external) {
            return (
                <a href={item.link} target="_blank">
                    {i18n.t(item.heading)}
                </a>
            );
        }

        return <Link to={item.link}>{i18n.t(item.heading)}</Link>;
    };

    return (
        <Layout.Container>
            <img className="logo" src={getFooterLogo()}/>
            <Menu className="footer-menu" theme="dark" mode="inline" defaultOpenKeys={[]}>
                {footerSections
                    .filter(section => section.items.length)
                    .map((section, index) => (
                        <Menu.SubMenu key={index} title={i18n.t(section.heading)}>
                            {section.items.map((item, index) => {
                                return (
                                    <Menu.Item key={index}>
                                        {getMenuItem(item)}
                                    </Menu.Item>
                                );
                            })}
                        </Menu.SubMenu>
                    ))
                }
            </Menu>
            <FooterContact/>
            <SocialIcons/>
        </Layout.Container>
    );
};

export default MobileFooter;
