import React, { ReactNode } from 'react';
import cn from 'classnames';

interface Props {
    img: string | ReactNode;
    children: ReactNode;
}

export default function FadedImage({ img, children }: Props) {
    return (
        <div className="faded-image">
            {img}
            <div
                className={cn('faded-image__overlay', {
                    'faded-image__overlay--without-image': !img,
                })}
            >
                {children}
            </div>
        </div>
    );
}
