import React, { useState, ChangeEvent } from 'react';
import validator from 'validator';
import { passwordRegex } from '../../constants/regex';

import { Button, Modal, Input } from '../../components';
import { ModalSizes } from '../../constants/modal';
import { i18n } from '../../services/i18n';
import PasswordInput from '../../fields/PasswordInput';
import Icon from 'antd/es/icon';
import { InputField } from '../../fields';
import { Simulate } from 'react-dom/test-utils';
// import copy = Simulate.copy;

interface Props {
    visible?: boolean;
    onConfirm: (email: string) => void;
    onCancel?: () => void;
}

export default function ChangePasswordModal({ visible, onConfirm, onCancel }: Props) {
    const [password, setPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [passwordVisibility, setPasswordVisibility] = useState({ '0': false, '1': false });

    function isValid() {
        return validator.matches(password, passwordRegex);
    }

    function isPasswordsEqual() {
        return isValid() && confirmedPassword === password;
    }

    function handleConfirmClick() {
        onConfirm && onConfirm(confirmedPassword);
    }

    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        setPassword(e.currentTarget.value);
    }

    function handleConfirmInputChange(e: ChangeEvent<HTMLInputElement>) {
        setConfirmedPassword(e.currentTarget.value);
    }

    function changePasswordVisibility(index) {
        setPasswordVisibility({ ...passwordVisibility, [index]: !passwordVisibility[index] });
    }

    return (
        <Modal
            visible={visible}
            footer={null}
            width={ModalSizes.default}
            closable
            onCancel={onCancel}
        >
            <div className="marketing-modal">
                <h1>{i18n.t('changePasswordModal.title')}</h1>
                <Input
                    error={
                        password.length > 1 && !isValid()
                            ? i18n.t('form.errors.password')
                            : undefined
                    }
                    type={passwordVisibility['0'] ? 'text' : 'password'}
                    suffix={
                        <Icon type="eye-invisible" onClick={() => changePasswordVisibility(0)} />
                    }
                    placeholder={i18n.t('changePasswordModal.inputPlaceholder')}
                    onChange={handleInputChange}
                />
                <Input
                    placeholder={i18n.t('changePasswordModal.inputPlaceholderConfirm')}
                    type={passwordVisibility['1'] ? 'text' : 'password'}
                    suffix={
                        <Icon type="eye-invisible" onClick={() => changePasswordVisibility(1)} />
                    }
                    onChange={handleConfirmInputChange}
                    style={{
                        margin: '10px 0',
                    }}
                />
                <div className="marketing-modal-btns">
                    <Button onClick={handleConfirmClick} disabled={!isPasswordsEqual()}>
                        {i18n.t('changePasswordModal.confirmButton')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
