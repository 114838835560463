import cn from 'classnames';
import React, { Fragment, ReactNode } from 'react';

import DiscountBadge from '../DiscountBadge';
import { environment } from '../../../environments/environment';
import { TransactionDryRunBenefit } from '../../models/NewTransaction';

interface Props {
    withoutDiscount?: ReactNode;
    withDiscount?: ReactNode;
    addon?: ReactNode;
    type?: 'positive' | 'negative' | 'default';
    pricePosition?: 'top' | 'bottom' | 'center';
    badges?: TransactionDryRunBenefit[];
    displayDiscount: boolean;
}

export default ({
    type = 'positive',
    pricePosition = 'top',
    withoutDiscount,
    withDiscount,
    addon,
    badges = [],
    displayDiscount,
}: Props) => {
    function renderPriceTopLayout() {
        return (
            <Fragment>
                {environment.config.dualPrices && withoutDiscount && displayDiscount && (
                    <div className="total-price__without-discount">{withoutDiscount}</div>
                )}
                {withDiscount && <div className="total-price__with-discount">{withDiscount}</div>}
                {badges.length > 0 && (
                    <div className="total-price__badges">
                        {badges.map((badge, index) => (
                            <DiscountBadge key={index} benefit={badge} />
                        ))}
                    </div>
                )}
                {addon && <div className="total-price__addon">{addon}</div>}
            </Fragment>
        );
    }

    function renderPriceBottomLayout() {
        return (
            <Fragment>
                {withDiscount && <div className="total-price__with-discount">{withDiscount}</div>}
                {environment.config.dualPrices && withoutDiscount && displayDiscount && (
                    <div className="total-price__without-discount">{withoutDiscount}</div>
                )}
                {badges.length > 0 && (
                    <div className="total-price__badges">
                        {badges.map((badge, index) => (
                            <DiscountBadge key={index} benefit={badge} />
                        ))}
                    </div>
                )}
                {addon && <div className="total-price__addon">{addon}</div>}
            </Fragment>
        );
    }

    function renderPriceCenterLayout() {
        return (
            <Fragment>
                <div className="total-price-center">
                    {withDiscount && (
                        <div className="total-price__with-discount">{withDiscount}</div>
                    )}
                    {environment.config.dualPrices && withoutDiscount && displayDiscount && (
                        <div className="total-price__without-discount">{withoutDiscount}</div>
                    )}
                    {badges.length > 0 && (
                        <div className="total-price-center__badges">
                            {badges.map((badge, index) => (
                                <DiscountBadge key={index} benefit={badge} />
                            ))}
                        </div>
                    )}
                    {addon && <div className="total-price__addon">{addon}</div>}
                </div>
            </Fragment>
        );
    }

    return (
        <div
            className={cn('total-price', {
                'total-price--negative': type === 'negative',
                'total-price--default': type === 'default',
            })}
        >
            {pricePosition === 'top' && renderPriceTopLayout()}
            {pricePosition === 'bottom' && renderPriceBottomLayout()}
            {pricePosition === 'center' && renderPriceCenterLayout()}
        </div>
    );
};
