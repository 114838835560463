import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

import { Footer, Header, Layout } from '../../common/components';
import { setShowRegisterModalAction } from '../user/ducks';

interface DispatchToProps {
    setShowRegisterModal(show: boolean): void;
}

type Props = RouteComponentProps & DispatchToProps;

export const RegistrationRedirectComponent: FunctionComponent<Props> = ({setShowRegisterModal}) => {
    useEffect(
        () => {
            setShowRegisterModal(true);
        },
        []
    );

    return (
        <Layout>
            <Header />
            <Redirect to={`/shopping/eshop`} />
            <Footer />
        </Layout>
    );
};

const mapDispatchToProps: DispatchToProps = {
    setShowRegisterModal: setShowRegisterModalAction,
};

export const RegistrationRedirect = withRouter(
    connect(
        null,
        mapDispatchToProps
    )(RegistrationRedirectComponent)
);
