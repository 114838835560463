import { Divider, Icon, Menu } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { Button, Drawer, Logo } from '../../../../components';
import { LoginFormValues } from '../../../../models/User';
import { i18n } from '../../../../services/i18n';
import { AuthDropDown } from '../../components';
import { menu } from '../../constants/menu';
import { Season } from '../../../../models/AppState';
import { ClickParam } from 'antd/lib/menu';
import { environment } from '../../../../../environments/environment';

interface OwnProps {
    seasons: Season[];
    season?: Season;
    visible: boolean;
    isLogged: boolean;
    onClose: () => void;
    onLoginSubmit: (values: LoginFormValues) => void;
    onRegister: () => void;
    onLogout: () => void;
    onChangeSeason?: (season: Season) => void;
    onPasswordForget?: () => void;
}

type Props = OwnProps & RouteComponentProps;

function MobileMenu(props: Props) {
    const [authDpVisible, setAuthDpVisibility] = useState(false);
    useEffect(
        () => {
            if (authDpVisible && props.isLogged) {
                props.history.push('/');
            }
        },
        [props.isLogged]
    );

    function handleVisibleChange(visible: boolean) {
        setAuthDpVisibility(visible);
    }

    function handleSeasonChange(season: Season) {
        return function(_param: ClickParam) {
            props.onChangeSeason && props.onChangeSeason(season);
        };
    }

    function getDrawerStyle() {
        return authDpVisible ? { display: 'none' } : undefined;
    }

    function renderAboutLipno() {
        return props.season ? (
            props.season.type === 'summer' && environment.config.aboutCard && (
                <Menu.Item onClick={() => props.history.push('/aboutCard')}>
                    {i18n.t('header.menu.aboutLipno')}
                </Menu.Item>
            )
        ) : (
            <div />
        );
    }

    function renderLoggged() {
        return (
            <Fragment>
                <Menu>
                    {props.seasons.map(season => {
                        return (
                            <Menu.Item key={season._id} onClick={handleSeasonChange(season)}>
                                {i18n.t('seasons.' + season.type)}
                            </Menu.Item>
                        );
                    })}
                    {renderAboutLipno()}
                </Menu>
                <Divider dashed>{i18n.t('header.auth.personal')}</Divider>
                <Menu>
                    {menu.map((item, index) => (
                        <Menu.Item key={index}>
                            <Link to={item.link}>
                                <Icon type={item.icon} />
                                {i18n.t(item.key)}
                            </Link>
                        </Menu.Item>
                    ))}
                </Menu>
                <div className="mobile-menu-actions">
                    <Button onClick={props.onLogout} size="small" block type="primary">
                        {i18n.t('header.loggedDropdown.logout')}
                    </Button>
                </div>
            </Fragment>
        );
    }

    function renderNotLogged() {
        return (
            <Fragment>
                <Menu>
                    {props.seasons.map(season => {
                        return (
                            <Menu.Item key={season._id} onClick={handleSeasonChange(season)}>
                                {i18n.t('seasons.' + season.type)}
                            </Menu.Item>
                        );
                    })}
                    {environment.config.aboutCard && renderAboutLipno()}
                </Menu>
                <div className="mobile-menu-actions">
                    <AuthDropDown
                        onLogin={props.onLoginSubmit}
                        isLogged={props.isLogged}
                        onVisibilityChange={handleVisibleChange}
                        visible={authDpVisible}
                        onPassswordForget={props.onPasswordForget}
                        isMobile={true}
                    >
                        <Button size="small" block>
                            {i18n.t('header.auth.login')}
                        </Button>
                    </AuthDropDown>
                    <Button onClick={props.onRegister} size="small" block type="primary">
                        {i18n.t('header.auth.register')}
                    </Button>
                </div>
            </Fragment>
        );
    }

    return (
        <Drawer
            placement="left"
            onClose={props.onClose}
            visible={props.visible}
            type="flex"
            style={getDrawerStyle()}
        >
            <div className="mobile-menu">
                <Logo />
                {props.isLogged ? renderLoggged() : renderNotLogged()}
            </div>
        </Drawer>
    );
}

export default withRouter(MobileMenu);
