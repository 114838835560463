import { Icon } from 'antd';
import cn from 'classnames';
import { isValid } from 'luhn-js';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { Button, Modal, WtpInput } from '../../components';
import { ModalSizes } from '../../constants/modal';
import { i18n } from '../../services/i18n';
import AddCardBirthdayModal from '../AddCardBirthdayModal';
import { environment } from '../../../environments/environment';
import { Token } from '../../models/Token';

interface Props {
    visible?: boolean;
    error?: boolean;
    verified?: boolean;
    card?: Token | null;
    initialValue?: string;
    exists?: string | null;
    onCancel?: () => void;
    onOk?: () => void;
    onValidate?: (code: string, addUser?: boolean, birthday?: string, subType?: string) => void;
    allowedSubtypes?: any[];
}

const AddCardModal = (props: Props) => {
    const [code, setCode] = useState(props.initialValue ? props.initialValue : '');
    const [dateModal, setDateModal] = useState(false);
    const [dateSet, setDateSet] = useState(false);

    useEffect(() => {
        if (environment.config.addCardType) {
            if (
                code &&
                props.verified &&
                props.card &&
                (!props.card.meta.subType || !props.card.birthday)
            ) {
                setDateModal(true);
                setDateSet(false);
            } else if (code && props.verified && props.card && props.card.meta.subType && props.card.birthday) {
                props.onValidate && props.onValidate(code, true);
            }
        }
    }, [props.verified]);

    function isCodeValidated() {
        return props.error || props.verified;
    }

    function isCodeValid() {
        return code.match(environment.config.cardPattern) && isLuhnValid();
    }

    function isLuhnValid() {
        return !environment.config.validateLuhnNumber ||
            !code.match(environment.config.cardPattern) ||
            isValid(code.replace(/\D+/g, ''));
    }

    function shouldRendredCancelButton() {
        return !isCodeValidated() || !cardHasTypeSet() || props.error;
    }

    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        setCode(e.currentTarget.value);
    }

    function handleVerifySubmit() {
        props.onValidate && props.onValidate(code, !environment.config.addCardType);
    }

    function handleDateModalSubmit(date, subType) {
        props.onValidate && props.onValidate(code, true, date, subType);
        setDateModal(false);
        setDateSet(true);
    }

    function handleCancelBirthday() {
        setDateModal(false);
        props.onCancel && props.onCancel();
    }

    function handleOkClick() {
        setCode('');
        props.onOk && props.onOk();
    }

    function handleCancelClick() {
        setCode('');
        props.onCancel && props.onCancel();
    }

    function handleInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            if (isCodeValid()) {
                props.onValidate && props.onValidate(code, !environment.config.addCardType);
            }
        }
    }

    function cardHasTypeSet() {
        return !environment.config.addCardType ||
            dateSet ||
            (
                props.card &&
                props.card.meta.subType &&
                props.card.birthday
            );
    }

    function renderStatusMessage(
        error = props.error,
        verified = props.verified,
        exists = props.exists
    ) {
        return (
            <div
                className={cn('add-card-modal-content-message', {
                    'add-card-modal-content-message--error': error,
                    'add-card-modal-content-message--verified': verified,
                })}
            >
                {error && (
                    <>
                        <Icon type="warning" />
                        {exists
                            ? i18n.t(`addCardModal.error.${exists}`)
                            : i18n.t('addCardModal.errorMessage')}
                    </>
                )}
                {verified && cardHasTypeSet() && (
                    <>
                        <Icon type="check" /> {i18n.t('addCardModal.verifiedMessage')}
                    </>
                )}
            </div>
        );
    }

    return (
        <>
            <AddCardBirthdayModal
                visible={dateModal}
                allowedSubtypes={props.allowedSubtypes}
                onCancel={handleCancelBirthday}
                onOk={handleDateModalSubmit}
            />
            <Modal
                visible={props.visible}
                width={ModalSizes.small}
                onCancel={props.onCancel}
                footer={null}
                closable
            >
                <div className="add-card-modal">
                    <div className="add-card-modal-header">
                        <h3>{i18n.t('addCardModal.title')}</h3>
                        <p>{i18n.t('addCardModal.subtitle')}</p>
                        {environment.config.addCardModalImage &&
                            <img
                                className="add-card-modal-header__card"
                                src={environment.config.addCardModalImage}
                                alt="CardBackImage"
                            />
                        }
                    </div>
                    <div className="add-card-modal-content">
                        <WtpInput
                            value={code}
                            verified={props.verified}
                            error={props.error}
                            onChange={handleInputChange}
                            onKeyDown={handleInputKeyDown}
                            inputSize="large"
                            autoFocus
                        />
                        {isCodeValidated() && renderStatusMessage()}
                        {!isLuhnValid() && renderStatusMessage(true, undefined, 'luhnInvalid')}
                    </div>
                    <div className="add-card-modal-buttons">
                        {!isCodeValidated() && (
                            <Button disabled={!isCodeValid()} onClick={handleVerifySubmit}>
                                {i18n.t('addCardModal.confirmButton')}
                            </Button>
                        )}
                        {shouldRendredCancelButton() && (
                            <Button onClick={handleCancelClick} type="primary">
                                {i18n.t('addCardModal.cancelButton')}
                            </Button>
                        )}
                        {isCodeValidated() && props.verified && cardHasTypeSet() && (
                            <Button onClick={handleOkClick} type="success">
                                {i18n.t('addCardModal.okButton')}
                            </Button>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AddCardModal;
