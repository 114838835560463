import React, { Fragment, ReactNode } from 'react';
import { environment } from '../../../environments/environment';

interface Props {
    value: ReactNode;
}

const Price = ({ value }: Props) => (
    <Fragment>
        {environment.getPriceWithCurrency(value !== undefined ? Number(value).toFixed(environment.config.priceDecimals) : '  -  ')}
    </Fragment>
);

export default Price;
