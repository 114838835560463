import { Field, FieldProps } from 'formik';
import React, { ReactNode } from 'react';
import { useRegisteredRef } from 'react-register-nodes';

import { Input } from '../../components';

interface Props {
    name: string;
    label?: ReactNode;
    placeholder?: string;
    type?: 'password' | 'text';
    onBlur?: any;
    suffix?: ReactNode;
}

export default function InputField({
    name,
    type,
    onBlur,
    ...bag
}: Props) {
    const ref = useRegisteredRef(name);

    return (
        <Field name={name}>
            {({ field, form: { errors } }: FieldProps<any>) => (
                <div ref={errors[name] ? ref : null}>
                    <Input
                        type={type}
                        error={errors[name]}
                        onBlur={(e) => {
                            field.onBlur(e);
                            onBlur && onBlur();
                        }}
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        {...bag}
                    />
                </div>
            )}
        </Field>
    );
}
