import { InputField } from '../index';
import { i18n } from '../../services/i18n';
import Icon from 'antd/es/icon';
import { Col } from 'antd';
import React, { ChangeEvent, Component, useEffect, useState } from 'react';
import { oc } from 'ts-optchain';

interface Props {
    label?: string;
    name?: string;
    placeholder: string;
    error?: string | undefined;
}

function PasswordInput(props: Props) {
    const [passwordVisiblity, setPasswordVisiblity] = useState(false);

    function showPassword() {
        setPasswordVisiblity(!passwordVisiblity);
        setTimeout(() => setPasswordVisiblity(false), 5000);
    }

    return (
        <InputField
            name={oc(props).name('password')}
            type={passwordVisiblity ? 'text' : 'password'}
            label={props.label}
            placeholder={props.placeholder}
            suffix={
                <Icon
                    type={passwordVisiblity ? 'eye-invisible' : 'eye'}
                    onClick={showPassword}
                    style={{ marginRight: '9px' }}
                />
            }
        />
    );
}

export default PasswordInput;
