import { apiClient } from '../services/api-client';

export const userApi = {
    passwordForgot: (email: string, locale: string, recoveryUrl: string) =>
        apiClient.post(`/users/password/reset`, {
            email,
            locale,
            recoveryUrl,
        }),
    passwordReset: (token: string, password: string) =>
        apiClient.post(`/users/recovery`, {
            token,
            password,
        }),
    activateAccount: (token: string) => apiClient.get(`/users/activation?token=${token}`),
};
