import { flattenDeep, reduce } from 'lodash';

export enum RequestActionTypes {
    REQUEST = 'REQUEST',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export const createActionType = (...parts) => flattenDeep(parts).join('_');

// Implement later

// type ReducerModifier<State> = (state: State, payload: any) => State;

// export function createReducerTS<State, ActionTypes>(
//     initialState: State,
//     reducerMap: {
//         [P in keyof ActionTypes]:
//             | ReducerModifier<State>
//             | { [P in keyof RequestActionTypes]: ReducerModifier<State> }
//     }
// ) {
//     const iterator = (reducers, initial = {}, prefix = []) =>
//         reduce(
//             reducers,
//             (acc, reducer, type) => {
//                 if (typeof reducer === 'function') {
//                     return { ...acc, [createActionType(prefix, type)]: reducer };
//                 }
//                 return iterator(reducer, acc, [createActionType(prefix, type)] as never[]);
//             },
//             initial
//         );

//     const flattened = iterator(reducerMap);

//     // @ts-ignore
//     return (state = initialState, action) => {
//         const reducer = flattened[action.type];
//         return reducer ? reducer(state, action.payload) : state;
//     };
// }

// export function createActionCreatorTS<ActionType, ActionPayload>(type: ActionType) {
//     return (payload?: ActionPayload) => ({
//         type,
//         payload,
//     });
// }

// export function createApiActionCreatorsTS<ActionType, ResponseType>(type: ActionType) {
//     return {
//         success: createActionCreatorTS<ActionType, ResponseType>(type),
//     };
// }

export const createActionCreator = (...type) => (payload?) => ({
    type: createActionType(type),
    payload,
});

export const createApiActionCreators = (...type) => ({
    request: createActionCreator(type, RequestActionTypes.REQUEST),
    success: createActionCreator(type, RequestActionTypes.SUCCESS),
    failure: createActionCreator(type, RequestActionTypes.FAILURE),
});

export function createReducer(initialState, reducerMap) {
    const iterator = (reducers, initial = {}, prefix = []) =>
        reduce(
            reducers,
            (acc, reducer, type) => {
                if (typeof reducer === 'function') {
                    return { ...acc, [createActionType(prefix, type)]: reducer };
                }
                return iterator(reducer, acc, [createActionType(prefix, type)] as never[]);
            },
            initial
        );

    const flattened = iterator(reducerMap);

    return (state = initialState, action) => {
        const reducer = flattened[action.type];
        return reducer ? reducer(state, action.payload) : state;
    };
}

export function replaceAt(array, index, value) {
    const ret = array.slice(0);
    ret[index] = value;
    return ret;
}

export const replaceInArray = (array, selector, value) => {
    const idx = array.findIndex(selector);

    return idx >= 0 ? [...array.slice(0, idx), value, ...array.slice(idx + 1)] : array;
};

export const updateInArray = (array, selector, diff?) => {
    const idx = array.findIndex(selector);

    return idx >= 0
        ? [...array.slice(0, idx), { ...array[idx], ...diff }, ...array.slice(idx + 1)]
        : array;
};

export const removeFromArray = (array, selector) => {
    const idx = array.findIndex(selector);

    return idx >= 0 ? [...array.slice(0, idx), ...array.slice(idx + 1)] : array;
};
