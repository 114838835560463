import { apiClient } from '../../common/services/api-client';
import { UpdateInfo } from './ducks';

export const api = {
    getProductById: (id: string) => apiClient.get(`/products/${id}`),

    getChangeStatus: (id: string, changeId: string) =>
        apiClient.get(`/v2/transaction/${id}/change-date/${changeId}/status`),

    submitChangeProcess: (id: string, email: string, items: Record<string, UpdateInfo>) =>
        apiClient.post(
            `/v2/transaction/${id}/change-date`,
            {
                email,
                items: Object.keys(items).map(uuid => ({
                    uuid,
                    ...items[uuid]
                })),
            }
        ),
};
