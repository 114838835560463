import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { CheckBox, Select } from '../../../../components';
import { i18n } from '../../../../services/i18n';
import { AppState } from '../../../../models/AppState';
import { ProductAssigment } from '../../../../models/Cart';
import { oc } from 'ts-optchain';
import { getExchangeOption, getOptions, isCardSameType, METHOD_MAP } from './helpers';
import { EntryExchangeMethods, EntryMethod } from '../../../../models/NewTransaction';
import { Token } from '../../../../models/Token';
import { getCardNumber } from '../../../../utils/project-helpers';
import { ProductAssignerLabel } from '../index';
import LipnoCard from '../../../LipnoCard';
import {
    CardValidationState,
    cardVerifyActions,
    cardVerifyResetAction,
    selectCardsWithAvailability,
    selectCardValidationStatus
} from '../../../../../features/user/ducks';
import { SelectOption } from '../../../Form/Select';
import { environment } from '../../../../../environments/environment';
import { Control } from '../../../Form/Control';
import { DatePicker, message } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import { getSubTypeFromBirthday } from '../../../../utils/getSubTypeFromBirthday';
import { SelectValue } from 'antd/lib/select';
import { updateItemAssigmentAction, updateItemIsGiftAction } from '../../../../../features/shopping/ducks/cart';
import { AddCardModal } from '../../../../modals';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface Props {
    id: string;
    assigment: ProductAssigment;
    isGift?: boolean;
    tags: string[];
    giftStatus?: 'allowed' | 'required';
    cardTypes: string[];
}

function FormatSelect({ id, assigment, isGift, tags, giftStatus, cardTypes, cards, cardValidationStatus, updateAssigment, updateIsGift, verifyCard, resetVerifyCard }: Props & DispatchToProps & StateToProps) {
    const [addCardModal, setAddCardModal] = useState(false);
    const [option, setOption] = useState<string | undefined>(undefined);

    const selectedOption = oc(assigment).method();
    const exchangeBirthday = oc(assigment).exchangeBirthday();

    const EXCHANGE_OPTION = getExchangeOption();
    const OPTIONS = getOptions();

    const cardOptions = useMemo(() => {
        const renderExtra = (card: Token) => {
            if (!isCardSameType(cardTypes, card)) {
                return i18n.t('productAssigment.otherCategory');
            } else if (card.isUsed && oc(assigment).token._id() !== oc(card)._id()) {
                return i18n.t('productAssigment.alreadyUsed');
            } else {
                return null;
            }
        };

        return cards.map(card => ({
            value: card._id,
            disabled: card.isUsed || !isCardSameType(cardTypes, card),
            label: (
                <ProductAssignerLabel
                    disabled={card.isUsed || !isCardSameType(cardTypes, card)}
                    icon={<LipnoCard.Identificator type={oc(card).meta.subType() || 'adult'} />}
                    content={`${i18n.t('cardName')} #${getCardNumber(card.extIdentifiers)}`}
                    extra={renderExtra(card)}
                />
            ),
        }));
    }, [tags, cards]);

    const options = useMemo(() => {
        if (tags.includes(METHOD_MAP[EntryMethod.Exchange])) {
            return [EXCHANGE_OPTION];
        }

        const options: SelectOption[] = OPTIONS.filter(o => tags.includes(METHOD_MAP[o.value]));

        if (tags.includes(METHOD_MAP[EntryMethod.Card]) && cardOptions.length) {
            const index = options.findIndex(o => o.value === EntryMethod.Card);

            return [...options.slice(0, index), ...cardOptions, ...options.slice(index)];
        }

        return options;
    }, [tags, cardOptions]);

    useEffect(() => {
        if (!isGift && giftStatus === 'required') {
            updateIsGift({ id, isGift: true });
        } else if (isGift && !giftStatus) {
            updateIsGift({ id, isGift: undefined });
        }
    }, [giftStatus, isGift]);

    useEffect(() => {
        if (!selectedOption || !tags.includes(METHOD_MAP[selectedOption])) {
            setDefaultOptionValue();
        }
    }, [tags, selectedOption]);

    useEffect(() => {
        if (selectedOption === EntryMethod.Card) {
            setOption(oc(assigment).token._id() || EntryMethod.Card);
        } else {
            setOption(selectedOption);
        }
    }, [selectedOption]);

    const setDefaultOptionValue = () => {
        for (const method of [EntryMethod.Print, EntryMethod.Prepaid, EntryMethod.Exchange]) {
            if (tags.includes(METHOD_MAP[method])) {
                setOption(method);
                return updateAssigment({ id, assigment: { method } });
            }
        }

        setOption(undefined);
        return updateAssigment({ id, assigment: undefined });
    };

    const isCardBirthdayValid = () => {
        const subType = getSubTypeFromBirthday(exchangeBirthday);

        return subType && cardTypes.includes(subType);
    };

    const handleOptionSelect = (value: SelectValue) => {
        if (!value) {
            return;
        }

        if (value === EntryMethod.Card) {
            setOption(EntryMethod.Card);
            setAddCardModal(true);
        } else if (Object.values(EntryMethod).includes(value as EntryMethod)) {
            setOption(value as string);
            updateAssigment({ id, assigment: { method: value as EntryMethod } });
        } else {
            setOption(value as string);
            updateAssigment({
                id,
                assigment: {
                    method: EntryMethod.Card,
                    token: cards.find(c => c._id === value)
                }
            });
        }
    };

    const handleChangeBirthday = (_, dateString) => {
        updateAssigment({
            id,
            assigment: {
                ...assigment,
                exchangeBirthday: dateString,
            }
        });
    };

    const handleCardValidate = (wtpNumber: string, addToUser?: boolean, cardBirthday?: string, subType?: string) => {
        if (cardTypes.length > 0) {
            verifyCard({
                wtpNumber,
                addToUser,
                birthday: cardBirthday,
                subType,
                subTypes: cardTypes,
            });
        } else {
            message.error(i18n.t('variant.subtypeUndefined'));
        }
    };

    const handleCardModalOkClick = () => {
        const card = oc(cardValidationStatus).card();

        if (card) {
            setOption(EntryMethod.Card);
            updateAssigment({
                id,
                assigment: { method: EntryMethod.Card, token: card }
            });
        } else {
            setDefaultOptionValue();
        }

        setAddCardModal(false);
        resetVerifyCard();
    };

    const handleCardModalCancel = () => {
        setDefaultOptionValue();
        setAddCardModal(false);
        resetVerifyCard();
    };

    const handleChangeGift = (e: CheckboxChangeEvent) => {
        updateIsGift({ id, isGift: e.target.checked || undefined });
    };

    return (
        <>
            <AddCardModal
                visible={addCardModal}
                error={cardValidationStatus.error}
                verified={cardValidationStatus.verified}
                exists={cardValidationStatus.exists}
                card={cardValidationStatus.card}
                allowedSubtypes={cardTypes}
                onValidate={handleCardValidate}
                onCancel={handleCardModalCancel}
                onOk={handleCardModalOkClick}
            />
            <div className="product-assigner__card">
                <div className="product-assigner__card__select">
                    <Select
                        dropdownMatchSelectWidth={false}
                        disabled={tags.includes('for_exchange_only')}
                        placeholder={i18n.t('productAssigment.ticket.placeholder')}
                        label={i18n.t('productAssigment.ticket.label')}
                        value={option}
                        options={options}
                        onChange={handleOptionSelect}
                    />
                </div>
                {EntryExchangeMethods.includes(selectedOption) && (
                    <div className="product-assigner__card__payment">
                        <h3>{i18n.t('productAssigment.cardCost')}</h3>
                        <span>{i18n.t('productAssigment.lipnoCardFee')}</span>
                    </div>
                )}
            </div>
            {EntryExchangeMethods.includes(selectedOption) && environment.config.exchangeTicketCardBirthday && (
                <div className="product-assigner__card">
                    <div className="product-assigner__card__select">
                        <Control
                            label={i18n.t('productAssigment.cardBirthday.input.label')}
                            error={!isCardBirthdayValid() ? i18n.t('productAssigment.cardBirthday.input.error') : undefined}
                        >
                            <DatePicker
                                className={cn({ 'ant-input-warning': !isCardBirthdayValid() })}
                                placeholder={i18n.t('productAssigment.cardBirthday.input.placeholder')}
                                allowClear={false}
                                format={'DD.MM.YYYY'}
                                defaultPickerValue={moment('01.01.2000', 'DD.MM.YYYY')}
                                onChange={handleChangeBirthday}
                                value={exchangeBirthday ? moment(exchangeBirthday, 'DD.MM.YYYY') : undefined}
                            />
                        </Control>
                    </div>
                    <div className="product-assigner__card__payment">
                        <h3>{i18n.t('productAssigment.cardBirthday.description.title')}</h3>
                        <span>{i18n.t('productAssigment.cardBirthday.description.content')}</span>
                    </div>
                </div>
            )}
            {!!giftStatus && (
                <div className="product-assigner__card">
                    <div className="product-assigner__card__select">
                        <CheckBox
                            className="product-assigner__checkbox"
                            checked={isGift || false}
                            disabled={giftStatus === 'required'}
                            onChange={handleChangeGift}
                            htmlLabel={i18n.t('productAssigment.purchaseAsGift')}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

interface DispatchToProps {
    updateAssigment: (payload: { id: string; assigment?: ProductAssigment }) => void;
    updateIsGift: (payload: { id: string; isGift?: boolean }) => void;
    verifyCard: (payload: {
        wtpNumber: string;
        addToUser?: boolean;
        birthday?: string;
        subType?: string;
        subTypes?: string[]
    }) => void;
    resetVerifyCard: () => void;
}

const mapDispatchToProps: DispatchToProps = {
    updateAssigment: updateItemAssigmentAction,
    updateIsGift: updateItemIsGiftAction,
    verifyCard: cardVerifyActions.request,
    resetVerifyCard: cardVerifyResetAction,
};

interface StateToProps {
    cards: Token[];
    cardValidationStatus: CardValidationState;
}

const mapStateToProps = (state: AppState): StateToProps => ({
    cards: selectCardsWithAvailability(state),
    cardValidationStatus: selectCardValidationStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSelect);
