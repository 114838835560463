import React, { FunctionComponent, ReactNode } from 'react';

interface Props {
    label?: ReactNode;
    error?: ReactNode;
    children?: ReactNode;
}

export const Control: FunctionComponent<Props> = ({ label, error, children }) => (
    <div className="control">
        <div className="control__label">{label}</div>
        <div className="control__child">{children}</div>
        {error && <div className="control__error">{error}</div>}
    </div>
);
