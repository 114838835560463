import { ConnectedRouter } from 'connected-react-router';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';

import { history } from '../store/history';
import { Config } from '../../common/models/Config';
import { ScrollToTop } from './ScrollToTop';
import { Shopping } from '../../features/shopping';
import { Pesonal } from '../../features/personal';
import { StartWithCode } from '../../features/code';
import { StartWithCard } from '../../features/card';
import { StartWithTablet } from '../../features/tablet';
import { Terms } from '../../features/terms';
import { AboutCard } from '../../features/aboutCard';
import { Banner } from '../../features/banners';
import { PartnerDiscounts } from '../../features/partnerDiscounts';
import { Activate } from '../../features/activate';
import { AppState } from '../../common/models/AppState';
import { fetchConfigActions, fetchSeasonsAction, selectEshopConfig } from '../../features/shopping/ducks';
import { Layout } from '../../common/components';
import { i18n } from '../../common/services/i18n';
import { getLogo } from '../../common/utils/project-helpers';
import { environment } from '../../environments/environment';
import { RegistrationRedirect } from '../../features/registrationRedirect';

interface DispatchToProps {
    fetchConfig(): void;
    fetchSeasons(): void;
}

interface StateToProps {
    config: Config | null;
}

export const ConfigWrapperComponent: FunctionComponent<StateToProps & DispatchToProps> = ({
    config,
    fetchConfig,
    fetchSeasons,
}) => {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized) {
            fetchConfig();
            fetchSeasons && fetchSeasons();
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        const timeout = setInterval(() => fetchConfig(), 120000);

        return () => {
            clearInterval(timeout);
        };
    }, []);

    return (
        <>
            {!config && <div>Loading</div>}
            {config && config.enabled ? (
                <ConnectedRouter history={history}>
                    <ScrollToTop>
                        <Switch>
                            <Route path="/shopping" component={Shopping} />
                            <Route path="/personal" component={Pesonal} />
                            {environment.config.paths.kod &&
                                <Route path="/kod" component={StartWithCode}/>
                            }
                            {environment.config.paths.karta &&
                                <Route path="/karta" component={StartWithCard} />
                            }
                            {environment.config.paths.tablet &&
                                <Route path="/tablet" component={StartWithTablet} />
                            }
                            <Route path="/terms" component={Terms} />
                            {environment.config.aboutCard && (
                                <Route path="/aboutCard" component={AboutCard} />
                            )}
                            <Route path="/banners/:slug" component={Banner} />
                            <Route path="/discounts/partner" component={PartnerDiscounts} />
                            <Route path="/users/activation" component={Activate} />
                            {environment.config.paths.registerRedirect &&
                                <Route
                                    path={`/${environment.config.paths.registerRedirect}`}
                                    component={RegistrationRedirect}
                                />
                            }
                            <Redirect path="/" to="/shopping" />
                        </Switch>
                    </ScrollToTop>
                </ConnectedRouter>
            ) : (
                <Layout>
                    <Layout.Header>
                        <Layout.Container fullHeight>
                            <div className="header-step">
                                <div className="header-step__middle">
                                    <img className="logo" src={getLogo()} alt="logo" />
                                </div>
                            </div>
                        </Layout.Container>
                    </Layout.Header>
                    <Layout.Container className="banners-container">
                        <h3>{i18n.t('disabledEshop.title.cz')} </h3>
                        <h3>{i18n.t('disabledEshop.title.en')} </h3>
                        <h3>{i18n.t('disabledEshop.title.de')} </h3>
                    </Layout.Container>
                </Layout>
            )}
        </>
    );
};

const mapStateToProps = (state: AppState): StateToProps => ({
    config: selectEshopConfig(state),
});

const mapDispatchToProps: DispatchToProps = {
    fetchConfig: fetchConfigActions.request,
    fetchSeasons: fetchSeasonsAction.request,
};

export const ConfigWrapper = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfigWrapperComponent);
