import React, { Fragment, ReactNode } from 'react';
import { Trans } from 'react-i18next';

import { FadedImage, Layout } from '../../components';

interface Props {
    img?: string | ReactNode;
    children?: ReactNode;
    overlay?: ReactNode;
}

export default ({ children, img, overlay }: Props) => {
    function renderOverlay() {
        return overlay ? (
            overlay
        ) : (
            <Fragment>
                <h1>
                    <Trans i18nKey="home.hero.heading" />
                </h1>
                <p>
                    <Trans i18nKey="home.hero.description" />
                </p>
            </Fragment>
        );
    }
    return (
        <React.Fragment>
            <FadedImage img={img ? img : ''}>
                <div className="hero">
                    <Layout.Container>{renderOverlay()}</Layout.Container>
                </div>
            </FadedImage>
            <Layout.Container>
                <div className="hero-content">{children}</div>
            </Layout.Container>
        </React.Fragment>
    );
};
