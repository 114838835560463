import { Row } from 'antd';
import React, { ReactNode } from 'react';

import { Layout } from '../../../../common/components';

interface Props {
    children?: ReactNode;
    title?: ReactNode;
}

export default ({ children, title }: Props) => {
    return (
        <Layout.Container className="featured-container">
            <Row>
                <h2>{title}</h2>
            </Row>
            {children}
        </Layout.Container>
    );
};
