import moment, { Moment } from 'moment';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import { oc } from 'ts-optchain';

import TimeSlotPicker from '../TimeSlotPicker';
import { i18n } from '../../../../../../../../common/services/i18n';
import { isProductAndSelectionValidAtDate } from '../../../../../../../../common/utils/visibility-helpers';
import { Product } from '../../../../../../../../common/models/Product';
import { VariantsSelection } from '../VariantsDropdown';

import 'react-multi-carousel/lib/styles.css';
import {
    getTimeSlotOptionsActions,
    selectTimeSlotTranslations,
    TimeSlotTranslation,
} from '../../../../../../ducks';
import { AppState } from '../../../../../../../../common/models/AppState';
import LocalizedString from '../../../../../../../../common/components/LocalizedString';
import DateSelectCarousel from '../DateSelectCarousel/DateSelectCarousel';

interface Props {
    product?: Product;
    selectedVariants: VariantsSelection[];
    arrival: string;
    time: string;
    variantCount: number;
    timeSlotGroup: string;
    small?: boolean;
    hidePrices?: boolean;
    onlyDateRow?: boolean;

    setDate(date: Moment): void;
    setTime(time): void;
    setTimeSlotType?: (type: string) => void;
}

function TimeCarousel(props: Props & DispatchToProps & StateToProps) {

    useEffect(() => {
        if (props.arrival && props.timeSlotGroup) {
            props.getTimeSlotTimes({
                date: moment(props.arrival).format(),
                groupId: props.timeSlotGroup,
                days: getProductValidityAmount(),
            });
        }
    }, [props.arrival, props.timeSlotGroup]);

    const hasDateOptions = useMemo(
        () => {
            const d = moment().subtract(1, 'days');

            while (d.add(1, 'days').isSameOrBefore(moment(oc(props).product.validity.to()))) {
                if (
                    isProductAndSelectionValidAtDate(
                        props.product,
                        props.selectedVariants.filter(vs => vs.count > 0).map(vs => vs.id),
                        d
                    )
                ) {
                    return true;
                }
            }

            return false;
        },
        [props.arrival, props.product, props.selectedVariants],
    );

    const checkSelectionDateValid = () => {
        return isProductAndSelectionValidAtDate(
            props.product,
            props.selectedVariants.filter(v => v.count).map(v => v.id),
            props.arrival === '' ? undefined : props.arrival,
        );
    };

    const getProductValidityAmount = () => {
        return oc(props.product).meta.tokenValidityUnit() === 'days'
            ? Number(oc(props.product).meta.tokenValidityAmount('1'))
            : 1;
    };

    return (
        <Row className={`info-row ${props.small ? 'info-row--small' : ''}`} type="flex" align="bottom">
            {!props.onlyDateRow && (
                <Col xs={24}>
                    {props.translations.title && (
                        <p className="info-row__text--top">
                            <LocalizedString html={props.translations.title} />
                        </p>
                    )}
                    {!props.translations.title && (
                        <p
                            className="info-row__text--top"
                            dangerouslySetInnerHTML={{__html: i18n.t('timeSlots.textBefore')}}
                        />
                    )}
                </Col>
            )}
            {hasDateOptions &&
                <Col xs={24}>
                    <DateSelectCarousel
                        validFrom={props.arrival}
                        product={props.product}
                        selectedVariants={props.selectedVariants}
                        small={props.small}
                        hidePrices={props.hidePrices}
                        onDateChange={props.setDate}
                    />
                    {!props.onlyDateRow && (
                        <TimeSlotPicker
                            arrival={props.arrival}
                            time={props.time}
                            count={props.variantCount}
                            setTime={props.setTime}
                            isDateValid={checkSelectionDateValid()}
                            setTimeSlotType={props.setTimeSlotType}
                        />
                    )}
                </Col>
            }
            {!hasDateOptions &&
                <Col xs={24}>
                    <div className="time-picker__empty">
                        {i18n.t('timeSlots.timesNotAvailableForSelection')}
                    </div>
                </Col>
            }
            {!props.onlyDateRow && (
                <Col xs={24}>
                    {props.translations.description && (
                        <p className="info-row__text--bottom">
                            <LocalizedString html={props.translations.description} />
                        </p>
                    )}
                    {!props.translations.description && (
                        <p
                            className="info-row__text--bottom"
                            dangerouslySetInnerHTML={{__html: i18n.t('timeSlots.textAfter')}}
                        />
                    )}
                </Col>
            )}
        </Row>
    );
}

interface DispatchToProps {
    getTimeSlotTimes: (payload: { date: string, groupId: string, days: number }) => void;
}

const mapDispatchToProps: DispatchToProps = {
    getTimeSlotTimes: getTimeSlotOptionsActions.request,
};

interface StateToProps {
    translations: TimeSlotTranslation;
}

const mapStateToProps = (state: AppState): StateToProps => ({
    translations: selectTimeSlotTranslations(state),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TimeCarousel);
